/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
import './ServerDetails.css';

import { Button, Grid, InputField, Typography } from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MIN_PORT_LENGTH, MIN_TARGET_LENGTH } from '../../constants';

function ServerDetails({
  appLauncherSelection,
  setServerPortValue,
  setServerTargetValue,
  setIsExpandedServerDetails,
}) {
  const [tempTargetValue, setTempTargetValue] = useState('');
  const [tempPortValue, setTempPortValue] = useState('3389');

  const { Item } = Grid;

  useEffect(() => {
    if (appLauncherSelection) {
      setIsExpandedServerDetails(true);
    }
  }, [appLauncherSelection]);

  const continueButtonOnClick = () => {
    setServerTargetValue(tempTargetValue);
    setServerPortValue(tempPortValue);
    setIsExpandedServerDetails(false);
  };

  const renderContinueButton =
    tempTargetValue?.length >= MIN_TARGET_LENGTH &&
    tempPortValue?.length >= MIN_PORT_LENGTH;

  return (
    <Grid className='ServerDetails' spacing={2}>
      <Item className='ServerDetails-inputField' desktop={6} tablet={6}>
        <InputField
          name='targetServer'
          placeholder='Enter the name or IP address of the remote system'
          label={
            <Typography kind='label' htmlFor='targetServer'>
              Enter the name or IP address of the remote system
            </Typography>
          }
          backgroundColor='secondary'
          value={tempTargetValue}
          setValue={setTempTargetValue}
          isRequired
          // TODO: update minRequiredChars based on acceptence criteria
          minRequiredChars={5}
        />
      </Item>
      <Item className='ServerDetails-inputField' desktop={6} tablet={6}>
        <InputField
          name='serverPortNumber'
          // TODO: Need to figure out PH port default value and if required
          placeholder='Enter the Port Number'
          label={
            <Typography kind='label' htmlFor='serverPortNumber'>
              Port Number
            </Typography>
          }
          backgroundColor='secondary'
          value={tempPortValue}
          setValue={setTempPortValue}
        />
      </Item>
      {renderContinueButton && (
        <Item className='ServerDetails-continueButton' desktop={12} tablet={12}>
          <Button
            type='button'
            kind='filled'
            onClick={() => continueButtonOnClick()}>
            Continue
          </Button>
        </Item>
      )}
    </Grid>
  );
}

ServerDetails.propTypes = {
  // TODO: update these next prop to have const
  appLauncherSelection: PropTypes.objectOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  serverPortValue: PropTypes.string,
  serverTargetValue: PropTypes.string,
  setServerPortValue: PropTypes.func.isRequired,
  setServerTargetValue: PropTypes.func.isRequired,
  setIsExpandedServerDetails: PropTypes.func.isRequired,
};

ServerDetails.defaultProps = {
  appLauncherSelection: null,
  serverPortValue: null,
  serverTargetValue: null,
};

export default ServerDetails;
