import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './MenuItem.css';

function MenuItem({ option, selectedValue, onClick, className, tabIndex }) {
  const handleOptionClick = (event) => {
    // prevent the default behavior and event bubbling.
    event.preventDefault();
    event.stopPropagation();

    if (option?.isDisabled) return;
    onClick(event.target);
  };

  return (
    <li
      key={option.value}
      onClick={handleOptionClick}
      className={classnames(
        'MenuItem',
        option?.isDisabled && 'MenuItem--isDisabled',
        option?.isChecked && 'MenuItem--isChecked',
        className
      )}
      role='option'
      aria-selected={selectedValue && selectedValue.value === option.value}
      tabIndex={option?.isDisabled ? -1 : tabIndex}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleOptionClick(e);
        }
      }}>
      {option.label}
    </li>
  );
}

MenuItem.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    isChecked: PropTypes.bool,
  }).isRequired,
  selectedValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

MenuItem.defaultProps = {
  selectedValue: null,
  className: null,
  tabIndex: -1,
};

export default MenuItem;
