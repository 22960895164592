import {
  LIST_ACCOUNT_REMOTE_METADATA,
  LIST_EXCLUSIVE_ACCESS,
  LIST_MAX_CREDLESS_TIME,
  listAPIListReceivedActionType,
  listRowSelectedActionType,
} from '../actions/privilegedAccessAction';

const initialState = {
  ListRowItems: {},
  SelectedListDetails: {},
  SelectedListAccountRemoteAppMetadata: null,
  SelectedListIsExclusiveAccess: false,
  // TODO: Make sure from AC that this should be 10 hours
  SelectedListMaxCredlessSessionRequestTime: '36000',
};

export const privilegedAccessReducer = (state = initialState, action) => {
  switch (action.type) {
    case listAPIListReceivedActionType: {
      return {
        ...state,
        ListRowItems: action.payload,
      };
    }

    case listRowSelectedActionType: {
      return {
        ...state,
        SelectedListDetails: action.payload,
      };
    }

    case LIST_ACCOUNT_REMOTE_METADATA: {
      return {
        ...state,
        SelectedListAccountRemoteAppMetadata: action.payload,
      };
    }

    case LIST_EXCLUSIVE_ACCESS: {
      return {
        ...state,
        SelectedListIsExclusiveAccess: action.payload,
      };
    }

    case LIST_MAX_CREDLESS_TIME: {
      return {
        ...state,
        SelectedListMaxCredlessSessionRequestTime: action.payload,
      };
    }

    default:
      return state;
  }
};

export default privilegedAccessReducer;
