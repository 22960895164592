import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Cookies } from 'react-cookie';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TooltipComponent } from '@saviynt/design-system';
import styles from './Header.module.css';
import Logo from '../Logo/Logo';
import { fullScreenRoutes, nonFullscreenGriv2routes } from '../../Constants';
import Profile from '../Profile/Profile';
import HelperMenu from '../HelperMenu';
import MenuHamburger from '../../ECMv6/assets/MenuHamburger';
import { Button, Icon, ModalIsland, Tooltip } from '@saviynt/design-system';
import { ShowLoader } from 'ui-common/src/utils/Loading-actions';
import { fetchParallelEnv } from 'ui-home/src/actions/ParallelEnvAction';

const cookies = new Cookies();
const COOKIE_AGE = 24 * 60 * 60 * 180;
const UPGRADE_COOKIE = 'redirect-update-to-version';
const UPGRADE_SAVROLE = 'ROLE_SAV_UPGRADE_EXPERIENCE';
const NEW_ENV_PREFERRED = 'always';
const parallelEnvUpgradeProcessStates = {
  NEW_ENV_AVAILABLE: 'UPGRADE_READY',
  ONLY_OLD_ENV_AVAILABLE: 'UPGRADE_ROLLBACK',
  ONLY_NEW_ENV_AVAILABLE: 'UPGRADE_ROLLOVER',
  NOT_CONFIGURED: 'NONE'
}


const Header = ({
  classes,
  isSideMenuAvailable,
  isWhite,
  intl,
  isVisible,
  expandSideMenu,
  handleMobileDrawerClick,
}) => {
  const dispatch = useDispatch();
  const roles = useSelector((state) => state?.user?.userInfo?.roles);
  const parallelEnv = useSelector((state) => state?.parallelEnv);
  const IsMobileDevice = useMediaQuery('(max-width:480px)');
  const { username, helpDetails } = useSelector((state) => state.profile);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showDowngradeModal, setshowDowngradeModal] = useState(false);
  const [chatBot, setChatBot] = useState(false);
  const [hasUpgradeAccess, setHasUpgradeAccess] = useState(false);

  let tabIndex = 0;
  const isMaintenancePages = /maintenance\/securityQuestions/gm.test(window.location.href);

  if (
    fullScreenRoutes
      .map((r) =>
        matchPath(window.location.pathname, {
          path: r,
          exact: true,
          strict: true,
        })
      )
      .filter((x) => !!x).length > 0
  ) {
    tabIndex = -1;
  } else if (
    nonFullscreenGriv2routes
      .map((r) =>
        matchPath(window.location.pathname, {
          path: r,
          exact: true,
          strict: true,
        })
      )
      .filter((x) => !!x).length > 0
  ) {
    tabIndex = 1;
  }

  const chatBotsOpen = () => {
    setChatBot(!chatBot);
  };

  const handleUpgrade = () => {
    setShowUpgradeModal(false);
    dispatch(ShowLoader());
    cookies.set(UPGRADE_COOKIE, NEW_ENV_PREFERRED, {
      path: '/',
      maxAge: COOKIE_AGE,
      secure: true,
    });
    window.location.reload();
  };

  const handleDowngrade = () => {
    setshowDowngradeModal(false);
    dispatch(ShowLoader());
    cookies.remove(UPGRADE_COOKIE, {
      path: '/',
      secure: true,
    });
    window.location.reload();
  };

  const clearPersistentCookie = () => {
    cookies.remove(UPGRADE_COOKIE, {
      path: '/',
      secure: true,
    });
  };

  const createPersistentCookie = () => {
    if (
      cookies.get(UPGRADE_COOKIE)
    ) {
      cookies.set(UPGRADE_COOKIE, NEW_ENV_PREFERRED, {
        path: '/',
        maxAge: COOKIE_AGE,
        secure: true,
      });
    }
  };

  useEffect(() => {
    if (roles?.includes(UPGRADE_SAVROLE)) {
      dispatch(fetchParallelEnv());
    } else {
      setHasUpgradeAccess(false);
      clearPersistentCookie();
    }
  }, [roles]);

  useEffect(() => {
    if (parallelEnv.data) {
      if (parallelEnv.data.upgrade_status === parallelEnvUpgradeProcessStates.NOT_CONFIGURED) {
        clearPersistentCookie();
        setHasUpgradeAccess(false);
        return;
      }
      if (
        parallelEnv.data.upgrade_status === parallelEnvUpgradeProcessStates.ONLY_NEW_ENV_AVAILABLE ||
        parallelEnv.data.upgrade_status === parallelEnvUpgradeProcessStates.ONLY_OLD_ENV_AVAILABLE
      ) {
        setHasUpgradeAccess(false);
        return;
      }
      if (parallelEnv.data.upgrade_status === parallelEnvUpgradeProcessStates.NEW_ENV_AVAILABLE) {
        createPersistentCookie();
        setHasUpgradeAccess(true);
      }
    }
  }, [parallelEnv.data]);

  if (isVisible) {
    return (
      <>
        <AppBar
          position="fixed"
          className={IsMobileDevice ? styles.mobileAppBar : styles.appBar}
          color="inherit"
        >
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" onClick={handleMobileDrawerClick}>
              <MenuHamburger />
            </IconButton>
            {!expandSideMenu && !IsMobileDevice && (
              <Logo isSideMenuAvailable={isSideMenuAvailable} isWhite={isWhite} />
            )}
            <div className={styles.container}>
              <div style={{ marginLeft: '20px' }}>
                {hasUpgradeAccess &&
                  (cookies.get(UPGRADE_COOKIE) === NEW_ENV_PREFERRED ? (
                    <Tooltip
                      //text="Switch back to the previous version of the platform."
                      text={intl.formatMessage({ id: 'HOME.PARALLEL.DOWNGRADE.BUTTON.TOOLTIP.LABEL' })}
                      position="bottom"
                    >
                      <Button
                        type="button"
                        kind="subtle"
                        size="medium"
                        leftIcon={<Icon kind="ArrowLeft" size="medium" color={'neutral-100'} />}
                        onClick={() => setshowDowngradeModal(true)}
                      >
                        {intl.formatMessage({ id: 'HOME.PARALLEL.BACKVERSION.BUTTON.LABEL' })}
                        {/* Back to Previous Version */}
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      text={intl.formatMessage({ id: 'HOME.PARALLEL.NEWVERSION.BUTTON.TOOLTIP.LABEL' })}
                      // text="Switch your interface and features to the new version"
                      position="bottom"
                    >
                      <Button
                        type="button"
                        kind="subtle"
                        size="medium"
                        rightIcon={<Icon kind="ArrowRight" size="medium" color={'neutral-100'} />}
                        onClick={() => setShowUpgradeModal(true)}
                      >
                        {intl.formatMessage({ id: 'HOME.PARALLEL.NEWVERSION.BUTTON.LABEL' })}
                        {/* New Version Available */}
                      </Button>
                    </Tooltip>
                  ))}
              </div>
              <div className={styles.profileMainContainer}>
                {(helpDetails.isHelpEnabled || helpDetails.isDocEnabled) && <HelperMenu />}
                <div className={styles.profileContainer}>
                  <Profile />
                  {!IsMobileDevice &&
                    (username && username.length > 25 ? (
                      <TooltipComponent
                        delay={250}
                        position="left"
                        text={username}
                        className={styles.headerUsernameTooltip}
                        maxWidth="none"
                      >
                        <div className={styles.navUsername}>{username}</div>
                      </TooltipComponent>
                    ) : (
                      <div className={styles.navUsername}>{username}</div>
                    ))}
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <ModalIsland
          kind="customAlert"
          customAlertIcon={<Icon kind="Saviynt" className="ModalIsland-alertContent-icon" />}
          alertBackgroundClassName="CustomAlertBackGround"
          alertTitle={intl.formatMessage({ id: 'HOME.PARALLEL.MODAL.TITLE.LABEL' })}
          // alertTitle="New Version Available"
          alertSubtitle={intl.formatMessage({ id: 'HOME.PARALLEL.MODAL.SUBTITLE.LABEL' })}
          // alertSubtitle="Experience new interface updates and feature enhancements. You can see what’s new in release notes, and you can go back to the previous version at any time."
          onClose={() => setShowUpgradeModal(false)}
          isOpen={showUpgradeModal}
          primaryButton={
            <Button type="button" kind="filled" size="medium" onClick={handleUpgrade}>
              {intl.formatMessage({ id: 'HOME.PARALLEL.GOTONEWVERSION.BUTTON.LABEL' })}
              {/* Go to New Version */}
            </Button>
          }
          secondaryButton={
            <Button
              type="button"
              kind="ghost"
              size="medium"
              onClick={() => setShowUpgradeModal(false)}
            >
              {intl.formatMessage({ id: 'HOME.PARALLEL.CANCEL.BUTTON.LABEL' })}
              {/* Cancel */}
            </Button>
          }
        />
        <ModalIsland
          kind="customAlert"
          customAlertIcon={<Icon kind="Saviynt" className="ModalIsland-alertContent-icon" />}
          alertBackgroundClassName="CustomAlertBackGround"
          alertTitle={intl.formatMessage({ id: 'HOME.PARALLEL.DOWNGRADE.POPUP.TITLE' })}
          // alertTitle="Switch Back?"
          alertSubtitle={intl.formatMessage({ id: 'HOME.PARALLEL.DOWNGRADE.POPUP.SUBTITLE' })}
          // alertSubtitle="Want to go to the previous version of the platform? You can return to the new version at any time."
          onClose={() => setshowDowngradeModal(false)}
          isOpen={showDowngradeModal}
          primaryButton={
            <Button type="button" kind="filled" size="medium" onClick={handleDowngrade}>
              {intl.formatMessage({ id: 'HOME.PARALLEL.DOWNGRADE.POPUP.BUTTON.LABEL' })}
              {/* Switch to Previous Version */}
            </Button>
          }
          secondaryButton={
            <Button
              type="button"
              kind="ghost"
              size="medium"
              onClick={() => setshowDowngradeModal(false)}
            >
              {intl.formatMessage({ id: 'HOME.PARALLEL.CANCEL.BUTTON.LABEL' })}
              {/* Cancel */}
            </Button>
          }
        />
      </>
    );
  }

  return null;
};

export default injectIntl(Header);
