import { combineReducers } from 'redux';
import { logoutActionType } from 'ui-common/src/utils/LoginActions';
import { defaultTheme } from '../views/UIBrandConfiguration/ConfigurationTypes/defaultTheme';

import { MessageLabelReducer } from '../views/LabelChangeManagement/reducer';

import {
  AdminHomeLoaded,
  FEATURE_LIST_SUCCESS,
  VIEW_PREVIEW_THEME,
  SHOWV_PREVIEW_MODAL,
  FETCH_THEME_CONFIG_LIST_SUCCESS,
  FETCH_THEME_CONFIG_LIST_FAILURE,
  REQUEST_MANAGE_LOGS_LIST,
  REQUEST_MANAGE_LOGS_LIST_SUCCESS,
  REQUEST_MANAGE_LOGS_LIST_FAILURE,
  GET_ALL_THEMES,
  GET_ALL_LOGOS_SUCCESS,
  GET_ALL_LOGOS_FAILURE,
  GET_ALL_THEMES_SUCCESS,
  GET_ALL_THEMES_FAILURE,
  GET_ACTIVE_THEME,
  CREATE_THEME,
  CREATE_THEME_SUCCESS,
  CREATE_THEME_FAILURE,
  GET_ACTIVE_THEME_FAILURE,
  GET_ACTIVE_THEME_SUCCESS,
  ACTIVATE_THEME,
  ACTIVATE_THEME_SUCCESS,
  ACTIVATE_THEME_FALIURE,
  UPDATE_THEME,
  UPDATE_THEME_SUCCESS,
  UPDATE_THEME_FAILURE,
  GET_LOGO_SUCCESS,
  GET_LOGO_FAILURE,
  GET_LOGO,
  GET_LOGO_TO_SHOW_SUCCESS,
  GET_LOGO_TO_SHOW_FAILURE,
  GET_ACTIVE_LOGO_SUCCESS,
  GET_ACTIVE_LOGO_FAILURE,
  GET_ACTIVE_HEADER_LOGO_SUCCESS,
  GET_ACTIVE_HEADER_LOGO_FAILURE,
  DELETE_THEME,
  DELETE_THEME_SUCCESS,
  DELETE_THEME_FAILURE,
  DELETE_LOGO_SUCCESS,
  DELETE_LOGO_FAILURE,
  GET_FAVICON_SUCCESS,
  GET_FAVICON_FAILURE,
  UPLOAD_LOGO,
  UPLOAD_LOGO_SUCCESS,
  UPLOAD_LOGO_FAILURE,
  UPLOAD_FAVICON_SUCCESS,
  UPLOAD_FAVICON_FAILURE,
  GET_ACTIVE_FAVICON_SUCCESS,
  GET_ACTIVE_FAVICON_FAILURE,
  GET_ACTIVE_LOGIN_LOGOUT_SUCCESS,
  GET_ACTIVE_LOGIN_LOGOUT_FAILURE,
} from './adminHome-actions';
import { defaultGen2Theme } from '../views/BrandingConfig/utils/constants';

export const adminHomeReducer = (adminHomeState = {}, action) => {
  switch (action.type) {
    case AdminHomeLoaded: {
      return { ...adminHomeState, loadedMsg: action.payload.message };
    }

    default: {
      return adminHomeState;
    }
  }
};

const featureListReducer = (featureListState = [], action) => {
  switch (action.type) {
    case FEATURE_LIST_SUCCESS: {
      return action.payload;
    }
    default: {
      return featureListState;
    }
  }
};

const fetchSavedThemefromLocalStorage = () => {
  if (JSON.parse(localStorage.getItem('savedTheme'))) {
    const updateTheme = { ...defaultTheme, ...JSON.parse(localStorage.getItem('savedTheme')) };
    return JSON.stringify({
      ...updateTheme,
    });
  }
  return JSON.stringify({
    ...defaultTheme,
  });
};

const checkforThemeinLocalStorage = () => {
  if (
    JSON.parse(localStorage.getItem('uiConfigTheme')) &&
    JSON.parse(localStorage.getItem('uiConfigTheme')).previewTheme
  ) {
    return JSON.parse(localStorage.getItem('uiConfigTheme')).previewTheme;
  }
  return fetchSavedThemefromLocalStorage();
};

const initialUIConfigtate = {
  previewTheme: checkforThemeinLocalStorage(),
  showPreviewTheme: false,
  defaultThemeConfig: {},
};

const UIConfigReducer = (state = initialUIConfigtate, action) => {
  switch (action.type) {
    case VIEW_PREVIEW_THEME: {
      const serializedUIConfigState = JSON.stringify({
        previewTheme: action.themeObj,
      });

      const uiConfigThemeModal = JSON.stringify({
        showPreviewTheme: true,
      });

      localStorage.setItem('uiConfigTheme', serializedUIConfigState);
      localStorage.setItem('uiConfigThemeModal', uiConfigThemeModal);

      return {
        ...state,
        previewTheme: action.themeObj,
        showPreviewTheme: true,
      };
    }

    case SHOWV_PREVIEW_MODAL: {
      return {
        ...state,
        showPreviewTheme: action.show,
      };
    }
    case FETCH_THEME_CONFIG_LIST_SUCCESS: {
      const updateTheme = { ...defaultTheme, ...action.response };
      return {
        ...state,
        defaultThemeConfig: updateTheme,
      };
    }
    case FETCH_THEME_CONFIG_LIST_FAILURE: {
      return {
        ...state,
        defaultThemeConfig: defaultTheme,
      };
    }
    case logoutActionType: {
      return {
        ...state,
        previewTheme: {},
        showPreviewTheme: false,
        defaultThemeConfig: {},
      };
    }
    // case '@@Internal/ModuleManager/ModuleAdded': {
    //   if (action.payload === 'adminHome') {
    //     const storedAppReqInitialState = loadUIConfigFromStorage('uiConfig')
    //       ? loadUIConfigFromStorage('uiConfig').uiConfig
    //       : undefined;
    //     const initialState = storedAppReqInitialState || initialUIConfigtate;
    //     return initialState;
    //   }
    //   return initialUIConfigtate;
    // }
    default: {
      return initialUIConfigtate;
    }
  }
};

const initialLogstate = {
  managelogsList: [],
  noRecordsfound: false,
  error: false,
  preLoading: true,
};

const logsListReducer = (state = initialLogstate, action) => {
  switch (action.type) {
    case REQUEST_MANAGE_LOGS_LIST: {
      return {
        ...state,
        managelogsList: [],
        noRecordsfound: false,
        error: false,
        preLoading: true,
      };
    }
    case REQUEST_MANAGE_LOGS_LIST_SUCCESS: {
      return {
        ...state,
        managelogsList: action.response,
        noRecordsfound: !action.response.length > 0,
        preLoading: false,
      };
    }
    case REQUEST_MANAGE_LOGS_LIST_FAILURE: {
      return {
        ...state,
        managelogsList: [],
        error: true,
        preLoading: false,
      };
    }
    default: {
      return initialLogstate;
    }
  }
};

const initialBrandingConfigState = {
  themes: [],
  logos: [],
  activeLogo: {},
  logoToShow: {},
  favIcon: '',
  loginLogoutLogo: '',
  favIconFileName: '',
  uploadLogoSuccess: false,
};

const BrandingConfigReducer = (state = initialBrandingConfigState, action) => {
  switch (action.type) {
    case GET_ALL_THEMES:
      return { ...state, themes: [] };
    case GET_ALL_LOGOS_SUCCESS:
      return { ...state, logos: action.payload };
    case GET_ALL_LOGOS_FAILURE:
      return { ...state, logos: action.payload };
    case GET_ALL_THEMES_SUCCESS:
      return { ...state, themes: action.response };
    case GET_ALL_THEMES_FAILURE:
      return { ...state, themes: [] };

    case CREATE_THEME:
      return {
        ...state,
        createdTheme: {},
      };
    case CREATE_THEME_SUCCESS: {
      return {
        ...state,
        themes: [...state.themes, { ...action.response }],
        createdTheme: action.response,
      };
    }
    case CREATE_THEME_FAILURE:
      return {
        ...state,
        createdTheme: action.error,
      };

    case GET_ACTIVE_THEME:
      return {
        ...state,
        activeTheme: {},
      };
    case GET_ACTIVE_THEME_SUCCESS: {
      return {
        ...state,
        activeTheme: action.response,
      };
    }
    case GET_ACTIVE_THEME_FAILURE:
      return {
        ...state,
        activeTheme: defaultGen2Theme,
      };

    case ACTIVATE_THEME:
      return {
        ...state,
        activatedTheme: {},
      };
    case ACTIVATE_THEME_SUCCESS: {
      return {
        ...state,
        activeTheme: action.response,
        activatedTheme: action.response,
      };
    }
    case ACTIVATE_THEME_FALIURE:
      return {
        ...state,
        activatedTheme: action.error,
      };

    case UPDATE_THEME:
      return {
        ...state,
        updatedTheme: {},
      };

    case UPDATE_THEME_SUCCESS: {
      let newThemes = [...state.themes];
      newThemes = newThemes.filter(
        (theme) =>
          theme?.name !== action?.response?.name ||
          (theme?.name === action?.response?.name &&
            theme?.appearance !== action?.response?.appearance)
      );
      return {
        ...state,
        themes: [...newThemes, { ...action.response }],
        updatedTheme: action.response,
        ...(action?.isThemeActive && { activeTheme: action.response }),
      };
    }

    case UPDATE_THEME_FAILURE:
      return {
        ...state,
        updatedTheme: action.error,
      };

    // case GET_LOGO:
    //   return {
    //     ...state,
    //     navLogo: '',
    //     getNavLogoError: '',
    //   };
    // case GET_LOGO_SUCCESS: {
    //   return {
    //     ...state,
    //     navLogo: action.response,
    //     getNavLogoError: '',
    //   };
    // }
    // case GET_LOGO_FAILURE:
    //   return {
    //     ...state,
    //     navLogo: '',
    //     getNavLogoError: action.error,
    //   };
    case GET_ACTIVE_LOGO_SUCCESS: {
      return {
        ...state,
        navLogo: action.response,
        navLogoError: '',
      };
    }
    case GET_ACTIVE_LOGO_FAILURE: {
      return {
        ...state,
        navLogo: '',
        navLogoError: action.error,
      };
    }
    case GET_ACTIVE_HEADER_LOGO_SUCCESS: {
      return {
        ...state,
        headerLogo: action.response,
        headerLogoError: '',
      };
    }
    case GET_ACTIVE_FAVICON_SUCCESS: {
      document.getElementById('favicon').setAttribute('href', action.response);
      return {
        ...state,
        favIcon: action.response,
        favIconError: '',
      };
    }
    case GET_ACTIVE_FAVICON_FAILURE: {
      // document.getElementById("favicon").setAttribute("href", window.location.origin + "/ECMv6/api/idw/resource/image/app/title.png");
      return {
        ...state,
        favIcon: '',
        favIconError: 'No custom favicon',
      };
    }
    case GET_ACTIVE_LOGIN_LOGOUT_SUCCESS: {
      return {
        ...state,
        loginLogoutLogo: action?.payload?.loginLogoutLogoName,
        loginLogoutLogoError: '',
      };
    }
    case GET_ACTIVE_LOGIN_LOGOUT_FAILURE: {
      return {
        ...state,
        loginLogoutLogo: '',
        loginLogoutLogoError: 'No custom Login Logout Logo',
      };
    }
    case GET_ACTIVE_HEADER_LOGO_FAILURE: {
      return {
        ...state,
        headerLogo: '',
        headerLogoError: action.error,
      };
    }
    case GET_LOGO_TO_SHOW_SUCCESS: {
      return {
        ...state,
        logoToShow: { ...state.logoToShow, [action.response.name]: action.response.json },
      };
    }
    case GET_FAVICON_SUCCESS: {
      return {
        ...state,
        favIconFileName: action.payload?.fileName,
      };
    }
    case GET_FAVICON_FAILURE: {
      return {
        ...state,
        favIcon: action.error,
      };
    }

    case GET_LOGO_TO_SHOW_FAILURE: {
      return {
        ...state,
        logoToShowError: action.error,
      };
    }

    case DELETE_THEME:
      return {
        ...state,
        deletedTheme: '',
        deletedThemeError: '',
      };

    case DELETE_THEME_SUCCESS: {
      let newThemes = [...state.themes];
      newThemes = newThemes.filter((theme) => theme?.name !== action?.response?.name);
      return {
        ...state,
        themes: [...newThemes],
        deletedTheme: action?.response?.name,
        deletedThemeError: '',
      };
    }

    case DELETE_LOGO_SUCCESS: {
      let newLogos = [...state.logos];
      newLogos = newLogos.filter((logo) => logo?.name !== action?.response?.name);
      return {
        ...state,
        logos: [...newLogos],
        deleteLogoSuccessMsg: action?.response?.message,
        deleteLogoSuccess: true,
      };
    }

    case UPLOAD_LOGO: {
      return {
        ...state,
        uploadLogoSuccess: false,
      };
    }

    case UPLOAD_LOGO_SUCCESS: {
      let newLogo = action?.payload?.logoName;
      let updatedLogos = [
        ...state?.logos,
        {
          isOOTB: false,
          name: newLogo,
        },
      ].filter((el, i, arr) => i === arr.findIndex((logo) => logo.name === el.name));
      return {
        ...state,
        logos: [...updatedLogos],
        uploadLogoSuccess: action?.payload?.success,
      };
    }

    case UPLOAD_FAVICON_SUCCESS: {
      return {
        ...state,
        favIcon: action?.payload?.favIconName,
      };
    }

    case UPLOAD_LOGO_FAILURE: {
      return {
        ...state,
        uploadLogoFailure: action?.payload,
      };
    }

    case UPLOAD_FAVICON_FAILURE: {
      return {
        ...state,
        uploadFavIconFailure: action?.payload,
      };
    }

    case DELETE_THEME_FAILURE:
      return {
        ...state,
        deletedThemeError: action.error,
      };

    case DELETE_LOGO_FAILURE:
      return {
        ...state,
        deletedLogoError: action.error,
        deleteLogoSuccess: false,
      };

    default:
      return state;
  }
};

const rootReducer = combineReducers({
  initialLoad: adminHomeReducer,
  featureList: featureListReducer,
  uiConfig: UIConfigReducer,
  messageProps: MessageLabelReducer,
  logs: logsListReducer,
  brandingConfig: BrandingConfigReducer,
});
export default rootReducer;
