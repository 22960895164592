/* eslint-disable no-console */
import { put, call, takeLatest, putResolve, all } from 'redux-saga/effects';
import { push } from 'react-router-redirect';
import { mapValues } from 'lodash';
import { Cookies } from 'react-cookie';
import { checkEnvironment, ecmLogin, initialiseEnv } from 'ui-common/src/lib/utils';
import SessionStorageService from 'ui-common/src/utils/SessionStorageService';
import { APP_CONSTANTS } from 'ui-common/src/constants';
import {
  loginRequestActionType,
  loginFailedAction,
  userInfoAction,
  userModulesActionType,
  userModulesSuccessAction,
  loginInfoSuccess,
  appConstantsSuccess,
  appMessagesSuccess,
  APP_CONSTANTS_ACTION,
  APP_MESSAGES,
  appMessages,
  userDetailsActionType,
  GET_HEADER_MENU,
  getHeaderDetailsSuccess,
  UPDATE_EMAIL_LANGUAGE,
  ECM_LOGOUT,
  GET_INITIAL_DATA_ACTION,
  getInitialDataSuccess,
  getIntitialDataAction,
  setUpdateLanguageState,
  reloadPage,
  GET_LEFTNAV_MODERN_EXP_GEN2,
  getLeftNavModernGen2Success,
  GET_HOMEPAGE_MODERN_EXP_GEN2,
  GET_SIDEMENU_REQUEST_CONFIG,
  getHomePageGen2Success,
  getRequestGen2ConfigSuccess,
  GET_PAM_GEN2,
  getPamGen2Success,
} from 'ui-common/src/utils/LoginActions';
import { setSecureCookies } from 'ui-common/src/utils/common';
import { LANGUAGE_CHANGE_STATE } from './constants';

import {
  FETCH_THEME_CONFIG_LIST,
  fetchThemeConfigListSuccess,
  fetchThemeConfigListFailure,
  GET_ACTIVE_THEME,
  GET_ACTIVE_THEME_SUCCESS,
  GET_ACTIVE_THEME_FAILURE,
  getActiveThemeFailure,
  getActiveThemeSuccess,
  GET_ACTIVE_LOGO,
  getActiveLogoSuccess,
  getActiveLogoFailure,
  GET_ACTIVE_HEADER_LOGO,
  getActiveHeaderLogoSuccess,
  getActiveHeaderLogoFailure,
  getActiveFavIconSuccess,
  getActiveFavIconFailure,
  GET_ACTIVE_FAVICON,
} from '../../AdminHome/redux/adminHome-actions';
import configJson from './domainConfig.json';
import { requestEcmVersionSaga } from '../Version/redux/version-saga';
import { FETCH_ECM_VERSION } from '../Version/redux/constants';
import { getHostUrl, loadImage } from '../../AdminHome/views/BrandingConfig/utils/helper';

const httpResponseCode = {
  // "Synthetic" response code outside of the RFC 9110 spec range to distinguish valid HTTP responses from `fetch`
  // calls that threw an error of some kind
  FETCH_ERROR: 600,
};
const cookies = new Cookies();

function* userInfoSaga(action) {
  const { GATEWAY_API_URL } = checkEnvironment();
  const response = yield call(fetch, `${GATEWAY_API_URL}/api/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // TENANT_ID: 'TEST',
    },
    body: JSON.stringify(action.payload),
  });
  if (response && response.status === 200) {
    const json = yield call([response, response.json]);
    if (json) {
      setSecureCookies('token_type', json.tokenType);
      sessionStorage.setItem('micro_app', 'requestMiniApp');
      localStorage.setItem('micro_app', 'requestMiniApp');
      setSecureCookies('access_token', json.accessToken);
      setSecureCookies('user_name', json.username);
      // get All the initial data i.e UserDetails, HeaderDetails, userModules, Themes..
      // Send true so that messages is not loaded in this API.
      yield put(getIntitialDataAction('', true));
      // ecmLoginData();
      yield putResolve({ type: 'loginSuccessAction', json });
      const refreshTokenResponse = yield call(fetch, `${GATEWAY_API_URL}/api/auth/refresh-token`, {
        method: 'POST',
        headers: {
          Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
          // TENANT_ID: 'TEST',
        },
      });
      if (refreshTokenResponse && refreshTokenResponse.status === 200) {
        const refreshTokenJson = yield call([refreshTokenResponse, refreshTokenResponse.json]);
        if (refreshTokenJson) {
          setSecureCookies('refresh_token', refreshTokenJson.refreshToken);
        }
      }
    }
    if (action.destinationUrl) {
      push(action.destinationUrl);
    }
  } else if (response && response.status === 401) {
    yield put(loginFailedAction());
  }
}

function* fetchUserDetails() {
  const { GATEWAY_API_URL } = checkEnvironment();
  const formdata = new FormData();
  formdata.append('username', cookies.get('user_name'));
  const userDetails = yield call(fetch, `${GATEWAY_API_URL}/api/user/details`, {
    method: 'POST',
    headers: {
      ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      }),
      // TENANT_ID: 'TEST',
    },
    body: formdata,
  });
  if (userDetails && userDetails.status === 200) {
    const userjson = yield call([userDetails, userDetails.json]);
    yield put(userInfoAction(userjson));
    yield put(
      loginInfoSuccess({ ...userjson.userSavRoleMap, showLoginSnack: !cookies.get('userKey') })
    );
    setSecureCookies('first_name', userjson.firstname);
    setSecureCookies('userKey', userjson.id);
  }
}

function* userModulesSaga() {
  const { GATEWAY_API_URL, BLOCKED_URLS } = checkEnvironment();
  if (!GATEWAY_API_URL) return;
  if (!cookies.get('token_type')) return;
  let url = `${GATEWAY_API_URL}/api/auth/featuresMap`;
  let method = 'POST';
  const username = cookies.get('user_name');
  if (BLOCKED_URLS) {
    url = `${GATEWAY_API_URL}/api/userms/auth/v2/features/${username}`;
    method = 'GET';
  }
  const response = yield call(fetch, url, {
    method,
    headers: {
      ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      }),
      'X-NOLOADER': true,
      // TENANT_ID: 'TEST',
    },
  });
  if (response && response.status === 200) {
    const json = yield call([response, response.json]);
    if (json) {
      yield put(userModulesSuccessAction(json));
    }
  }
}

function parseConfigValues(configData) {
  // Remove any occurance if $domain_name with hostname
  const constValues = mapValues(configData, (value) => {
    if (typeof value === 'string') {
      if (value.toLocaleLowerCase() === 'true' || value.toLocaleLowerCase() === 'false') {
        return value.toLocaleLowerCase() === 'true';
      }
      if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        return value.replace('https://$domain', localStorage.getItem('localDomain')); // taking care of localhost for development.
      }
      return value.replace('$domain', window.location.host); // $domain in value string will be replaced.
    }
    return value; // if value is object , it wil be unmodified
  });
  return constValues;
}

function* fetchAppConstants(action) {
  try {
    // const query = useQuery();
    // const { GATEWAY_API_URL } = checkEnvironment();
    const { domain, locale } = action.payload;
    let url = '';
    if (window.DEMO_MODE) {
      url = `http://${window.location.hostname}:3100/api/appConstants?domain=${domain}`;
    } else if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    ) {
      if (localStorage.getItem('localDomain') !== null) {
        url = `${localStorage.getItem('localDomain')}/ECMv6/api/config/ui-track?v=${Math.random(
          100
        )}`;
      } else url = `${domain}/ECMv6/api/config/ui-track`;
    } else {
      url = `/ECMv6/api/config/ui-track?v=${Math.random(100)}`; // url should be from config. not based on GATEWAY URL
    }
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {},
    });

    if (response && response.status === 200) {
      const serverJson = yield call([response, response.json]);
      const json = serverJson;
      let mergedConfig = {};
      if (
        serverJson &&
        serverJson.propertySources &&
        serverJson.propertySources[0] &&
        serverJson.propertySources[0].source
      ) {
        mergedConfig = {
          ...configJson.propertySources[0].source,
          ...serverJson.propertySources[0].source,
        };
      } else {
        mergedConfig = configJson.propertySources[0].source;
      }
      if (json.propertySources) {
        const constValues = parseConfigValues(mergedConfig);
        SessionStorageService.set(APP_CONSTANTS, { [domain]: constValues });
        initialiseEnv(constValues);
        yield put(appConstantsSuccess(constValues));
        if (constValues.GATEWAY_API_URL) {
          yield put(appMessages(locale || 'en', constValues.GATEWAY_API_URL));
        }
      }
    }
  } catch (error) {
    console.error('Error---', error);
  }
}

function* fetchAppMessages(action) {
  try {
    let url = null;
    const lang = action.payload.locale;
    if (window.DEMO_MODE) {
      url = `http://${window.location.hostname}:3100/api/messages?locale=${
        lang && lang !== 'undefined' ? lang : 'en'
      }`;
    } else {
      url = `${action.payload.gateway}/api/idw/messageProperties/retrieveMessages?locale=${
        lang && lang !== 'undefined' ? lang : 'en'
      }`;
    }

    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
        ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
          Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
        }),
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      const messagesObj = {};
      json.forEach((j) => {
        messagesObj[j.messageKey] = j.messageValue;
      });
      yield put(appMessagesSuccess(messagesObj));
    }
  } catch (error) {
    console.error('Error---', error);
  }
}

function* loginInfoSaga(action) {
  const { GATEWAY_API_URL } = checkEnvironment();
  if (!GATEWAY_API_URL) return;
  const response = yield call(fetch, `${GATEWAY_API_URL}/api/user/info`, {
    methd: 'GET',
    headers: {
      ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      }),
    },
  });
  if (response && response.status === 200) {
    const loginInfoJson = yield call([response, response.json]);
    if (loginInfoJson.lastLoginTime) {
      yield put(loginInfoSuccess(loginInfoJson));
    }
  }
}

function* fetchHeaderMenu() {
  const { GATEWAY_API_URL } = checkEnvironment();
  try {
    const url = `${GATEWAY_API_URL}/api/idw/config/global?featureType=HEADERSPREFERENCES`;

    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
          Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
        }),
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      yield put(getHeaderDetailsSuccess(json));
    }
  } catch (error) {
    console.error('Error---', error);
  }
}

function* updateEmailAndLanguage(action) {
  try {
    const { GATEWAY_API_URL, ECM_URL } = checkEnvironment();
    const response = yield call(fetch, `${GATEWAY_API_URL}/api/idw/users/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
          Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}
        `,
        }),
        'X-NOLOADER': action.noLoader,
      },
      body: JSON.stringify(action.payload),
    });
    const langResp = yield call(
      fetch,
      `${ECM_URL}/users/updateLanguage?lang=${action.payload.locale}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
            Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
          }),
          'X-NOLOADER': action.noLoader,
        },
      }
    );
    if (response && response.status === 200) {
      // do something if needed
    }

    if (langResp && langResp.status === 200) {
      yield put(setUpdateLanguageState(LANGUAGE_CHANGE_STATE.SUCCESS));
    } else {
      yield put(setUpdateLanguageState(LANGUAGE_CHANGE_STATE.FAILED));
    }
    // Reload the page to update ECM gsp pages render the updated language
    yield put(reloadPage());
  } catch (error) {
    console.error(error);
    yield put(setUpdateLanguageState(LANGUAGE_CHANGE_STATE.FAILED));
  }
}

function* ecmLogoutSaga() {
  const { ECM_URL } = checkEnvironment();
  const response = yield call(fetch, `${ECM_URL}/j_spring_security_logout`, {
    method: 'POST',
  });
  if (response && response.status === 302) {
    console.log('Logged Out from ECM');
  }
}

function* fetchThemeConfigSaga() {
  try {
    const { GATEWAY_API_URL } = checkEnvironment();
    const url = `${GATEWAY_API_URL}/api/idw/res/file?path=/app/usr/theme.json`;

    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
          Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
        }),
        'Content-Type': 'application/json',
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      if (json) {
        yield put(fetchThemeConfigListSuccess(json));
        if (Object.keys(json).length > 0) {
          localStorage.setItem(
            'savedTheme',
            JSON.stringify({
              ...json,
            })
          );
        }
      }
    } else {
      yield put(fetchThemeConfigListFailure());
    }
  } catch (error) {
    console.error(error);
    yield put(fetchThemeConfigListFailure());
  }
}

function* getActiveThemeSaga() {
  const URL = getHostUrl();
  if (URL === 'localhost' || URL) {
    try {
      const url = `${URL}/navigation/theme`;
      const response = yield call(fetch, url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-NOLOADER': true,
          ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
            Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
          }),
        },
      });
      if (response && response.status === 200) {
        const json = yield call([response, response.json]);
        if (!json?.error) {
          const resStyles = JSON.parse(json?.styles);

          // Convert styles field to JavaScript object
          if (json?.styles) {
            json.styles = resStyles;
          }
          yield put({ type: GET_ACTIVE_THEME_SUCCESS, response: json });
        } else {
          yield put({ type: GET_ACTIVE_THEME_FAILURE, error: json });
        }
      } else {
        const jsonString = yield call([response, response.text]);
        yield put({ type: GET_ACTIVE_THEME_FAILURE, error: jsonString });
      }
    } catch (error) {
      yield put({ type: GET_ACTIVE_THEME_FAILURE });
    }
  }
}

function* getActiveLogoSaga() {
  const URL = getHostUrl();
  if (URL === 'localhost' || URL) {
    try {
      const url = `${URL}/navigation/logos/logo`;
      const response = yield call(fetch, url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-NOLOADER': true,
          ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
            Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
          }),
        },
      });

      if (response && response.status === 200) {
        try {
          const json = yield call([response, response.blob]);
          if (json && json?.type !== 'text/json' && json?.type !== 'application/json') {
            yield put(getActiveLogoSuccess(json));
          } else {
            yield put(getActiveLogoFailure(json));
          }
        } catch (e) {
          yield put(getActiveLogoFailure(e));
        }
      } else {
        yield put(getActiveLogoFailure(response));
      }
    } catch (error) {
      yield put(getActiveLogoFailure(error));
    }
  }
}

function* getActiveHeaderLogoSaga() {
  const URL = getHostUrl();
  if (URL === 'localhost' || URL) {
    try {
      const url = `${URL}/navigation/logos/headerlogo`;
      const response = yield call(fetch, url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-NOLOADER': true,
          ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
            Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
          }),
        },
      });

      if (response && response.status === 200) {
        try {
          const json = yield call([response, response.blob]);
          if (json && json?.type !== 'text/json' && json?.type !== 'application/json') {
            yield put(getActiveHeaderLogoSuccess(json));
          } else {
            yield put(getActiveHeaderLogoFailure(json));
          }
        } catch (e) {
          yield put(getActiveHeaderLogoFailure(e));
        }
      } else {
        yield put(getActiveHeaderLogoFailure(response));
      }
    } catch (e) {
      yield put(getActiveHeaderLogoFailure(e));
    }
  }
}

function* getActiveFavIconSaga() {
  const URL = getHostUrl();
  if (URL === 'localhost' || URL) {
    try {
      const url = `${URL}/navigation/favicon`;
      const response = yield call(fetch, url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-NOLOADER': true,
          ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
            Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
          }),
        },
      });

      if (response && response.status === 200) {
        try {
          const json = yield call([response, response.blob]);
          if (json && json?.type !== 'text/json' && json?.type !== 'application/json') {
            yield put(getActiveFavIconSuccess(loadImage(json)));
          } else {
            yield put(getActiveFavIconFailure(json));
          }
        } catch (e) {
          yield put(getActiveFavIconFailure(e));
        }
      } else {
        yield put(getActiveFavIconFailure(response));
      }
    } catch (e) {
      yield put(getActiveFavIconFailure(e));
    }
  }
}

// TODO This was a quick fix for FON-14544. The design of this function should be improved b/c it assumes `fn` is
// `fetch`. Should make return type to s/t like `{ result: ... } | { error: Error }`, or rename it `fetchCall` and
// remove the `fn` parameter.
function* safeCall(fn, ...args) {
  try {
    return yield call(fn, ...args);
  } catch (error) {
    return { status: httpResponseCode.FETCH_ERROR, error };
  }
}

function* fetchInitialDataSaga(action) {
  const call = safeCall;
  const { GATEWAY_API_URL, BLOCKED_URLS, ECM_URL } = checkEnvironment();
  const formdata = new FormData();
  formdata.append('username', cookies.get('user_name'));
  if (!GATEWAY_API_URL) return;
  if (!cookies.get('token_type')) return;
  try {
    const username = cookies.get('user_name');
    let url = `${GATEWAY_API_URL}/api/auth/featuresMap`;
    let method = 'POST';
    if (BLOCKED_URLS) {
      url = `${GATEWAY_API_URL}/api/userms/auth/v2/features/${username}`;
      method = 'GET';
    }
    const [
      userDetails,
      headersResponse,
      userModulesResponse,
      themeResponse,
      gen2ActiveTheme,
      gen2ActiveLogo,
      gen2ActiveHeaderLogo,
      leftNavModernUIFlag,
      homePageGen2Response,
      requestGen2Config,
      pamGen2UIFlag,
    ] = yield all([
      call(fetch, `${GATEWAY_API_URL}/api/user/details`, {
        method: 'POST',
        headers: {
          ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
            Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
          }),
          'X-NOLOADER': true,
          // TENANT_ID: 'TEST',
        },
        body: formdata,
      }),
      call(fetch, `${GATEWAY_API_URL}/api/idw/config/global?featureType=HEADERSPREFERENCES`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
            Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
          }),
          'X-NOLOADER': true,
        },
      }),
      call(fetch, url, {
        method,
        headers: {
          ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
            Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
          }),
          'X-NOLOADER': true,
        },
      }),
      call(fetch, `${GATEWAY_API_URL}/api/idw/res/file?path=/app/usr/theme.json`, {
        method: 'GET',
        headers: {
          ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
            Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
          }),
          'Content-Type': 'application/json',
          'X-NOLOADER': true,
        },
      }),
      getHostUrl() &&
        call(fetch, `${getHostUrl()}/navigation/theme`, {
          method: 'GET',
          redirect: 'error',
          headers: {
            ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
              Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
            }),
            'Content-Type': 'application/json',
            'X-NOLOADER': true,
          },
        }),
      getHostUrl() &&
        call(fetch, `${getHostUrl()}/navigation/logos/logo`, {
          method: 'GET',
          redirect: 'error',
          headers: {
            ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
              Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
            }),
            'Content-Type': 'application/json',
            'X-NOLOADER': true,
          },
        }),
      getHostUrl() &&
        call(fetch, `${getHostUrl()}/navigation/logos/headerlogo`, {
          method: 'GET',
          redirect: 'error',
          headers: {
            ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
              Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
            }),
            'Content-Type': 'application/json',
            'X-NOLOADER': true,
          },
        }),
      call(fetch, `${GATEWAY_API_URL}/api/idw/config/GEN2_ENABLE_LEFT_NAVIGATION`, {
        method: 'GET',
        headers: {
          ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
            Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
          }),
          'Content-Type': 'application/json',
          'X-NOLOADER': true,
        },
      }),
      call(fetch, `${GATEWAY_API_URL}/api/idw/config/GEN2_ENABLE_HOME_PAGE`, {
        method: 'GET',
        headers: {
          ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
            Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
          }),
          'Content-Type': 'application/json',
          'X-NOLOADER': true,
        },
      }),
      call(fetch, `${GATEWAY_API_URL}/api/idw/config/ENABLE_NEW_ARS_UI`, {
        method: 'GET',
        headers: {
          ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
            Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
          }),
          'Content-Type': 'application/json',
          'X-NOLOADER': true,
        },
      }),
      call(fetch, `${GATEWAY_API_URL}/api/idw/config/ENABLE_PAM_GEN2`, {
        method: 'GET',
        headers: {
          ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
            Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}
          `,
          }),
          'Content-Type': 'application/json',
          'X-NOLOADER': true,
        },
      }),
    ]);
    if (!action.isLogin) {
      let url = null;
      const lang = action.locale;
      if (window.DEMO_MODE) {
        url = `http://${window.location.hostname}:3100/api/messages?locale=${
          lang && lang !== 'undefined' ? lang : 'en'
        }`;
      } else {
        url = `${GATEWAY_API_URL}/api/idw/messageProperties/retrieveMessages?locale=${
          lang && lang !== 'undefined' ? lang : 'en'
        }`;
      }
      const appMessagesResponse = yield call(fetch, url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-NOLOADER': true,
        },
      });
      try {
        if (appMessagesResponse && appMessagesResponse.status === 200) {
          const json = yield call([appMessagesResponse, appMessagesResponse.json]);
          const messagesObj = {};
          json.forEach((j) => {
            messagesObj[j.messageKey] = j.messageValue;
          });
          yield put(appMessagesSuccess(messagesObj));
        }
      } catch (error) {
        console.error(error);
      }
    }
    try {
      if (userDetails && userDetails.status === 200) {
        const userjson = yield call([userDetails, userDetails.json]);
        yield put(userInfoAction(userjson));
        yield put(
          loginInfoSuccess({ ...userjson.userSavRoleMap, showLoginSnack: !cookies.get('userKey') })
        );
        setSecureCookies('first_name', userjson.firstname);
        setSecureCookies('userKey', userjson.id);
      }
    } catch (error) {
      console.error(error);
    }

    try {
      if (headersResponse && headersResponse.status === 200) {
        const json = yield call([headersResponse, headersResponse.json]);
        yield put(getHeaderDetailsSuccess(json));
      }
    } catch (error) {
      console.error(error);
    }

    try {
      if (userModulesResponse && userModulesResponse.status === 200) {
        const json = yield call([userModulesResponse, userModulesResponse.json]);
        if (json) {
          yield put(userModulesSuccessAction(json));
        }
      }
    } catch (error) {
      console.error(error);
    }

    if (themeResponse && themeResponse.status === 200) {
      try {
        const json = yield call([themeResponse, themeResponse.json]);
        if (json) {
          yield put(fetchThemeConfigListSuccess(json));
          if (Object.keys(json).length > 0) {
            localStorage.setItem(
              'savedTheme',
              JSON.stringify({
                ...json,
              })
            );
          }
        }
      } catch (e) {
        yield put(fetchThemeConfigListFailure());
      }
    } else {
      yield put(fetchThemeConfigListFailure());
    }

    if (gen2ActiveTheme && gen2ActiveTheme.status === 200) {
      try {
        const json = yield call([gen2ActiveTheme, gen2ActiveTheme.text]);
        const res = JSON.parse(json);
        if (!res?.error) {
          const resStyles = JSON.parse(res?.styles);

          // Convert styles field to JavaScript object
          if (res?.styles) {
            res.styles = resStyles;
          }
          yield put(getActiveThemeSuccess(res));
        } else {
          yield put(getActiveThemeFailure({ error: res }));
        }
      } catch (e) {
        yield put(getActiveThemeFailure({ error: e }));
      }
    } else {
      ecmLogin();
    }

    if (gen2ActiveLogo && gen2ActiveLogo.status === 200) {
      try {
        const json = yield call([gen2ActiveLogo, gen2ActiveLogo.blob]);
        if (json && json?.type !== 'text/json') {
          yield put(getActiveLogoSuccess(json));
        } else {
          yield put(getActiveLogoFailure(json));
        }
      } catch (e) {
        yield put(getActiveLogoFailure(e));
      }
    } else {
      yield put(getActiveLogoFailure(gen2ActiveLogo));
    }

    if (gen2ActiveHeaderLogo && gen2ActiveHeaderLogo.status === 200) {
      try {
        const json = yield call([gen2ActiveHeaderLogo, gen2ActiveHeaderLogo.blob]);
        if (json && json?.type !== 'text/json') {
          yield put(getActiveHeaderLogoSuccess(json));
        } else {
          yield put(getActiveHeaderLogoFailure(json));
        }
      } catch (e) {
        yield put(getActiveHeaderLogoFailure(e));
      }
    } else {
      yield put(getActiveHeaderLogoFailure(gen2ActiveLogo));
    }
    try {
      if (leftNavModernUIFlag && leftNavModernUIFlag.status === 200) {
        const json = yield call([leftNavModernUIFlag, leftNavModernUIFlag.json]);
        cookies.set('leftNavGen2', json.configData, {
          path: '/',
        });
        localStorage.setItem('leftNavGen2', json.configData);
        yield put(getLeftNavModernGen2Success(json));
      }
    } catch (error) {
      console.error(error);
    }

    try {
      if (homePageGen2Response && homePageGen2Response.status === 200) {
        const json = yield call([homePageGen2Response, homePageGen2Response.json]);
        yield put(getHomePageGen2Success(json));
      }
    } catch (error) {
      console.error(error);
    }

    try {
      if (requestGen2Config && requestGen2Config.status === 200) {
        const json = yield call([requestGen2Config, requestGen2Config.json]);
        yield put(getRequestGen2ConfigSuccess(json));
      }
    } catch (error) {
      console.error(error);
    }

    try {
      if (pamGen2UIFlag && pamGen2UIFlag.status === 200) {
        const json = yield call([pamGen2UIFlag, pamGen2UIFlag.json]);
        cookies.set('pamGen2', json.configData, {
          path: '/',
        });
        localStorage.setItem('pamGen2', json.configData);
        yield put(getPamGen2Success(json));
      }
    } catch (error) {
      console.error(error);
    }
    yield put(getInitialDataSuccess());
  } catch (e) {
    // exception
    yield put(getInitialDataSuccess());
    // yield put(requestImportantActionsListFailure(finalResponseArray));
  }
}

function* getHomePageGen2Saga() {
  try {
    const { GATEWAY_API_URL } = checkEnvironment();
    const url = `${GATEWAY_API_URL}/api/idw/config/GEN2_ENABLE_HOME_PAGE`;

    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
          Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}
        `,
        }),
        'Content-Type': 'application/json',
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      if (json) {
        yield put(getHomePageGen2Success(json));
      }
    } else {
      console.error('GEN2_ENABLE_HOME_PAGE failed');
    }
  } catch (error) {
    console.error(error);
  }
}

function* getSideMenuRequestGen2Saga() {
  try {
    const { GATEWAY_API_URL } = checkEnvironment();
    const url = `${GATEWAY_API_URL}/api/idw/config/ENABLE_NEW_ARS_UI`;

    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
          Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
        }),
        'Content-Type': 'application/json',
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      if (json) {
        yield put(getRequestGen2ConfigSuccess(json));
      }
    } else {
      console.error('getRequestGen2Config failed');
    }
  } catch (error) {
    console.error(error);
  }
}

function* getLeftNavModernGen2() {
  try {
    const { GATEWAY_API_URL } = checkEnvironment();
    const url = `${GATEWAY_API_URL}/api/idw/config/GEN2_ENABLE_LEFT_NAVIGATION`;

    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
          Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}
        `,
        }),
        'Content-Type': 'application/json',
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      if (json) {
        yield put(getLeftNavModernGen2Success(json));
        localStorage.setItem('leftNavGen2', json.configData);
      }
    } else {
      console.error('GEN2_ENABLE_LEFT_NAVIGATION failed');
    }
  } catch (error) {
    console.error(error);
  }
}

function* getPamGen2() {
  try {
    const { GATEWAY_API_URL } = checkEnvironment();
    const url = `${GATEWAY_API_URL}/api/idw/config/ENABLE_PAM_GEN2`;

    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        ...(checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN || {
          Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}
        `,
        }),
        'Content-Type': 'application/json',
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      if (json) {
        yield put(getPamGen2Success(json));
        localStorage.setItem('pamGen2', json.configData);
      }
    } else {
      console.error('ENABLE_PAM_GEN2 failed');
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* loginSaga() {
  yield takeLatest(loginRequestActionType, userInfoSaga);
  yield takeLatest(userModulesActionType, userModulesSaga);
  // yield takeLatest(loginInfoActionType, loginInfoSaga);
  yield takeLatest(userDetailsActionType, fetchUserDetails);
  yield takeLatest(APP_CONSTANTS_ACTION, fetchAppConstants);
  yield takeLatest(APP_MESSAGES, fetchAppMessages);
  yield takeLatest(GET_HEADER_MENU, fetchHeaderMenu);
  yield takeLatest(UPDATE_EMAIL_LANGUAGE, updateEmailAndLanguage);
  yield takeLatest(ECM_LOGOUT, ecmLogoutSaga);
  yield takeLatest(FETCH_THEME_CONFIG_LIST, fetchThemeConfigSaga);
  yield takeLatest(GET_INITIAL_DATA_ACTION, fetchInitialDataSaga);
  yield takeLatest(FETCH_ECM_VERSION, requestEcmVersionSaga);
  yield takeLatest(GET_LEFTNAV_MODERN_EXP_GEN2, getLeftNavModernGen2);
  yield takeLatest(GET_HOMEPAGE_MODERN_EXP_GEN2, getHomePageGen2Saga);
  yield takeLatest(GET_SIDEMENU_REQUEST_CONFIG, getSideMenuRequestGen2Saga);
  yield takeLatest(GET_ACTIVE_THEME, getActiveThemeSaga);
  yield takeLatest(GET_ACTIVE_LOGO, getActiveLogoSaga);
  yield takeLatest(GET_ACTIVE_FAVICON, getActiveFavIconSaga);
  yield takeLatest(GET_ACTIVE_HEADER_LOGO, getActiveHeaderLogoSaga);
  yield takeLatest(GET_PAM_GEN2, getPamGen2);
}
