import { Logger } from '@saviynt/common';
import { useEffect } from 'react';

import {
  ACCOUNT_JIT_SELECTION,
  MODAL_PAGE_KINDS,
} from '../../components/ModalPageForms/constants';
import {
  handleCredentialFormAccountId,
  handleIsJustInTimeRequest,
} from '../../store/actions/index';
import { getAccountListApi } from '../../utilities/api/getDataFromApi';

const ACCORDION_OPEN_DELAY_IN_MS = 500;

const ModalPageAccountService = (
  modalPageKind,
  accountSelection,
  accountListArray,
  serviceEndpointName,
  serviceEndpointKey,
  setAccountListArray,
  setIsExpanedAccountSelection,
  dispatch
) => {
  useEffect(() => {
    if (accountSelection?.value === ACCOUNT_JIT_SELECTION.value) {
      dispatch(
        handleIsJustInTimeRequest(
          serviceEndpointName,
          'Just In Time set success'
        )
      );

      return;
    }

    if (!accountSelection) return;

    dispatch(
      handleCredentialFormAccountId(
        accountSelection?.value,
        'Account Id success'
      )
    );
  }, [accountSelection]);

  // Open Account accordion when list is ready
  useEffect(() => {
    if (accountListArray) setIsExpanedAccountSelection(true);

    const isCTSWithNoAccounts =
      modalPageKind === MODAL_PAGE_KINDS.CTS && accountListArray?.length === 0;

    if (isCTSWithNoAccounts) {
      const timer = setTimeout(() => {
        setIsExpanedAccountSelection(true);
      }, ACCORDION_OPEN_DELAY_IN_MS);

      return () => clearTimeout(timer);
    }

    return () => {};
  }, [accountListArray]);

  // Getting relavent info from accounts to send to Radio buttons
  const extractAccountDetails = (accountList) => {
    if (!accountList.result || !accountList.result.accounts) return;

    const { accounts } = accountList.result;
    const extractedDetails = accounts.map((account) => ({
      value: account.accountKey,
      title: account.name,
      description: account.pamAccountType,
      maxtime: account.accountConfig.maxrequestabletimeinsecs,
      exclusiveAccess: account.exclusiveAccess,
    }));

    setAccountListArray(extractedDetails);
  };

  useEffect(() => {
    // Update the accountListArray on mount
    if (serviceEndpointKey) {
      const credentialType =
        modalPageKind === MODAL_PAGE_KINDS.COC
          ? 'CREDENTIAL'
          : 'CREDENTIALLESS';

      getAccountListApi(serviceEndpointKey, credentialType)
        .then((response) => {
          extractAccountDetails(response);
        })
        .catch((error) => {
          Logger.error(
            'An error occurred while fetching the account list:',
            error
          );
        });
    }
  }, [serviceEndpointKey]);

  return {};
};

export default ModalPageAccountService;
