import './SpvPendingSession.css';

import { formatLocalizedDateTime, localize } from '@saviynt/common';
import {
  Box,
  Chip,
  DownloadFile,
  Icon,
  TabContent,
  TabGroup,
  TabItem,
} from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Cookies } from 'react-cookie';
import { useIntl } from 'react-intl';

import {
  ModuleDetails,
  RequestDetails,
  Session,
} from '../../../../../models/PamModels';
import getFileDownloadApiUrl from '../../../../../utilities/api/urls/getFileDownloadApiUrl';
import { msgs } from '../../../constants';
import SpvEndpoint from '../../SpvEndpoint/SpvEndpoint';
import SpvSection from '../../SpvSection/SpvSection';

function SpvPendingSession({
  userAccessData,
  accountDetails,
  endpointDetails,
  pendingSession,
  requestDetails,
  dataTestId,
  className,
}) {
  const classes = classnames('SpvPendingSession', className);
  const [activeTab, setActiveTab] = useState('1');

  const intl = useIntl();
  const cookies = new Cookies();

  const handleChange = (value) => {
    setActiveTab(value);
  };

  const requestDetailsHasFields =
    pendingSession.requestId ||
    requestDetails.sessionStatus ||
    requestDetails.requestDate ||
    requestDetails.sessionStartDate ||
    requestDetails.sessionEndDate;

  const isCredentialless =
    pendingSession.pamType?.toUpperCase() === 'CREDENTIALLESS';
  const remoteAppName = userAccessData.remoteAppMetadata?.displayName;

  const showRemoteAppName =
    isCredentialless && remoteAppName && remoteAppName !== 'Terminal';

  const sessionAccountHasFields =
    pendingSession?.accountName ||
    accountDetails.description ||
    accountDetails.pamAccountType ||
    accountDetails.accountConfig?.authenticationType;

  const pendingRequestHasComments =
    requestDetails.comments && requestDetails.comments.length > 0;

  const pendingRequestHasAttachments =
    requestDetails.attachments && requestDetails.attachments.length > 0;

  const renderComments = () => {
    const fields = [];

    requestDetails.comments.forEach((comment) => {
      const { commentor } = comment;
      const { firstName, lastName, userName } = commentor;

      const commentDate = comment.date;

      const labelTitle = `${firstName || ''} (${userName}) ${
        lastName || ''
      } at ${formatLocalizedDateTime(`${commentDate}Z`)}:`;

      fields.push({ label: labelTitle, value: comment.comment });
    });

    return fields;
  };

  const requestStatus = !pendingSession.sessionStatus ? (
    <Chip kind='subtleFilled' color='info' label='Pending Approval' />
  ) : null;

  const getAssignees = () => {
    const assignees = [];

    requestDetails.endpoints[0].approvals[0].assignee.map((assignedPerson) =>
      assignees.push(assignedPerson.displayName)
    );

    return assignees.join(',');
  };

  return (
    <section className={classes} data-testid={dataTestId}>
      <TabGroup
        className='SpvPendingSession-tabGroup'
        value={activeTab}
        onChange={handleChange}>
        <TabItem
          label={localize(intl, msgs.pam.sidePanel.session)}
          value='1'
          icon={
            <Icon kind='PrivilegedSession' size='medium' color='neutral-1000' />
          }
        />
        <TabItem
          label={localize(intl, msgs.pam.sidePanel.asset)}
          value='2'
          icon={
            <Icon kind='AssetTargetSystem' size='medium' color='neutral-1000' />
          }
        />
      </TabGroup>
      <TabContent
        className='SpvPendingSession-tabContent'
        value={activeTab}
        tabValue='1'>
        {requestDetailsHasFields && (
          <SpvSection
            title={localize(intl, msgs.pam.sidePanel.requestDetails)}
            fields={[
              {
                label: localize(intl, msgs.pam.sidePanel.requestStatus),
                value: requestStatus,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.requestId),
                value: pendingSession.requestId,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.requestTime),
                value: formatLocalizedDateTime(
                  `${requestDetails.requestDate}Z`
                ),
              },
              {
                label: localize(intl, msgs.pam.sidePanel.assignedTo),
                value: getAssignees(),
              },
              {
                label: localize(intl, msgs.pam.sidePanel.startTime),
                value: formatLocalizedDateTime(pendingSession.sessionStartDate),
              },
              {
                label: localize(intl, msgs.pam.sidePanel.endTime),
                value: formatLocalizedDateTime(pendingSession.sessionEndDate),
              },
              {
                label: localize(intl, msgs.pam.sidePanel.ticketNumber),
                value: requestDetails.ticketId,
              },
            ]}
          />
        )}
        {showRemoteAppName && (
          <SpvSection
            className={requestDetailsHasFields && 'SpvActiveSession-tabSection'}
            title={localize(intl, msgs.pam.sidePanel.remoteApplication)}
            fields={[
              {
                label: localize(intl, msgs.pam.sidePanel.name),
                value: remoteAppName,
              },
            ]}
          />
        )}
        {sessionAccountHasFields && (
          <SpvSection
            className={
              (showRemoteAppName || requestDetailsHasFields) &&
              'SpvPendingSession-tabSection'
            }
            title={localize(intl, msgs.pam.sidePanel.account)}
            fields={[
              {
                label: localize(intl, msgs.pam.sidePanel.name),
                value: pendingSession?.accountName,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.platform),
                value: accountDetails.platformType,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.description),
                value: accountDetails.description,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.accountType),
                value: accountDetails.pamAccountType,
              },
              {
                label: localize(
                  intl,
                  msgs.pam.sidePanel.authenticationMechanism
                ),
                value: accountDetails.accountConfig?.authenticationType,
              },
            ]}
          />
        )}
        {pendingRequestHasComments > 0 && (
          <SpvSection
            className={
              sessionAccountHasFields && 'SpvPendingSession-tabSection'
            }
            title={localize(intl, msgs.pam.sidePanel.comments)}
            fields={renderComments()}
          />
        )}
        {pendingRequestHasAttachments && (
          <SpvSection
            className={
              pendingRequestHasComments && 'SpvPendingSession-tabSection'
            }
            title={localize(intl, msgs.pam.sidePanel.attachments)}>
            <Box className='SpvPendingSession-downloadFile'>
              {requestDetails.attachments.map((attachment) => {
                const fileUrl = getFileDownloadApiUrl(
                  cookies.get('user_name'),
                  attachment.attachmentKey
                );

                return (
                  <DownloadFile
                    fileName={attachment.fileName}
                    fileSize={attachment.size}
                    fileUrl={fileUrl}
                  />
                );
              })}
            </Box>
          </SpvSection>
        )}
      </TabContent>
      <TabContent
        className='SpvPendingSession-assetTab'
        value={activeTab}
        tabValue='2'>
        <SpvEndpoint data={endpointDetails} />
      </TabContent>
    </section>
  );
}

SpvPendingSession.propTypes = {
  userAccessData: ModuleDetails,
  accountDetails: ModuleDetails,
  endpointDetails: ModuleDetails,
  pendingSession: Session,
  requestDetails: RequestDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvPendingSession.defaultProps = {
  userAccessData: null,
  accountDetails: null,
  endpointDetails: null,
  pendingSession: null,
  requestDetails: null,
  dataTestId: null,
  className: null,
};

export default SpvPendingSession;
