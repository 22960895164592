import React from 'react';
import { InterPoint } from '@saviynt/common';
import PropTypes from 'prop-types';

import AvatarText from '../AvatarText/AvatarText';
import IconText from '../IconText/IconText';

import './Description.css';

const KINDS = {
  iconText: 'icon-text',
  avatarText: 'avatar-text',
  labelValue: 'label-value',
  text: 'text',
};

function LabelValue({ label, value }) {
  return (
    <span className='ListRow-labelValue'>
      <span>
        {label}
        :&nbsp;
      </span>

      <span className='ListRow-text'>{value}</span>
    </span>
  );
}

function Description({ data }) {
  return (
    <div className='ListRow-description'>
      {data.map((item, index) => (
        <div key={index} className='ListRow-description-content'>
          {index > 0 && (
            <div className='ListRow-separator'>
              <InterPoint font='body3' />
            </div>
          )}
          {item.kind === 'icon-text' && (
            <IconText icon={item.icon} text={item.text} />
          )}
          {item.kind === 'label-value' && (
            <LabelValue label={item.label} value={item.value} />
          )}
          {item.kind === 'avatar-text' && (
            <AvatarText avatar={item.avatar} text={item.text} />
          )}
          {item.kind === 'text' && <div className='ListRow-description-content-text'>{item.text}</div>}
        </div>
      ))}
    </div>
  );
}

PropTypes.Description = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      kind: PropTypes.oneOf(Object.values(KINDS)).isRequired,
      icon: PropTypes.element,
      text: PropTypes.string,
      avatar: PropTypes.element,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

PropTypes.defaultProps = { data: [] };

export default Description;
