export const MS_PER_MINUTE = 60000;
export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;
export const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * MINUTES_PER_HOUR;
export const HOURS_PER_DAY = 24;
export const SECONDS_PER_DAY = SECONDS_PER_HOUR * HOURS_PER_DAY;
export const DAYS_PER_NONE_LEAPYEAR = 365;
export const SECONDS_PER_365_DAYS = SECONDS_PER_DAY * DAYS_PER_NONE_LEAPYEAR;

/**
 * A utility function for setting the day period of the time string to lowercase:
 * AM -> am
 * PM -> pm
 *
 *
 * @function
 * @param timeString {string} - The string time to format the period for
 * @returns {string} A formatted time string with the day period set to lowercase
 *
 * @example
 * 09:00am
 */
const periodToLowerCase = (timeString) =>
  timeString.replace(' AM', 'am').replace(' PM', 'pm');

/**
 * A utility function for the scheduler component to format a date string to follow the following format:
 * HH:MM[am/pm]
 *
 *
 * @function
 * @param dateString {string} - The string date representing the start or end of the time window.
 * @param roundToNearest {string} - String value representing the unit of time to round the time up/down to
 * @param is24HourFormat - Boolean value for displaying time format - Shows 24 hour clock if true
 * @returns {string} A formatted string time window for the scheduler component
 *
 * @example
 * 09:00am
 */
const getSchedulerTimeWindowString = (
  dateString,
  roundToNearest,
  is24HourFormat
) => {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
  };

  const date = new Date(dateString);

  // TODO: requirement change - possibly not supporting; remove?
  if (roundToNearest === 'hour') {
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
    date.setMinutes(0, 0, 0);
  } else if (roundToNearest === 'halfHour') {
    date.setMinutes(Math.ceil(date.getMinutes() / 30) * 30);
  }

  return periodToLowerCase(
    date.toLocaleString(is24HourFormat ? 'en-GB' : 'en-US', options)
  );
};

/**
 * A utility function for converting seconds to hours with customizable decimal places.
 *
 * @function
 * @param {number} seconds - The number of seconds to convert to hours.
 * @param {number} [decimalPlaces=2] - The number of decimal places to include in the result.
 * @returns {string} The number of hours formatted as a string.
 *
 * @example
 * const hours = secondsToHours(3600); // Returns '1.00'
 */
const secondsToHours = (seconds, decimalPlaces = 2) => {
  const hours = seconds / SECONDS_PER_HOUR;

  return hours.toFixed(decimalPlaces);
};

/**
 * A utility function to return the 3-letter abbreviated localized time zone
 *
 * @function
 * @returns {string} 3-letter abbreviated local time zone in all caps
 *
 * @example
 * PST
 */
const getLocalizedTimeZone = () =>
  new Date().toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();

/**
 * A utility function to format a time string to follow the following format:
 * HH:MM[am/pm] [3 letter Timezone]
 *
 * @function
 * @param {string} date - The string date that should be formatted.
 * @returns {string} A formatted localized time string.
 *
 * @example
 * formatLocalizedTime("Sat Mar 09 2024 00:00:01 GMT+0530 (India Standard Time)");
 * // Output example: "11:30 AM PST"
 */
const formatLocalizedTimeShort = (date) => {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  };

  return periodToLowerCase(new Date(date).toLocaleString('en-US', options));
};

export {
  formatLocalizedTimeShort,
  getLocalizedTimeZone,
  getSchedulerTimeWindowString,
  periodToLowerCase,
  secondsToHours,
};
