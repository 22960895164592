import './SpvFutureApprovedSession.css';

import { formatLocalizedDateTime, localize } from '@saviynt/common';
import { Icon, TabContent, TabGroup, TabItem } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  ModuleDetails,
  RequestDetails,
  Session,
} from '../../../../../models/PamModels';
import { msgs } from '../../../constants';
import SpvEndpoint from '../../SpvEndpoint/SpvEndpoint';
import SpvSection from '../../SpvSection/SpvSection';

function SpvFutureApprovedSession({
  userAccessData,
  accountDetails,
  endpointDetails,
  pendingSession,
  requestDetails,
  dataTestId,
  className,
}) {
  const classes = classnames('SpvFutureApprovedSession', className);

  const [activeTab, setActiveTab] = useState('1');
  const intl = useIntl();

  const handleChange = (value) => {
    setActiveTab(value);
  };

  const timeHasFields =
    pendingSession.sessionStartDate || pendingSession.sessionEndDate;

  const targetSystemHasFields =
    endpointDetails.customproperty9 || endpointDetails.customproperty35;

  const isCredentialless =
    pendingSession.pamType?.toUpperCase() === 'CREDENTIALLESS';
  const remoteAppName = userAccessData.remoteAppMetadata?.displayName;

  const showRemoteAppName =
    isCredentialless && remoteAppName && remoteAppName !== 'Terminal';

  const approvedBy =
    requestDetails.endpoints.length > 0 &&
    requestDetails.endpoints[0].approvals[0].assignee[0].displayName;
  const approvalTime = requestDetails.endpoints[0].approvals[0].approvalDate;
  const isApproved = approvedBy && approvalTime;

  const requestDetailsHasFields =
    pendingSession.requestId ||
    (requestDetails.endpoints.length > 0 &&
      requestDetails.endpoints[0].businessJustification) ||
    isApproved;

  const sessionAccountHasFields =
    pendingSession?.accountName ||
    accountDetails.description ||
    accountDetails.pamAccountType ||
    accountDetails.accountConfig?.authenticationType;

  return (
    <section className={classes} data-testid={dataTestId}>
      <TabGroup
        className='SpvFutureApprovedSession-tabGroup'
        value={activeTab}
        onChange={handleChange}>
        <TabItem
          label={localize(intl, msgs.pam.sidePanel.session)}
          value='1'
          icon={
            <Icon kind='PrivilegedSession' size='medium' color='neutral-1000' />
          }
        />
        <TabItem
          label={localize(intl, msgs.pam.sidePanel.asset)}
          value='2'
          icon={
            <Icon kind='AssetTargetSystem' size='medium' color='neutral-1000' />
          }
        />
      </TabGroup>
      <TabContent
        className='SpvFutureApprovedSession-tabContent'
        value={activeTab}
        tabValue='1'>
        {timeHasFields && (
          <SpvSection
            title={localize(intl, msgs.pam.sidePanel.time)}
            fields={[
              {
                label: localize(intl, msgs.pam.sidePanel.startTime),
                value: formatLocalizedDateTime(pendingSession.sessionStartDate),
              },
              {
                label: localize(intl, msgs.pam.sidePanel.endTime),
                value: formatLocalizedDateTime(pendingSession.sessionEndDate),
              },
            ]}
          />
        )}
        {targetSystemHasFields && (
          <SpvSection
            className={timeHasFields && 'SpvFutureApprovedSession-tabSection'}
            title={localize(intl, msgs.pam.sidePanel.targetSystem)}
            fields={[
              {
                label: localize(intl, msgs.pam.sidePanel.targetServer),
                value: endpointDetails.customproperty9,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.portNumber),
                value: endpointDetails.customproperty35,
              },
            ]}
          />
        )}
        {showRemoteAppName && (
          <SpvSection
            className={
              (timeHasFields || targetSystemHasFields) &&
              'SpvActiveSession-tabSection'
            }
            title={localize(intl, msgs.pam.sidePanel.remoteApplication)}
            fields={[
              {
                label: localize(intl, msgs.pam.sidePanel.name),
                value: remoteAppName,
              },
            ]}
          />
        )}
        {requestDetailsHasFields && (
          <SpvSection
            className={
              (showRemoteAppName || timeHasFields || targetSystemHasFields) &&
              'SpvFutureApprovedSession-tabSection'
            }
            title={localize(intl, msgs.pam.sidePanel.requestDetails)}
            fields={[
              {
                label: localize(intl, msgs.pam.sidePanel.requestId),
                value: pendingSession.requestId,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.justification),
                value: requestDetails.endpoints[0].businessJustification,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.approvedBy),
                value: approvedBy,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.approvalTime),
                value: formatLocalizedDateTime(`${approvalTime}Z`),
              },
            ]}
          />
        )}
        {sessionAccountHasFields && (
          <SpvSection
            className={
              requestDetailsHasFields && 'SpvFutureApprovedSession-tabSection'
            }
            title={localize(intl, msgs.pam.sidePanel.account)}
            fields={[
              {
                label: localize(intl, msgs.pam.sidePanel.name),
                value: pendingSession?.accountName,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.platform),
                value: accountDetails.platformType,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.description),
                value: accountDetails.description,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.accountType),
                value: accountDetails.pamAccountType,
              },
              {
                label: localize(
                  intl,
                  msgs.pam.sidePanel.authenticationMechanism
                ),
                value: accountDetails.accountConfig?.authenticationType,
              },
            ]}
          />
        )}
      </TabContent>
      <TabContent
        className='SpvFutureApprovedSession-assetTab'
        value={activeTab}
        tabValue='2'>
        <SpvEndpoint data={endpointDetails} />
      </TabContent>
    </section>
  );
}

SpvFutureApprovedSession.propTypes = {
  userAccessData: ModuleDetails,
  accountDetails: ModuleDetails,
  endpointDetails: ModuleDetails,
  pendingSession: Session,
  requestDetails: RequestDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvFutureApprovedSession.defaultProps = {
  userAccessData: null,
  accountDetails: null,
  endpointDetails: null,
  pendingSession: null,
  requestDetails: null,
  dataTestId: null,
  className: null,
};

export default SpvFutureApprovedSession;
