import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './TableRow.css';

function TableRow({ className, isHeader, dataTestId, children }) {
  return (
    <tr
      className={classnames(
        'TableRowDS',
        isHeader && 'TableRowDS-header',
        className
      )}
      data-testid={dataTestId}>
      {children}
    </tr>
  );
}

TableRow.propTypes = {
  className: PropTypes.string,
  isHeader: PropTypes.bool,
  dataTestId: PropTypes.string,
  children: PropTypes.node,
};
TableRow.defaultProps = {
  className: null,
  isHeader: false,
  dataTestId: null,
  children: null,
};

export default TableRow;
