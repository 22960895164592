import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import CheckboxCore from '../CheckboxCore/CheckboxCore';

import './CheckboxBase.css';

const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

function CheckboxBase(props) {
  const {
    name,
    size,
    isChecked,
    isDisabled,
    isIndeterminate,
    handleChange,
    showHoverEffect,
    tabIndex,
    dataTestId,
    error,
  } = props;
  const classes = classnames(
    'Checkbox',
    isDisabled && 'Checkbox--disabled',
    !showHoverEffect && 'Checkbox--hoverEffectDisabled',
    error && 'Checkbox--error'
  );

  return (
    <div className={`Checkbox--base ${classes}`} data-testid={dataTestId}>
      <label className={`Checkbox-label`}>
        <CheckboxCore
          name={name}
          size={size}
          isChecked={isChecked}
          isDisabled={isDisabled}
          isIndeterminate={isIndeterminate}
          handleChange={handleChange}
          tabIndex={isDisabled ? '-1' : tabIndex}
          dataTestId={dataTestId}
        />
      </label>
    </div>
  );
}

CheckboxBase.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf([SIZES.small, SIZES.medium, SIZES.large]),
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  handleChange: PropTypes.func,
  showHoverEffect: PropTypes.bool,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.string,
  dataTestId: PropTypes.string,
};

CheckboxBase.defaultProps = {
  name: null,
  size: SIZES.medium,
  isChecked: undefined,
  isDisabled: false,
  isIndeterminate: false,
  handleChange: null,
  showHoverEffect: true,
  tabIndex: 0,
  error: null,
  dataTestId: null,
};

export default CheckboxBase;
