import { Global404, NotFound, Route404 } from '@saviynt/common';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import LocalizedWrapper from '../components/LandingPage/LocalizedWrapper';
import LandingPage from './LandingPage/LandingPage';
import CheckOutCredential from './ModalPages/CheckOutCredential';
import ConnectToSession from './ModalPages/ConnectToSession';

const queryClient = new QueryClient();

function Routes() {
  const allowedRoutes = {
    staticRoutes: [
      '/request/privilegedAccess',
      '/request/privilegedAccess/check-out-credential',
      '/request/privilegedAccess/connect-to-session',
      '/request/privilegedAccess/launch/:launchId',],
    dynamicRoutes: [],
  };

  const pamGen2Flag = window.localStorage.getItem('pamGen2');

  const showGen2Pages = Boolean(parseInt(pamGen2Flag, 10));

  return (
    <div>
      <LocalizedWrapper>
        <QueryClientProvider client={queryClient}>
          <Suspense key='PAM' fallback={() => {}}>
            <Route404
              routes={allowedRoutes}
              render={(path) => <Global404 path={path} />}>
              <Switch>
                {showGen2Pages && (
                  <Route
                    exact
                    path='/request/privilegedAccess'
                    component={LandingPage}
                  />
                )}
                {showGen2Pages && (
                  <Route
                    exact
                    path='/request/privilegedAccess/check-out-credential'
                    component={CheckOutCredential}
                  />
                )}
                {showGen2Pages && (
                  <Route
                    exact
                    path='/request/privilegedAccess/connect-to-session'
                    component={ConnectToSession}
                  />
                )}
                {showGen2Pages && <Route component={NotFound} />}
              </Switch>
            </Route404>
          </Suspense>
        </QueryClientProvider>
      </LocalizedWrapper>
    </div>
  );
}

export default Routes;
