import { InSeparator, InterPoint } from '@saviynt/common';
import { Icon, ModalPageHeader, Typography } from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ModuleDetails } from '../../models/PamModels';

function ModalFormHeader({ title, endpointDetails, dataTestId }) {
  const history = useHistory();

  const closeHandler = () => {
    history.goBack();
  };

  const getSubHeadingContent = () => (
    <div className='ModalPage-subHeadingContent'>
      <Typography kind='body2' className='ModalPage-subHeadingContentBody'>
        {endpointDetails?.endpointName || endpointDetails?.displayName}
      </Typography>
      <InterPoint color='neutralSubtlest' />
      <Icon
        kind={endpointDetails?.assetType}
        color='neutral-000'
        size='small'
      />
      <Typography kind='body2' className='ModalPage-subHeadingContentBody'>
        {endpointDetails?.assetType}
      </Typography>
      <InSeparator color='neutralInverse' />
      <Typography kind='body2' className='ModalPage-subHeadingContentBody'>
        {endpointDetails?.platformType}
      </Typography>
    </div>
  );

  return (
    <ModalPageHeader
      title={title}
      subHeadingContent={getSubHeadingContent()}
      closeHandler={() => closeHandler()}
      className='ModalPage-header'
    />
  );
}

ModalFormHeader.propTypes = {
  title: PropTypes.string,
  endpointDetails: PropTypes.shape(ModuleDetails),
  dataTestId: PropTypes.string,
};

ModalFormHeader.defaultProps = {
  title: null,
  endpointDetails: null,
  dataTestId: null,
};

export default ModalFormHeader;
