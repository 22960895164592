import './SpvSection.css';

import { Typography } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function SpvSection({ title, fields, children, dataTestId, className }) {
  const classes = classnames('SpvSection', className);

  return (
    <section className={classes} data-testid={dataTestId}>
      <Typography kind='h4' className='SpvSection-title'>
        {title}
      </Typography>
      {children}
      {fields.map((field) =>
        field.value ? (
          <div key={field.label} className='SpvSection-field'>
            <Typography kind='body3' className='SpvSection-field'>
              {field.label}
            </Typography>
            <Typography kind='body2' className='SpvSection-value'>
              {field.value}
            </Typography>
          </div>
        ) : null
      )}
    </section>
  );
}

SpvSection.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.element,
      ]),
    })
  ),
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvSection.defaultProps = {
  title: null,
  fields: [],
  dataTestId: null,
  children: null,
  className: null,
};

export default SpvSection;
