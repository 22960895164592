import React, { useCallback, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ApplicationIcon from '../ApplicationIcon/ApplicationIcon';
import Box from '../Box/Box';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import FooterActionBar from '../FooterActionBar/FooterActionBar';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';

import './SidePanel.css';

function SidePanel({
  dataTestId,
  className,
  layoutClassName,
  headerClassName,
  footerClassName,
  isOpen,
  footerActionBarChildren,
  onClose,
  isScrolled,
  layout,
  header,
}) {
  const classes = classnames('SidePanel', className);
  const headerClasses = classnames('SidePanel-header', headerClassName);
  const layoutClasses = classnames('SidePanel-layout', layoutClassName);
  const footerClasses = classnames('SidePanel-footer', footerClassName);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    },
    [isOpen, onClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Box>
      {isOpen && (
        <div id='SidePanel' className={classes} data-testid={dataTestId}>
          <div className={headerClasses}>
            <ApplicationIcon kind='logo' logo={header.icon} size='large' />
            <div className='SidePanel-header-text'>
              <Typography kind='h3' className='SidePanel-header-title'>
                {header.title}
              </Typography>
              <Typography kind='body3' className='SidePanel-header-description'>
                {header.description}
              </Typography>
            </div>
            <div>
              <ButtonIcon
                size='large'
                kind='ghost'
                icon={<Icon kind='close' color='neutral-100' size='medium' />}
                onClick={onClose}
              />
            </div>
          </div>
          <div className={layoutClasses}>{layout}</div>
          <div className={footerClasses}>
            <FooterActionBar isScrolled={isScrolled}>
              {footerActionBarChildren}
            </FooterActionBar>
          </div>
        </div>
      )}
    </Box>
  );
}

SidePanel.propTypes = {
  dataTestId: PropTypes.string,
  isOpen: PropTypes.bool,
  layout: PropTypes.element,
  footerActionBarChildren: PropTypes.node,
  onClose: PropTypes.func,
  isScrolled: PropTypes.bool,
  header: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
  }),
  className: PropTypes.string,
  layoutClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  footerClassName: PropTypes.string,
};

SidePanel.defaultProps = {
  dataTestId: null,
  isOpen: false,
  layout: null,
  footerActionBarChildren: null,
  onClose: () => {},
  isScrolled: false,
  header: null,
  className: null,
  layoutClassName: null,
  headerClassName: null,
  footerClassName: null,
};

export default SidePanel;
