import React, { cloneElement, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import useOutsideClickEffect from '../../../hooks/use-outsideclickeffect';
import InputField from '../../Input/InputField/InputField';
import MenuItem from '../MenuItem/MenuItem';

import './MenuInput.css';

/**
 * - MenuInput renders a dropdown which can be typed into to filter options.
 * - Parent should define the width and not have position:static.
 * #### WIP Todo (CPAM):
 * - Finish styling.
 * - Expand menu up/down logic (if applicable).
 */

function MenuInput({
  options,
  onChange,
  backgroundColor,
  CriticalHelperText,
  isCritical,
  isReadOnly,
  className,
  dataTestId,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState(''); // for input search.

  const wrapperRef = useRef(null);

  useOutsideClickEffect(wrapperRef, () => setIsOpen(false), isOpen);

  const selectedElement =
    options.find((option) => option.isChecked) || options[0];

  useEffect(() => {
    if (selectedElement) {
      setSearchText(selectedElement.label);
    }
  }, [selectedElement]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleBlur = () => {
    const matchingOption = options.find(
      (option) => option.label.toLowerCase() === searchText.toLowerCase()
    );

    if (!matchingOption) {
      setSearchText(selectedElement.label);
    }
  };

  const handleSelectFirstOption = () => {
    const matchingOption = options.find((option) =>
      option.label.toLowerCase().startsWith(searchText.toLowerCase())
    );

    if (isOpen && matchingOption) {
      handleOptionClick(matchingOption);
    }
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowDown':
        setIsOpen(true); // open dropdown
        break;
      case 'Escape':
        setIsOpen(false); // close dropdown
        break;
      case 'Tab':
        handleBlur(); // ensure menu input value is valid
        break;
      case 'Enter':
        handleSelectFirstOption(); // select first option
        break;
      default:
        break;
    }
  };

  const trigger = (
    <InputField
      name='MenuInput-InputField'
      placeholder=''
      onClick={() => setIsOpen(!isOpen)}
      suffixButtonType='dropdown'
      suffixIsToggle={isOpen}
      suffixOnClick={() => setIsOpen(!isOpen)}
      value={searchText}
      setValue={setSearchText}
      onChange={(e) => {
        setSearchText(e.target.value);
      }}
      onKeyDown={handleKeyDown}
      backgroundColor={backgroundColor}
      isCritical={isCritical}
      CriticalHelperText={CriticalHelperText}
      isReadOnly={isReadOnly}
    />
  );
  const renderTrigger = () =>
    cloneElement(trigger, {
      isOpen,
      onClick: toggleDropdown,
      value: searchText,
      onFocus: () => setIsOpen(true),
      dataTestId: 'menuinput-trigger',
    });

  const renderMenuItems = () => {
    const filteredOptions =
      isOpen && searchText !== selectedElement?.label
        ? options.filter((option) =>
            option.label.toLowerCase().includes(searchText.toLowerCase())
          )
        : options;

    return filteredOptions.map((option) => (
      <React.Fragment key={option.value}>
        <MenuItem
          key={option.value}
          option={option}
          onClick={() => {
            !option.isDisabled && handleOptionClick(option);
          }}
          isDisabled={option.isDisabled}
          tabIndex={isOpen ? 0 : -1}
        />
      </React.Fragment>
    ));
  };

  return (
    <div
      className={classnames('MenuInput', className)}
      data-testid={dataTestId}
      ref={wrapperRef}
      role='combobox'
      aria-controls='custom-select-listbox'
      aria-expanded={isOpen}
      aria-label={selectedElement?.label}>
      {renderTrigger()}
      <div
        className={classnames(
          'Menu-dropdown',
          isOpen && 'Menu-dropdown--isVisible'
        )}>
        <ul className='Menu-items' role='listbox' tabIndex='-1'>
          {renderMenuItems()}
        </ul>
      </div>
    </div>
  );
}

MenuInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      isDisabled: PropTypes.bool,
      isChecked: PropTypes.bool,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  CriticalHelperText: PropTypes.node,
  isCritical: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

MenuInput.defaultProps = {
  backgroundColor: 'neutral',
  CriticalHelperText: null,
  isCritical: false,
  isReadOnly: false,
  className: null,
  dataTestId: null,
};

export default MenuInput;
