import {
  formatDateToReduxStore,
  getCurrentDate,
  Logger,
  MS_PER_MINUTE,
  pluralizeString,
  SECONDS_PER_365_DAYS,
  SECONDS_PER_DAY,
  SECONDS_PER_HOUR,
  SECONDS_PER_MINUTE,
} from '@saviynt/common';
import {
  addDays,
  differenceInSeconds,
  format,
  isBefore,
  parseISO,
} from 'date-fns';
import { useEffect, useState } from 'react';

import {
  ACCOUNT_JIT_SELECTION,
  AVG_DAYS_PER_MONTH,
  DURATION_TAB_TYPES,
  MODAL_PAGE_KINDS,
  OFFSET_SECONDS_PER_YEAR,
  SESSION_STATUSES,
} from '../../components/ModalPageForms/constants';
import { handleCredentialFormDates } from '../../store/actions/index';
import { getSessionsAccountScheduleApi } from '../../utilities/api/getDataFromApi';

const SECONDS_IN_FIVE_MINUTES = SECONDS_PER_MINUTE * 5;

const ModalPageDurationService = (
  modalPageKind,
  isNowTab,
  isRemoteAppEnabled,
  accountSelection,
  appLauncherSelection,
  isDurationCustom,
  shouldDurationContinue,
  nowTabStartDate,
  nowTabEndDate,
  setNowTabStartDate,
  setNowTabEndDate,
  futureTabStartDate,
  futureTabEndDate,
  setDurationAccordionValue,
  setNowStartDateOfNextCredential,
  setFutureStartDateOfNextCredential,
  userName,
  endpointKey,
  isCOCorExclusive,
  dispatch
) => {
  const [selectedAccountSessions, setSelectedAccountSessions] = useState([]);
  const [range, setRange] = useState(null);
  const [shouldCheckScheduleEveryMinute, setShouldCheckScheduleEveryMinute] =
    useState(false);

  const sessionsWithValidStatusCOC = selectedAccountSessions.filter(
    (session) => {
      const isValidStatusForUser =
        // Filter in matching usernames with any status except COMPLETED
        (session.sessionStatus !== SESSION_STATUSES.COMPLETED &&
          session.username === userName) ||
        // Filter in undefined status only for matching username
        (session.sessionStatus === SESSION_STATUSES.PENDING &&
          session.username === userName) ||
        // Keep the original condition for other usernames
        (session.sessionStatus !== SESSION_STATUSES.PENDING &&
          session.sessionStatus !== SESSION_STATUSES.COMPLETED);

      // remoteAppMetadata check for the same user
      const isDifferentAppForUser = !(
        session.remoteAppMetadata?.name !== appLauncherSelection?.key &&
        session.username === userName
      );

      return isValidStatusForUser && isDifferentAppForUser;
    }
  );

  const sessionsWithValidStatusCTS = selectedAccountSessions.filter(
    (session) => {
      const isValidStatusForUser =
        session.username === userName &&
        // Removing completed sessions here
        session.sessionStatus !== SESSION_STATUSES.COMPLETED;

      // remoteAppMetadata check for the same user
      const isDifferentAppForUser = !(
        session.remoteAppMetadata?.name !== appLauncherSelection?.key &&
        session.username === userName
      );

      return isValidStatusForUser && isDifferentAppForUser;
    }
  );

  const sessionsToBeIterated = isCOCorExclusive
    ? sessionsWithValidStatusCOC
    : sessionsWithValidStatusCTS;

  const parseAccordionValueNowTime = (durInSecs) => {
    if (durInSecs === null) return '';

    const roundedDurInSecs = durInSecs + SECONDS_PER_MINUTE / 2;

    const days = Math.floor(roundedDurInSecs / SECONDS_PER_DAY);
    const hoursAfterDays = Math.floor(
      (roundedDurInSecs % SECONDS_PER_DAY) / SECONDS_PER_HOUR
    );
    const remainingMinutes = Math.floor(
      (roundedDurInSecs % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE
    );

    if (days > 0) {
      let timeString = `Next ${days} ${pluralizeString('day', days !== 1)}`;

      if (hoursAfterDays > 0) {
        timeString += ` ${hoursAfterDays} ${pluralizeString(
          'hour',
          hoursAfterDays !== 1
        )}`;
      }

      if (remainingMinutes > 0) {
        timeString += ` ${remainingMinutes} ${pluralizeString(
          'minute',
          remainingMinutes !== 1
        )}`;
      }

      return timeString;
    }

    if (hoursAfterDays > 0) {
      let timeString = `Next ${hoursAfterDays} ${pluralizeString(
        'hour',
        hoursAfterDays !== 1
      )}`;

      if (remainingMinutes > 0) {
        timeString += ` ${remainingMinutes} ${pluralizeString(
          'minute',
          remainingMinutes !== 1
        )}`;
      }

      return timeString;
    }

    if (remainingMinutes > 0) {
      return `Next ${remainingMinutes} ${pluralizeString(
        'minute',
        remainingMinutes !== 1
      )}`;
    }

    return 'Next < 1 min';
  };

  const parseAccordionValueFutureTime = (durInSecs) => {
    const months = Math.floor(
      durInSecs / (AVG_DAYS_PER_MONTH * SECONDS_PER_DAY)
    );
    const daysAfterMonths = Math.floor(
      (durInSecs % (AVG_DAYS_PER_MONTH * SECONDS_PER_DAY)) / SECONDS_PER_DAY
    );
    const hours = Math.floor((durInSecs % SECONDS_PER_DAY) / SECONDS_PER_HOUR);
    const minutes = Math.floor(
      (durInSecs % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE
    );

    let durationStr = '';

    if (months > 0) {
      durationStr += `${months} ${pluralizeString('month', months !== 1)} `;
    }

    if (daysAfterMonths > 0) {
      durationStr += `${daysAfterMonths} ${pluralizeString(
        'day',
        daysAfterMonths !== 1
      )} `;
    }

    if (hours > 0) {
      durationStr += `${hours} ${pluralizeString('hour', hours !== 1)} `;
    }

    if (minutes > 0) {
      durationStr += `${minutes} ${pluralizeString('minute', minutes !== 1)}`;
    }

    durationStr = durationStr.trim();

    const dateFnsFormatString = 'MM/dd/yyyy, h:mm a';

    const formatDateToUI = (date) => format(date, dateFnsFormatString);

    return `From ${formatDateToUI(futureTabStartDate)} to ${formatDateToUI(
      futureTabEndDate
    )} (${durationStr})`;
  };

  useEffect(() => {
    let interval;

    if (isNowTab) {
      interval = setInterval(() => {
        const newStartDate = getCurrentDate();

        setNowTabStartDate(newStartDate);
        setShouldCheckScheduleEveryMinute(true);

        // If range is set, calculate new end date based on the range
        if (range) {
          const newEndDate = new Date(newStartDate.getTime() + range * 1000);

          setNowTabEndDate(newEndDate);
        }
      }, MS_PER_MINUTE);
    } else {
      interval = setInterval(() => {
        setShouldCheckScheduleEveryMinute(true);
      }, MS_PER_MINUTE);
    }

    return () => clearInterval(interval);
  }, [isNowTab, range]);

  useEffect(() => {
    if (nowTabEndDate && !range) {
      const newStartDate = getCurrentDate();

      setRange(differenceInSeconds(nowTabEndDate, newStartDate));
    }
  }, [nowTabEndDate]);

  useEffect(() => {
    // Avoid dispatch if dates are not set
    if (!isNowTab || !nowTabStartDate || !nowTabEndDate) return;
    const clipRange = differenceInSeconds(nowTabEndDate, nowTabStartDate);

    // Avoid dispatch if dates are to short
    if (clipRange < SECONDS_IN_FIVE_MINUTES) return;

    const clipRangeNow =
      clipRange > SECONDS_PER_365_DAYS ? SECONDS_PER_365_DAYS : clipRange;

    if (shouldDurationContinue) {
      setDurationAccordionValue(parseAccordionValueNowTime(clipRangeNow));
    }
  }, [
    isNowTab,
    accountSelection,
    isDurationCustom,
    shouldDurationContinue,
    nowTabStartDate,
    nowTabEndDate,
  ]);

  useEffect(() => {
    // Avoid dispatch if dates are not set
    if (
      isNowTab ||
      !futureTabStartDate ||
      !futureTabEndDate ||
      !shouldDurationContinue
    )
      return;
    const clipRange = differenceInSeconds(futureTabEndDate, futureTabStartDate);

    // Avoid dispatch if dates are to short
    if (clipRange < SECONDS_IN_FIVE_MINUTES) return;

    const clipRangeFuture =
      clipRange > OFFSET_SECONDS_PER_YEAR ? OFFSET_SECONDS_PER_YEAR : clipRange;

    setDurationAccordionValue(parseAccordionValueFutureTime(clipRangeFuture));
  }, [
    isNowTab,
    accountSelection,
    isDurationCustom,
    shouldDurationContinue,
    futureTabStartDate,
    futureTabEndDate,
  ]);

  useEffect(() => {
    if (shouldDurationContinue && isNowTab) {
      dispatch(
        handleCredentialFormDates(
          formatDateToReduxStore(nowTabStartDate),
          formatDateToReduxStore(nowTabEndDate),
          'Date Range success'
        )
      );
    } else if (shouldDurationContinue && !isNowTab) {
      dispatch(
        handleCredentialFormDates(
          formatDateToReduxStore(futureTabStartDate),
          formatDateToReduxStore(futureTabEndDate),
          'Date Range success'
        )
      );
    }
  }, [shouldDurationContinue]);

  // Get schedule. Should only be needed once per account switch as it is based solely on the day.
  useEffect(() => {
    if (accountSelection?.value) {
      setNowStartDateOfNextCredential(null);
      setFutureStartDateOfNextCredential(null);

      const formatDate = (date) => format(date, 'yyyy-MM-dd');
      const validDayStart = getCurrentDate();
      const formattedStartDate = formatDate(validDayStart);
      const formattedEndDate = formatDate(addDays(validDayStart, 365));

      // Check Out Credential
      const isCOCModalOrNoRemoteDetials =
        modalPageKind === MODAL_PAGE_KINDS.COC || !isRemoteAppEnabled;
      // Remote App Enabled
      const isRAEWithAppSelection = isRemoteAppEnabled && appLauncherSelection;
      // JIT Selection
      const isJITSelection = accountSelection === ACCOUNT_JIT_SELECTION;

      if (isCOCModalOrNoRemoteDetials || isRAEWithAppSelection)
        getSessionsAccountScheduleApi(
          isJITSelection ? null : accountSelection.value,
          endpointKey,
          formattedStartDate,
          formattedEndDate,
          appLauncherSelection ? appLauncherSelection.key : null
        )
          .then((response) => {
            if (Array.isArray(response.result.sessions)) {
              const extractedDates = response.result.sessions
                .filter((session) => {
                  if (isJITSelection)
                    return (
                      session.accountConfig?.['Saviynt-Status']?.justInTime ===
                        'true' && session.username === userName
                    );

                  return session;
                })
                .map((session) => ({
                  accountName: session.accountName,
                  endpointDisplayName: session.endpointDisplayName,
                  endpointKey: session.endpointKey,
                  endpointName: session.endpointName,
                  federatedAccount: session.federatedAccount,
                  firstname: session.firstname,
                  lastname: session.lastname,
                  pamType: session.pamType,
                  requestAccessKey: session.requestAccessKey,
                  requestAccessStatus: session.requestAccessStatus,
                  requestId: session.requestId,
                  requestType: session.requestType,
                  sessionEndDate: session.sessionEndDate,
                  sessionStartDate: session.sessionStartDate,
                  sessionStatus: session.sessionStatus,
                  remoteAppMetadata: session.remoteAppMetadata,
                  accountKey: session.accountKey,
                  username: session.username,
                }));

              setSelectedAccountSessions(extractedDates);
            } else {
              Logger.error('Account sessions is not an array:', response);
            }
          })
          .catch((error) => {
            Logger.error('Error fetching sessions:', error);
          });
    }
  }, [accountSelection, appLauncherSelection]);

  const getEarliestSession = (filteredCreds, type) => {
    if (filteredCreds?.length > 0) {
      const earliestSessionObj = filteredCreds?.reduce(
        (earliest, currentSession) => {
          const currentStartDate = new Date(currentSession.sessionStartDate);
          const isNewEarliestSession =
            !earliest || currentStartDate < new Date(earliest.sessionStartDate);

          if (isNewEarliestSession) {
            return currentSession;
          }

          return earliest;
        }
      );

      if (type === DURATION_TAB_TYPES.NOW)
        setNowStartDateOfNextCredential(earliestSessionObj.sessionStartDate);

      if (type === DURATION_TAB_TYPES.FUTURE)
        setFutureStartDateOfNextCredential(earliestSessionObj.sessionStartDate);
    } else {
      setFutureStartDateOfNextCredential(null);
    }
  };

  useEffect(() => {
    const hasSessions = sessionsToBeIterated?.length > 0;

    if (hasSessions) {
      const now = getCurrentDate();

      const filteredExpiredCredentials = sessionsToBeIterated.filter(
        (session) => {
          const sessionEndDate = parseISO(session.sessionEndDate);

          return isBefore(now, sessionEndDate);
        }
      );

      getEarliestSession(filteredExpiredCredentials, DURATION_TAB_TYPES.NOW);
    }

    if (shouldCheckScheduleEveryMinute) {
      setShouldCheckScheduleEveryMinute(false);
    }
  }, [selectedAccountSessions, shouldCheckScheduleEveryMinute]);

  useEffect(() => {
    const hasFutureDateSessionsInFuture =
      futureTabStartDate && sessionsToBeIterated?.length > 0 && !isNowTab;

    if (hasFutureDateSessionsInFuture) {
      const dateObjectFutureStartDate = new Date(futureTabStartDate);
      const filteredExpiredCredentials = sessionsToBeIterated.filter(
        (session) => {
          const sessionEndDate = parseISO(session.sessionEndDate);

          // Keep the session only if the end date is in the future
          return isBefore(dateObjectFutureStartDate, sessionEndDate);
        }
      );

      getEarliestSession(filteredExpiredCredentials, DURATION_TAB_TYPES.FUTURE);
    }

    if (shouldCheckScheduleEveryMinute) {
      setShouldCheckScheduleEveryMinute(false);
    }
  }, [
    isNowTab,
    futureTabStartDate,
    selectedAccountSessions,
    shouldCheckScheduleEveryMinute,
  ]);

  return { sessionsToBeIterated };
};

export default ModalPageDurationService;
