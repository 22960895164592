import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SessionStorageService from 'ui-common/src/utils/SessionStorageService';
import {
  appMessages,
} from 'ui-common/src/utils/LoginActions';
import {
  APP_CONSTANTS,
  LOCALE,
} from 'ui-common/src/constants';
import { LANGUAGE_CHANGE_STATE } from '../../../features/MainLayout/Login/constants';
import { InitializeUpdateLanguage } from '../../../actions/profileMenuAction';

const useStyles = makeStyles(() => ({
  constainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    '& h4': {
      fontSize: '18px',
      fontWeight: '500 !important',
    },
  },
  list: {
    fontSize: '16px',
  },
}));
const LanguageList = ({
  intl, onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [languageSupport, setLanguageSupport] = useState([]);

  const { editLanguage } = useSelector((state) => state.user || {});

  const { languagesSupported } = useSelector((state) => state.profile);

  const updateLanguage = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (editLanguage === LANGUAGE_CHANGE_STATE.SUCCESS) {
      if (updateLanguage) {
        updateLanguage();
      }
    }
  }, [editLanguage]);

  const changeLanguage = (lang) => {
    localStorage.setItem(LOCALE, lang);
    // reCreateMenu();
    const domainName = localStorage.getItem('localDomain') !== null
      ? localStorage.getItem('localDomain')
      : window.location.hostname.split('.')[0];
    const sessionItems = SessionStorageService.get(APP_CONSTANTS);
    const appConstants = sessionItems && sessionItems[domainName];

    // X-NOLoader should be true during login, so sent extra param

    dispatch(InitializeUpdateLanguage(lang));
    // dispatch(updateMuteEmailAndLanguage({ locale: lang }, !!noLoader));

    if (appConstants && Object.keys(appConstants).length) {
      dispatch(appMessages(lang, appConstants.GATEWAY_API_URL));
    }

    onClose();
    // setLangPopup(false);
  };

  const getSupportLanguageList = () => {
    const langs = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [lang] of Object.entries(languagesSupported)) {
      langs.push({ code: lang, value: languagesSupported[lang] });
    }

    setLanguageSupport(langs);
  };

  useEffect(() => {
    getSupportLanguageList();
  }, [languagesSupported]);

  return (
    <div className={classes.constainer}>
      <h4>
        {intl.formatMessage({
          id: 'RequestHome.Dialog.Title.Select.Language',
        })}
      </h4>
      <List>
        {languageSupport.map((langObj) => (
          <ListItem
            className={classes.list}
            button
            onClick={() => changeLanguage(langObj.code)}
          >
            {intl.formatMessage({
              id: `RequestHome.Dialog.Language.Option.${langObj.value}`,
            })}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

LanguageList.propTypes = {
  onClose: PropTypes.func.isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default injectIntl(LanguageList);
