import './SpfEndpoint.css';

import { Box, Button, Icon } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ModuleDetails } from '../../../../models/PamModels';
import { getAccountListApi } from '../../../../utilities/api/getDataFromApi';
import { MODAL_PAGE_KINDS } from '../../../ModalPageForms/constants';
import { ASSET_TYPES } from '../../constants';

const BUTTON_LABELS = {
  CHECKOUT_CREDENTIALS: 'Check Out Credentials',
  CONNECT_TO_SESSION: 'Connect to Session',
};

const PAM_TYPES = {
  CREDENTIAL: 'CREDENTIAL',
  CREDENTIALLESS: 'CREDENTIALLESS',
};

function SpfEndpoint({
  data,
  credentialRequestOnClick,
  dataTestId,
  className,
}) {
  const classes = classnames('SpfEndpoint', className);
  const [accounts, setAccounts] = useState([]);
  const [credentialedArray, setCredentialedArray] = useState([]);
  const [credentiallessArray, setCredentiallessArray] = useState([]);
  const isDBorServer =
    data.assetType === ASSET_TYPES.DATABASE ||
    data.assetType === ASSET_TYPES.SERVER;

  const isCredentialed = credentialedArray.length > 0;

  const isCredentialless =
    (accounts.length >= 0 && credentiallessArray.length >= 0 && isDBorServer) ||
    credentiallessArray.length > 0;

  const parseAccountsForPamType = () => {
    const credArray = [];
    const credlessArray = [];

    getAccountListApi(data.endpointKey)
      .then((response) => {
        setAccounts(response.result.accounts);

        if (response.result.accounts.length > 0) {
          response.result.accounts.forEach((account) => {
            if (account.pamType?.toUpperCase() === PAM_TYPES.CREDENTIAL) {
              credArray.push(account);
            } else if (
              account.pamType?.toUpperCase() === PAM_TYPES.CREDENTIALLESS
            ) {
              credlessArray.push(account);
            }
          });
        }

        setCredentialedArray(credArray);
        setCredentiallessArray(credlessArray);
      })
      .catch((error) => {
        console.error(
          'An error occurred while fetching the account list:',
          error
        );
      });
  };

  useEffect(() => {
    parseAccountsForPamType();
  }, [data]);

  const renderBothButtons = () => (
    <Box className='SpfEndpoint-twoButtons'>
      {isCredentialed && (
        <Button
          type='button'
          kind='outlined'
          className='SpfEndpoint-twoButtons--buttonStyle'
          onClick={() => credentialRequestOnClick(MODAL_PAGE_KINDS.COC)}
          leftIcon={
            <Icon
              kind='PasswordCredentialUnlock'
              size='smallMedium'
              color='neutral-100'
            />
          }>
          {BUTTON_LABELS.CHECKOUT_CREDENTIALS}
        </Button>
      )}
      {isCredentialless && (
        <Button
          type='button'
          kind='outlined'
          className='SpfEndpoint-twoButtons--buttonStyle'
          onClick={() => credentialRequestOnClick(MODAL_PAGE_KINDS.CTS)}
          leftIcon={
            <Icon kind='ConnectPlug' size='smallMedium' color='neutral-100' />
          }>
          {BUTTON_LABELS.CONNECT_TO_SESSION}
        </Button>
      )}
    </Box>
  );

  return (
    <Box className={classes} dataTestId={dataTestId}>
      {renderBothButtons()}
    </Box>
  );
}

SpfEndpoint.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  credentialRequestOnClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SpfEndpoint.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SpfEndpoint;
