import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './TabItem.css';

function TabItem({ label, isActive, onClick, icon, size, className }) {
  const classes = classNames(
    'TabItem',
    `TabItem--${size}`,
    isActive && 'TabItem--isActive',
    className
  );

  return (
    <div
      role='button'
      tabIndex='0'
      className={classes}
      onClick={onClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onClick();
        }
      }}>
      {icon}
      <div className='TabItem-label'>{label}</div>
    </div>
  );
}

TabItem.propTypes = {
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  size: PropTypes.oneOf(['medium', 'large']),
  className: PropTypes.string,
};

TabItem.defaultProps = {
  isActive: false,
  onClick: () => {},
  icon: null,
  size: 'large',
  className: null,
};

export default TabItem;
