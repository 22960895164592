import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';
import * as Icons from '../Icon/Icons';

import * as ApplicationIcons from './ApplicationIcons';

import './ApplicationIcon.css';

const SIZES = {
  xxSmall: 'xxSmall',
  xSmall: 'xSmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
  xLarge: 'xLarge',
  xxLarge: 'xxLarge',
};

const logoToComponentMap = {
  account: 'Account',
  ad: 'AD',
  aws: 'AWS',
  azure: 'Azure',
  azure_ad: 'AzureAD',
  gcp: 'GCP',
  okta: 'Okta',
  sap: 'SAP',
  saviynt: 'Saviynt',
  salesforce: 'Salesforce',
  webappconsole: 'WebAppConsole',
  application: 'Application',
  database: 'Database',
  server: 'Server',
  placeholder: 'Placeholder',
};

function ApplicationIcon({ className, size, icon, logo }) {
  const classes = classnames(
    'ApplicationIcon',
    className,
    size && `ApplicationIcon--${size}`
  );

  if (icon) {
    return (
      <div className={classes}>
        <Icon
          kind={icon}
          color='neutral-1000'
          className='ApplicationIcon-icon'
        />
      </div>
    );
  }

  const logoName =
    logoToComponentMap[logo.toLowerCase()] || logoToComponentMap.placeholder;
  const Logo = ApplicationIcons[logoName];

  return (
    <div className={classes}>
      <Logo />
    </div>
  );
}

ApplicationIcon.propTypes = {
  size: PropTypes.oneOf(Object.values(SIZES)),
  className: PropTypes.string,
  icon: PropTypes.oneOf(Icons),
  logo: PropTypes.oneOf(ApplicationIcons),
};

ApplicationIcon.defaultProps = {
  size: 'small',
  className: null,
  icon: '',
  logo: '',
};

export default ApplicationIcon;
