/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable object-curly-newline */
import './MpaJustificationDetails.css';

import { localize } from '@saviynt/common';
import {
  InlineMessage,
  InputField,
  Typography,
  Upload,
  useUploadFile,
} from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import useRequestUploadsMetaData from '../../../../hooks/use-requestuploadmetadata';
import { BYTES_PER_MB, msgs } from '../../constants';

function MpaJustificationDetails({
  justificationInputValue,
  setJustificationInputValue,
  isJustificationInputCritical,
  setIsJustificationInputCritical,
  setUploadedDetailFiles,
  setHasErrorInDetailFiles,
  ticketNumber,
  setTicketNumber,
}) {
  const intl = useIntl();

  const JUSTIF_LABEL_TEXT = localize(
    intl,
    msgs.pam.modalPage.justificationDetails.justifLabel
  );
  const JUSTIF_PLACEHOLDER_TEXT = localize(
    intl,
    msgs.pam.modalPage.justificationDetails.justifPlaceholder
  );
  const TICKET_LABEL_TEXT = localize(
    intl,
    msgs.pam.modalPage.justificationDetails.ticketLabel
  );
  const TICKET_PLACEHOLDER_TEXT = localize(
    intl,
    msgs.pam.modalPage.justificationDetails.ticketPlaceholder
  );
  const ATTACH_TITLE_TEXT = localize(
    intl,
    msgs.pam.modalPage.justificationDetails.attachTitle
  );

  const fileUploadMetaDataObj = useRequestUploadsMetaData();

  const {
    uploadedFiles,
    handleFileSelect,
    handleFileDelete,
    handleFileReupload,
    reuploadFileName,
    isMaxNumberUploaded,
    maxFileCount,
    hasAnyUploadErrors,
  } = useUploadFile(
    (fileUploadMetaDataObj?.MAX_FILE_SIZE ?? 0) / BYTES_PER_MB,
    fileUploadMetaDataObj?.MAX_FILE_COUNT
  );

  useEffect(() => {
    setUploadedDetailFiles(uploadedFiles);
    setHasErrorInDetailFiles(hasAnyUploadErrors);
  }, [uploadedFiles, hasAnyUploadErrors]);

  return (
    <div className='MpaJustificationDetails'>
      <div className='MpaJustificationDetails-fields'>
        <InputField
          kind='multiline'
          backgroundColor='secondary'
          name='Justification Details'
          placeholder={JUSTIF_PLACEHOLDER_TEXT}
          label={
            <Typography kind='label' htmlFor='Justification'>
              {JUSTIF_LABEL_TEXT}
            </Typography>
          }
          value={justificationInputValue}
          setValue={setJustificationInputValue}
          isCritical={isJustificationInputCritical}
          setIsCritical={setIsJustificationInputCritical}
          CriticalHelperText={
            <InlineMessage kind='missingRequiredField' size='small' />
          }
          minRequiredChars={3}
          isRequired
        />
        <InputField
          name='Ticket Number'
          placeholder={TICKET_PLACEHOLDER_TEXT}
          label={
            <Typography kind='label' htmlFor='Ticket Number (optional)'>
              {TICKET_LABEL_TEXT}
            </Typography>
          }
          backgroundColor='secondary'
          value={ticketNumber}
          setValue={setTicketNumber}
        />
        <div className='MpaJustificationDetails-attachments'>
          <Typography kind='h3'>{ATTACH_TITLE_TEXT}</Typography>
          <Upload
            kind='dropzone'
            isMultiple
            onFileSelect={handleFileSelect}
            onFileDelete={handleFileDelete}
            onFileReupload={handleFileReupload}
            uploadedFiles={uploadedFiles}
            acceptTypes={fileUploadMetaDataObj?.ALLOWED_FILE_TYPES}
            reuploadFileName={reuploadFileName}
            isMaxNumberUploaded={isMaxNumberUploaded}
            maxFileCount={maxFileCount}
          />
        </div>
      </div>
    </div>
  );
}

MpaJustificationDetails.propTypes = {
  justificationInputValue: PropTypes.string,
  setJustificationInputValue: PropTypes.func.isRequired,
  isJustificationInputCritical: PropTypes.bool.isRequired,
  setIsJustificationInputCritical: PropTypes.func.isRequired,
  setUploadedDetailFiles: PropTypes.func.isRequired,
  setHasErrorInDetailFiles: PropTypes.func.isRequired,
  ticketNumber: PropTypes.string,
  setTicketNumber: PropTypes.func.isRequired,
};

MpaJustificationDetails.defaultProps = {
  justificationInputValue: null,
  ticketNumber: null,
};

export default MpaJustificationDetails;
