import React from 'react';
import PropTypes from 'prop-types';

import Description from '../ListRowHelpers/Description/Description';
import Title from '../ListRowHelpers/Title/Title';

import './ListRowSession.css';

function ListRowSession({ title, description }) {
  return (
    <div className='ListRowSession-data'>
      <div className='ListRowSession-data-title'>
        {title.length > 0 && <Title data={title} />}
      </div>
      <div className='ListRowSession-data-description'>
        {description && <Description data={description} />}
      </div>
    </div>
  );
}

ListRowSession.propTypes = {
  title: PropTypes.arrayOf(
    PropTypes.shape({
      kind: PropTypes.oneOf(['text', 'label-value']),
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  description: PropTypes.arrayOf(
    PropTypes.shape({
      kind: PropTypes.oneOf(['icon-text', 'avatar-text', 'label-value', 'text'])
        .isRequired,
      icon: PropTypes.element,
      text: PropTypes.string,
      avatar: PropTypes.element,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

ListRowSession.defaultProps = {
  title: [],
  description: [],
};

export default ListRowSession;
