export const REQUEST_ACTIONS_LIST = 'REQUEST_ACTIONS_LIST';
export const REQUEST_ACTIONS_LIST_SUCCESS = 'REQUEST_ACTIONS_LIST_SUCCESS';
export const REQUEST_ACTIONS_LIST_FAILURE = 'REQUEST_ACTIONS_LIST_FAILURE';
export const REQUEST_FEATURES_LIST = 'REQUEST_FEATURES_LIST';
export const REQUEST_FEATURES_LIST_SUCCESS = 'REQUEST_FEATURES_LIST_SUCCESS';
export const REQUEST_FEATURES_LIST_FAILURE = 'REQUEST_FEATURES_LIST_FAILURE';
export const REQUEST_ACTIVITY_LIST = 'REQUEST_ACTIVITY_LIST';
export const REQUEST_ACTIVITY_LIST_SUCCESS = 'REQUEST_ACTIVITY_LIST_SUCCESS';
export const REQUEST_ACTIVITY_LIST_FAILURE = 'REQUEST_ACTIVITY_LIST_FAILURE';
export const REQUEST_IMPORTANT_ACTIONS_LIST = 'REQUEST_IMPORTANT_ACTIONS_LIST';
export const REQUEST_IMPORTANT_ACTIONS_LIST_SUCCESS = 'REQUEST_IMPORTANT_ACTIONS_LIST_SUCCESS';
export const REQUEST_IMPORTANT_ACTIONS_LIST_FAILURE = 'REQUEST_IMPORTANT_ACTIONS_LIST_FAILURE';
export const ADD_KPI_DATA = 'ADD_KPI_DATA';
export const REQUEST_TEAM_USER_LIST_SUCCESS = 'REQUEST_TEAM_USER_LIST_SUCCESS';
export const REQUEST_TEAM_USER_LIST = 'REQUEST_TEAM_USER_LIST';
export const REQUEST_RECENT_USER_LIST_SUCCESS = 'REQUEST_USER_RECENT_LIST_SUCCESS';
export const REQUEST_RECENT_USER_LIST_FAILURE = 'REQUEST_RECENT_USER_LIST_FAILURE';
export const REQUEST_RECENT_USER_LIST = 'REQUEST_RECENT_USER_LIST';
export const REQUESTHOME_PENDING_ACTIONS = 'REQUESTHOME_PENDING_ACTIONS';
export const REQUESTHOME_PENDING_ACTIONS_SUCCESS = 'REQUESTHOME_PENDING_ACTIONS_SUCCESS';
export const RESET_REFRESH_KPI_FLAG = 'RESET_REFRESH_KPI_FLAG';

export const FETCH_WELCOME_TILES = 'FETCH_WELCOME_TILES';
export const FETCH_WELCOME_TILES_SUCCESS = 'FETCH_WELCOME_TILES_SUCCESS';
export const FETCH_WELCOME_TILES_FAILURE = 'FETCH_WELCOME_TILES_FAILURE';

export const FETCH_RECENT_REQUEST = 'FETCH_RECENT_REQUEST';
export const FETCH_RECENT_REQUEST_SUCCESS = 'FETCH_RECENT_REQUEST_SUCCESS';
export const FETCH_RECENT_REQUEST_FAILURE = 'FETCH_RECENT_REQUEST_FAILURE';

export const FETCH_HELP_REQUEST = 'FETCH_HELP_REQUEST';
export const FETCH_HELP_REQUEST_SUCCESS = 'FETCH_HELP_REQUEST_SUCCESS';
export const FETCH_HELP_REQUEST_FAILURE = 'FETCH_HELP_REQUEST_FAILURE';

export const FETCH_BANNER_REQUEST = 'FETCH_BANNER_REQUEST';
export const FETCH_BANNER_REQUEST_SUCCESS = 'FETCH_BANNER_REQUEST_SUCCESS';
export const FETCH_BANNER_REQUEST_FAILURE = 'FETCH_BANNER_REQUEST_FAILURE';

export const FETCH_APP_RECOMMENDATIONS = 'FETCH_APP_RECOMMENDATIONS';
export const FETCH_APP_RECOMMENDATIONS_SUCCESS = 'FETCH_APP_RECOMMENDATIONS_SUCCESS';
export const FETCH_APP_RECOMMENDATIONS_FAILURE = 'FETCH_APP_RECOMMENDATIONS_FAILURE';

export const FETCH_REQUEST_ACCESS_DETAILS = 'FETCH_REQUEST_ACCESS_DETAILS';
export const FETCH_REQUEST_ACCESS_DETAILS_SUCCESS = 'FETCH_REQUEST_ACCESS_DETAILS_SUCCESS';
export const FETCH_REQUEST_ACCESS_DETAILS_FAILURE = 'FETCH_REQUEST_ACCESS_DETAILS_FAILURE';

export const HIDE_ALERT_BANNER = 'HIDE_ALERT_BANNER';

export const requestRecentUserListSuccess = (response) => ({
  type: REQUEST_RECENT_USER_LIST_SUCCESS,
  response,
});

export const requestRecentUserList = () => ({
  type: REQUEST_RECENT_USER_LIST,
});

export const requestRecentUserListFailure = () => ({
  type: REQUEST_RECENT_USER_LIST_FAILURE,
});

export const requestUserListSuccess = (response, searchText) => ({
  type: REQUEST_TEAM_USER_LIST_SUCCESS,
  response,
  searchText,
});

export const requestUserList = (searchText) => ({
  type: REQUEST_TEAM_USER_LIST,
  searchText,
});

export const requestActionsList = () => ({
  type: REQUEST_ACTIONS_LIST,
});

export const requestActionsListSuccess = (response) => ({
  type: REQUEST_ACTIONS_LIST_SUCCESS,
  response,
});

export const requestActionsListFailure = (error) => ({
  type: REQUEST_ACTIONS_LIST_FAILURE,
  error,
});

export const requestFeaturesList = () => ({
  type: REQUEST_FEATURES_LIST,
});

export const requestFeaturesListSuccess = (response) => ({
  type: REQUEST_FEATURES_LIST_SUCCESS,
  response,
});

export const requestFeaturesListFailure = (error) => ({
  type: REQUEST_FEATURES_LIST_FAILURE,
  error,
});

export const requestActivityList = () => ({
  type: REQUEST_ACTIVITY_LIST,
});

export const requestActivityListSuccess = (response) => ({
  type: REQUEST_ACTIVITY_LIST_SUCCESS,
  response,
});

export const requestActivityListFailure = (error) => ({
  type: REQUEST_ACTIVITY_LIST_FAILURE,
  error,
});

export const requestImportantActionsList = () => ({
  type: REQUEST_IMPORTANT_ACTIONS_LIST,
});

export const requestImportantActionsListSuccess = (response) => ({
  type: REQUEST_IMPORTANT_ACTIONS_LIST_SUCCESS,
  response,
});
export const requestImportantActionsListFailure = (response) => ({
  type: REQUEST_IMPORTANT_ACTIONS_LIST_FAILURE,
  response,
});
export const getPendingActions = (requestType, maxRecords) => ({
  type: REQUESTHOME_PENDING_ACTIONS,
  requestType,
  maxRecords,
});
export const requestPendingActionsSuccess = (responseJson, requestType) => ({
  type: REQUESTHOME_PENDING_ACTIONS_SUCCESS,
  responseJson,
  requestType,
});
export const resetRefreshKPIFlag = () => ({
  type: RESET_REFRESH_KPI_FLAG,
});

// New Home Page Actions

export const fetchWelcomeTiles = () => ({
  type: FETCH_WELCOME_TILES,
});

export const fetchWelcomeTilesSuccess = (response) => ({
  type: FETCH_WELCOME_TILES_SUCCESS,
  response,
});

export const fetchWelcomeTilesFailure = (error) => ({
  type: FETCH_WELCOME_TILES_FAILURE,
  error,
});

export const fetchRecentRequests = () => ({
  type: FETCH_RECENT_REQUEST,
});

export const fetchRecentRequestsSuccess = (response) => ({
  type: FETCH_RECENT_REQUEST_SUCCESS,
  response,
});

export const fetchRecentRequestsFailure = (error) => ({
  type: FETCH_RECENT_REQUEST_FAILURE,
  error,
});

export const fetchHelpRequests = () => ({
  type: FETCH_HELP_REQUEST,
});

export const fetchHelpRequestsSuccess = (response) => ({
  type: FETCH_HELP_REQUEST_SUCCESS,
  response,
});

export const fetchHelpRequestsFailure = (error) => ({
  type: FETCH_HELP_REQUEST_FAILURE,
  error,
});

export const fetchBannerRequests = () => ({
  type: FETCH_BANNER_REQUEST,
});

export const fetchBannerRequestsSuccess = (response) => ({
  type: FETCH_BANNER_REQUEST_SUCCESS,
  response,
});

export const fetchBannerRequestsFailure = (error) => ({
  type: FETCH_BANNER_REQUEST_FAILURE,
  error,
});

export const fetchAppRecommendations = () => ({
  type: FETCH_APP_RECOMMENDATIONS,
});

export const fetchAppRecommendationsSuccess = (response) => ({
  type: FETCH_APP_RECOMMENDATIONS_SUCCESS,
  response,
});

export const fetchAppRecommendationsFailure = (error) => ({
  type: FETCH_APP_RECOMMENDATIONS_FAILURE,
  error,
});

export const hideAlertBanner = () => ({
  type: HIDE_ALERT_BANNER,
});

export const fetchRequestDetails = () => ({
  type: FETCH_REQUEST_ACCESS_DETAILS,
});

export const fetchRequestDetailsSuccess = (response) => ({
  type: FETCH_REQUEST_ACCESS_DETAILS_SUCCESS,
  response,
});

export const fetchRequestDetailsFailure = (error) => ({
  type: FETCH_REQUEST_ACCESS_DETAILS_FAILURE,
  error,
});
