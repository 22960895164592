import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import checkEnvironment from 'ui-common/src/lib/checkEnvironment';
import { useDispatch } from 'react-redux';
import {
  ACCESS_TOKEN,
  APP_CONSTANTS,
  CCSRF_TOKEN,
  FIRST_NAME,
  INGRESSCOOKIE,
  JSESSIONID,
  LOCALE,
  MICRO_APP,
  MS_REDIRECT_URL,
  REFRESH_TOKEN,
  TOKEN_TYPE,
  USER_KEY,
  USER_NAME,
} from 'ui-common/src/constants';
import {
  logoutAction,
} from 'ui-common/src/utils/LoginActions';
import SessionStorageService from 'ui-common/src/utils/SessionStorageService';
import CookieService from 'ui-common/src/utils/CookieService';
import { HideLoader } from 'ui-common/src/utils/Loading-actions';
import MenuItem from '../MenuItem/MenuItem';

export const LogoutIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.16671 4.00012C4.15419 4.00012 3.33337 4.82093 3.33337 5.83345V17.5344C3.33337 18.5469 4.15419 19.3677 5.16671 19.3677H16.269C17.2815 19.3677 18.1023 18.5469 18.1023 17.5344V17.1668C18.1023 16.8906 17.8784 16.6668 17.6023 16.6668C17.3262 16.6668 17.1023 16.8906 17.1023 17.1668V17.5344C17.1023 17.9946 16.7292 18.3677 16.269 18.3677H5.16671C4.70647 18.3677 4.33337 17.9946 4.33337 17.5344V5.83345C4.33337 5.37322 4.70647 5.00012 5.16671 5.00012H16.269C16.7292 5.00012 17.1023 5.37322 17.1023 5.83346V6.50012C17.1023 6.77626 17.3262 7.00012 17.6023 7.00012C17.8784 7.00012 18.1023 6.77626 18.1023 6.50012V5.83346C18.1023 4.82093 17.2815 4.00012 16.269 4.00012H5.16671Z"
      fill="#032185"
    />
    <path
      d="M17.8865 8.73844C18.0817 8.54314 18.3983 8.54307 18.5936 8.73829L21.1869 11.3305C21.2807 11.4243 21.3334 11.5515 21.3334 11.6841C21.3334 11.8168 21.2807 11.944 21.1869 12.0377L18.5936 14.63C18.3983 14.8252 18.0817 14.8252 17.8865 14.6299C17.6913 14.4346 17.6913 14.118 17.8866 13.9227L19.6247 12.1853H11.6158C11.3396 12.1853 11.1158 11.9615 11.1158 11.6853C11.1158 11.4092 11.3396 11.1853 11.6158 11.1853H19.6272L17.8866 9.44555C17.6913 9.25032 17.6913 8.93374 17.8865 8.73844Z"
      fill="#032185"
    />
  </svg>
);

const Logout = ({ intl, menuDetails }) => {
  const dispatch = useDispatch();
  let domainUrl = checkEnvironment().GATEWAY_API_URL;

  const handleClick = () => {
    localStorage.removeItem('storedTime');
    dispatch(logoutAction());
    dispatch(HideLoader());
    // Removing session cookies
    CookieService.remove(ACCESS_TOKEN, true);
    CookieService.remove(CCSRF_TOKEN, true);
    CookieService.remove(INGRESSCOOKIE, true);
    CookieService.remove(USER_NAME, true);
    CookieService.remove(TOKEN_TYPE, true);
    CookieService.remove(JSESSIONID, true);
    // Removing cookies with maxAge
    CookieService.remove(ACCESS_TOKEN);
    CookieService.remove(REFRESH_TOKEN);
    CookieService.remove(TOKEN_TYPE);
    CookieService.remove(MS_REDIRECT_URL);
    CookieService.remove(USER_NAME);
    CookieService.remove(USER_KEY);
    CookieService.remove(FIRST_NAME);
    SessionStorageService.remove(MICRO_APP);
    localStorage.removeItem(MICRO_APP);
    localStorage.removeItem(LOCALE);
    SessionStorageService.remove(APP_CONSTANTS);
    domainUrl = domainUrl.replace(/\/ECMv6/g, '');
    window.location.href = `${domainUrl}${menuDetails.link}`;
  };

  return (
    <MenuItem
      label={intl.formatMessage({
        id: `gen2.profileMenu.label.${menuDetails.id}`,
      })}
      icon={<LogoutIcon />}
      handleClick={handleClick}
    />
  );
};

Logout.propTypes = {
  menuDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  intl: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      formatMessage: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default injectIntl(Logout);
