/**
 * Formats the uploaded file size.
 *
 * @param {number} fileSizeInBytes - Size of the file in bytes.
 * @returns {string} - Formatted file size in megabytes (MB).
 *
 * @example
 * Returns 'Calculating...' if fileSizeInBytes is null or undefined.
 * formatUploadFileSize(null);
 *
 * Returns the formatted size in MB format.
 * formatUploadFileSize(1024);
 *
 * @remarks
 * This function is used to format the file size regardless of whether
 * the useUploadFile custom hook is used.
 */
const formatUploadFileSize = (fileSizeInBytes) => {
  const FILESIZE_DECIMAL_PLACES = 2;
  const BYTES_PER_KB = 1024;

  if (fileSizeInBytes === null || fileSizeInBytes === undefined) {
    return 'Calculating...';
  }

  const fileSizeInMB = fileSizeInBytes / BYTES_PER_KB;

  return `${fileSizeInMB.toFixed(FILESIZE_DECIMAL_PLACES)}KB`;
};

/**
 * Returns a string containing the first x number of
 * accept types from the acceptTypesString param.
 *
 * @param {string} acceptTypesString - string containing accept types separated by commas.
 * @param {number} x - number of accept types to return.
 * @returns {string} - first x number of accept types joined by commas.
 * @example Returns '.png,.jpeg' if x is 2.
 */
const getXNumberOfAcceptTypes = (acceptTypesString, x) => {
  const types = acceptTypesString.split(',').map((type) => type.trim());

  return types.slice(0, x).join(', ');
};

/**
 * Returns the count of accept types in the acceptTypesString param.
 *
 * @param {string} acceptTypesString - string containing accept types separated by commas.
 * @returns {number} - count of accept types.
 * @example Returns 2 if acceptTypesString is '.png,.jpeg'.
 */
const getAcceptTypesCount = (acceptTypesString) =>
  acceptTypesString.split(',').length;

/**
 * Adds 1 space between accept types in the acceptTypesString.
 *
 * @param {string} acceptTypesString - string containing accept types separated by commas.
 * @returns {string} - accept types string with 1 space added between types.
 * @example Returns '.png, .jpeg' if acceptTypesString is '.png,.jpeg'.
 */
const addSpaceBetweenAcceptTypes = (acceptTypesString) =>
  acceptTypesString.split(',').join(', ');

export {
  addSpaceBetweenAcceptTypes,
  formatUploadFileSize,
  getAcceptTypesCount,
  getXNumberOfAcceptTypes,
};
