import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import MenuItem from '../MenuItem/MenuItem';
import getIconColors from '../../utils/getIconColors';

const SearchResults = ({
  isSearchActive, searchData, classes, children, expandSideMenu, handleDrawerClose, handleReset, intl,
}) => {
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const res = [];

    const result = (arrayData) => {
      arrayData.forEach((element) => {
        if (element.subMenu?.length > 0) {
          result(element.subMenu);
        } else {
          res.push(element);
        }
      });
    };

    result(searchData);
    setSearchResults(res);
  }, [searchData]);

  if (isSearchActive) {
    if (searchData.length > 0) {
      return (
        <>
          <span className={classes.searchText}>Search Results</span>
          {searchResults.map((menu) => (
            <MenuItem
              key={menu.id}
              icon={menu?.parentID[0]}
              title={menu.id}
              subTitle={menu?.parentID.map((m) => intl.formatMessage({ id: `gen2.leftnav.label.${m}` })).join(' > ')}
              isExpandable={false}
              isOpen={false}
              nestedClassName={classes.single}
              isParentOpen={false}
              selected={false}
              to={menu.link}
              color={getIconColors(menu.id)}
              isSearchActive={isSearchActive}
              isSideMenuOpen={expandSideMenu}
              handleDrawerClose={handleDrawerClose}
              handleClick={handleReset}
              isTranslated
            />
          ))}
        </>
      );
    }

    return <span className={classes.searchText}>No results</span>;
  }

  return children;
};

export default injectIntl(SearchResults);
