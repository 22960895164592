/**
 * Custom hook for handling file uploads.
 *
 * @param {number} [maxFileSize=1] - Max file size allowed per file in MB.
 * @param {number} [maxFileCount=10] - Max number of files allowed to be uploaded.
 * @param {boolean} [shouldIgnoreDuplicateFilenames=false] - Flag to determine whether to ignore duplicate filenames.
 * @param {boolean} [shouldReuploadWithDelete=true] - determine whether to reupload with delete (for backwards compat only).
 *
 * @returns {Object} - Object containing the uploaded files and various file handling functions.
 */
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { getRandomString, localize } from '@saviynt/common';

const BYTES_PER_KB = 1024;
const BYTES_PER_MB = 1024 * BYTES_PER_KB;
const FILESIZE_DECIMAL_PLACES = 2;
const msgs = {
  neo: {
    upload: {
      fileTypeNotSupported: {
        id: 'neo.upload.fileTypeNotSupported',
        defaultMessage: 'File type not supported. Try again.',
      },
      fileSizeExceeds: {
        id: 'neo.upload.fileSizeExceeds',
        defaultMessage: 'File size exceeds {maxFileSize}KB limit. Try again.',
      },
    },
  },
};

const useUploadFile = (
  maxFileSize = 1, // in MB
  maxFileCount = 5,
  shouldIgnoreDuplicateFilenames = false,
  shouldReuploadWithDelete = true // sync this value with the Upload component.
) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [reuploadFileName, setReuploadFileName] = useState(null);
  const intl = useIntl();
  const FILE_TYPE_NOT_SUPPORTED_TEXT = localize(
    intl,
    msgs.neo.upload.fileTypeNotSupported
  );

  const handleFileSelect = async (files, allowedFileTypes) => {
    const allowedExtensions = allowedFileTypes
      ? allowedFileTypes.map((type) => type.replace('.', ''))
      : null;

    let filesToProcess = files.slice(0, maxFileCount - uploadedFiles.length);

    // when reuploading, process only the first file
    if (reuploadFileName) filesToProcess = [files[0]];

    const fileObjects = filesToProcess
      .map((file) => {
        let fileName = file.name;
        const fileExtension = fileName.split('.').pop();

        const isDuplicate =
          uploadedFiles.some((f) => f.fileName === fileName) &&
          fileName !== reuploadFileName;

        if (isDuplicate) {
          if (shouldIgnoreDuplicateFilenames) {
            return null;
          }

          fileName = `${fileName.replace(
            `.${fileExtension}`,
            ''
          )}-${getRandomString()}.${fileExtension}`;
        }

        // check if file type is allowed
        if (allowedExtensions && !allowedExtensions.includes(fileExtension)) {
          return {
            file,
            fileName,
            fileSize: file.size,
            isError: true,
            errorMessage: FILE_TYPE_NOT_SUPPORTED_TEXT,
            isUploading: false,
            id: Date.now() + Math.random(),
          };
        }

        if (file.size / BYTES_PER_MB > maxFileSize) {
          return {
            file,
            fileName,
            fileSize: file.size,
            isError: true,
            // convert MB to KB below for error message
            errorMessage: localize(intl, msgs.neo.upload.fileSizeExceeds, {
              maxFileSize: (maxFileSize * BYTES_PER_KB).toFixed(
                FILESIZE_DECIMAL_PLACES
              ),
            }),
            isUploading: false,
            id: Date.now() + Math.random(),
          };
        }

        return {
          file,
          fileName,
          fileSize: file.size,
          isError: false,
          errorMessage: '',
          isUploading: true,
          id: Date.now() + Math.random(), // unique identifier (internal component use)
        };
      })
      // filter null entries from possibly ignored duplicates
      .filter((fileObject) => fileObject !== null);

    // add new files and update reuploaded file in the state.
    // if reuploading and shouldReuploadWithDelete, the previous file has already been deleted.
    if (shouldReuploadWithDelete) {
      setUploadedFiles((currentFiles) => [...currentFiles, ...fileObjects]);
    } else {
      // old behavior for backwards compatibility (reupload cancel button broken)
      setUploadedFiles((currentFiles) => {
        if (reuploadFileName) {
          return currentFiles.map((file) =>
            file.fileName === reuploadFileName ? { ...fileObjects[0] } : file
          );
        }

        return [...currentFiles, ...fileObjects];
      });
    }

    if (reuploadFileName) {
      setReuploadFileName(null);
    }
  };

  const handleFileDelete = (fileName) => {
    setUploadedFiles((currentFiles) =>
      currentFiles.filter((file) => file.fileName !== fileName)
    );
  };

  const handleFileReupload = (fileName) => {
    // reset the error state of the file being reuploaded
    setUploadedFiles((currentFiles) =>
      currentFiles.map((file) =>
        file.fileName === fileName
          ? { ...file, isError: false, errorMessage: '' }
          : file
      )
    );
    setReuploadFileName(fileName);
  };

  const hasAnyUploadErrors = uploadedFiles.some((file) => file.isError);

  return {
    uploadedFiles,
    handleFileSelect,
    handleFileDelete,
    handleFileReupload,
    reuploadFileName,
    isMaxNumberUploaded: uploadedFiles.length >= maxFileCount,
    maxFileCount: Number(maxFileCount),
    hasAnyUploadErrors,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useUploadFile };
