import './SpvActiveSession.css';

import {
  camelCaseToTitleCase,
  formatLocalizedDateTime,
  localize,
} from '@saviynt/common';
import {
  Box,
  Button,
  Icon,
  InputField,
  TabContent,
  TabGroup,
  TabItem,
  Typography,
} from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  ModuleDetails,
  Session,
  SessionDetails,
} from '../../../../../models/PamModels';
import { msgs } from '../../../constants';
import SpvEndpoint from '../../SpvEndpoint/SpvEndpoint';
import SpvSection from '../../SpvSection/SpvSection';

const CREDENTIALED_KEYS = {
  PASSWORD: 'password',
  PRIVATE_KEY: 'privateKey',
  SECRET: 'secret',
};

function SpvActiveSession({
  userAccessData,
  accountDetails,
  endpointDetails,
  session,
  sessionDetails,
  dataTestId,
  className,
}) {
  const classes = classnames('SpvActiveSession', className);

  const [activeTab, setActiveTab] = useState('1');
  const [showPassword, setShowPassword] = useState(false);
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [showSecret, setShowSecret] = useState(false);

  const isCredentialed = session.pamType?.toUpperCase() === 'CREDENTIAL';
  const isCredentialless = session.pamType?.toUpperCase() === 'CREDENTIALLESS';
  const isPasswordEnabledCredentialless =
    session.accountConfig?.['Saviynt-Status']?.showPassword === 'ENABLED';

  const isCredentiallessWithPasswordEnabled =
    isCredentialless && isPasswordEnabledCredentialless;

  const isCredentiallessWithoutPasswordEnabled =
    isCredentialless && !isPasswordEnabledCredentialless;

  const showCredentials = isCredentiallessWithPasswordEnabled || isCredentialed;

  const intl = useIntl();

  const handleChange = (value) => {
    setActiveTab(value);
  };

  const sessionTimeHasFields =
    session?.sessionStartDate || session?.sessionEndDate;

  const targetSystemHasFields =
    endpointDetails.customproperty9 || endpointDetails.customproperty35;

  const remoteAppName = userAccessData.remoteAppMetadata?.displayName;

  const showRemoteAppName =
    isCredentialless && remoteAppName && remoteAppName !== 'Terminal';

  const sessionAccountHasFields =
    session?.accountName ||
    accountDetails.description ||
    accountDetails.platformType ||
    accountDetails.pamAccountType ||
    accountDetails.accountConfig?.authenticationType;

  const copyOnClick = async (value) => {
    await navigator.clipboard.writeText(value);
  };

  const handleMaskedFields = (key) => {
    switch (key) {
      case CREDENTIALED_KEYS.PASSWORD:
        setShowPassword(!showPassword);
        break;
      case CREDENTIALED_KEYS.PRIVATE_KEY:
        setShowPrivateKey(!showPrivateKey);
        break;
      case CREDENTIALED_KEYS.SECRET:
        setShowSecret(!showSecret);
        break;
      default:
        break;
    }
  };

  const showToggleState = (key) => {
    switch (key) {
      case CREDENTIALED_KEYS.PASSWORD:
        return showPassword;
      case CREDENTIALED_KEYS.PRIVATE_KEY:
        return showPrivateKey;
      case CREDENTIALED_KEYS.SECRET:
        return showSecret;
      default:
        break;
    }

    return null;
  };

  const isMaskedField = (key, isTypePassword) => {
    const isPassword = key === CREDENTIALED_KEYS.PASSWORD && !showPassword;

    const isPrivateKey =
      key === CREDENTIALED_KEYS.PRIVATE_KEY && !showPrivateKey;

    const isSecret = key === CREDENTIALED_KEYS.SECRET && !showSecret;

    return (
      (isTypePassword && isPassword) ||
      (isTypePassword && isPrivateKey) ||
      (isTypePassword && isSecret)
    );
  };

  useEffect(() => {
    let passwordTimeout;

    if (showPassword) {
      passwordTimeout = setTimeout(() => {
        setShowPassword(false);
      }, 5000);
    }

    return () => {
      clearTimeout(passwordTimeout);
    };
  }, [showPassword]);

  useEffect(() => {
    let privateKeyTimeout;

    if (showPrivateKey) {
      privateKeyTimeout = setTimeout(() => {
        setShowPrivateKey(false);
      }, 5000);
    }

    return () => {
      clearTimeout(privateKeyTimeout);
    };
  }, [showPrivateKey]);

  useEffect(() => {
    let secretTimeout;

    if (showSecret) {
      secretTimeout = setTimeout(() => {
        setShowSecret(false);
      }, 5000);
    }

    return () => {
      clearTimeout(secretTimeout);
    };
  }, [showSecret]);

  return (
    <section className={classes} data-testid={dataTestId}>
      <TabGroup
        className='SpvActiveSession-tabGroup'
        value={activeTab}
        onChange={handleChange}>
        <TabItem
          label={localize(intl, msgs.pam.sidePanel.session)}
          value='1'
          icon={
            <Icon kind='PrivilegedSession' size='medium' color='neutral-1000' />
          }
        />
        <TabItem
          label={localize(intl, msgs.pam.sidePanel.asset)}
          value='2'
          icon={
            <Icon kind='AssetTargetSystem' size='medium' color='neutral-1000' />
          }
        />
      </TabGroup>
      <TabContent
        className='SpvActiveSession-tabContent'
        value={activeTab}
        tabValue='1'>
        {showCredentials && sessionDetails && (
          <SpvSection className='SpvActiveSession-credentials'>
            {Object.entries(sessionDetails).map(([key, valueObject]) => {
              const translationKey = `credentialledField${key}`;

              msgs[translationKey] = {
                id: `consoleCredential.${key}`,
                defaultMessage: camelCaseToTitleCase(key),
              };

              const field = localize(intl, msgs[translationKey]);
              const { value } = valueObject;
              const { effect } = valueObject;

              const isTypePassword =
                effect === 'PASSWORD' || effect === 'TEXTAREAPASSWORD';

              return (
                <section className='SpvActiveSession-credential'>
                  <Box className='SpvActiveSession-credential-field'>
                    <InputField
                      label={
                        <Typography
                          kind='h4'
                          className='SpvActiveSession-credential-label'>
                          {field}
                        </Typography>
                      }
                      type={
                        isMaskedField(key, isTypePassword) ? 'password' : 'text'
                      }
                      placeholder={field}
                      name={field.toLowerCase()}
                      suffixButtonType={isTypePassword ? 'password' : null}
                      value={value}
                      suffixOnClick={() => {
                        handleMaskedFields(key);
                      }}
                      suffixIsToggle={showToggleState(key)}
                      setValue={() => {}}
                      isReadOnly
                    />
                  </Box>
                  <Button
                    type='button'
                    kind='outlined'
                    size='large'
                    leftIcon={
                      <Icon kind='Copy' size='medium' color='neutral-100' />
                    }
                    onClick={() => copyOnClick(value)}
                    dataTestId={`copy-button-${key}`}
                  />
                </section>
              );
            })}
          </SpvSection>
        )}
        {sessionTimeHasFields && (
          <SpvSection
            className={sessionDetails && 'SpvActiveSession-tabSection'}
            title={localize(intl, msgs.pam.sidePanel.time)}
            fields={[
              {
                label: localize(intl, msgs.pam.sidePanel.startTime),
                value: formatLocalizedDateTime(session.sessionStartDate),
              },
              {
                label: localize(intl, msgs.pam.sidePanel.endTime),
                value: formatLocalizedDateTime(session.sessionEndDate),
              },
            ]}
          />
        )}
        {isCredentiallessWithoutPasswordEnabled && targetSystemHasFields && (
          <SpvSection
            className={sessionTimeHasFields && 'SpvActiveSession-tabSection'}
            title={localize(intl, msgs.pam.sidePanel.targetSystem)}
            fields={[
              {
                label: localize(intl, msgs.pam.sidePanel.targetServer),
                value: endpointDetails.customproperty9,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.portNumber),
                value: endpointDetails.customproperty35,
              },
            ]}
          />
        )}
        {showRemoteAppName && (
          <SpvSection
            className={
              (sessionTimeHasFields ||
                (isCredentiallessWithoutPasswordEnabled &&
                  targetSystemHasFields)) &&
              'SpvActiveSession-tabSection'
            }
            title={localize(intl, msgs.pam.sidePanel.remoteApplication)}
            fields={[
              {
                label: localize(intl, msgs.pam.sidePanel.name),
                value: remoteAppName,
              },
            ]}
          />
        )}
        {sessionAccountHasFields && (
          <SpvSection
            className={
              (showRemoteAppName ||
                (isCredentiallessWithoutPasswordEnabled &&
                  targetSystemHasFields)) &&
              'SpvActiveSession-tabSection'
            }
            title={localize(intl, msgs.pam.sidePanel.account)}
            fields={[
              {
                label: localize(intl, msgs.pam.sidePanel.name),
                value: session?.accountName,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.platform),
                value: accountDetails.platformType,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.description),
                value: accountDetails.description,
              },
              {
                label: localize(intl, msgs.pam.sidePanel.accountType),
                value: accountDetails.pamAccountType,
              },
              {
                label: localize(
                  intl,
                  msgs.pam.sidePanel.authenticationMechanism
                ),
                value: accountDetails.accountConfig?.authenticationType,
              },
            ]}
          />
        )}
      </TabContent>
      <TabContent
        className='SpvActiveSession-assetTab'
        value={activeTab}
        tabValue='2'>
        <SpvEndpoint data={endpointDetails} />
      </TabContent>
    </section>
  );
}

SpvActiveSession.propTypes = {
  userAccessData: ModuleDetails,
  accountDetails: ModuleDetails,
  endpointDetails: ModuleDetails,
  session: Session,
  sessionDetails: SessionDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvActiveSession.defaultProps = {
  userAccessData: null,
  accountDetails: null,
  endpointDetails: null,
  session: null,
  sessionDetails: null,
  dataTestId: null,
  className: null,
};

export default SpvActiveSession;
