import './SpvEndpoint.css';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ModuleDetails } from '../../../../models/PamModels';
import { ASSET_TYPES } from '../../constants';
import SpvApplication from './resources/SpvApplication/SpvApplication';
import SpvDatabase from './resources/SpvDatabase/SpvDatabase';
import SpvServer from './resources/SpvServer/SpvServer';

function SpvEndpoint({ data, dataTestId, className }) {
  const classes = classnames('SpvEndpoint', className);

  const renderSpv = (resourceType) => {
    switch (resourceType) {
      case ASSET_TYPES.APPLICATION:
        return <SpvApplication data={data} />;
      case ASSET_TYPES.SERVER:
        return <SpvServer data={data} />;
      case ASSET_TYPES.DATABASE:
        return <SpvDatabase data={data} />;
      default:
        return <SpvApplication data={data} />;
    }
  };

  return (
    <div className={classes} data-testid={dataTestId}>
      {renderSpv(data.assetType)}
    </div>
  );
}

SpvEndpoint.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvEndpoint.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SpvEndpoint;
