import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Saviynt from '../../ECMv6/assets/images/Logo/saviynt.svg';
import SaviyntWhite from '../../ECMv6/assets/images/Logo/saviynt-white.svg';

import './Logo.css';

const LOGOS = {
  saviynt: 'saviynt',
  saviyntWhite: 'saviyntWhite',
};

const getSVG = (kind) => {
  switch (kind) {
    case LOGOS.saviyntWhite:
      return <SaviyntWhite />;

    // Add more logos here
    default:
      return <Saviynt />;
  }
};

function Logo({ kind, className, onClick }) {
  const classes = classnames(
    'Logo',
    `Logo-kind--${kind}`,
    onClick && 'Logo--click',
    className
  );

  return (
    <div className={classes} onClick={onClick} role='button'>
      {getSVG(kind)}
    </div>
  );
}

Logo.propTypes = {
  kind: PropTypes.oneOf(Object.values(LOGOS)),
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Logo.defaultProps = { kind: 'saviynt', onClick: null, className: null };

export default Logo;
