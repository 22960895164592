import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './Table.css';

function Table({ className, dataTestId, children }) {
  return (
    <table
      className={classnames('TableDS', className)}
      data-testid={dataTestId}>
      {children}
    </table>
  );
}

Table.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  children: PropTypes.node,
};
Table.defaultProps = {
  className: null,
  dataTestId: null,
  children: null,
};

export default Table;
