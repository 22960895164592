import './SpvServer.css';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { usePlatformLabel } from '../../../../../../hooks/use-labels';
import { ModuleDetails } from '../../../../../../models/PamModels';
import { LABELS, TITLES } from '../../../../constants';
import SpvSection from '../../../SpvSection/SpvSection';

function SpvServer({ data, dataTestId, className }) {
  const classes = classnames('SpvServer', className);

  const cloudProvider = data.connectionType?.toUpperCase();

  const summaryHasFields =
    data.platformType || data.description || data.customproperty10;

  const systemDetailsHasFields =
    data.name ||
    data.customproperty17 ||
    data.customproperty16 ||
    data.customproperty5 ||
    data.customproperty11 ||
    data.customproperty9 ||
    data.customproperty15;

  return (
    <div className={classes} data-testid={dataTestId}>
      {summaryHasFields && (
        <SpvSection
          title={TITLES.SUMMARY}
          fields={[
            {
              label: LABELS.PLATFORM,
              value: data.platformType,
            },
            {
              label: LABELS.DESCRIPTION,
              value: data.description,
            },
            {
              label: LABELS.LOCATION,
              value: data.customproperty10,
            },
          ]}
        />
      )}
      {systemDetailsHasFields && (
        <SpvSection
          title={TITLES.SYSTEM_DETAILS}
          fields={[
            {
              // TODO: Temp mapping; real mapped field unknown; needs update
              label: LABELS.ID,
              value: data.name,
            },
            {
              label: usePlatformLabel(cloudProvider),
              value: data.customproperty17,
            },
            {
              label: LABELS.OPERATING_SYSTEM,
              value: data.customproperty16,
            },
            {
              label: LABELS.ENVIRONMENT,
              value: data.customproperty5,
            },
            {
              label: LABELS.NETWORK,
              value: data.customproperty11,
            },
            {
              label: LABELS.PUBLIC_IP,
              value: data.customproperty9,
            },
            {
              label: LABELS.PRIVATE_IP,
              value: data.customproperty15,
            },
          ]}
          className='SpvServer-section'
        />
      )}
    </div>
  );
}

SpvServer.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvServer.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SpvServer;
