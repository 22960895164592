import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ButtonIcon from '../ButtonIcon/ButtonIcon';
import Icon from '../Icon/Icon';

import ListRowAccount from './ListRowAccount/ListRowAccount';
import ListRowSession from './ListRowSession/ListRowSession';
import ListRowTarget from './ListRowTarget/ListRowTarget';

import './ListRow.css';

const KINDS = {
  account: 'account',
  endpoint: 'endpoint',
  session: 'session',
};

function ListRowContent({ kind, category, title, description }) {
  switch (kind) {
    case KINDS.account:
      return (
        <ListRowAccount
          category={category}
          title={title}
          description={description}
        />
      );
    case KINDS.endpoint:
      return <ListRowTarget title={title} description={description} />;
    case KINDS.session:
      return <ListRowSession title={title} description={description} />;
    default:
      return null;
  }
}

function ListRow({
  type,
  hasBookmark,
  onRowClick,
  applicationIcon,
  category,
  title,
  description,
  status,
  primaryIcon,
  secondaryIcon,
  moreOptionsIcon,
  moreOptionOnClick,
  dataTestId,
  className,
}) {
  const classes = classnames('ListRow-row-data', className);

  const bookmarkIcon = (
    <Icon kind='BookmarkOutline' color={null} size='medium' />
  );

  const buttonIconHandler = (e) => {
    e.stopPropagation();   
    moreOptionOnClick();
  };

  const moreOptionIcon = (
    <ButtonIcon
      size='large'
      kind='ghost'
      icon={<Icon kind='moreVertical' color='navy-500' />}
      onClick={(e) => buttonIconHandler(e, 'moreOption')}
    />
  );

  return (
    <div
      className='ListRow-container'
      onClick={onRowClick}
      role='presentation'
      data-testid={dataTestId}>
      <span className='ListRow-hover-span' />
      <div className={classes}>
        <div className='ListRow-sectionLeft'>
          <div className='ListRow-bookmark-badgeContainer'>
            {hasBookmark && (
              <div className='ListRow-bookmark'>{bookmarkIcon}</div>
            )}
          </div>
          {/* Todo: To replace this with ApplicationIcon */}
          {applicationIcon && (
            <div className='ListRow-applicationIcon'>{applicationIcon}</div>
          )}
          <ListRowContent
            kind={type}
            category={category}
            title={title}
            description={description}
          />
        </div>
        <div className='ListRow-sectionRight'>
          {status && <div className='ListRow-status'>{status}</div>}
          {primaryIcon && <div className='ListRow-icon'>{primaryIcon}</div>}
          {secondaryIcon && <div className='ListRow-icon'>{secondaryIcon}</div>}
          <div
            className={`${
              moreOptionsIcon
                ? 'ListRow-verticalIcon'
                : 'ListRow-verticalIcon-hidden'
            }`}>
            {moreOptionsIcon || moreOptionIcon}
          </div>
        </div>
      </div>
    </div>
  );
}

ListRowContent.propTypes = {
  kind: PropTypes.string,
  category: PropTypes.shape({
    account: PropTypes.string,
    icon: PropTypes.node,
    platform: PropTypes.string,
  }),
  title: PropTypes.arrayOf(
    PropTypes.shape({
      kind: PropTypes.oneOf(['text', 'label-value']),
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  description: PropTypes.arrayOf(
    PropTypes.shape({
      kind: PropTypes.oneOf(['icon-text', 'avatar-text', 'label-value', 'text'])
        .isRequired,
      icon: PropTypes.element,
      text: PropTypes.string,
      avatar: PropTypes.element,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

ListRowContent.defaultProps = {
  kind: '',
  category: null,
  title: [],
  description: [],
};

ListRow.propTypes = {
  applicationIcon: PropTypes.node,
  status: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.node,
  ]),
  primaryIcon: PropTypes.node,
  secondaryIcon: PropTypes.node,
  moreOptionsIcon: PropTypes.element,
  moreOptionOnClick: PropTypes.func,
  onRowClick: PropTypes.func,
  className: PropTypes.string,
  hasBookmark: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(KINDS)),
  category: PropTypes.shape({
    account: PropTypes.string,
    icon: PropTypes.node,
    platform: PropTypes.string,
  }),
  title: PropTypes.arrayOf(
    PropTypes.shape({
      kind: PropTypes.oneOf(['text', 'label-value']),
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  description: PropTypes.arrayOf(
    PropTypes.shape({
      kind: PropTypes.oneOf(['icon-text', 'avatar-text', 'label-value', 'text'])
        .isRequired,
      icon: PropTypes.element,
      text: PropTypes.string,
      avatar: PropTypes.element,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  dataTestId: PropTypes.string,
};

ListRow.defaultProps = {
  applicationIcon: null,
  status: '',
  primaryIcon: null,
  secondaryIcon: null,
  moreOptionsIcon: null,
  moreOptionOnClick: () => {},
  onRowClick: () => {},
  className: null,
  hasBookmark: true,
  type: null,
  category: null,
  title: [],
  description: [],
  dataTestId: null,
};

export default ListRow;
