import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SessionStorageService from 'ui-common/src/utils/SessionStorageService';
import classnames from 'classnames';
import { Collapse } from '@material-ui/core';
import List from '@material-ui/core/List';
import MenuItem from '../MenuItem/MenuItem';
import findAssociatedMenuForNavigatedUrl from '../../utils/findAssociatedMenuForNavigatedUrl';
import styles from './MenuList.module.css';
import getIconColors from '../../utils/getIconColors';
import { getBackgroundColor, getTextColor } from 'ui-home/src/features/AdminHome/views/BrandingConfig/utils/helper';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  single: {
    background: 'var(--SIDEMENU-BACKGROUND)',
    color: 'var(--SIDEMENU-TEXT-COLOR)',
    // background: getBackgroundColor(),
    // color: getTextColor(),
  },
  nested: {
    background: 'var(--SIDEMENU-BACKGROUND)',
    color: 'var(--SIDEMENU-TEXT-COLOR)',
    // background: getBackgroundColor(),
    // color: getTextColor(),
  },
  nestedLevel3: {
    background: 'var(--SIDEMENU-BACKGROUND)',
    color: 'var(--SIDEMENU-TEXT-COLOR)',
    // background: getBackgroundColor(),
    // color: getTextColor(),
  },
}));

const CollapsableMenu = ({
  classes,
  nestedClasses,
  selected,
  menu,
  level,
  isParentOpen,
  handleBackClick,
  expandSideMenu,
  menuId,
  parentID,
  collapseSideMenu,
  selectedMenuId,
  color,
  isSearchActive,
  handleDrawerClose,
}) => {
  const highlightMenu = parentID?.includes(menu.id);
  const conditionToExpandMenu = isParentOpen === false ? false : parentID?.includes(menu.id);

  const [isOpen, setIsOpen] = useState(conditionToExpandMenu);

  // for handling expand/collapse of menu on hover off
  useEffect(() => {
    // const isHomePageOrNonNavigationLink = (menuId === 'null');
    const isLevel2MenuOpenAndSearchIsNotActiveInCollapsedSideBarView =
      level === 2 && isOpen && !isSearchActive && !expandSideMenu;
    const isLevel1OrLevel2MenuOpenAndSearchIsActiveInCollapsedSideBarView =
      (level === 2 || level === 1) && isOpen && isSearchActive && !expandSideMenu;

    if (
      isLevel2MenuOpenAndSearchIsNotActiveInCollapsedSideBarView ||
      isLevel1OrLevel2MenuOpenAndSearchIsActiveInCollapsedSideBarView
    ) {
      setIsOpen(false);
    }
  }, [collapseSideMenu]);

  // for handling expand/collapse of menu on hover in
  useEffect(() => {
    const isValidNavigationLinkAndLevel2MenuIsNotOpenAndSearchIsNotActiveInExpandedSideBarView =
      conditionToExpandMenu && level === 2 && !isOpen && !isSearchActive && expandSideMenu;

    if (isValidNavigationLinkAndLevel2MenuIsNotOpenAndSearchIsNotActiveInExpandedSideBarView) {
      const timeoutID = setTimeout(() => {
        setIsOpen(true);
      }, 200);

      return () => {
        clearTimeout(timeoutID);
      };
    }
  }, [expandSideMenu]);

  useEffect(() => {
    if (isParentOpen && conditionToExpandMenu && !isSearchActive && level === 1) {
      setIsOpen(true);
    }
  }, [isSearchActive]);

  useEffect(() => {
    if (isParentOpen && conditionToExpandMenu && !isSearchActive && level === 1) {
      console.log('isParentOpen', menu, level, isOpen, isParentOpen);
      setIsOpen(true);
    } else if (isParentOpen === false) {
      setIsOpen(false);
    }
  }, [isParentOpen]);

  const handleClick = () => {
    setIsOpen(!isOpen);

    if (level === 1) {
      handleBackClick(menu.id);
    }
  };

  const isExpandable = menu.subMenu?.length > 0;

  return (
    <div className={classnames(level === 1 && styles.spacer)}>
      <MenuItem
        icon={level !== 3 ? menu.id : null}
        title={menu.id}
        subTitle={level === 1 && selected != null && highlightMenu && !isParentOpen ? menuId : null}
        isOpen={isOpen}
        isExpandable={isExpandable ? !isParentOpen || isSearchActive : null}
        level={level}
        handleClick={handleClick}
        nestedClassName={nestedClasses}
        isParentOpen={isParentOpen}
        handleBackClick={handleBackClick}
        selected={
          (level === 1 && highlightMenu === true && !isParentOpen) ||
          (level === 2 && highlightMenu === true && !isOpen)
        }
        to={false}
        collapseSideMenu={collapseSideMenu}
        selectedMenuId={selectedMenuId}
        isSideMenuOpen={expandSideMenu}
        color={color}
        isSearchActive={isSearchActive}
        handleDrawerClose={handleDrawerClose}
      />
      {isExpandable ? (
        <Collapse
          in={isOpen}
          timeout="auto"
          unmountOnExit
          className={level === 2 ? classes.nestedLevel3 : classes.nested}
        >
          <List disablePadding className={classes.root}>
            <MenuList
              data={menu.subMenu}
              selected={selected}
              level={level + 1}
              handleBackClick={handleBackClick}
              expandSideMenu={expandSideMenu}
              collapseSideMenu={collapseSideMenu}
              selectedMenuId={selectedMenuId}
              color={color}
              isSearchActive={isSearchActive}
              handleDrawerClose={handleDrawerClose}
            />
          </List>
        </Collapse>
      ) : null}
    </div>
  );
};

const MenuList = ({
  data,
  selected,
  level = 1,
  isParentOpen,
  handleBackClick,
  expandSideMenu,
  collapseSideMenu,
  selectedMenuId,
  color,
  isSearchActive,
  handleDrawerClose,
}) => {
  const classes = useStyles();
  let currentMenu = findAssociatedMenuForNavigatedUrl(data, selected);
  if (!currentMenu) {
    currentMenu = JSON.parse(localStorage.getItem('currentMenu'));
    SessionStorageService.set('currentMenu', localStorage.getItem('currentMenu'));
  }
  const menuId = currentMenu?.id || SessionStorageService.get('currentMenu');
  const parentID = currentMenu?.parentID || null;
  // console.log('currentMenu', currentMenu);
  // const value = SessionStorageService.get('currentMenu') === 'null' ? SessionStorageService.get('currentMenu')
  //   : (JSON.parse(SessionStorageService.get('currentMenu')));

  // console.log('testing for redirection', value, selected, SessionStorageService.get('currentMenu'));
  // console.log('menuId', menuId, 'parentID', parentID);

  return data.map((menu) => {
    if (menu.subMenu?.length > 0) {
      return (
        <CollapsableMenu
          classes={classes}
          key={menu.id}
          nestedClassName={classes.single}
          menu={menu}
          selected={selected}
          level={level}
          isParentOpen={isParentOpen}
          handleBackClick={handleBackClick}
          expandSideMenu={expandSideMenu}
          menuId={menuId}
          parentID={parentID}
          collapseSideMenu={collapseSideMenu}
          selectedMenuId={selectedMenuId}
          color={color || getIconColors(menu.id)}
          isSearchActive={isSearchActive}
          handleDrawerClose={handleDrawerClose}
        />
      );
    }

    return (
      <MenuItem
        key={menu.id}
        icon={level !== 3 ? menu.id : null}
        title={menu.id}
        isExpandable={false}
        isOpen={false}
        nestedClassName={classes.single}
        isParentOpen={isParentOpen}
        selected={menuId === menu.id}
        to={menu.link}
        collapseSideMenu={collapseSideMenu}
        selectedMenuId={selectedMenuId}
        isSideMenuOpen={expandSideMenu}
        color={color}
        isSearchActive={isSearchActive}
        handleDrawerClose={handleDrawerClose}
        level={level}
      />
    );
  });
};

export default MenuList;
