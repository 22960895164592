import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { localize, Logger } from '@saviynt/common';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ButtonIcon from '../../ButtonIcon/ButtonIcon';
import Icon from '../../Icon/Icon';

import '../UploadedFile/UploadedFile.css';
import './DownloadFile.css';

/* Note: current implementation opens in a new window for browser download.
<a
  rel="noopener noreferrer"
  href={`${SERVICE_ENDPOINT}/requests/attachment/${file.attachmentKey
  }?username=${cookies.get('user_name')}`}
  target="_blank"
>
*/
const STATES = {
  READY: 'ready',
  DOWNLOADING: 'downloading',
  FAILED: 'failed',
};

const msgs = {
  neo: {
    download: {
      downloadFailed: {
        id: 'neo.download.downloadFailed',
        defaultMessage: 'Download failed',
      },
    },
  },
};

/**
 * Component to display an uploaded file to download.
 * Browser capabilities for features such as cancelling and tracking
 * progress are generally limited for security and privacy.
 *
 * ###### TODO:
 * - Refactor folder structure (nice to have).
 */

function DownloadFile({ fileName, fileSize, fileUrl, className, dataTestId }) {
  const [downloadStatus, setDownloadStatus] = useState(STATES.READY);

  const intl = useIntl();
  const DOWNLOAD_FAILED_TEXT = localize(intl, msgs.neo.download.downloadFailed);

  const handleDownload = async () => {
    setDownloadStatus(STATES.DOWNLOADING);

    fetch(fileUrl)
      .then((response) => {
        if (!response.ok) {
          setDownloadStatus(STATES.FAILED);
          Logger.error('Network error');

          return null;
        }

        return response.blob();
      })
      .then((blob) => {
        // have the file content as a Blob now
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');

        anchor.href = url;
        anchor.download = fileName; // download w/out redirect, specify filename
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(url); // clean up
      })
      .catch((error) => {
        setDownloadStatus(STATES.FAILED);
        Logger.error('Download component, problem with fetch:', error);
      });
  };

  const renderButtonContent = () => {
    switch (downloadStatus) {
      case STATES.DOWNLOADING:
        return (
          <ButtonIcon
            size='large'
            kind='outlinedSecondary'
            icon={<Icon kind='Download' color='neutral-100' size='xSmall' />}
            onClick={handleDownload}
          />
        );
      case STATES.FAILED:
        return (
          <>
            <ButtonIcon
              size='large'
              kind='outlinedSecondary'
              icon={<Icon kind='Retry' color='neutral-100' size='xSmall' />}
              onClick={handleDownload}
            />
            <ButtonIcon
              size='large'
              kind='outlinedSecondary'
              icon={<Icon kind='Close' color='neutral-100' size='xSmall' />}
              onClick={() => {
                setDownloadStatus(STATES.READY);
              }}
            />
          </>
        );
      default:
        return (
          <ButtonIcon
            size='large'
            kind='outlinedSecondary'
            icon={<Icon kind='Download' color='neutral-100' size='xSmall' />}
            onClick={handleDownload}
          />
        );
    }
  };

  return (
    <section className={classnames('UploadedFile-container', className)}>
      <div
        className={classnames(
          'UploadedFile',
          downloadStatus === STATES.FAILED && 'UploadedFile--error'
        )}
        data-testid={dataTestId}>
        <Icon kind='document' />
        <div className='UploadedFile-nameSizeContainer'>
          <span className='UploadedFile-fileName'>{fileName}</span>
          <span className='UploadedFile-fileSize'>
            {fileSize?.toUpperCase()}
          </span>
        </div>
        <div className='UploadedFile-actionIcons'>{renderButtonContent()}</div>
      </div>
      {downloadStatus === STATES.FAILED && (
        <div className='UploadedFile-error'>
          <Icon
            kind='AlertCritical'
            size='xSmall'
            className='UploadedFile-errorIcon'
          />
          <span className='UploadedFile-errorMessage'>
            {DOWNLOAD_FAILED_TEXT}
          </span>
        </div>
      )}
    </section>
  );
}

DownloadFile.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};
DownloadFile.defaultProps = {
  className: null,
  dataTestId: null,
};

export default DownloadFile;
