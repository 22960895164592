import * as C from './constants'

const getVersionObj = (name, version) => {
  return {
    name: name,
    branch: '-',
    commitId: '-',
    version: version || '-'
  }
}

const initialState = {
  versionPageList: [getVersionObj(C.APP_UI), getVersionObj(C.APP_ECM)],
  loadingMSVersion: false,
  loadingECMVersion: false,
};

const updateAppVersion = (state, action, name) => {
  const ecmObj = getVersionObj(name, action.version);
  const filteredVersions = state.versionPageList.filter( obj => obj.name !== name );
  return filteredVersions.concat(ecmObj);
}

const updateMSVersions = (state, action) => {
  if (action.response) {
    const msVersions = action.response.filter(obj => obj.name && obj.name.toLowerCase() !== 'unknown');
    return state.versionPageList.concat(msVersions);
  }
  return state.versionPageList
}

const versionPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.REQUEST_VERSION_LIST:
      return {
        ...state,
        loadingMSVersion: true,
      };
    case C.REQUEST_VERSION_LIST_SUCCESS:
      return {
        ...state,
        loadingMSVersion: false,
        versionPageList: updateMSVersions(state, action),
      };
    case C.REQUEST_VERSION_LIST_FAILURE:
      return {
        ...state,
        loadingMSVersion: false,
      };
    case C.FETCH_ECM_VERSION:
      return {
        ...state,
        loadingECMVersion: true,
      };
    case C.FETCH_ECM_VERSION_SUCCESS:
      return {
        ...state,
        versionPageList: updateAppVersion(state, action, C.APP_ECM),
        loadingECMVersion: false
      }
    case C.FETCH_ECM_VERSION_FAILED:
      return {
        ...state,
        loadingECMVersion: false,
      };
    case C.UPDATE_UI_VERSION:
      return {
        ...state,
        versionPageList: updateAppVersion(state, action, C.APP_UI)
      }
    default: {
      return state;
    }
  }
};

export default versionPageReducer;
