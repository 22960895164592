// eslint-disable-next-line import/prefer-default-export
export const msgs = {
  neo: {
    inlineMessage: {
      missingReqField: {
        id: 'neo.inlineMessage.missingReqField',
        defaultMessage: 'This required field is incomplete',
      },
    },
  },
};
