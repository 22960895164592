import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import PaginationItem from './PaginationItem/PaginationItem';

import './PaginationGroup.css';

/**
 * #### WIP TODO:
 *
 * - Update range selection with SingleSelect
 * - Update PaginationCondensed to open Menu single select
 * - Question to design: What do you want the condensed button to be? I just made it a ghost button.
 */

function PaginationGroup({
  pageCount,
  pageIndex,
  setPageIndex,
  className,
  dataTestId,
}) {
  const prevPageIndex = useRef(null);

  useEffect(() => {
    // Store the previous page index, for the transition effects with no ellipses
    prevPageIndex.current = pageIndex;
  }, [pageIndex]);

  const paginationGroupClass = classnames('PaginationGroup', className);

  // Create an array to represent the pages
  const pagesArray = Array.from({ length: pageCount }, (_, index) => index + 1);

  // Function to get transition class name for PaginationItem
  const transitionClass = (itemIndex) => {
    if (pageCount <= 5) return 'PaginationItem--transition';

    // Logic for no transition at the start of the range
    const isAtStartRange =
      (prevPageIndex.current === 5 && itemIndex === 4) ||
      (pageIndex === 5 && itemIndex === 4);

    // Logic for no transition at the end of the range
    const isAtEndRange =
      (prevPageIndex.current === pageCount - 4 &&
        itemIndex === pageCount - 3) ||
      (pageIndex === pageCount - 4 && itemIndex === pageCount - 3);

    if (isAtStartRange || isAtEndRange) return 'PaginationItem--noTransition';
    if (itemIndex >= pageCount - 3 || itemIndex <= 4)
      return 'PaginationItem--transition';

    return 'PaginationItem--noTransition';
  };

  const getItemKind = (itemIndex) => {
    if (pageCount <= 5) return 'number';

    // Check if the page index is centered
    const isCentered =
      itemIndex === pageIndex - 2 || itemIndex === pageIndex + 2;
    // Check if we are at the start or end
    const isAtExtremeEnds = itemIndex === 1 || itemIndex === pageCount;

    if (isAtExtremeEnds) return 'number';

    // Check if the page index is near the start
    const isPageIndexNearStart = pageIndex >= 1 && pageIndex <= 4;
    const isItemNearStart = itemIndex >= 2 && itemIndex <= 5;

    if (isPageIndexNearStart && isItemNearStart) return 'number';
    if (isPageIndexNearStart && itemIndex === 6) return 'ellipses';

    // Check if the page index is near the end
    const isPageIndexNearEnd =
      pageIndex >= pageCount - 3 && pageIndex <= pageCount;
    const isItemNearEnd = itemIndex >= pageCount - 4 && itemIndex <= pageCount;

    if (isPageIndexNearEnd && isItemNearEnd) return 'number';
    if (isPageIndexNearEnd && itemIndex === pageCount - 5) return 'ellipses';
    if (isCentered) return 'ellipses';

    return 'number';
  };

  const getItemIsSelected = (itemIndex) => itemIndex === pageIndex;

  const getOutOfSelectRange = (itemIndex) => {
    if (pageCount <= 5) return true;

    // Item is the current page or adjacent to it
    if (Math.abs(itemIndex - pageIndex) <= 2) return true;

    // Item is the first or last page
    if (itemIndex === 1 || itemIndex === pageCount) return true;

    // Start and End of the pages
    if (pageIndex <= 4 && itemIndex >= 3 && itemIndex <= 6) return true;
    if (
      pageIndex >= pageCount - 3 &&
      itemIndex >= pageCount - 5 &&
      itemIndex <= pageCount
    )
      return true;

    return false;
  };

  const handlePageDirection = (dir) => {
    setPageIndex(dir ? pageIndex + 1 : pageIndex - 1);
  };

  if (pageCount <= 1) return null;

  return (
    <section className={paginationGroupClass} data-testid='group-test-id'>
      <PaginationItem
        kind='start'
        isDisabled={pageIndex === 1}
        onClick={() => handlePageDirection(false)}
        dataTestId='prev-button'
      />
      {pagesArray.map((page, index) => (
        <PaginationItem
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          kind={getItemKind(index + 1)}
          itemIndex={index + 1}
          onClick={() => setPageIndex(index + 1)}
          isSelected={getItemIsSelected(index + 1)}
          isVisible={getOutOfSelectRange(index + 1)}
          className={transitionClass(index + 1)}
          dataTestId={`pagination-item-${index + 1}`}
        />
      ))}
      <PaginationItem
        kind='end'
        isDisabled={pageIndex >= pageCount}
        onClick={() => handlePageDirection(true)}
        dataTestId='next-button'
      />
    </section>
  );
}

PaginationGroup.defaultProps = {
  pageCount: null,
  pageIndex: null,
  setPageIndex: null,
  className: null,
  dataTestId: null,
};

PaginationGroup.propTypes = {
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  setPageIndex: PropTypes.func,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default PaginationGroup;

PaginationGroup.displayName = 'Pagination';
