import React from 'react';
import PropTypes from 'prop-types';
import { TooltipComponent } from '@saviynt/design-system';
import classes from '../../BrandingConfig.module.scss';
import Icon from '../Icons/Icon';
import { getDisplayedTheme } from '../../utils/helper';

const ThemeCard = ({
  data, handleSelection, isSelected, activeTheme, activeLabel
}) => {
  const {
    name, isDark, styles, isOOTB,
  } = getDisplayedTheme(data);
  const isActive = data.find((theme) => theme.name === activeTheme?.name);

  const handleClick = () => {
    if (handleSelection && typeof handleSelection === 'function') {
      handleSelection(data);
      console.log(`bcconsole Clicked on ${name}`);
    }
  };

  const backgroundColor = name === 'saviynt-classic' ? styles?.background : styles?.primary;

  return (
    <div
      className={`${classes.themeCard} ${(isSelected && classes.selected)}`}
      onClick={handleClick}
      onKeyDown={(e) => e.key === 'Enter' && handleClick()}
      role="button"
      tabIndex={0}
    >
      <div>
        <div className={classes.themeColourWrapper}>
          <div
            className={`${classes.themeColour}`}
            style={{ backgroundColor }}
          >
            <div className={classes.overlay}>
              {isActive && (
                <TooltipComponent
                  text={activeLabel}
                  position="rightTop"
                  delay={250}
                >
                  <span className={classes.starIcon}>
                    <Icon kind="star" color="neutral-100" size="full" />
                  </span>
                </TooltipComponent>
              )}
            </div>
          </div>
        </div>
        <div className={classes.themeNameWrap}>
          <TooltipComponent
            text={styles?.displayName}
            position="rightTop"
            delay={250}
          >
            <div className={`${classes.typography_body_1} ${classes.text_center} ${classes.noWrap} ${classes.fullWidth}`}>{styles?.displayName}</div>
          </TooltipComponent>
        </div>
      </div>
    </div>
  );
};

ThemeCard.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  data: PropTypes.shape({}).isRequired,
  handleSelection: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  activeTheme: PropTypes.shape({}).isRequired,
};

export default ThemeCard;
