import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Cookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { Icon } from '@saviynt/design-system';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import { checkEnvironment } from 'ui-common/src/lib/utils';
import WelcomeTiles from './WelcomeTiles';
import RequestAccessModal from './RequestAccessModal';
import RequestAccessMobileModal from './RequestAccessMobileModal';
import NoAccessModal from './NoAccessModal';
import './WelcomeSection.scss';
import { TooltipComponent } from '@saviynt/design-system';

const cookies = new Cookies();

const WelcomeSection = ({ intl }) => {
  // Add Custom Hooks for useMediaQuery, currently being used from MUI
  const isMobileDevice = useMediaQuery('(max-width:480px)');

  const [requestAccessModal, setRequestAccessModal] = useState(false);
  const [requestAccessMobileModal, setRequestAccessMobileModal] = useState(false);

  const [noAccessModal, setNoAccessModal] = useState(false);

  let domainUrl = checkEnvironment().GATEWAY_API_URL;
  const history = useHistory();
  const { welcomeTiles, hasRequestAccess, welcomeTilesFetchError } = useSelector(
    (state) => state.requestHome.initialLoad
  );
  const [username, setUserName] = useState('');
  const userInfo = useSelector((state) => state.user.userInfo) || {};

  useEffect(() => {
    if (userInfo) {
      let name = cookies.get('user_name');
      if (userInfo.firstname || userInfo.lastname) {
        name = `${userInfo.firstname || ''} ${userInfo.lastname || ''}`;
      }
      setUserName(name);
    }
  }, []);

  const handleNavigation = (tile) => {
    if (tile.tileId === 'request-access') {
      if (hasRequestAccess) {
        setNoAccessModal(true);
      } else if (isMobileDevice) {
        setRequestAccessMobileModal(true);
      } else {
        setRequestAccessModal(true);
      }
    } else if (tile.url.includes('ECM')) {
      if (tile.tileId === 'view-existing-access') {
        const navigateLink = tile?.url.replace('{userKey}', userInfo.id);
        domainUrl = domainUrl.replace(/\/ECMv6/g, '');
        window.location.replace(`${domainUrl}${navigateLink}`);
      } else if (tile.tileId === 'pending-approvals') {
        window.location.replace(`${domainUrl}${tile.url}`);
      }
    } else {
      history.push(tile.url);
    }
  };

  const handleRequestAccessModalClose = () => {
    setRequestAccessModal(false);
  };
  const handleRequestAccessMobileModalClose = () => {
    setRequestAccessMobileModal(false);
  };

  const handleNoAccessModalClose = () => {
    setNoAccessModal(false);
  };

  return (
    <div className="welcome-container row">
      <div className="welcome-message">
        <h1 className="welcome-text">
          {intl.formatMessage({ id: 'NewRequestHome.Welcome.Title' })}
          ,
          <br />
          {' '}
          {username && username.length > 25
            ? (
              <TooltipComponent
                delay={250}
                position="top"
                text={username}
                className="welcome-message-tooltip-section"
              >
                <div className='welcome-message-title'>{username}</div>
              </TooltipComponent>
            )
            : (
              <div>{username}!</div>
            )}
        </h1>
      </div>
      {welcomeTilesFetchError && (
        <div className="welcome-tile-fetch-error">
          <div className="welcome-tile-alert-icon">
            <Icon
              kind="AlertCriticalFilledSeparateColors"
              className="ModalIsland-alertContent-icon"
            />
          </div>
          <div>
            <span className="welcome-tile-error-text">Feature Unavailable. </span>
            <span className="welcome-tile-error-description">
              The server can't display the Welcome tiles because of a network problem. You can try
              again later.
            </span>
          </div>
        </div>
      )}
      {welcomeTiles.length > 0 && (
        <WelcomeTiles data={welcomeTiles} onCardClick={handleNavigation} />
      )}
      <RequestAccessModal
        open={requestAccessModal}
        onDialogClose={handleRequestAccessModalClose}
        username={username}
      />
      <RequestAccessMobileModal
        open={requestAccessMobileModal}
        onDialogClose={handleRequestAccessMobileModalClose}
        username={username}
      />
      <NoAccessModal open={noAccessModal} onDialogClose={handleNoAccessModalClose} />
    </div>
  );
};

WelcomeSection.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(WelcomeSection);
