/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
import '../ModalPageListIsland.css';

import { localize } from '@saviynt/common';
import {
  Box,
  Button,
  FooterActionBar,
  Icon,
  InlineMessage,
  InputField,
  ModalIsland,
  RadioButton,
  Typography,
} from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { CredentialRequestAccountSelection } from '../../../../models/PamModels';
import { msgs } from '../../constants';

function AccountListModalIsland({
  accountListArray,
  accountListModalContentRef,
  sectionRefIsScrolled,
  accountSelection,
  setAccountSelection,
  isAccountListModal,
  setIsAccountListModal,
}) {
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [modalAccountSelection, setModalAccountSelection] = useState(null);
  const intl = useIntl();

  const BTN_SAVE_TEXT = localize(intl, msgs.pam.common.save);
  const BTN_CANCEL_TEXT = localize(intl, msgs.pam.common.cancel);
  const ISLAND_HEADING_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.accountHeader
  );
  const NO_MATCHING_RECORDS_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.noAccountMatchingRecords
  );

  useEffect(() => {
    setModalAccountSelection(accountSelection);
  }, [accountSelection]);

  useEffect(() => {
    setFilteredData(accountListArray);
  }, [accountListArray]);

  useEffect(() => {
    setSearchValue('');
  }, [isAccountListModal]);

  useEffect(() => {
    const searchString = searchValue.toLowerCase();

    setSearchValue(searchString);

    const filteredArray = accountListArray?.filter(
      (item) =>
        item.title.toLowerCase().includes(searchString) ||
        item.description.toLowerCase().includes(searchString)
    );

    setFilteredData(filteredArray);
  }, [searchValue]);

  const handleClick = (e, value) => {
    e.stopPropagation();
    setModalAccountSelection(value);
  };

  const handleCancel = () => {
    setIsAccountListModal(false);
    setModalAccountSelection(accountSelection);
  };

  const handleSave = () => {
    setIsAccountListModal(false);
    setAccountSelection(modalAccountSelection);
  };

  const getModalIslandContent = () => (
    <Box className='ModalPageListIsland-modalIslandContent'>
      <Box className='ModalPageListIsland-modalIslandContent-search'>
        <InputField
          name='modalIsland-search'
          kind='search'
          placeholder='Search by account name or description'
          prefixIcon={<Icon kind='search' size='smallMedium' />}
          value={searchValue}
          setValue={setSearchValue}
        />
      </Box>
      {filteredData?.length === 0 || !filteredData ? (
        <InlineMessage>
          <Box className='ModalPageListIsland-noMatchingSearchRecords'>
            <Icon kind='alertCriticalOutline' />
            <Typography
              kind='h3'
              className='ModalPageListIsland-noMatchingSearchRecords-title'>
              {NO_MATCHING_RECORDS_TEXT}
            </Typography>
          </Box>
        </InlineMessage>
      ) : (
        <Box className='ModalPageListIsland-radioButtons'>
          {filteredData?.map((account) => (
            <RadioButton
              key={`accountModalRadio-${account.value}`}
              id={`accountModalRadio-${account.value}`}
              name='accountListSelectionRadioGroup'
              title={account.title}
              description={account.description}
              value={account}
              radioSelection={modalAccountSelection}
              onClick={handleClick}
              isTruncated
            />
          ))}
        </Box>
      )}
    </Box>
  );

  const modalIslandFooterActionBar = () => (
    <Box className='ModalPageListIsland-footerActionBar'>
      <Button kind='outlined' size='medium' onClick={() => handleCancel()}>
        {BTN_CANCEL_TEXT}
      </Button>
      <Button
        isDisabled={!modalAccountSelection}
        kind='filled'
        size='medium'
        onClick={() => handleSave()}>
        {BTN_SAVE_TEXT}
      </Button>
    </Box>
  );

  return (
    <ModalIsland
      className='ModalPageListIsland'
      onClose={() => setIsAccountListModal(false)}
      isOpen={isAccountListModal}
      headerIcon={<Icon kind='account' size='large' />}
      title={ISLAND_HEADING_TEXT}
      contentBody={getModalIslandContent()}
      sectionRef={accountListModalContentRef}
      FooterActionBarComp={
        <FooterActionBar size='large' isScrolled={sectionRefIsScrolled}>
          {modalIslandFooterActionBar()}
        </FooterActionBar>
      }
    />
  );
}

AccountListModalIsland.propTypes = {
  accountListArray: PropTypes.arrayOf(CredentialRequestAccountSelection),
  accountListModalContentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  sectionRefIsScrolled: PropTypes.bool,
  accountSelection: CredentialRequestAccountSelection,
  setAccountSelection: PropTypes.func.isRequired,
  isAccountListModal: PropTypes.bool.isRequired,
  setIsAccountListModal: PropTypes.func.isRequired,
};

AccountListModalIsland.defaultProps = {
  accountListArray: null,
  accountListModalContentRef: null,
  sectionRefIsScrolled: null,
  accountSelection: null,
};

export default AccountListModalIsland;
