import './AppLauncher.css';

import { localize } from '@saviynt/common';
import { Box, Grid, Link, RadioButton } from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import {
  AppLauncherSelectionModel,
  RemoteAppMetadataArrayModel,
} from '../../../../models/PamModels';
import {
  MAX_APPS_BEFORE_SPLICE,
  MAX_APPS_TO_SHOW,
  msgs,
} from '../../constants';

function AppLauncher({
  appLauncherSelection,
  remoteAppMetadata,
  remoteAppListArray,
  setRemoteAppListArray,
  setAppLauncherSelection,
  setIsRemoteAppListModal,
  setIsExpandedAppLauncher,
}) {
  const intl = useIntl();
  const { Item } = Grid;

  const MORE_LINK_TEXT = localize(
    intl,
    msgs.pam.modalPage.accountSelection.moreLink
  );

  const handleClick = (e, value) => {
    e.stopPropagation();
    setAppLauncherSelection(value);
    setIsExpandedAppLauncher(false);
  };

  const handleAccountList = () => {
    setIsRemoteAppListModal(true);
  };

  const createRadioOptions = () => {
    const radioOptionArray = [];

    if (remoteAppMetadata?.length > 0) {
      remoteAppMetadata.forEach((detail) => {
        radioOptionArray.push({
          key: detail.key,
          displayName: detail.displayName,
          icon: detail.icon,
          // TODO: Need backend to update the detials to include a description
          description: detail.displayName,
        });
      });
    }

    setRemoteAppListArray(radioOptionArray);
  };

  useEffect(() => {
    createRadioOptions();
  }, [remoteAppMetadata]);

  const newRemoteAppListArray = Array.isArray(remoteAppListArray)
    ? [...remoteAppListArray]
    : [];

  if (newRemoteAppListArray?.length > 0) {
    const selectedIndex = newRemoteAppListArray.findIndex(
      (account) => account.value === appLauncherSelection?.value
    );

    if (selectedIndex > MAX_APPS_BEFORE_SPLICE) {
      const [selectedItem] = newRemoteAppListArray.splice(selectedIndex, 1);

      newRemoteAppListArray.unshift(selectedItem);
    }
  }

  return (
    <Box className='AppLauncher'>
      <Box className='MpaAccountSelection-selectCurrentAccount'>
        <Grid className='AppLauncher-grid' spacing={2}>
          {remoteAppListArray?.slice(0, MAX_APPS_TO_SHOW).map((app, index) => (
            <Item
              key={`appLauncher-${app.key}`}
              className='AppLauncher-radioButtons'
              desktop={6}
              tablet={6}>
              <RadioButton
                id={`appLauncher-${index}`}
                name='appLauncherRadioGroup'
                title={app.displayName}
                // TODO: MVP-2, update the description when backend is updated
                // description={app.description}
                value={app}
                radioSelection={appLauncherSelection}
                onClick={handleClick}
                isTruncated
              />
            </Item>
          ))}
        </Grid>
        {remoteAppListArray?.length > MAX_APPS_TO_SHOW ? (
          <Link
            href='Account More Modal Button'
            text={MORE_LINK_TEXT}
            size='large'
            onClick={() => handleAccountList()}
          />
        ) : null}
      </Box>
    </Box>
  );
}

AppLauncher.propTypes = {
  appLauncherSelection: AppLauncherSelectionModel,
  remoteAppMetadata: RemoteAppMetadataArrayModel,
  remoteAppListArray: PropTypes.arrayOf(AppLauncherSelectionModel),
  setRemoteAppListArray: PropTypes.func.isRequired,
  setAppLauncherSelection: PropTypes.func.isRequired,
  setIsRemoteAppListModal: PropTypes.func.isRequired,
  setIsExpandedAppLauncher: PropTypes.func.isRequired,
};

AppLauncher.defaultProps = {
  appLauncherSelection: null,
  remoteAppMetadata: [],
  remoteAppListArray: [],
};

export default AppLauncher;
