import { Avatar, Icon } from '@saviynt/design-system';
import React from 'react';

export const mockListItems = [
  {
    type: 'endpoint',
    label: {
      text: 'Account',
      icon: {
        kind: 'Account',
        color: 'navy-500',
        size: 'small',
      },
    },
    name: 'AWS-Win-AP2',
    description: [
      {
        kind: 'icon-text',
        icon: <Icon kind='Success' color='navy-500' size='small' />,
        text: 'Server',
      },
      {
        kind: 'avatar-text',
        avatar: (
          <Avatar
            size='xxSmall'
            alt='Sample'
            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDWMv02cOqnvtHN-GEszt7Bqf7xttiFXBA_MQtQ6YhV6h_BqpwXA-BTI4t7eNWgyxhBfQ&usqp=CAU'
          />
        ),
        text: 'AWS',
      },
      { kind: 'label-value', label: 'Location', value: 'us-east-1' },
      { kind: 'text', text: 'This connects to Windows server. ' },
    ],
    primaryIconValue: (
      <Icon kind='ConnectPlug' color='navy-700' size='medium' />
    ),
    secondaryIconValue: (
      <Icon kind='PasswordCredentialUnlock' color='navy-700' size='medium' />
    ),
  },
  {
    type: 'account',
    label: {
      account: 'Account',
      icon: <Icon kind='Account' color='neutral-1000' size='small' />,
      platform: 'Active Directory',
    },
    name: 'jsmith-a',
    description: [{ kind: 'text', text: 'Jsmith personal IT domain account' }],
    primaryIconValue: (
      <Icon kind='ConnectPlug' color='navy-700' size='medium' />
    ),

    hasMoreOption: true,
  },
  {
    type: 'session',
    name: [
      { kind: 'text', text: 'AWS-WIN-2' },
      { kind: 'label-value', label: 'Account', value: 'jsmith-a' },
    ],
    description: [
      {
        kind: 'icon-text',
        icon: <Icon kind='Success' color='navy-500' size='small' />,
        text: 'Server',
      },
      {
        kind: 'avatar-text',
        avatar: (
          <Avatar
            size='xxSmall'
            alt='Sample'
            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDWMv02cOqnvtHN-GEszt7Bqf7xttiFXBA_MQtQ6YhV6h_BqpwXA-BTI4t7eNWgyxhBfQ&usqp=CAU'
          />
        ),
        text: 'AWS',
      },
      { kind: 'label-value', label: 'Location', value: 'us-east-1' },

      { kind: 'text', text: 'This connects to Windows server. ' },
    ],
    status: '10m left',
    primaryIconValue: (
      <Icon kind='ConnectPlug' color='navy-700' size='medium' />
    ),
    secondaryIconValue: (
      <Icon kind='PasswordCredentialUnlock' color='navy-700' size='medium' />
    ),

    hasMoreOption: true,
  },
  {
    category: null,
    title: 'i-0dac9302b67de7f29',
    description: [
      {
        kind: 'icon-text',
        icon: <Icon kind='Success' color='navy-500' size='small' />,
        text: 'Server',
      },
      {
        kind: 'avatar-text',
        avatar: (
          <Avatar
            size='xxSmall'
            alt='Sample'
            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDWMv02cOqnvtHN-GEszt7Bqf7xttiFXBA_MQtQ6YhV6h_BqpwXA-BTI4t7eNWgyxhBfQ&usqp=CAU'
          />
        ),
        text: 'AWS',
      },
      { kind: 'label-value', label: 'Location', value: 'us-east-1' },

      { kind: 'text', text: 'This connects to Windows server. ' },
    ],
    hasMoreOption: false,
    primaryIconValue: null,
    secondaryIconValue: null,
    status: false,
    type: 'endpoint',
  },
];

// TODO: update mockData to include: pamAccountType which is the new key to match the account filters with, and platformType for platform
export const mockData = {
  result: {
    userAccesses: [
      {
        requestAccessKey: 3370,
        startDate: '2023-09-22T09:34:00.000+00:00',
        endDate: '2023-09-22T18:00:00.000+00:00',
        accountName: 'pamtestuser',
        accountKey: 1074,
        endpointName: 'i-059bf643005207f13',
        resourceType: 'Server',
        applicationType: 'AWS',
        connectionType: 'AWS',
        endpointKey: 3,
        status: '2',
        type: 'session',
        description: 'Dev Server details will come in description',
        assetType: 'Server',
        location: 'us-east',
      },
      {
        accountKey: '1018',
        accountName: 'preauthasishff1',
        credentialType: 'CREDENTIAL',
        description: 'Dev Acc',
        endpointName: 'i-059bf643005207f13',
        resourceType: 'Server',
        applicationType: 'AWS',
        connectionType: 'AWS',
        endpointKey: 111,
        status: '1',
        type: 'account',
        assetType: 'Application',
        location: 'us-east',
      },
      {
        accountKey: '595',
        accountName: 'testingacc',
        credentialType: 'CREDENTIAL',
        description: 'Dev Account details will be mentioned here',
        endpointName: 'i-059bf643005207f13',
        resourceType: 'Server',
        applicationType: 'AWS',
        connectionType: 'AWS',
        endpointKey: 111,
        status: '1',
        type: 'account',
        assetType: 'Application',
        location: 'us-east',
      },
      {
        accountKey: '596',
        accountName: 'testingacc2',
        credentialType: 'CREDENTIALLESS',
        description: 'Dev Account details will be mentioned here',
        endpointName: 'i-059bf643005207f14',
        resourceType: 'Server',
        applicationType: 'AWS',
        connectionType: 'AWS',
        endpointKey: 111,
        status: '1',
        type: 'account',
        location: 'us-east',
      },
      {
        endpointName: 'i-059bf643005207f13',
        endpointKey: 111,
        credentialType: 'CREDENTIAL',
        displayName: 'member-machine-linux-ubuntu2022.0',
        customproperty2:
          'Environment:qa,Name:member-machine-linux,Owner:Sumant,Client:saviynt',
        customproperty10: 'us-east-2',
        resourceType: 'Server',
        applicationType: 'AWS',
        connectionType: 'AWS',
        description: 'Dev Server',
        status: '1',
        type: 'endpoint',
        assetType: 'Server',
        location: 'us-east',
      },
      {
        endpointName: 'i-0dac9302b67de7f29',
        endpointKey: 71,
        displayName: 'membermachine',
        customproperty2: 'Environment:qa,Name:member,Client:saviynt',
        customproperty10: 'us-east-2',
        resourceType: 'Database',
        applicationType: 'AWS',
        connectionType: 'AWS',
        description: 'Dev Server details will come in description',
        status: '1',
        type: 'endpoint',
        assetType: 'Database',
        location: 'us-east',
      },
      {
        endpointKey: 3,
        endpointName: 'GCP-SA',
        endpointDisplayName: 'GCP-SA',
        epCustomproperty6: 'abcd',
        epPamConfig: {
          maxConcurrentSession: '200',
          rotateKey: 'false',
        },
        assetType: 'Application',
        applicationTypeKey: 8,
        applicationTypeName: 'GCP-Organization',
        applicationTypeDisplayName: 'Google Cloud Platform',
        remoteAppEnabled: true,
        remoteAppMetadatas: [
          {
            key: 'googlecloudlogin',
            displayName: 'Web App',
            icon: 'webapp',
            setup: 'PAM_REMOTE_APP_SETUP',
            internalAttr: {
              client:
                'https://console.cloud.google.com/home/dashboard?login=true&ref=https:%2F%2Fconsole.cloud.google.com%2Fhome%2Fdashboard',
            },
          },
        ],
        exclusiveAccess: false,
        connectionType: 'GCP',
        platformType: 'GCP',
        pamPlatformType: 'GCPConsole',
        endpointStatus: 1,
        type: 'endpoint',
        location: 'us-east',
      },
      {
        endpointKey: 37,
        endpointName: 'dummy_GCP_SA',
        endpointDisplayName: 'dummy_GCP_SA',
        epCustomproperty22: '',
        epPamConfig: {
          maxConcurrentSession: '50',
          rotateKey: 'false',
        },
        assetType: 'Application',
        applicationTypeKey: 8,
        applicationTypeName: 'GCP-Organization',
        applicationTypeDisplayName: 'Google Cloud Platform',
        remoteAppEnabled: true,
        remoteAppMetadatas: [
          {
            key: 'googlecloudlogin',
            displayName: 'Web App',
            icon: 'webapp',
            setup: 'PAM_REMOTE_APP_SETUP',
            internalAttr: {
              client:
                'https://console.cloud.google.com/home/dashboard?login=true&ref=https:%2F%2Fconsole.cloud.google.com%2Fhome%2Fdashboard',
            },
          },
        ],
        exclusiveAccess: false,
        connectionType: 'GCP',
        platformType: 'GCP',
        pamPlatformType: 'GCPConsole',
        endpointStatus: 1,
        type: 'endpoint',
      },
      {
        endointName: 'i-0dac9302b67de7f23',
        endpointKey: 72,
        displayName: 'membermachine1',
        credentialType: 'CREDENTIALLESS',
        customproperty2: 'Environment:qa,Name:member,Client:saviynt',
        customproperty10: 'us-east-2',
        resourceType: 'Application',
        applicationType: 'AWS',
        connectionType: 'AWS',
        description: 'Dev Server details will come in description',
        status: '1',
        type: 'endpoint',
      },
    ],
  },
  count: 6,
};

// applicationType
export const mockPlatformResponse = {
  AZURE: 'Azure',
  AD: 'Active Directory',
  SAP: 'SAP',
  GCP: 'GCP',
  OKTA: 'Okta',
  AWS: 'AWS',
  AZURE_AD: 'AzureAD',
  SALESFORCE: 'Salesforce',
  ON_PREMISE: 'On-Premise',
};

// resourceType
export const mockAssetResponse = {
  DATABASE: 'Database',
  SERVER: 'Server',
  APPLICATION: 'Application',
  VAULT: 'Vault',
};

// TODO: Replace this Account data with correct key value pairs from API, and replace the filter logic in the ListRow comp.
export const mockAccountResponse = {
  SHARED_ACCOUNT: 'Shared Account',
  SERVICE_ACCOUNT: 'Service Account',
  JIT_ACCOUNT: 'Temporary Admin (JIT) Account',
};

export const mockCredintialRequest = {
  accesstype: 'PRIVILEGEDACCESS',
  requestor: 'pamadmin',
  requestedfor: 'pamadmin',
  comments: null,
  sessions: [
    {
      businessjustification: null,
      startdate: null,
      enddate: null,
      accountId: 3545,
    },
  ],
  pamtype: 'credentials',
};

// Scheduler Component Mock Data
const newDate = new Date();

export const getMockDate = (date, days, hours) => {
  date.setDate(date.getDate() + days);

  date.setHours(date.getHours() + hours);

  return date.toISOString();
};

export const mockSessions = [
  {
    firstname: 'Huy',
    lastname: 'Pham',
    username: 'huy.pham',
    startDate: getMockDate(newDate, 0, 0),
    endDate: getMockDate(newDate, 0, 2),
    requestId: 4380307,
  },
  {
    firstname: 'Huy',
    lastname: 'Pham',
    username: 'huy.pham',
    startDate: getMockDate(newDate, 2, 0),
    endDate: getMockDate(newDate, 0, 2),
    requestId: 4380307,
  },
  {
    firstname: 'Nathan',
    lastname: 'Kim',
    username: 'nathan.kim',
    startDate: getMockDate(newDate, 0, 3),
    endDate: getMockDate(newDate, 0, 5),
    requestId: 4380308,
  },
  {
    firstname: 'Jacob',
    lastname: 'Eades',
    username: 'jacob.eades',
    startDate: getMockDate(newDate, 1, 3),
    endDate: getMockDate(newDate, 0, 5),
    requestId: 4380309,
  },
  {
    firstname: 'Yash',
    lastname: 'Agarwal',
    username: 'yash.agarwal',
    startDate: getMockDate(newDate, 2, 2),
    endDate: getMockDate(newDate, 0, 4),
    requestId: 4380310,
  },
];
