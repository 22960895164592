export const TABS = {
  ACCOUNT: 'Account',
  ASSET: 'Asset',
  SCHEDULE: 'Schedule',
  SESSION: 'Session',
};

export const TITLES = {
  SUMMARY: 'Summary',
  SYSTEM_DETAILS: 'System Details',
  TIME: 'Time',
  ACCOUNT: 'Account',
  REQUEST_DETAILS: 'Request Details',
  COMMENTS: 'Comments',
  ATTACHMENTS: 'Attachments',
  TARGET_SYSTEM: 'Target System',
  REMOTE_APPLICATION: 'Remote Application',
};

export const TYPES = {
  ACCOUNT: 'account',
  SESSION: 'session',
  ENDPOINT: 'endpoint',
};

export const ASSET_TYPES = {
  ACCOUNT: 'Account',
  APPLICATION: 'Application',
  DATABASE: 'Database',
  SERVER: 'Server',
  CONSOLE: 'Console',
};

export const CLOUD_PROVIDERS = {
  AWS: 'AWS',
  AZURE: 'AZURE',
  GCP: 'GCP',
};

export const PLATFORMS_LABELS = {
  AWS_ACCOUNT: 'AWS Account',
  AZURE_SUBSCRIPTION: 'Azure Subscription',
  GCP_PROJECT: 'GCP Project',
};

export const TENANTS_LABELS = {
  TENANT_AWS: 'Tenant (AWS Account)',
  TENANT_AZURE: 'Tenant (Azure Account)',
  TENANT_GCP: 'Tenant (GCP Account)',
};

export const BUTTON_LABELS = {
  CHECK_IN: 'Check In',
  EXTEND: 'Extend',
  // TODO: Remove after getting requirements for Credentialless
  CHECKOUT_CREDENTIALS: 'Check Out Credential',
  CONNECT_TO_SESSION: 'Connect to Session',
  CANCEL_REQUEST: 'Cancel Request',
  END: 'End',
  CANCEL_SESSION: 'Cancel Session',
  LAUNCH_SESSION: 'Launch Session',
  EXTEND_SESSION: 'Extend Session',
  END_SESSION: 'End Session',
};

export const LABELS = {
  NAME: 'Name',
  PLATFORM: 'Platform',
  DESCRIPTION: 'Description',
  LOCATION: 'Location',
  ACCOUNT_TYPE: 'Account Type',
  AUTHENTICATION_MECHANISM: 'Authentication Mechanism',
  ID: 'ID',
  OPERATING_SYSTEM: 'Operating System',
  ENVIRONMENT: 'Environment',
  NETWORK: 'Network',
  DATABASE_NAME: 'Database Name',
  INSTANCE: 'Instance',
  INSTANCE_OS: 'Instance (OS)',
  RDS_TYPE: 'RDS Type',
  PUBLIC_IP: 'Public IP',
  PRIVATE_IP: 'Private IP',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  REQUEST_STATUS: 'Request Status',
  REQUEST_ID: 'Request ID',
  REQUEST_TIME: 'Request Time',
  ASSIGNED_TO: 'Assigned to',
  TICKET_NUMBER: 'Ticket Number',
  JUSTIFICATION: 'Justification',
  APPROVED_BY: 'Approved By',
  APPROVAL_TIME: 'Approval Time',
  TARGET_SERVER: 'Target Server',
  PORT_NUMBER: 'Port Number',
  DATABASE_TYPE: 'Database Type',
  IP_HOST: 'IP/HOST',
};

export const JIT_PAM_TYPES = {
  UNIX: 'unix',
  WINDOWS: 'windows',
  DB: 'db',
};

export const msgs = {
  pam: {
    sidePanel: {
      // Buttons
      checkoutOutCredential: {
        id: 'pam.sidePanel.checkoutCredential',
        defaultMessage: BUTTON_LABELS.CHECKOUT_CREDENTIALS,
      },
      connectToSession: {
        id: 'pam.sidePanel.connectToSession',
        defaultMessage: BUTTON_LABELS.CONNECT_TO_SESSION,
      },
      checkIn: {
        id: 'pam.sidePanel.checkIn',
        defaultMessage: BUTTON_LABELS.CHECK_IN,
      },
      end: {
        id: 'pam.sidePanel.end',
        defaultMessage: BUTTON_LABELS.END,
      },
      extend: {
        id: 'pam.sidePanel.extend',
        defaultMessage: BUTTON_LABELS.EXTEND,
      },
      cancelRequest: {
        id: 'pam.sidePanel.cancelRequest',
        defaultMessage: BUTTON_LABELS.CANCEL_REQUEST,
      },
      cancelSession: {
        id: 'pam.sidePanel.cancelSession',
        defaultMessage: BUTTON_LABELS.CANCEL_SESSION,
      },
      launchSession: {
        id: 'pam.sidePanel.launchSession',
        defaultMessage: BUTTON_LABELS.LAUNCH_SESSION,
      },
      extendSession: {
        id: 'pam.sidePanel.extendSession',
        defaultMessage: BUTTON_LABELS.EXTEND_SESSION,
      },
      endSession: {
        id: 'pam.sidePanel.endSession',
        defaultMessage: BUTTON_LABELS.END_SESSION,
      },

      // Assets
      server: {
        id: 'pam.sidePanel.server',
        defaultMessage: ASSET_TYPES.SERVER,
      },
      application: {
        id: 'pam.sidePanel.application',
        defaultMessage: ASSET_TYPES.APPLICATION,
      },
      database: {
        id: 'pam.sidePanel.database',
        defaultMessage: ASSET_TYPES.DATABASE,
      },

      // Tab Titles
      session: {
        id: 'pam.sidePanel.session',
        defaultMessage: TABS.SESSION,
      },
      asset: {
        id: 'pam.sidePanel.asset',
        defaultMessage: TABS.ASSET,
      },
      account: {
        id: 'pam.sidePanel.account',
        defaultMessage: TABS.ACCOUNT,
      },
      schedule: {
        id: 'pam.sidePanel.schedule',
        defaultMessage: TABS.SCHEDULE,
      },

      // Tab Headers
      summary: {
        id: 'pam.sidePanel.summary',
        defaultMessage: TITLES.SUMMARY,
      },
      systemDetails: {
        id: 'pam.sidePanel.systemDetails',
        defaultMessage: TITLES.SYSTEM_DETAILS,
      },
      time: {
        id: 'pam.sidePanel.time',
        defaultMessage: TITLES.TIME,
      },
      requestDetails: {
        id: 'pam.sidePanel.requestDetails',
        defaultMessage: TITLES.REQUEST_DETAILS,
      },
      comments: {
        id: 'pam.sidePanel.comments',
        defaultMessage: TITLES.COMMENTS,
      },
      attachments: {
        id: 'pam.sidePanel.attachments',
        defaultMessage: TITLES.ATTACHMENTS,
      },
      targetSystem: {
        id: 'pam.sidePanel.targetSystem',
        defaultMessage: TITLES.TARGET_SYSTEM,
      },
      remoteApplication: {
        id: 'pam.sidePanel.remoteApplication',
        defaultMessage: TITLES.REMOTE_APPLICATION,
      },

      // Labels
      accountType: {
        id: 'pam.sidePanel.accountType',
        defaultMessage: LABELS.ACCOUNT_TYPE,
      },
      startTime: {
        id: 'pam.sidePanel.startTime',
        defaultMessage: LABELS.START_TIME,
      },
      endTime: {
        id: 'pam.sidePanel.endTime',
        defaultMessage: LABELS.END_TIME,
      },
      name: {
        id: 'pam.sidePanel.name',
        defaultMessage: LABELS.NAME,
      },
      platform: {
        id: 'pam.sidePanel.platform',
        defaultMessage: LABELS.PLATFORM,
      },
      location: {
        id: 'pam.sidePanel.location',
        defaultMessage: LABELS.LOCATION,
      },
      id: {
        id: 'pam.sidePanel.id',
        defaultMessage: LABELS.ID,
      },
      awsAccount: {
        id: 'pam.sidePanel.awsAccount',
        defaultMessage: PLATFORMS_LABELS.AWS_ACCOUNT,
      },
      gcpAccount: {
        id: 'pam.sidePanel.gcpAccount',
        defaultMessage: PLATFORMS_LABELS.GCP_PROJECT,
      },
      databaseName: {
        id: 'pam.sidePanel.databaseName',
        defaultMessage: LABELS.DATABASE_NAME,
      },
      rdsType: {
        id: 'pam.sidePanel.rdsType',
        defaultMessage: LABELS.RDS_TYPE,
      },
      operatingSystem: {
        id: 'pam.sidePanel.operatingSystem',
        defaultMessage: LABELS.OPERATING_SYSTEM,
      },
      environment: {
        id: 'pam.sidePanel.environment',
        defaultMessage: LABELS.ENVIRONMENT,
      },
      network: {
        id: 'pam.sidePanel.network',
        defaultMessage: LABELS.NETWORK,
      },
      publicIP: {
        id: 'pam.sidePanel.publicIP',
        defaultMessage: LABELS.PUBLIC_IP,
      },
      privateIP: {
        id: 'pam.sidePanel.privateIP',
        defaultMessage: LABELS.PRIVATE_IP,
      },
      description: {
        id: 'pam.sidePanel.description',
        defaultMessage: LABELS.DESCRIPTION,
      },
      authenticationMechanism: {
        id: 'pam.sidePanel.authenticationMechanism',
        defaultMessage: LABELS.AUTHENTICATION_MECHANISM,
      },
      requestStatus: {
        id: 'pam.sidePanel.requestStatus',
        defaultMessage: LABELS.REQUEST_STATUS,
      },
      requestId: {
        id: 'pam.sidePanel.requestId',
        defaultMessage: LABELS.REQUEST_ID,
      },
      requestTime: {
        id: 'pam.sidePanel.requestTime',
        defaultMessage: LABELS.REQUEST_TIME,
      },
      assignedTo: {
        id: 'pam.sidePanel.assignedTo',
        defaultMessage: LABELS.ASSIGNED_TO,
      },
      ticketNumber: {
        id: 'pam.sidePanel.ticketNumber',
        defaultMessage: LABELS.TICKET_NUMBER,
      },
      justification: {
        id: 'pam.sidePanel.justification',
        defaultMessage: LABELS.JUSTIFICATION,
      },
      approvedBy: {
        id: 'pam.sidePanel.approvedBy',
        defaultMessage: LABELS.APPROVED_BY,
      },
      approvalTime: {
        id: 'pam.sidePanel.approvalTime',
        defaultMessage: LABELS.APPROVAL_TIME,
      },
      targetServer: {
        id: 'pam.sidePanel.targetServer',
        defaultMessage: LABELS.TARGET_SERVER,
      },
      portNumber: {
        id: 'pam.sidePanel.portNumber',
        defaultMessage: LABELS.PORT_NUMBER,
      },
      databaseType: {
        id: 'pam.sidePanel.databaseType',
        defaultMessage: LABELS.DATABASE_TYPE,
      },
      ipHost: {
        id: 'pam.sidePanel.ipHost',
        defaultMessage: LABELS.IP_HOST,
      },
    },
  },
};
