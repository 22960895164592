import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { COLORS_KEYS } from './dist/Color-constants';

import './Color.css';

function Color({ children, className, type, color, dataTestId }) {
  const classes = classnames(
    className,
    `Color-${type}-${color}`,
    `Color-${color}`
  );

  return (
    <span className={classes} data-testid={dataTestId}>
      {children}
    </span>
  );
}

Color.propTypes = {
  type: PropTypes.oneOf(['foreground', 'background', 'icon']),
  color: PropTypes.oneOf(COLORS_KEYS),
};

Color.defaultProps = {
  color: 'navy-1000',
  type: 'foreground',
};

export default Color;
