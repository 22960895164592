/* eslint-disable react/jsx-curly-spacing */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Avatar from '../Avatar/Avatar';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import Icon from '../Icon/Icon';
import Logo from '../Logo/Logo';

import './Header.css';

function Header({
  dataTestId,
  helpOnClick,
  notificationsOnClick,
  avatarOnClick,
  user,
  isScrolled,
  className,
}) {
  const classes = classnames(
    'Header',
    isScrolled && 'Header--scrolled',
    className
  );

  return (
    <div id='Header' className={classes} data-testid={dataTestId}>
      <div className='Header-extraContent'>
        <Logo className='Header-logo' />
      </div>
      <div className='Header-actionItems'>
        {helpOnClick && (
          <ButtonIcon
            kind='ghost'
            icon={
              <Icon kind='HelpOutlined' size='medium' color='neutral-1000' />
            }
            onClick={() => {
              console.log('Help feature does not exist yet');
            }}
          />
        )}
        {notificationsOnClick && (
          <ButtonIcon
            kind='ghost'
            icon={
              <Icon kind='Notification' size='medium' color='neutral-1000' />
            }
            onClick={() => {
              console.log('Notifications feature does not exist yet');
            }}
          />
        )}
        <ButtonIcon
          kind='ghost'
          className='Header-avatar'
          icon={
            <Avatar alt='avatar' size='small' src={user?.url}>
              {!user?.url && user?.name.charAt(0)}
            </Avatar>
          }
          onClick={avatarOnClick}
        />
      </div>
    </div>
  );
}

Header.propTypes = {
  dataTestId: PropTypes.string,
  helpOnClick: PropTypes.func,
  notificationsOnClick: PropTypes.func,
  avatarOnClick: PropTypes.func,
  // TODO: this object needs to be updated with the correct props
  user: PropTypes.shape({ name: PropTypes.string, url: PropTypes.string }),
  isScrolled: PropTypes.bool,
  className: PropTypes.string,
};

Header.defaultProps = {
  dataTestId: null,
  helpOnClick: null,
  notificationsOnClick: null,
  avatarOnClick: null,
  user: null,
  isScrolled: false,
  className: null,
};

export default Header;
