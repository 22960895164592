import React from 'react';
import './Title.css';

const LabelValueFortitle = ({ label, value }) => {
    return (
        <span className='ListRow-labelValue'>
            <span className='ListRow-labelValue-label'>{label}:&nbsp;</span>

            <span className='ListRow-labelValue-text'>{value}</span>
        </span>
    );
};

const Title = ({ data }) => {
    const dataArray = Array.isArray(data) ? data : [data];

    return (
        <div className='ListRow-title'>
            {dataArray.map((item, index) => (
                <div key={index} className='ListRow-title-content'>
                    {index > 0 && <span className='ListRow-dot'></span>}
                    {item.kind === 'label-value' && (
                        <LabelValueFortitle label={item.label} value={item.value} />
                    )}
                    {item.kind === 'text' && item.text}
                </div>
            ))}
        </div>
    );
};

export default Title;