import './SpvAccounts.css';

import { Icon, TabContent, TabGroup, TabItem } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useTenantLabel } from '../../../../hooks/use-labels';
import { mockSessions } from '../../../../models/MockData/MockData';
import { ModuleDetails } from '../../../../models/PamModels';
import Scheduler from '../../../Scheduler/Scheduler';
import { LABELS, TABS, TITLES } from '../../constants';
import SpvSection from '../SpvSection/SpvSection';

function SpvAccounts({ data, dataTestId, className }) {
  const classes = classnames('SpvAccounts', className);

  const cloudProvider = data.connectionType?.toUpperCase();

  const [activeTab, setActiveTab] = useState('1');

  const handleChange = (value) => {
    setActiveTab(value);
  };

  const accountSummaryHasFields = data.connectionType || data.description;

  const accountSystemDetailsHasFields = data.accountType || data.accountKey;

  const assetSummaryHasFields =
    data.connectionType || data.customproperty10 || data.accountType;

  const assetSystemDetailsHasFields =
    data.accountKey || data.customproperty5 || data.connectionType;

  return (
    <section className={classes} data-testid={dataTestId}>
      <TabGroup
        className='SpvAccounts-tabGroup'
        value={activeTab}
        onChange={handleChange}>
        <TabItem
          label={TABS.ACCOUNT}
          value='1'
          icon={<Icon kind='Account' size='medium' color='neutral-1000' />}
        />
        <TabItem
          label={TABS.ASSET}
          value='2'
          icon={
            <Icon kind='AssetTargetSystem' size='medium' color='neutral-1000' />
          }
        />
        <TabItem
          label={TABS.SCHEDULE}
          value='3'
          icon={<Icon kind='Calendar' size='medium' color='neutral-1000' />}
        />
      </TabGroup>
      <TabContent
        className='SpvAccounts-tabContent'
        value={activeTab}
        tabValue='1'>
        {accountSummaryHasFields && (
          <SpvSection
            title={TITLES.SUMMARY}
            fields={[
              {
                label: LABELS.PLATFORM,
                value: data.connectionType,
              },
              {
                label: LABELS.DESCRIPTION,
                value: data.description,
              },
            ]}
          />
        )}
        {accountSystemDetailsHasFields && (
          <SpvSection
            title={TITLES.SYSTEM_DETAILS}
            fields={[
              { label: LABELS.ACCOUNT_TYPE, value: data.accountType },
              {
                label: LABELS.AUTHENTICATION_MECHANISM,
                value: data.accountKey ? 'Key' : null,
              },
            ]}
            className='SpvAccounts-tabSection'
          />
        )}
      </TabContent>
      <TabContent
        className='SpvAccounts-tabContent'
        value={activeTab}
        tabValue='2'>
        {assetSummaryHasFields && (
          <SpvSection
            title={TITLES.SUMMARY}
            fields={[
              {
                label: LABELS.PLATFORM,
                value: data.connectionType,
              },
              {
                label: LABELS.LOCATION,
                value: data.customproperty10,
              },
              {
                label: LABELS.ACCOUNT_TYPE,
                value: data.accountType,
              },
            ]}
          />
        )}
        {assetSystemDetailsHasFields && (
          <SpvSection
            title={TITLES.SYSTEM_DETAILS}
            fields={[
              { label: LABELS.ID, value: data.accountName },
              {
                label: useTenantLabel(cloudProvider),
                value: data.accountKey ? 'Key' : null,
              },
              {
                // TODO: Temp mapping; real mapped field unknown; needs update
                label: LABELS.INSTANCE_OS,
                value: data.accountKey,
              },
              {
                label: LABELS.ENVIRONMENT,
                value: data.customproperty5,
              },
              {
                label: LABELS.NETWORK,
                value: data.connectionType,
              },
            ]}
            className='SpvAccounts-tabSection'
          />
        )}
      </TabContent>
      <TabContent
        className='SpvAccounts-tabContent'
        value={activeTab}
        tabValue='3'>
        <Scheduler
          sessions={mockSessions}
          className='SpvAccounts-Scheduler'
          timeFormat={{ roundToNearest: 'minute', is24HourFormat: false }}
        />
      </TabContent>
    </section>
  );
}

SpvAccounts.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvAccounts.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SpvAccounts;
