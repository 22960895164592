import React from 'react';
import PropTypes from 'prop-types';

/**
 * 'Box' is a simple wrapper div for components and page elements.
 * It takes tag and className props.
 * The tag prop is intended to be used for simple semantic HTML
 * elements such as 'section', 'article', etc.
 */
function Box({ children, tag: Tag, id, dataTestId, className }) {
  return (
    <Tag className={className} id={id} data-testid={dataTestId}>
      {children}
    </Tag>
  );
}

Box.propTypes = {
  children: PropTypes.node,
  tag: PropTypes.string,
  id: PropTypes.string,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

Box.defaultProps = {
  children: null,
  tag: 'div',
  id: null,
  dataTestId: null,
  className: null,
};

export default Box;
