import React, { cloneElement, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import useOutsideClickEffect from '../../../hooks/use-outsideclickeffect';
import ButtonCore from '../../Button/ButtonCore/ButtonCore';
import Icon from '../../Icon/Icon';
import MenuItem from '../MenuItem/MenuItem';

import './MenuAction.css';

const MENU_EXPAND_DIRECTIONS = {
  top: 'top',
  topLeft: 'topLeft',
  topRight: 'topRight',
  bottom: 'bottom',
  bottomLeft: 'bottomLeft',
  bottomRight: 'bottomRight',
};

const TRIGGER_AND_MENU_ALIGNMENT = {
  EDGE_TO_EDGE: 'edgeToEdge',
  OVERLAP: 'overlap',
};

/**
 * Menu Action renders a trigger and dropdown that
 * performs an action when an option is selected.
 *
 * #### WIP Todo:
 * - Finish styling.
 * - Dynamic menu positioning.
 * - Move isOpen state to dropdown parent.
 */

function MenuAction({
  options,
  label,
  trigger,
  onChange,
  expandDirection,
  triggerMenuAlignment,
  dataTestId,
}) {
  const [isOpen, setIsOpen] = useState(false);

  // TODO: implement dynamic menu positioning using the below state.
  // eslint-disable-next-line no-unused-vars
  const [menuPosition, setMenuPosition] = useState(expandDirection);

  const wrapperRef = useRef(null);
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);

  useOutsideClickEffect(wrapperRef, () => setIsOpen(false), isOpen);

  const selectedElement = options.find((option) => option.isChecked);

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const renderTrigger = () =>
    trigger ? (
      cloneElement(trigger, {
        isOpen,
        onClick: toggleDropdown,
      })
    ) : (
      <ButtonCore
        type='button'
        className='Menu-button'
        onClick={toggleDropdown}
        dataTestId='menu-trigger'
        aria-pressed={isOpen}>
        {label || selectedElement?.label || options?.[0]?.label}
        <Icon
          kind='ButtonSelectChevronDown'
          size='xxxSmall'
          rotate={isOpen ? '180' : null}
        />
      </ButtonCore>
    );

  const renderMenuItems = () =>
    options.map((option) => (
      <React.Fragment key={option.value}>
        <MenuItem
          key={option.value}
          option={option}
          onClick={() => !option.isDisabled && handleOptionClick(option)}
          isDisabled={option.isDisabled}
          tabIndex={isOpen ? 0 : -1}
        />
      </React.Fragment>
    ));

  return (
    <div
      className='Menu MenuAction'
      ref={wrapperRef}
      role='combobox'
      aria-controls='custom-select-listbox'
      aria-expanded={isOpen}
      aria-label={label}
      data-testid={dataTestId}>
      <div ref={triggerRef} className='Menu-triggerWrapper'>
        {renderTrigger()}
      </div>
      <div
        className={classnames(
          'Menu-dropdownContainer',
          `Menu-dropdown--${menuPosition}`,
          `Menu-dropdown--${triggerMenuAlignment}`,
          isOpen && 'Menu-dropdown--isVisible'
        )}>
        <div
          className={classnames(
            'Menu-dropdown',
            isOpen && 'Menu-dropdown--isVisible'
          )}>
          <ul
            className='Menu-items'
            role='listbox'
            tabIndex='-1'
            ref={dropdownRef}>
            {renderMenuItems()}
          </ul>
        </div>
      </div>
    </div>
  );
}

MenuAction.propTypes = {
  label: PropTypes.string,
  trigger: PropTypes.element,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      isDisabled: PropTypes.bool,
      isChecked: PropTypes.bool,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  expandDirection: PropTypes.oneOf(Object.values(MENU_EXPAND_DIRECTIONS)),
  triggerMenuAlignment: PropTypes.oneOf(
    Object.values(TRIGGER_AND_MENU_ALIGNMENT)
  ),
  dataTestId: PropTypes.string,
};

MenuAction.defaultProps = {
  label: '',
  trigger: null,
  expandDirection: MENU_EXPAND_DIRECTIONS.bottom,
  triggerMenuAlignment: TRIGGER_AND_MENU_ALIGNMENT.OVERLAP,
  dataTestId: null,
};

export default MenuAction;
