import { getCurrentDate } from '@saviynt/common';
import { addOrSubtractMinutesFromDateTime } from '@saviynt/common/src/utilities/date';
import { Box } from '@saviynt/design-system';
import classnames from 'classnames';
import { addHours } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Session } from '../../../models/PamModels';
import { STATUS } from '../constants';
import SchedulerBlock from '../SchedulerBlock/SchedulerBlock';

const freshDay = getCurrentDate();

freshDay.setHours(0, 0, 0, 0);

function SchedulerDay({
  sessions,
  availableStartDate,
  availableHours,
  timeFormat,
  isModalView,
  dataTestId,
  className,
  filters,
}) {
  const classes = classnames('SchedulerDay', className);
  const startDateTime = new Date(availableStartDate);

  const [filteredSchedule, setFilteredSchedule] = useState([]);
  const getStart = () => startDateTime.toString();

  const endDateTime = () => addHours(startDateTime, availableHours);

  const getEndTime = endDateTime().toString();

  const getSessionDetails = (session) => ({
    firstname: session.firstname,
    lastname: session.lastname,
    username: session.username,
    sessionStartDate: session.sessionStartDate,
    sessionEndDate: session.sessionEndDate,
    requestId: session.requestId,
    sessionStatus: session.sessionStatus,
  });

  const filteredDay = () => {
    const availableStartTime = startDateTime.getTime();
    const availableEndTime = endDateTime().getTime();

    const scheduleBlocksArray = [];

    // Day has no sessions
    if (sessions.length === 0) {
      scheduleBlocksArray.push(
        <SchedulerBlock
          startTime={getStart()}
          endTime={getEndTime}
          timeFormat={timeFormat}
          isModalView={isModalView}
          status={STATUS.AVAILABLE}
        />
      );
    }

    // Day has sessions
    else {
      const sortFunction = (a, b) =>
        new Date(a.sessionStartDate) - new Date(b.sessionStartDate);

      // Ensure that sessions are sorted by sessionStartDate in chronological order
      sessions.sort(sortFunction);

      // check first session and set first block
      const firstSession = sessions[0];
      const firstSessionStartDate = firstSession.sessionStartDate;
      const firstSessionEndDate = firstSession.sessionEndDate;
      const firstSessionStartTime = new Date(firstSessionStartDate).getTime();

      const firstSessionStartTimeAdjusted = addOrSubtractMinutesFromDateTime(
        firstSessionStartDate,
        'subtract',
        1
      ).toString();

      if (firstSessionStartTime !== availableStartTime) {
        scheduleBlocksArray.push(
          <SchedulerBlock
            startTime={getStart()}
            endTime={firstSessionStartTimeAdjusted}
            timeFormat={timeFormat}
            isModalView={isModalView}
            status={STATUS.AVAILABLE}
          />,
          <SchedulerBlock
            details={getSessionDetails(firstSession)}
            startTime={firstSessionStartDate}
            endTime={firstSessionEndDate}
            timeFormat={timeFormat}
            isModalView={isModalView}
            status={STATUS.UNAVAILABLE}
          />
        );
      } else {
        scheduleBlocksArray.push(
          <SchedulerBlock
            details={getSessionDetails(firstSession)}
            startTime={firstSessionStartDate}
            endTime={firstSessionEndDate}
            timeFormat={timeFormat}
            isModalView={isModalView}
            status={STATUS.UNAVAILABLE}
          />
        );
      }

      // check the rest of the sessions and set the rest of the blocks
      for (let i = 1; i < sessions.length; i += 1) {
        // current session
        const currentSession = sessions[i];
        const currentSessionStartDate = currentSession.sessionStartDate;
        const currentSessionEndDate = currentSession.sessionEndDate;

        const currentSessionStartTime = new Date(
          currentSessionStartDate
        ).getTime();

        // previous session
        const previousSessionEndTime = new Date(
          sessions[i - 1].sessionStartDate
        ).getTime();

        const previousSessionEndDate = sessions[i - 1].sessionEndDate;

        const previousSessionEndTimeAdjusted = addOrSubtractMinutesFromDateTime(
          previousSessionEndDate,
          'add',
          1
        ).toString();

        const currentSessionEndTimeAdjusted = addOrSubtractMinutesFromDateTime(
          currentSessionEndDate,
          'add',
          1
        ).toString();

        const currentSessionStartTimeAdjusted =
          addOrSubtractMinutesFromDateTime(
            currentSessionStartDate,
            'subtract',
            1
          ).toString();

        // sequence sessions and build scheduler blocks
        if (currentSessionStartTime !== previousSessionEndTime) {
          scheduleBlocksArray.push(
            <SchedulerBlock
              startTime={previousSessionEndTimeAdjusted}
              endTime={currentSessionStartTimeAdjusted}
              timeFormat={timeFormat}
              isModalView={isModalView}
              status={STATUS.AVAILABLE}
            />,
            <SchedulerBlock
              details={getSessionDetails(currentSession)}
              startTime={currentSessionStartDate}
              endTime={currentSessionEndDate}
              timeFormat={timeFormat}
              isModalView={isModalView}
              status={STATUS.UNAVAILABLE}
            />
          );
        } else {
          scheduleBlocksArray.push(
            <SchedulerBlock
              details={getSessionDetails(currentSession)}
              startTime={currentSessionStartDate}
              endTime={currentSessionEndDate}
              timeFormat={timeFormat}
              isModalView={isModalView}
              status={STATUS.UNAVAILABLE}
            />,
            <SchedulerBlock
              startTime={currentSessionEndTimeAdjusted}
              endTime={currentSessionStartTimeAdjusted}
              timeFormat={timeFormat}
              isModalView={isModalView}
              status={STATUS.AVAILABLE}
            />
          );
        }
      }

      // set last block as available if last session end time is before day end time
      const lastSession = sessions[sessions.length - 1];
      const lastSessionEndDate = lastSession.sessionEndDate;
      const lastSessionEndTime = new Date(lastSessionEndDate).getTime();

      const lastSessionEndTimeAdjusted = addOrSubtractMinutesFromDateTime(
        lastSessionEndDate,
        'add',
        1
      ).toString();

      if (lastSessionEndTime < availableEndTime) {
        scheduleBlocksArray.push(
          <SchedulerBlock
            startTime={lastSessionEndTimeAdjusted}
            endTime={getEndTime}
            timeFormat={timeFormat}
            isModalView={isModalView}
            status={STATUS.AVAILABLE}
          />
        );
      }
    }

    if (filters.length === 0) {
      return scheduleBlocksArray;
    }

    return scheduleBlocksArray.filter((scheduleBlock) =>
      filters.some((option) => scheduleBlock.props.status === option)
    );
  };

  useEffect(() => {
    setFilteredSchedule(filteredDay);
  }, [filters]);

  const renderDay = (schedule) =>
    schedule.map((block, index) => (
      <div key={`${block.props.status}-${index}`}>{block}</div>
    ));

  return (
    <Box dataTestId={dataTestId} className={classes}>
      {filters.length === 0
        ? renderDay(filteredDay())
        : renderDay(filteredSchedule)}
    </Box>
  );
}

SchedulerDay.propTypes = {
  sessions: PropTypes.arrayOf(Session).isRequired,
  availableStartDate: PropTypes.instanceOf(Date),
  availableHours: PropTypes.number,
  timeFormat: PropTypes.shape({
    roundToNearest: PropTypes.string,
    is24HourFormat: PropTypes.bool,
  }).isRequired,
  isModalView: PropTypes.bool,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.string),
};

SchedulerDay.defaultProps = {
  availableStartDate: freshDay,
  availableHours: 24,
  isModalView: false,
  dataTestId: null,
  className: null,
  filters: [],
};

export default SchedulerDay;
