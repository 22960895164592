import React, { useEffect, useState } from 'react';
import { LivePreview, LiveProvider } from 'react-live';
import { injectIntl } from 'react-intl';
import KpiSrcEdit from './KpiSrcEdit';
import { Link } from 'react-router-dom';
import './KpiWidget.scss';
const LiveKpi = ({ scope, sourceCode }) => (
  <LiveProvider code={sourceCode} scope={scope}>
    <LivePreview />
  </LiveProvider>
)
const KpiWidget = ({ kpiInfo, userName, editable, intl, deleteKpi, isNew = false }) => {

  const [kpiSrc, setKpiSrc] = useState(kpiInfo.source);
  const scope = { useEffect, useState, Link, userName, intl, cardDomId: kpiInfo.sourceLocation };
  const handleEnter = e => {
    if (e) {
      if (e.keyCode === 13) {
        if (
          e.target &&
          e.target.getElementsByTagName('a') &&
          e.target.getElementsByTagName('a').length > 0
        ) {
          const loc = e.target.getElementsByTagName('a')[0].href;
          e.preventDefault();
          window.location.href = loc;
        }
      }
    }
  };

  useEffect(() => {
    if (kpiInfo && kpiInfo.name) {
      if (document.getElementById(kpiInfo.name)) {
        if (
          document.getElementById(kpiInfo.name).getElementsByTagName('a') &&
          document.getElementById(kpiInfo.name).getElementsByTagName('a').length > 0
        ) {
          document.getElementById(kpiInfo.name).getElementsByTagName('a')[0].tabIndex = -1;
          document.getElementById(kpiInfo.name).addEventListener('keypress', handleEnter);
        }
      }
    }
  }, [kpiInfo]);

  if (!isNew) return (
    <div className={`requestAction-Tiles`} id={kpiInfo.name} tabIndex="0">
      <div className={editable ? 'mt-3' : 'm-auto'}>
        <LiveKpi scope={scope} sourceCode={kpiSrc} />
      </div>
      {editable && (
        <KpiSrcEdit
          kpiInfo={kpiInfo}
          setCodeStr={setKpiSrc}
          scopeObj={scope}
          isNew={false}
          deleteKpi={deleteKpi}
        />
      )}
    </div>
  );
  else return null;

};

export default injectIntl(KpiWidget);
