import { Title } from '@saviynt/common';
import { Button } from '@saviynt/design-system';

function LandingPage() {
  return (<div data-test-id="LandingPage">
    <Title>This is a Certs landing page <Button>Submit</Button></Title>
  </div>);
}

export default LandingPage;
