import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const TYPES = {
  text: 'text',
  password: 'password',
  email: 'email',
  number: 'number',
  date: 'date',
  time: 'time',
};

function InputCore({
  name,
  id,
  type,
  placeholder,
  className,
  autoComplete,
  onChange,
  value,
  isDisabled,
  isRequired,
  ariaDescribedBy,
  onMouseDown,
  onKeyDown,
  tabIndex,
}) {
  const inputCoreClass = classNames('InputCore', className);

  return (
    <input
      name={name}
      id={id}
      type={type}
      placeholder={placeholder}
      className={inputCoreClass}
      autoComplete={autoComplete}
      onChange={onChange}
      value={value}
      disabled={isDisabled}
      required={isRequired}
      aria-describedby={ariaDescribedBy}
      onMouseDown={onMouseDown}
      onKeyDown={onKeyDown}
      tabIndex={isDisabled ? -1 : tabIndex}
    />
  );
}

InputCore.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.oneOf(Object.values(TYPES)),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  ariaDescribedBy: PropTypes.string,
  onMouseDown: PropTypes.func,
  onKeyDown: PropTypes.func,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

InputCore.defaultProps = {
  name: null,
  id: null,
  type: TYPES.text,
  placeholder: null,
  className: null,
  autoComplete: 'off',
  onChange: null,
  value: '',
  isDisabled: false,
  isRequired: false,
  ariaDescribedBy: '',
  onMouseDown: null,
  onKeyDown: null,
  tabIndex: 0,
};

export default InputCore;
