import { useEffect } from 'react';

/**
 * Custom hook to handle outside clicks
 *
 * @param {React.RefObject} ref - React ref for the target element
 * @param {Function} onClick - Function to execute on outside click
 * @param {boolean} shouldAttach - Flag to attach/detach the listener
 */
const useOutsideClickEffect = (ref, onClick, shouldAttach) => {
  const handleOutsideClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClick();
    }
  };

  useEffect(() => {
    if (shouldAttach) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [shouldAttach]);
};

export default useOutsideClickEffect;
