/* eslint-disable */
import React, { useEffect } from 'react';
import { getDynamicUrl } from 'ui-common/src/utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';
import styles from './Logo.module.css';
import AppLogo from '../../features/MainLayout/Header/AppLogo/AppLogo';
import { fullScreenRoutes, nonFullscreenGriv2routes } from './../../Constants';
import { loadImage, useTheme } from 'ui-home/src/features/AdminHome/views/BrandingConfig/utils/helper';

const homeLink = '/ECMv6/request/requestHome';

const Logo = ({ isSideMenuAvailable, isWhite, isSideNav }) => {

  let isWhiteLogo = isWhite;
  
  const navLogo = useSelector((state) => state.adminHome?.brandingConfig?.navLogo) || '';
  const headerLogo = useSelector((state) => state.adminHome?.brandingConfig?.headerLogo) || '';

  let tabIndex = 0;
  const { IDWMS_API_URL, APPLICATION_LOGO } = useSelector((state) => state.user.domainInfo || {});

  const imageSrc = isSideNav ? loadImage(navLogo) : loadImage(headerLogo);

  const isMaintenancePages = /maintenance\/securityQuestions/gm.test(window.location.href);
  const checkRoute = (data) => data
    .map((r) => matchPath(window.location.pathname, { path: r, exact: true, strict: true }))
    .filter((x) => !!x).length > 0;

  if (checkRoute(fullScreenRoutes)) {
    tabIndex = -1;
  } else if (checkRoute(nonFullscreenGriv2routes)) {
    tabIndex = 1;
  }

  const LogoImage = () => <AppLogo src={imageSrc} isSideNav={isSideNav} isWhite={isWhiteLogo} isGen2Nav />;
  const logoClassName = isSideMenuAvailable ? (!isWhite ? styles.logo : styles.logoHiddenMenu) : styles.logoWithoutMenu;
  const attrs = {
    className: logoClassName,
    tabIndex,
  };

  if (isMaintenancePages) {
    return (
      <div {...attrs}>
        <LogoImage />
      </div>
    );
  }

  return (
    <a href={homeLink} {...attrs}>
      <LogoImage />
    </a>
  );
};

export default Logo;
