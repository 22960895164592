import './SpvApplication.css';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ModuleDetails } from '../../../../../../models/PamModels';
import { LABELS, TITLES } from '../../../../constants';
import SpvSection from '../../../SpvSection/SpvSection';

function SpvApplication({ data, dataTestId, className }) {
  const classes = classnames('SpvApplication', className);

  const summaryHasFields = data.connectionType || data.description;
  const systemDetailsHasFields = data.customproperty10;

  return (
    <div className={classes} data-testid={dataTestId}>
      {summaryHasFields && (
        <SpvSection
          title={TITLES.SUMMARY}
          fields={[
            {
              label: LABELS.PLATFORM,
              value: data.connectionType,
            },
            {
              label: LABELS.DESCRIPTION,
              value: data.description,
            },
          ]}
        />
      )}
      {systemDetailsHasFields && (
        <SpvSection
          title={TITLES.SYSTEM_DETAILS}
          fields={[{ label: LABELS.LOCATION, value: data.customproperty10 }]}
          className='SpvApplication-section'
        />
      )}
    </div>
  );
}

SpvApplication.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvApplication.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SpvApplication;
