import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import AlertBanner from './AlertBanner/AlertBanner';
import WelcomeSection from './WelcomeSection/WelcomeSection';
import RecentRequests from './RecentRequests/RecentRequests';
import GetHelp from './GetHelp/GetHelp';
import { changePageTitle } from 'ui-common/src/lib/utils';
import AppsRecommendation from './AppsRecommendation/AppsRecommendation';
import {
  fetchWelcomeTiles,
  fetchBannerRequests,
  fetchRecentRequests,
  fetchHelpRequests,
  fetchAppRecommendations,
  fetchRequestDetails,
} from '../../redux/requestHome-actions';
import './Homepage.scss';

const leftNavGen2 = window.localStorage.getItem('leftNavGen2');

const HomePageContainer = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    changePageTitle(props.intl.formatMessage({ id: 'requestHome.RequestHome' }));
    dispatch(fetchWelcomeTiles());
    dispatch(fetchRequestDetails());
    dispatch(fetchBannerRequests());
    dispatch(fetchRecentRequests());
    dispatch(fetchHelpRequests());
    // ToDo - Don't delete the below line as it be used later on
    // dispatch(fetchAppRecommendations());
  }, []);

  const { helpDetails, bannerDetails, appRecommendations } = useSelector(
    (state) => state.requestHome.initialLoad
  );
  const {
    helpDetails: { isHelpFetchError },
  } = useSelector((state) => state.requestHome.initialLoad);

  return (
    <div className="requestHome privilegedAccounts box-layout">
      <div
        className="homepage-container"
        style={{ paddingTop: `${leftNavGen2 === '0' ? '62px' : '19px'}` }}
      >
        {bannerDetails.isBannerEnabled && <AlertBanner />}
        <WelcomeSection />
        <div className="homepage-body">
          <RecentRequests />
          {/* ToDo -DONT DELETE this commented line as we need AppsRecommendation later*/}
          {/* {appRecommendations.length > 0 && <AppsRecommendation />} */}
          {(helpDetails.isHelpEnabled || helpDetails.isDocEnabled || isHelpFetchError) && (
            <GetHelp />
          )}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(HomePageContainer);
