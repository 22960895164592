import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Icon/Icon';

import './CheckboxGroup.css';

/**
 * ##### Every Checkbox requires a unique name prop.
 */
function CheckboxGroup({ checkboxes, groupLabel, dataTestId, error }) {
  const renderedCheckboxGroup = (
    <div className='CheckboxGroup-container'>
      <fieldset className='CheckboxGroup' data-testid={dataTestId}>
        <legend className='CheckboxGroup-legend'>{groupLabel}</legend>
        {checkboxes.map((checkbox) => (
          <React.Fragment key={checkbox.name}>{checkbox}</React.Fragment>
        ))}
      </fieldset>
      {error && (
        <div className='CheckboxGroup-error'>
          <Icon kind='AlertCritical' color='critical-600' size='xxxSmall' />
          {error}
        </div>
      )}
    </div>
  );

  return renderedCheckboxGroup;
}

CheckboxGroup.propTypes = {
  checkboxes: PropTypes.arrayOf(PropTypes.element).isRequired,
  groupLabel: PropTypes.string,
  error: PropTypes.string,
  dataTestId: PropTypes.string,
};

CheckboxGroup.defaultProps = {
  groupLabel: null,
  error: null,
  dataTestId: null,
};

export default CheckboxGroup;
