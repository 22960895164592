import './Extend.css';

import {
  formatLocalizedDateTime,
  formatLocalizedTimeShort,
  formatSchedulerLocalizedDateString,
  getCurrentDate,
  localize,
  Logger,
  SECONDS_PER_365_DAYS,
  secondsToHours,
} from '@saviynt/common';
import {
  Button,
  ButtonIcon,
  DateAndTimePicker,
  Icon,
  InlineMessage,
  InputField,
  Typography,
} from '@saviynt/design-system';
import {
  addDays,
  addSeconds,
  format,
  isBefore,
  isSameDay,
  parseISO,
} from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  getEndpointDetailsApi,
  getExtendSessionApi,
  getListRowApi,
  getSessionsAccountScheduleApi,
} from '../../utilities/api/getDataFromApi';
import { ACCOUNT_TYPE, EXTEND_MSGS } from './constants';

const TIMES = { interval: 15 };

function Extend({
  setIsExtendModalOpen,
  row,
  setAlertBannerData,
  setListRowData,
  userName,
}) {
  const intl = useIntl();
  const isJit = row.pamAccountType?.toUpperCase().includes('JIT');
  const userMaxTime =
    row?.accountConfig?.maxrequestabletimeinsecs > 0
      ? row?.accountConfig?.maxrequestabletimeinsecs
      : SECONDS_PER_365_DAYS;

  let jitMaxTime = 0;

  const isExclusive = row?.exclusiveAccess === true;
  const isNotExclusive =
    row?.exclusiveAccess === undefined || row?.exclusiveAccess === false;
  const pamType = row?.pamType?.toLowerCase();
  const pamPlatformType = row?.pamPlatformType?.toLowerCase();

  // Critical states
  const [isOutOfRange, setIsOutOfRange] = useState(false);
  const [isCritical, setIsCritical] = useState(false);
  // DatePicker states
  const [endTimeMin, setEndTimeMin] = useState({ minutes: 0, hours: 0 });
  const [endTimeMax, setEndTimeMax] = useState({ minutes: 45, hours: 23 });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // Details
  const [justificationInputValue, setJustificationInputValue] = useState('');
  // API related states
  const [maxEndDateCalculation, setMaxEndDateCalculation] = useState(null);
  const [sessionsAccountSchedule, setSessionsAccountSchedule] = useState(null);
  const [startDateOfNextBlockedSession, setStartDateOfNextBlockedSession] =
    useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isExtendTimeSame, setIsExtendTimeSame] = useState(true);
  const [endpointDetails, setEndpointDetails] = useState(null);

  const msgs = {
    extendSuccessMessage: {
      id: 'pam.extend.success',
      defaultMessage: `You've extended your access to ${row.accountName} until ${endDate}.`,
    },
    extendDurationAllowed: {
      id: 'pam.extend.durationAllowed',
      defaultMessage: `Adjust your end time. Your session can’t exceed a total of {maxDuration} hours.`,
    },
    extendDurationConflicts: {
      id: 'pam.extend.durationConflicts',
      defaultMessage: `Adjust your end time. Another session with this asset is scheduled to start at ${formatLocalizedTimeShort(
        startDateOfNextBlockedSession
      )} on ${formatSchedulerLocalizedDateString(
        startDateOfNextBlockedSession
      )}.`,
    },
  };

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      setIsExtendModalOpen(false);
    }
  };

  const getEarliestSessionAfterCurrent = (filteredCreds) => {
    if (filteredCreds) {
      // Sort by startDate from newest to oldest
      const sortedCreds = filteredCreds.sort(
        (a, b) => new Date(a.startDate) - new Date(b.startDate)
      );

      // Remove the first item (newest session)
      sortedCreds.shift();

      if (sortedCreds.length > 0) {
        setStartDateOfNextBlockedSession(parseISO(sortedCreds[0]?.startDate));
      }
    }
  };

  useEffect(() => {
    const validDayStart = getCurrentDate();
    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    const formattedStartDate = formatDate(validDayStart);
    const formattedEndDate = formatDate(addDays(validDayStart, 365));

    if (isJit) {
      getEndpointDetailsApi(row.endpointKey).then((response) => {
        jitMaxTime =
          response.bootstrapConfigModel?.[pamPlatformType]
            .maxCredlessSessionRequestTime;

        setEndpointDetails(response);
        setMaxEndDateCalculation(
          addSeconds(parseISO(row.sessionStartDate), jitMaxTime)
        );
      });
    }

    getSessionsAccountScheduleApi(
      isJit ? undefined : row.accountKey,
      row.endpointKey,
      formattedStartDate,
      formattedEndDate
    )
      .then((response) => {
        if (Array.isArray(response.result.sessions)) {
          const extractedDates = response.result.sessions
            .filter((session) => {
              if (!isExclusive && session.remoteAppMetadata?.name) {
                return (
                  session.remoteAppMetadata?.name ===
                  row.remoteAppMetadata?.name
                );
              }

              return true;
            })
            .filter((session) => {
              if (isJit) {
                return (
                  session.accountConfig?.['Saviynt-Status']?.justInTime ===
                    'true' && session.username === userName
                );
              }

              if (
                pamType === ACCOUNT_TYPE.CREDENTIAL ||
                (pamType === ACCOUNT_TYPE.CREDENTIALLESS && isExclusive)
              ) {
                return true; // Pass all sessions
              }

              return (
                pamType === ACCOUNT_TYPE.CREDENTIALLESS &&
                isNotExclusive &&
                session.username === userName
              );
            })
            .map((session) => ({
              startDate: session.sessionStartDate,
              endDate: session.sessionEndDate,
            }));

          setSessionsAccountSchedule(extractedDates);
        } else {
          Logger.error('Account sessions is not an array:', response);
        }
      })
      .catch((error) => {
        Logger.error('Error fetching sessions:', error);
      });

    if (!isJit) {
      setMaxEndDateCalculation(
        addSeconds(parseISO(row.sessionStartDate), userMaxTime)
      );
    }

    setStartDate(parseISO(row.sessionStartDate));
    setEndDate(parseISO(row.sessionEndDate));
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, []);

  useEffect(() => {
    if (sessionsAccountSchedule?.length > 0) {
      const filteredExpiredCredentials = sessionsAccountSchedule.filter(
        (session) => {
          const sessionEndDate = parseISO(session.endDate);

          return isBefore(startDate, sessionEndDate);
        }
      );

      getEarliestSessionAfterCurrent(filteredExpiredCredentials);
    }
  }, [sessionsAccountSchedule]);

  useEffect(() => {
    setEndTimeMin({ minutes: 0, hours: 0 });
    setEndTimeMax({ minutes: 45, hours: 23 });

    if (startDate && endDate) {
      if (isSameDay(startDate, endDate)) {
        setEndTimeMin({
          hours: parseISO(row.sessionEndDate).getHours(),
          minutes: parseISO(row.sessionEndDate).getMinutes(),
        });
      }

      if (isSameDay(maxEndDateCalculation, endDate)) {
        setEndTimeMax({
          hours: maxEndDateCalculation.getHours(),
          minutes: maxEndDateCalculation.getMinutes(),
        });
      }

      if (
        startDateOfNextBlockedSession &&
        isSameDay(startDateOfNextBlockedSession, endDate) &&
        maxEndDateCalculation !== startDateOfNextBlockedSession
      )
        setStartDateOfNextBlockedSession(startDateOfNextBlockedSession);
    }
  }, [
    endDate,
    startDate,
    startDateOfNextBlockedSession,
    maxEndDateCalculation,
  ]);

  useEffect(() => {
    const isNowInUserRange =
      maxEndDateCalculation && endDate > maxEndDateCalculation;
    const isBlockedBySession =
      startDateOfNextBlockedSession && endDate > startDateOfNextBlockedSession;

    setIsExtendTimeSame(endDate <= parseISO(row.sessionEndDate));

    const extendDurationAllowedMessage = (maxDurationValue) => {
      const maxDuration = secondsToHours(
        parseInt(
          isJit
            ? endpointDetails.bootstrapConfigModel?.[pamPlatformType]
              .maxCredlessSessionRequestTime
            : maxDurationValue,
          10
        )
      );

      return localize(intl, msgs.extendDurationAllowed, { maxDuration });
    };

    if (isNowInUserRange || isBlockedBySession) {
      setIsOutOfRange(true);
      setErrorMessage(
        isNowInUserRange
          ? extendDurationAllowedMessage(isJit ? jitMaxTime : userMaxTime)
          : localize(intl, msgs.extendDurationConflicts)
      );
    } else {
      setIsOutOfRange(false);
    }
  }, [endDate]);

  const filterTimesPassedNextSession = (time) => {
    const maxDate = new Date(startDateOfNextBlockedSession);
    const selectedDate = new Date(time);

    return selectedDate.getTime() < maxDate.getTime();
  };

  // TODO: update this to disable id the endDate is the same as the row.sessionEndDate
  const isExtendDisabled = Boolean(
    !endDate || isCritical || isOutOfRange || isExtendTimeSame
  );

  // TODO: Create this component from Modal Component

  return (
    <div className='Extend-overlay'>
      <div className='Extend-content'>
        <div className='Extend-header'>
          <div className='Extend-header-label'>
            <Typography kind='h2' htmlFor='ExtendSession'>
              Extend Session
            </Typography>
          </div>
          <ButtonIcon
            id='Extend-closeIcon'
            size='medium'
            kind='ghost'
            icon={<Icon kind='close' color='neutral-100' size='smallMedium' />}
            onClick={() => setIsExtendModalOpen(false)}
          />
        </div>
        <div className='Extend-body'>
          {/* Add your content here */}
          <div className='Extend-timePicker'>
            <div className='Extend-startTime'>
              <Typography className='Extend-startTime-label' kind='h4'>
                Start Time
              </Typography>

              <Typography kind='body2' className='Extend-startTime-value'>
                {formatLocalizedDateTime(row.sessionStartDate, true, false)}
              </Typography>
            </div>
            <div className='Extend-endDateContainer'>
              {maxEndDateCalculation ? (
                <div className='Extend-endDate'>
                  <DateAndTimePicker
                    kind='dateEnd'
                    label='End Date'
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    maxDate={maxEndDateCalculation}
                    minDate={parseISO(row.sessionEndDate)}
                    isCritical={isOutOfRange}
                  />
                  <DateAndTimePicker
                    kind='timeEnd'
                    label='End Time'
                    endDate={endDate}
                    setEndDate={setEndDate}
                    timeIntervals={TIMES.interval}
                    dateFormat='h:mm aa'
                    timeHeaderText=''
                    timeMin={endTimeMin}
                    timeMax={endTimeMax}
                    filterTime={startDateOfNextBlockedSession
                      ? filterTimesPassedNextSession
                      : null}
                    isCritical={isOutOfRange}
                  />
                </div>
              ) : (
                <div>Loading</div>
              )}
              <InlineMessage
                text={errorMessage}
                colorTheme='critical'
                size='small'
                leftIcon={<Icon kind='AlertCritical' color='critical-700' />}
                isVisible={isOutOfRange}
              />
            </div>

            <InputField
              name='Justification Details'
              placeholder='Provide a reason for your request'
              label={(<Typography kind='h4' htmlFor='Justification'>
                  Justification
              </Typography>)}
              value={justificationInputValue}
              setValue={setJustificationInputValue}
              kind='multiline'
              isCritical={isCritical}
              setIsCritical={setIsCritical}
              isRequired
              minRequiredChars={3}
              CriticalHelperText={<InlineMessage kind='missingRequiredField' size='small' />}
            />
          </div>
        </div>
        <div className='Extend-footer'>
          <div className='Extend-footer-buttons'>
            <Button
              type='button'
              kind='outlined'
              size='medium'
              onClick={() => setIsExtendModalOpen(false)}>
              Cancel
            </Button>
            <Button
              type='button'
              kind='filled'
              size='medium'
              isDisabled={isExtendDisabled}
              onClick={() => {
                if (justificationInputValue.length < 3) {
                  setIsCritical(!isCritical);

                  return;
                }

                const isoString = endDate.toISOString();
                const formattedDate = isoString.substring(0, 10);
                const formattedTime = isoString.substring(11, 19);
                const formattedDateTime = `${formattedDate} ${formattedTime}`;

                getExtendSessionApi(
                  row.requestAccessKey,
                  formattedDateTime,
                  justificationInputValue
                )
                  .then((response) => {
                    if (response.success) {
                      setAlertBannerData((prevState) => [
                        ...prevState,
                        {
                          colorTheme: 'Success',
                          title: localize(
                            intl,
                            EXTEND_MSGS.alertBannerSuccessTitle
                          ),
                          description: localize(
                            intl,
                            msgs.extendSuccessMessage
                          ),
                        },
                      ]);
                    } else {
                      response.json().then((errorBody) => {
                        setAlertBannerData((prevState) => [
                          ...prevState,
                          {
                            colorTheme: 'Critical',
                            title: localize(
                              intl,
                              EXTEND_MSGS.alertBannerFailureTitle
                            ),
                            description: localize(
                              intl,
                              errorBody.error ||
                                EXTEND_MSGS.extendFailedDefaultMessage
                            ),
                          },
                        ]);
                      });
                    }

                    getListRowApi().then((res) => {
                      setListRowData(res);
                    });
                  })
                  .catch((error) => {
                    Logger.error('Error fetching sessions:', error);
                  });

                setIsExtendModalOpen(false);
              }}>
              Extend
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

Extend.propTypes = {
  row: PropTypes.shape({
    sessionStartDate: PropTypes.string.isRequired,
    sessionEndDate: PropTypes.string.isRequired,
    accountKey: PropTypes.number.isRequired,
    pamType: PropTypes.string.isRequired,
    endpointKey: PropTypes.number.isRequired,
    pamPlatformType: PropTypes.string.isRequired,
    pamAccountType: PropTypes.string.isRequired,
    exclusiveAccess: PropTypes.bool.isRequired,
  }).isRequired,
  setIsExtendModalOpen: PropTypes.func.isRequired,
  setAlertBannerData: PropTypes.func.isRequired,
  setListRowData: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
};

export default Extend;
