import HttpService from 'ui-home/src/misc/HttpService/HttpService';
import checkEnvironment from 'ui-common/src/lib/checkEnvironment';
import apiData from '../Layout/apiData';

export const SIDE_MENU_LOADING = 'SIDE_MENU_LOADING';
export const SIDE_MENU_LOADED = 'SIDE_MENU_LOADED';

const sideMenuLoading = () => ({ type: SIDE_MENU_LOADING, loading: true });

const InitializeSideMenu = () => (dispatch) => {
  dispatch(sideMenuLoading());
  const { ECM_URL } = checkEnvironment();
  const url = window.location.href;
  // TODO - Commented code to be removed after testing
  // const URL = GATEWAY_API_URL || url.substring(0, url.lastIndexOf('/ECM')).concat('/ECMv6');
  // const response = HttpService.get(`${GATEWAY_API_URL}/ECM/navigation/leftNav`);
  const response = HttpService.get(`/ECM/navigation/leftNav`);

  // TODO: remove the below dispatch when the API calls are working
  dispatch({
    type: SIDE_MENU_LOADED,
    // data: apiData,
    data: [],
  });

  response
    .then((res) => {
      dispatch({
        type: SIDE_MENU_LOADED,
        data: res.topLevel,
      });
    });
};

export {
  InitializeSideMenu,
};
