import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../../../Icon/Icon';
import InputField from '../../../Input/InputField/InputField';
import Typography from '../../../Typography/Typography';

import './MenuMultiSearchBar.css';

function MenuMultiSearchBar({
  text,
  setText,
  className,
  tabIndex,
  dataTestId,
}) {
  return (
    <div
      className={classnames('MenuMultiSearchBar', className)}
      data-testid={dataTestId}>
      <InputField
        name='basic'
        placeholder='Search'
        label={
          <Typography kind='label' htmlFor='Label'>
            Label
          </Typography>
        }
        kind='search'
        size='small'
        prefixIcon={
          <Icon kind='search' color='neutral-600' size='smallMedium' />
        }
        value={text}
        setValue={setText}
        tabIndex={tabIndex}
      />
    </div>
  );
}

MenuMultiSearchBar.propTypes = {
  text: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  className: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataTestId: PropTypes.string,
};
MenuMultiSearchBar.defaultProps = {
  className: null,
  tabIndex: 0,
  dataTestId: null,
};

export default MenuMultiSearchBar;
