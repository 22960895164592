import { format } from 'date-fns';

import {
  CLEAR_CREDENTIAL_REQUEST_FORM,
  CLEAR_CREDENTIAL_REQUEST_FORM_DATES,
  CREDENTIAL_REQUEST_ACCOUNT_ID,
  CREDENTIAL_REQUEST_BUSINESS_JUSTIFICATION,
  CREDENTIAL_REQUEST_DATABASE_PRIVILEGES,
  CREDENTIAL_REQUEST_PAMTYPE_CREDENTIAL,
  CREDENTIAL_REQUEST_PAMTYPE_JUSTINTIME,
  CREDENTIAL_REQUEST_REMOTE_APP_DETAILS,
  CREDENTIAL_REQUEST_REQUESTOR,
  CREDENTIAL_REQUEST_START_END_DATES,
  DURATION_HAS_UNSAVED_CHANGES,
} from '../actions/credentialRequestFormAction';

const initialState = {
  accesstype: 'PRIVILEGEDACCESS',
  requestor: '',
  requestedfor: '',
  // Localized in ModalPageFormSubmissionService.js
  comments:
    'This user is requesting privileged access. No additional comments are needed.',
  pamtype: null,
  isDurationBeingEdited: false,
  sessions: [
    {
      accountId: null,
      businessjustification: null,
      startdate: null,
      enddate: null,
      justintimedetails: { endpointname: null },
      remoteApp: {
        name: null,
        displayName: null,
        icon: null,
      },
      privileges: [],
    },
  ],
};

export const credentialRequestFormReducer = (state = initialState, action) => {
  switch (action.type) {
    // Combined requestor and requestedfor as they should always be the same value
    case CREDENTIAL_REQUEST_REQUESTOR: {
      return {
        ...state,
        requestor: action.payload,
        requestedfor: action.payload,
      };
    }

    case CREDENTIAL_REQUEST_PAMTYPE_CREDENTIAL: {
      return {
        ...state,
        pamtype: 'credentials',
      };
    }

    case CREDENTIAL_REQUEST_PAMTYPE_JUSTINTIME: {
      return {
        ...state,
        sessions: [
          {
            ...state.sessions[0],
            requesttype: 'justintime',
            justintimedetails: { endpointname: action.payload },
            // Unset the other type of form keys
            accountId: null,
            remoteApp: {
              name: null,
              displayName: null,
              icon: null,
            },
          },
        ],
      };
    }

    case CREDENTIAL_REQUEST_ACCOUNT_ID: {
      return {
        ...state,
        sessions: [
          {
            ...state.sessions[0],
            accountId: action.payload,
            // Unset the other type of form keys
            requesttype: null,
            privileges: [],
          },
        ],
      };
    }

    case CREDENTIAL_REQUEST_DATABASE_PRIVILEGES: {
      return {
        ...state,
        sessions: [
          {
            ...state.sessions[0],
            requesttype: 'justintime',
            privileges: action.payload,
            // Unset the other type of form keys
            accountId: null,
            remoteApp: {
              name: null,
              displayName: null,
              icon: null,
            },
          },
        ],
      };
    }

    case CREDENTIAL_REQUEST_REMOTE_APP_DETAILS: {
      return {
        ...state,
        sessions: [
          {
            ...state.sessions[0],
            remoteApp: {
              name: action.payload.key,
              displayName: action.payload.displayName,
              icon: action.payload.icon,
            },
          },
        ],
      };
    }

    case CREDENTIAL_REQUEST_BUSINESS_JUSTIFICATION: {
      return {
        ...state,
        sessions: [
          { ...state.sessions[0], businessjustification: action.payload },
        ],
      };
    }

    case CREDENTIAL_REQUEST_START_END_DATES: {
      const getUserTimezoneOffset = (date) => {
        const offsetInMinutes = date.getTimezoneOffset();

        return offsetInMinutes * 60 * 1000; // Convert minutes to milliseconds
      };

      const applyTimezoneOffset = (date, offset) =>
        new Date(date.getTime() + offset * 2);

      const timezoneOffset = getUserTimezoneOffset(new Date());

      // Parse the dates from the payload
      const startDate = new Date(action.payload.start);
      const endDate = new Date(action.payload.end);

      // Apply the timezone offset
      const localStartDate = applyTimezoneOffset(startDate, timezoneOffset);
      const localEndDate = applyTimezoneOffset(endDate, timezoneOffset);
      const dateFnsSring = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";

      const formatDate = (date) => format(date, dateFnsSring);

      return {
        ...state,
        sessions: [
          {
            ...state.sessions[0],
            startdate: formatDate(localStartDate),
            enddate: formatDate(localEndDate),
          },
        ],
      };
    }

    case CLEAR_CREDENTIAL_REQUEST_FORM_DATES: {
      return {
        ...state,
        sessions: [{ ...state.sessions[0], startdate: null, enddate: null }],
      };
    }

    case CLEAR_CREDENTIAL_REQUEST_FORM: {
      return initialState;
    }

    case DURATION_HAS_UNSAVED_CHANGES: {
      return {
        ...state,
        isDurationBeingEdited: action.payload,
      };
    }

    default:
      return state;
  }
};

export default credentialRequestFormReducer;
