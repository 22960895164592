/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-spacing */
import '../ModalPages.css';

import {
  getCurrentDate,
  isObjectEmpty,
  localize,
  removeClassesForHidingElements,
  useDetectScroll,
} from '@saviynt/common';
import {
  AccordionStepper,
  AlertBanner,
  Box,
  Button,
  FooterActionBar,
  Icon,
  InlineMessage,
  Link,
} from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import MpaAccountSelection from '../../../components/ModalPageForms/AccordionContentPanels/MpaAccountSelection/MpaAccountSelection';
import MpaJustificationDetails from '../../../components/ModalPageForms/AccordionContentPanels/MpaJustificationDetails/MpaJustificationDetails';
import MpaTimeAccessDuration from '../../../components/ModalPageForms/AccordionContentPanels/MpaTimeAccessDuration/MpaTimeAccessDuration';
import {
  ACCOUNT_JIT_SELECTION,
  ASSET_TYPES,
  initialCriticalState,
  MAX_RADIO_OPTIONS_TO_SHOW,
  MODAL_PAGE_KINDS,
  msgs,
} from '../../../components/ModalPageForms/constants';
import AppLauncher from '../../../components/ModalPageForms/CTSAccordionContentPanels/AppLauncher/AppLauncher';
import ServerDetails from '../../../components/ModalPageForms/CTSAccordionContentPanels/ServerDetails/ServerDetails';
import ModalFormHeader from '../../../components/ModalPageForms/ModalFormHeader';
import AccountListModalIsland from '../../../components/ModalPageForms/ModalIslands/AccountListModalIsland/AccountListModalIsland';
import DatabaseJitModalIsland from '../../../components/ModalPageForms/ModalIslands/DatabaseJitModalIsland/DatabaseJitModalIsland';
import RemoteAppsListModalIsland from '../../../components/ModalPageForms/ModalIslands/RemoteAppsListModalIsland/RemoteAppsListModalIsland';
import ViewScheduleLink from '../../../components/ModalPageForms/ModalIslands/ViewScheduleModalIsland/ViewScheduleLink/ViewScheduleLink';
import ViewScheduleModalIsland from '../../../components/ModalPageForms/ModalIslands/ViewScheduleModalIsland/ViewScheduleModalIsland';
import ModalPageAccountService from '../../../services/ModalPages/ModalPageAccountService';
import ModalPageDurationService from '../../../services/ModalPages/ModalPageDurationService';
import ModalPageFormSubmissionService from '../../../services/ModalPages/ModalPageFormSubmissionService';
import {
  handleClearCredentialForm,
  handleCredentialFormDatabasePrivleges,
  handleCredentialFormRemoteAppDetails,
  handleCredentialFormRequestor,
  handleIsCredentialsRequest,
} from '../../../store/actions';
import hideHostElementsWrapper from '../../../utilities/hideHostElements';

function ModalPageContent({ modalPageKind }) {
  // Accordion expanded booleans
  const [isExpanedAccountSelection, setIsExpanedAccountSelection] =
    useState(false);
  const [isExpandedAppLauncher, setIsExpandedAppLauncher] = useState(false);
  const [isExpandedServerDetails, setIsExpandedServerDetails] = useState(false);
  const [isExpandedDuration, setIsExpandedDuration] = useState(false);
  const [isExpandedJustificationDetails, setIsExpandedJustificationDetails] =
    useState(false);
  // AccountSelection
  const [accountListArray, setAccountListArray] = useState(null);
  const [accountSelection, setAccountSelection] = useState(null);
  const [isAccountListModal, setIsAccountListModal] = useState(false);
  const [isCTSAccountExclusive, setIsCTSAccountExclusive] = useState(false);
  // App Launcher
  const [remoteAppListArray, setRemoteAppListArray] = useState(null);
  const [appLauncherSelection, setAppLauncherSelection] = useState(null);
  const [isRemoteAppListModal, setIsRemoteAppListModal] = useState(false);
  // Server Details
  const [serverTargetValue, setServerTargetValue] = useState('');
  const [serverPortValue, setServerPortValue] = useState('');
  // TimeAccessDuration
  const [isScheduleViewOpen, setIsScheduleViewOpen] = useState(false);
  const [toggleChoice, setToggleChoice] = useState(1);
  const [isDurationCustom, setIsDurationCustom] = useState(false);
  const [shouldDurationContinue, setShouldDurationContinue] = useState(false);
  const [nowStartDateOfNextCredential, setNowStartDateOfNextCredential] =
    useState(null);
  const [futureStartDateOfNextCredential, setFutureStartDateOfNextCredential] =
    useState(null);
  const [nowTabStartDate, setNowTabStartDate] = useState(getCurrentDate());
  const [nowTabEndDate, setNowTabEndDate] = useState(null);
  const [futureTabStartDate, setFutureTabStartDate] = useState(null);
  const [futureTabEndDate, setFutureTabEndDate] = useState(null);
  const [durationAccordionValue, setDurationAccordionValue] = useState(null);
  const [isTimeBlocked, setIsTimeBlocked] = useState(false);
  // Justification Details
  const [hasDurationBeenFilled, setHasDurationBeenFilled] = useState(false);
  const [justificationInputValue, setJustificationInputValue] = useState('');
  const [isJustificationInputCritical, setIsJustificationInputCritical] =
    useState(false);
  const [ticketNumber, setTicketNumber] = useState('');
  // Upload
  const [uploadedDetailFiles, setUploadedDetailFiles] = useState(null);
  const [hasErrorInDetailFiles, setHasErrorInDetailFiles] = useState(null);
  // Critical State
  const [isFormCritical, setIsFormCritical] = useState(initialCriticalState);
  const [isFormFailureAlertVisible, setIsFormFailureAlertVisible] =
    useState(false);
  const [isDurationEditingAlertVisible, setIsDurationEditingAlertVisible] =
    useState(false);

  const [isDatabaseJitCreateAccount, setIsDatabaseJitCreateAccount] =
    useState(false);
  const [databaseJitAccountInfo, setDatabaseJitAccountInfo] = useState({
    entitlements: [],
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const accordionContentRef = useRef(null);
  const accountListModalContentRef = useRef(null);
  const remoteAppListModalContentRef = useRef(null);
  const viewScheduleModalContentRef = useRef(null);
  const databaseJitModalContentRef = useRef(null);

  const [cookies] = useCookies(['user_name']);
  const userName = cookies.user_name;
  const isCOCModal = modalPageKind === MODAL_PAGE_KINDS.COC;
  const isCTSModal = modalPageKind === MODAL_PAGE_KINDS.CTS;
  const isCOCorExclusive = isCOCModal || isCTSAccountExclusive;
  const isJITSelection =
    accountSelection?.value === ACCOUNT_JIT_SELECTION.value;
  const isNowTab = toggleChoice === 1;

  // Redux Selectors
  const endpointDetails = useSelector(
    (state) => state.privilegedAccess.SelectedListDetails
  );
  const remoteAppMetadata = useSelector(
    (state) => state.privilegedAccess.SelectedListAccountRemoteAppMetadata
  );
  const isExclusiveAccess = useSelector(
    (state) => state.privilegedAccess.SelectedListIsExclusiveAccess
  );
  const jitMaxTime = useSelector(
    (state) => state.privilegedAccess.SelectedListMaxCredlessSessionRequestTime
  );

  // Bools for flow conditions
  const isRemoteAppEnabled = remoteAppMetadata?.length > 0;
  const isAssetTypeDatabase =
    endpointDetails.assetType === ASSET_TYPES.DATABASE;
  const isAssetTypeServer = endpointDetails.assetType === ASSET_TYPES.SERVER;

  // Localization
  // Page
  const MODAL_HEADER_TEXT = localize(
    intl,
    msgs.pam[modalPageKind].page.modalHeader
  );
  const SUBMIT_BTN_TEXT = localize(intl, msgs.pam.modalPage.page.submitBtn);
  // Critical
  const SUBMIT_TITLE_TEXT = localize(
    intl,
    msgs.pam.modalPage.critical.submitErrorAlert
  );
  // Accordion Headings
  const ACCOUNT_HEADER_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.accountHeader
  );
  const ACCOUNT_SUBHEADER_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.accountSubheader
  );
  const APP_LAUNCHER_HEADER_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.appLauncherHeader
  );
  const APP_LAUNCHER_SUBHEADER_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.appLauncherSubheader
  );
  const SERVER_DETAILS_HEADER_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.serverDetailsHeader
  );
  const SERVER_DETAILS_SUBHEADER_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.serverDetailsSubheader
  );
  const DURATION_HEADER_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.durationHeader
  );
  const DURATION_SUBHEADER_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.durationSubheader
  );
  const JUST_DETAILS_HEADER_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.detailsHeader
  );
  const JUST_DETAILS_SUBHEADER_TEXT = localize(
    intl,
    msgs.pam.modalPage.accordionHeading.detailsSubheader
  );
  const CREDENTIAL_REQUEST_COMMENT_TEXT = localize(
    intl,
    msgs.pam.modalPage.credentialRequest.comment
  );
  const EDITING_DURATION_CRITICAL_BANNER_TEXT = localize(
    intl,
    msgs.pam.modalPage.critical.editingAlert
  );
  const ALERT_JUMP_TO_LINK_TEXT = localize(intl, msgs.pam.common.links.view);

  // Redirect on refresh due to lost SelectedListDetails store data
  useEffect(() => {
    if (isObjectEmpty(endpointDetails)) {
      // Can't use goBack in react.strict
      history.push('/request/privilegedAccess');
    }

    // Clear COC form on history.push() back
    dispatch(handleClearCredentialForm('Clear COC Request Form success'));

    // hide host toast messages, etc when flag is set
    hideHostElementsWrapper();

    // Update CTS page Exclusive Access, falseback t
    setIsCTSAccountExclusive(isExclusiveAccess);

    // Set form pamtype to 'credential' on COC pages
    if (isCOCModal)
      dispatch(handleIsCredentialsRequest('Updated COC form pamtype'));

    return () => {
      removeClassesForHidingElements();
    };
  }, []);

  useEffect(() => {
    // Update the redux requestor on mount
    if (userName)
      dispatch(handleCredentialFormRequestor(userName, 'Requestor success'));
  }, [userName]);

  // Account Service
  ModalPageAccountService(
    modalPageKind,
    accountSelection,
    accountListArray,
    endpointDetails?.endpointName,
    endpointDetails?.endpointKey,
    setAccountListArray,
    setIsExpanedAccountSelection,
    dispatch
  );

  // Duration Service
  const { sessionsToBeIterated } = ModalPageDurationService(
    modalPageKind,
    isNowTab,
    isRemoteAppEnabled,
    accountSelection,
    appLauncherSelection,
    isDurationCustom,
    shouldDurationContinue,
    nowTabStartDate,
    nowTabEndDate,
    setNowTabStartDate,
    setNowTabEndDate,
    futureTabStartDate,
    futureTabEndDate,
    setDurationAccordionValue,
    setNowStartDateOfNextCredential,
    setFutureStartDateOfNextCredential,
    userName,
    endpointDetails?.endpointKey,
    isCOCorExclusive,
    dispatch
  );

  // Form Sumbmit Service
  const {
    isCredentialRequestFormFilled,
    submitResponseErrorMessage,
    handleCredentialFormRequest,
  } = ModalPageFormSubmissionService(
    isCOCModal,
    isRemoteAppEnabled,
    uploadedDetailFiles,
    hasErrorInDetailFiles,
    justificationInputValue,
    isJustificationInputCritical,
    isNowTab,
    isTimeBlocked,
    hasDurationBeenFilled,
    ticketNumber,
    userName,
    setIsFormCritical,
    setNowTabStartDate,
    setIsFormFailureAlertVisible,
    setIsDurationEditingAlertVisible,
    setIsJustificationInputCritical,
    dispatch,
    CREDENTIAL_REQUEST_COMMENT_TEXT
  );

  // Page scroll references
  const { isScrolled: accordionContentRefIsScrolled } = useDetectScroll(
    null,
    accordionContentRef
  );
  const { isScrolled: accountModalSectionRefIsScrolled } = useDetectScroll(
    null,
    accountListModalContentRef
  );
  const { isScrolled: remoteAppModalSectionRefIsScrolled } = useDetectScroll(
    null,
    remoteAppListModalContentRef
  );
  const { isScrolled: viewScheduleModalSectionRefIsScrolled } = useDetectScroll(
    null,
    viewScheduleModalContentRef
  );
  const { isScrolled: databaseJitModalSectionRefIsScrolled } = useDetectScroll(
    null,
    databaseJitModalContentRef
  );

  // Close Account Accordion on selection
  useEffect(() => {
    const isNotRemoteAppEnabledOrIsCOC = isCOCModal || !isRemoteAppEnabled;
    const isRemoteAppEnabledAndIsCTS = isCTSModal && isRemoteAppEnabled;

    // Reset app launcher on app switch
    if (appLauncherSelection && isJITSelection) setAppLauncherSelection(null);

    if (accountSelection && isNotRemoteAppEnabledOrIsCOC) {
      setIsExpanedAccountSelection(false);
      setIsExpandedDuration(true);
    } else if (
      isRemoteAppEnabledAndIsCTS &&
      (isJITSelection || accountSelection)
    ) {
      // Open AppLauncher in JIT and other flows
      setIsExpanedAccountSelection(false);
      setIsExpandedAppLauncher(true);
    }
  }, [accountSelection]);

  // DB JIT
  useEffect(() => {
    dispatch(
      handleCredentialFormDatabasePrivleges(
        databaseJitAccountInfo.entitlements,
        'Form Database Privileges updated successfully'
      )
    );
  }, [databaseJitAccountInfo]);

  // Close AppLauncher Accordion on selection
  useEffect(() => {
    if (appLauncherSelection) {
      setIsExpandedAppLauncher(false);
      setIsExpandedDuration(true);
      dispatch(
        handleCredentialFormRemoteAppDetails(
          appLauncherSelection,
          'Remote App Details update, success'
        )
      );
    }
  }, [appLauncherSelection]);

  // Close Duration Accordion on selection
  useEffect(() => {
    if (isNowTab && durationAccordionValue && shouldDurationContinue) {
      setIsExpandedDuration(false);
      setIsExpandedJustificationDetails(true);
      setShouldDurationContinue(false);
    }

    if (!isNowTab && durationAccordionValue && shouldDurationContinue) {
      setIsExpandedDuration(false);
      setIsExpandedJustificationDetails(true);
      setShouldDurationContinue(false);
    }
  }, [durationAccordionValue, shouldDurationContinue]);

  useEffect(() => {
    // On critical state open Missing value accordions
    if (isFormCritical.accountId) setIsExpanedAccountSelection(true);
    if (isFormCritical.appSelection) setIsExpandedAppLauncher(true);
    if (isFormCritical.serverTarget) setIsExpandedServerDetails(true);
    if (isFormCritical.startdate || isFormCritical.enddate)
      setIsExpandedDuration(true);
    if (isJustificationInputCritical) setIsExpandedJustificationDetails(true);
  }, [isFormCritical, isJustificationInputCritical]);

  // Render conditions
  // Curently account is in every flow, on PAM MVP-1 completion consider removing this
  const renderAccountAccordion = true;

  const renderAppLauncherAccordion = () => {
    // Never show App Launcher in COC pages
    if (isCOCModal) return false;

    const hasRemoteOptionsAndAnyAccountSelection =
      isRemoteAppEnabled && (isJITSelection || accountSelection);

    if (hasRemoteOptionsAndAnyAccountSelection) return true;

    return false;
  };

  // TODO: This renderServerDetailsAccordion is for FS6
  const renderServerDetailsAccordion = Boolean(false);

  const renderDurationAccordion = () => {
    const isCOCWithAccount = isCOCModal && accountSelection;
    const isCTSWithAccount = isCTSModal && accountSelection;

    // ---COC---
    // Has Account selection
    if (isCOCWithAccount) return true;

    // ---CTS---
    // Has Account selection when Remote App is not Enabled
    if (isCTSWithAccount && !isRemoteAppEnabled) return true;
    // Has App Launcher selection with Remote App Enabled
    if (isCTSWithAccount && appLauncherSelection) return true;

    return false;
  };

  const shouldSubmitBeFilled = Boolean(
    isCredentialRequestFormFilled && !hasErrorInDetailFiles
  );

  return (
    <Box className='ModalPage-root'>
      <ModalFormHeader
        title={MODAL_HEADER_TEXT}
        endpointDetails={endpointDetails}
      />
      <Box className='ModalPage-alertBanner'>
        <AlertBanner
          colorTheme='Critical'
          title={SUBMIT_TITLE_TEXT}
          description={localize(intl, submitResponseErrorMessage)}
          isVisible={isFormFailureAlertVisible}
          onCancel={() => setIsFormFailureAlertVisible(false)}
        />
        <AlertBanner
          colorTheme='Critical'
          isColonVisible={false}
          shouldAutoDismiss
          description={EDITING_DURATION_CRITICAL_BANNER_TEXT}
          isVisible={isDurationEditingAlertVisible}
          onCancel={() => setIsDurationEditingAlertVisible(false)}
          LinkComponent={
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              url='#Section_further_down'
              text={ALERT_JUMP_TO_LINK_TEXT}
              suffixIconKind='ArrowDown'
              color='neutralInverse'
              target='_self'
            />
          }
        />
      </Box>
      <section className='ModalPage' ref={accordionContentRef}>
        <Box className='ModalPage-scrollingArea'>
          {/* Modals */}
          {isDatabaseJitCreateAccount && (
            <DatabaseJitModalIsland
              isOpen={isDatabaseJitCreateAccount}
              setIsDatabaseJitCreateAccount={setIsDatabaseJitCreateAccount}
              setDatabaseJitAccountInfo={setDatabaseJitAccountInfo}
              databaseJitAccountInfo={databaseJitAccountInfo}
              setAccountSelection={setAccountSelection}
              sectionRefIsScrolled={databaseJitModalSectionRefIsScrolled}
              databaseJitModalContentRef={databaseJitModalContentRef}
            />
          )}

          {accountListArray?.length > MAX_RADIO_OPTIONS_TO_SHOW ? (
            <AccountListModalIsland
              accountListArray={accountListArray}
              accountListModalContentRef={accountListModalContentRef}
              sectionRefIsScrolled={accountModalSectionRefIsScrolled}
              accountSelection={accountSelection}
              setAccountSelection={setAccountSelection}
              isAccountListModal={isAccountListModal}
              setIsAccountListModal={setIsAccountListModal}
            />
          ) : null}
          {remoteAppListArray?.length > MAX_RADIO_OPTIONS_TO_SHOW ? (
            <RemoteAppsListModalIsland
              remoteAppListArray={remoteAppListArray}
              remoteAppListModalContentRef={remoteAppListModalContentRef}
              sectionRefIsScrolled={remoteAppModalSectionRefIsScrolled}
              appLauncherSelection={appLauncherSelection}
              setAppLauncherSelection={setAppLauncherSelection}
              isRemoteAppListModal={isRemoteAppListModal}
              setIsRemoteAppListModal={setIsRemoteAppListModal}
            />
          ) : null}
          <ViewScheduleModalIsland
            viewScheduleModalContentRef={viewScheduleModalContentRef}
            sectionRefIsScrolled={viewScheduleModalSectionRefIsScrolled}
            isScheduleViewOpen={isScheduleViewOpen}
            setIsScheduleViewOpen={setIsScheduleViewOpen}
            selectedAccountName={accountSelection?.title}
            selectedAccountSessions={sessionsToBeIterated}
          />
          {/* Account Accordion */}
          {renderAccountAccordion ? (
            <AccordionStepper
              isExpanded={isExpanedAccountSelection}
              setIsExpanded={setIsExpanedAccountSelection}
              prefixIconKind='account'
              headerText={ACCOUNT_HEADER_TEXT}
              primarySupportingText={ACCOUNT_SUBHEADER_TEXT}
              accordionValue={accountSelection?.title}
              isCritical={isFormCritical.accountId}
              contentPanelClassName='ModalPage-ctsAccountSelectionPanel'
              CriticalMessage={
                isFormCritical.accountId && (
                  <InlineMessage kind='missingRequiredField' size='small' />
                )
              }>
              <MpaAccountSelection
                modalPageKind={modalPageKind}
                isAssetTypeDatabase={isAssetTypeDatabase}
                isAssetTypeServer={isAssetTypeServer}
                accountListArray={accountListArray}
                accountSelection={accountSelection}
                setAccountSelection={setAccountSelection}
                setIsAccountListModal={setIsAccountListModal}
                databaseJitAccountInfo={databaseJitAccountInfo}
                setDatabaseJitAccountInfo={setDatabaseJitAccountInfo}
                setIsDatabaseJitCreateAccount={setIsDatabaseJitCreateAccount}
              />
            </AccordionStepper>
          ) : null}
          {/* App Launcher Accordion */}
          {renderAppLauncherAccordion() ? (
            <AccordionStepper
              isExpanded={isExpandedAppLauncher}
              setIsExpanded={setIsExpandedAppLauncher}
              prefixIconKind='privilegedSession'
              headerText={APP_LAUNCHER_HEADER_TEXT}
              primarySupportingText={APP_LAUNCHER_SUBHEADER_TEXT}
              accordionValue={appLauncherSelection?.displayName}
              isCritical={isFormCritical.appSelection}
              CriticalMessage={
                isFormCritical.appSelection && (
                  <InlineMessage kind='missingRequiredField' size='small' />
                )
              }>
              <AppLauncher
                appLauncherSelection={appLauncherSelection}
                remoteAppMetadata={remoteAppMetadata}
                remoteAppListArray={remoteAppListArray}
                setRemoteAppListArray={setRemoteAppListArray}
                setAppLauncherSelection={setAppLauncherSelection}
                setIsRemoteAppListModal={setIsRemoteAppListModal}
                setIsExpandedAppLauncher={setIsExpandedAppLauncher}
              />
            </AccordionStepper>
          ) : null}
          {/* Server Accordion */}
          {renderServerDetailsAccordion ? (
            <AccordionStepper
              isExpanded={isExpandedServerDetails}
              setIsExpanded={setIsExpandedServerDetails}
              prefixIconKind='server'
              headerText={SERVER_DETAILS_HEADER_TEXT}
              primarySupportingText={SERVER_DETAILS_SUBHEADER_TEXT}
              accordionValue={serverTargetValue}
              isCritical={isFormCritical.serverTarget}
              CriticalMessage={
                isFormCritical.serverTarget && (
                  <InlineMessage kind='missingRequiredField' size='small' />
                )
              }>
              <ServerDetails
                appLauncherSelection={appLauncherSelection}
                setServerPortValue={setServerPortValue}
                setServerTargetValue={setServerTargetValue}
                setIsExpandedServerDetails={setIsExpandedServerDetails}
              />
            </AccordionStepper>
          ) : null}
          {/* Duration Accordion */}
          {renderDurationAccordion() && (
            <Box id='Section_further_down'>
              <AccordionStepper
                isExpanded={isExpandedDuration}
                setIsExpanded={setIsExpandedDuration}
                prefixIconKind='time'
                headerText={DURATION_HEADER_TEXT}
                primarySupportingText={DURATION_SUBHEADER_TEXT}
                accordionValue={durationAccordionValue}
                suffixLink={
                  <ViewScheduleLink
                    setIsScheduleViewOpen={setIsScheduleViewOpen}
                  />
                }
                isCritical={isFormCritical.startdate || isFormCritical.enddate}
                CriticalMessage={
                  (isFormCritical.startdate || isFormCritical.enddate) && (
                    <InlineMessage kind='missingRequiredField' size='small' />
                  )
                }>
                <MpaTimeAccessDuration
                  modalPageKind={modalPageKind}
                  userName={userName}
                  toggleChoice={toggleChoice}
                  setToggleChoice={setToggleChoice}
                  setShouldDurationContinue={setShouldDurationContinue}
                  jitMaxTime={jitMaxTime}
                  nowStartDateOfNextCredential={nowStartDateOfNextCredential}
                  futureStartDateOfNextCredential={
                    futureStartDateOfNextCredential
                  }
                  nowTabStartDate={nowTabStartDate}
                  setNowTabStartDate={setNowTabStartDate}
                  nowTabEndDate={nowTabEndDate}
                  setNowTabEndDate={setNowTabEndDate}
                  futureTabStartDate={futureTabStartDate}
                  setFutureTabStartDate={setFutureTabStartDate}
                  futureTabEndDate={futureTabEndDate}
                  setFutureTabEndDate={setFutureTabEndDate}
                  isTimeBlocked={isTimeBlocked}
                  setIsTimeBlocked={setIsTimeBlocked}
                  setIsScheduleViewOpen={setIsScheduleViewOpen}
                  accountSelection={accountSelection}
                  setDurationAccordionValue={setDurationAccordionValue}
                  isDurationCustom={isDurationCustom}
                  setIsDurationCustom={setIsDurationCustom}
                  hasDurationBeenFilled={hasDurationBeenFilled}
                  setHasDurationBeenFilled={setHasDurationBeenFilled}
                  selectedAccountSessions={sessionsToBeIterated}
                  isCOCorExclusive={isCOCorExclusive}
                  isCTSAccountExclusive={isCTSAccountExclusive}
                  isExpandedDuration={isExpandedDuration}
                  setIsExpandedDuration={setIsExpandedDuration}
                  isDurationBeingEditedCritical={
                    isFormCritical.isDurationBeingEdited
                  }
                />
              </AccordionStepper>
            </Box>
          )}
          {/* Details Accordion */}
          {hasDurationBeenFilled && (
            <AccordionStepper
              isExpanded={isExpandedJustificationDetails}
              setIsExpanded={setIsExpandedJustificationDetails}
              prefixIconKind='document'
              headerText={JUST_DETAILS_HEADER_TEXT}
              primarySupportingText={JUST_DETAILS_SUBHEADER_TEXT}
              accordionValue={justificationInputValue}>
              <MpaJustificationDetails
                justificationInputValue={justificationInputValue}
                setJustificationInputValue={setJustificationInputValue}
                isJustificationInputCritical={isJustificationInputCritical}
                setIsJustificationInputCritical={
                  setIsJustificationInputCritical
                }
                ticketNumber={ticketNumber}
                setTicketNumber={setTicketNumber}
                setUploadedDetailFiles={setUploadedDetailFiles}
                setHasErrorInDetailFiles={setHasErrorInDetailFiles}
              />
            </AccordionStepper>
          )}
        </Box>
      </section>
      <FooterActionBar
        className='ModalPage-footer'
        isScrolled={accordionContentRefIsScrolled}>
        <Button
          kind={shouldSubmitBeFilled ? 'filled' : 'outlined'}
          isDisabled={isTimeBlocked}
          onClick={() => handleCredentialFormRequest()}
          rightIcon={<Icon kind='arrowRight' />}>
          {SUBMIT_BTN_TEXT}
        </Button>
      </FooterActionBar>
    </Box>
  );
}

ModalPageContent.propTypes = { modalPageKind: PropTypes.string.isRequired };

ModalPageContent.defaultProps = {};

export default ModalPageContent;
