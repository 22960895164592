import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ButtonCore from '../../Button/ButtonCore/ButtonCore';

import './ToggleButton.css';

const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const KINDS = {
  start: 'start',
  centered: 'centered',
  end: 'end',
};

/**
 * #### WIP Info:
 *
 * ###### TODO:
 * - ToolTips
 */

function ToggleButton({
  isDarkMode,
  label,
  kind,
  size,
  icon,
  isDisabled,
  index,
  selection,
  setSelection,
  className,
  dataTestId,
}) {
  const toggleButtonClass = classnames(
    'ToggleButton',
    `ToggleButton--${isDarkMode}`,
    `ToggleButton--${size}`,
    `ToggleButton--${kind}`,
    isDisabled && `ToggleButton--isDisabled`,
    selection === index && `ToggleButton--isSelected`,
    selection === index && `ToggleButton--${kind}--isSelected`,
    className
  );

  const labelClass = classnames(
    'ToggleButton-label',
    `ToggleButton-label--${size}`,
    isDisabled && `ToggleButton-label--isDisabled`,
    selection === index && `ToggleButton-label--${size}--isSelected`
  );

  const toggleButtonOverlayClass = classnames(
    'ToggleButton-overlay',
    `ToggleButton-overlay--${kind}`,
    isDisabled && `ToggleButton-overlay--isDisabled`,
    selection === index && `ToggleButton-overlay--isSelected`
  );

  if (!kind) return <div>Error</div>;

  return (
    <ButtonCore
      className={toggleButtonClass}
      onClick={() => setSelection(index)}
      isDisabled={isDisabled}
      dataTestId={dataTestId}>
      {icon}
      {label && <p className={labelClass}>{label}</p>}
      <span
        data-testid='toggle-button-overlay'
        className={toggleButtonOverlayClass}
      />
    </ButtonCore>
  );
}

ToggleButton.propTypes = {
  isDarkMode: PropTypes.bool,
  label: PropTypes.string,
  kind: PropTypes.oneOf(Object.values(KINDS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  icon: PropTypes.node,
  isDisabled: PropTypes.bool,
  index: PropTypes.number,
  selection: PropTypes.number,
  setSelection: PropTypes.func,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

ToggleButton.defaultProps = {
  isDarkMode: false,
  label: null,
  kind: KINDS.centered,
  size: SIZES.medium,
  icon: null,
  isDisabled: false,
  index: null,
  selection: null,
  setSelection: () => {},
  className: null,
  dataTestId: null,
};

export default ToggleButton;
