import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ApplicationIcon from '../../../ApplicationIcon/ApplicationIcon';
import Checkbox from '../../../Checkbox/Checkbox';
import Icon from '../../../Icon/Icon';

import './MenuItemMulti.css';

function MenuItemMulti({
  option,
  selectedValue,
  onClick,
  className,
  tabIndex,
}) {
  const [checkboxChecked, setIsCheckboxChecked] = useState(
    option.isChecked || false
  );

  useEffect(() => {
    // only update if the option is not disabled
    if (!option.isDisabled) {
      setIsCheckboxChecked(Boolean(option.isChecked));
    }
  }, [option.isChecked, option.isDisabled]);

  const handleOptionClick = (event) => {
    // prevent the default behavior and event bubbling.
    event.preventDefault();
    event.stopPropagation();

    if (option.isDisabled) return;

    const newCheckedState = !checkboxChecked;

    setIsCheckboxChecked(newCheckedState);
    onClick(newCheckedState);
  };

  return (
    <li
      key={option.value}
      onClick={handleOptionClick}
      className={classnames(
        'MenuItemMulti',
        option.isDisabled && 'MenuItemMulti--isDisabled',
        className
      )}
      role='option'
      aria-selected={selectedValue && selectedValue.value === option.value}
      tabIndex={option.isDisabled ? -1 : tabIndex}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleOptionClick(e);
        }
      }}>
      <div className='MenuItemMulti-checkbox'>
        <Checkbox
          kind='base'
          tabIndex={option.isDisabled ? -1 : tabIndex}
          isChecked={checkboxChecked}
          isDisabled={option.isDisabled}
        />
      </div>
      {option.prefixIcon && !option.prefixIcon?.isApplicationIcon && (
        <Icon
          kind={option.prefixIcon.kind}
          size={option.prefixIcon.size}
          color={option.prefixIcon.color}
        />
      )}
      {option.prefixIcon && option.prefixIcon?.isApplicationIcon && (
        <ApplicationIcon
          kind='logo'
          logo={option.prefixIcon.kind}
          size={option.prefixIcon.size}
        />
      )}
      {option.label}
    </li>
  );
}

MenuItemMulti.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    prefixIcon: PropTypes.shape({
      isApplicationIcon: PropTypes.bool,
      kind: PropTypes.string,
      size: PropTypes.string,
      color: PropTypes.string,
    }),
  }).isRequired,
  selectedValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

MenuItemMulti.defaultProps = {
  selectedValue: null,
  className: null,
  tabIndex: -1,
};

export default MenuItemMulti;
