/* eslint-disable no-nested-ternary */
import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  button: {
    textTransform: 'none',
    padding: 0,
    height: 26,
    width: 64,
    marginRight: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  selected: {
    textTransform: 'none',
    padding: 0,
    height: 26,
    width: 64,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  largeButton: {
    textTransform: 'capitalize',
    marginTop: 10,
    padding: '11px 24px',
    zIndex: 30,
    fontSize: 14,
    // opacity: 1,
    // borderRadius: 4,
    // fontWeight: 500,
    float: 'right',
  },
});

const CommonButton = withStyles(styles)(props => (
  <Button
    {...props}
    variant={props.variant || 'outlined'}
    color="primary"
    className={
      props.size === 'large'
        ? props.classes.largeButton
        : props.isSelected
        ? props.classes.selected
        : props.classes.button
    }
  >
    {props.children}
  </Button>
));

export default CommonButton;
