/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
import './ViewScheduleModalIsland.css';

import { getLocalizedTimeZone, InterPoint, localize } from '@saviynt/common';
import {
  Box,
  ButtonSelect,
  MenuMulti,
  ModalIsland,
  Typography,
} from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Session } from '../../../../models/PamModels';
import { STATUS } from '../../../Scheduler/constants';
import Scheduler from '../../../Scheduler/Scheduler';
import { msgs } from '../../constants';

function ViewScheduleModalIsland({
  viewScheduleModalContentRef,
  sectionRefIsScrolled,
  isScheduleViewOpen,
  setIsScheduleViewOpen,
  selectedAccountName,
  selectedAccountSessions,
}) {
  const intl = useIntl();
  const statusOptions = [
    {
      label: 'Available',
      value: STATUS.AVAILABLE,
      isChecked: false,
      isDisabled: false,
    },
    {
      label: 'Unavailable',
      value: STATUS.UNAVAILABLE,
      isChecked: false,
      isDisabled: false,
    },
  ];
  const endpointDetails = useSelector(
    (state) => state.privilegedAccess.SelectedListDetails
  );

  const [options, setOptions] = useState(statusOptions);
  const [filters, setFilters] = useState([]);

  const handleChange = (selectedOptions) => {
    setOptions(
      options.map((option) => ({
        ...option,
        isChecked: selectedOptions.some(
          (selectedOption) => selectedOption.value === option.value
        ),
      }))
    );

    const filteredOptions = [];

    selectedOptions.forEach((option) => {
      filteredOptions.push(option.value);
    });
    setFilters(filteredOptions);
  };

  const enableFilters = (currentOptions) => {
    setOptions(
      currentOptions.map((currentOption) => ({
        ...currentOption,
        isDisabled:
          currentOption.value === STATUS.UNAVAILABLE &&
          selectedAccountSessions.length === 0,
      }))
    );
  };

  useEffect(() => {
    enableFilters(options);
  }, [selectedAccountSessions]);

  const getHeaderSubheadingContent = () => (
    <Box className='ViewScheduleModalIsland-headerSubtitle'>
      <Typography kind='body2' className='ViewScheduleModalIsland-detailName'>
        {localize(intl, msgs.pam.modalPage.scheduleModal.assetLabel)}
      </Typography>
      <Typography kind='body2' className='ViewScheduleModalIsland-detailInfo'>
        {endpointDetails.endpointName || endpointDetails.endointName}
      </Typography>
      <InterPoint color='neutralSubtle' font='body2' />
      <Typography kind='body2' className='ViewScheduleModalIsland-detailName'>
        {localize(intl, msgs.pam.modalPage.scheduleModal.accountLabel)}
      </Typography>
      <Typography kind='body2' className='ViewScheduleModalIsland-detailInfo'>
        {selectedAccountName}
      </Typography>
      <InterPoint color='neutralSubtle' font='body2' />
      <Typography kind='body2' className='ViewScheduleModalIsland-detailName'>
        {localize(intl, msgs.pam.modalPage.scheduleModal.timeZoneLabel)}
      </Typography>
      <Typography kind='body2' className='ViewScheduleModalIsland-detailInfo'>
        {getLocalizedTimeZone()}
      </Typography>
    </Box>
  );

  const modalIslandContent = () => (
    <Box className='ViewScheduleModalIsland-modalIslandContent'>
      <section className='ViewScheduleModalIsland-filters'>
        <MenuMulti
          onChange={handleChange}
          options={options}
          label='Status'
          trigger={<ButtonSelect kind='pill' label='Status' />}
        />
      </section>
      <Scheduler
        sessions={selectedAccountSessions}
        numOfDaysDisplayed={30}
        timeFormat={{ roundToNearest: 'minute', is24HourFormat: false }}
        filters={filters}
        isModalView
      />
    </Box>
  );

  return (
    <ModalIsland
      className='ViewScheduleModalIsland'
      onClose={() => {
        setIsScheduleViewOpen(false);
      }}
      isOpen={isScheduleViewOpen}
      title={localize(intl, msgs.pam.modalPage.scheduleModal.schedule)}
      subtitle={getHeaderSubheadingContent()}
      contentBody={modalIslandContent()}
      sectionRef={viewScheduleModalContentRef}
    />
  );
}

ViewScheduleModalIsland.propTypes = {
  viewScheduleModalContentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  sectionRefIsScrolled: PropTypes.bool,
  isScheduleViewOpen: PropTypes.bool.isRequired,
  setIsScheduleViewOpen: PropTypes.func.isRequired,
  selectedAccountName: PropTypes.string.isRequired,
  selectedAccountSessions: PropTypes.arrayOf(Session),
};

ViewScheduleModalIsland.defaultProps = {
  viewScheduleModalContentRef: null,
  sectionRefIsScrolled: null,
  selectedAccountSessions: [],
};

export default ViewScheduleModalIsland;
