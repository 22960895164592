import HttpService from 'ui-home/src/misc/HttpService/HttpService';
import checkEnvironment from 'ui-common/src/lib/checkEnvironment';

export const SAVE_PROFILE_IMAGE = 'SAVE_PROFILE_IMAGE';
export const CLEAR_PROFILE_IMAGE = 'CLEAR_PROFILE_IMAGE';
export const FETCH_PROFILE_MENU = 'FETCH_PROFILE_MENU';
export const FETCH_LANGUAGE_LIST = 'FETCH_LANGUAGE_LIST';
export const CLOSE_LANGUAGE_MODAL = 'CLOSE_LANGUAGE_MODAL';
export const FETCH_GET_HELP = 'FETCH_GET_HELP';

const InitializeProfileMenu = () => (dispatch) => {
  const { ECM_URL } = checkEnvironment();
  const response = HttpService.get('/ECM/navigation/userProfileMenu');

  response.then((res) => {
    dispatch({
      type: FETCH_PROFILE_MENU,
      data: res,
    });
  });
};

const SaveProfileImage = (url) => (dispatch) => {
  dispatch({
    type: SAVE_PROFILE_IMAGE,
    url,
  });
};

const ClearProfileImage = () => (dispatch) => {
  dispatch({
    type: CLEAR_PROFILE_IMAGE,
  });
};

const InitializeMuteEmail = (isMuted) => () => {
  const { ECM_URL } = checkEnvironment();

  const response = HttpService.post('/ECM/navigation/enableMuteEmail', {
    muteEmail: Number(isMuted),
  });

  response.then(() => {
    window.location.reload();
  });
};

const InitializeLanguageList = () => (dispatch) => {
  const { ECM_URL } = checkEnvironment();
  const response = HttpService.get('/ECM/navigation/configuredLanguages');

  response.then((res) => {
    dispatch({
      type: FETCH_LANGUAGE_LIST,
      data: res,
    });
  });
};

const InitializeUpdateLanguage = (selectedLanguage) => () => {
  const { ECM_URL } = checkEnvironment();

  HttpService.get(`/ECM/users/updateLanguage?lang=${selectedLanguage}`);
};

const CloseLanguageModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_LANGUAGE_MODAL,
  });
};

const InitializeGetHelp = () => (dispatch) => {
  const { ECM_URL } = checkEnvironment();
  const response = HttpService.get('/ECM/navigation/help');

  response.then((res) => {
    dispatch({
      type: FETCH_GET_HELP,
      data: res,
    });
  });
};

export {
  InitializeProfileMenu,
  InitializeMuteEmail,
  InitializeLanguageList,
  InitializeUpdateLanguage,
  CloseLanguageModal,
  SaveProfileImage,
  ClearProfileImage,
  InitializeGetHelp,
};
