import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './TabContent.css';

function TabContent({ value, tabValue, children, className }) {
  const classes = classNames('TabContent', className);

  return value === tabValue ? <div className={classes}>{children}</div> : null;
}

TabContent.propTypes = {
  value: PropTypes.string.isRequired,
  tabValue: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TabContent.defaultProps = { className: null };

export default TabContent;
