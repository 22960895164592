import './SidePanelFooter.css';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ModuleDetails } from '../../../models/PamModels';
import SpfAccounts from './SpfAccounts/SpfAccounts';
import SpfEndpoint from './SpfEndpoint/SpfEndpoint';
import SpfSession from './SpfSession/SpfSession';

const TYPE = {
  account: 'account',
  session: 'session',
  endpoint: 'endpoint',
};

function SidePanelFooter({
  data,
  credentialRequestOnClick,
  dataTestId,
  setIsCheckInModalOpen,
  setIconClickedForRow,
  setCheckInModalKind,
  setSidePanelOnClose,
  setIsExtendModalOpen,
  setSessionToLaunch,
  className,
}) {
  const classes = classnames('SidePanelFooter', className);

  const renderSidePanelFooter = (type) => {
    switch (type) {
      case TYPE.account:
        return (
          <SpfAccounts
            data={data}
            credentialRequestOnClick={credentialRequestOnClick}
          />
        );
      case TYPE.session:
        return (
          <SpfSession
            data={data}
            credentialRequestOnClick={credentialRequestOnClick}
            setIsCheckInModalOpen={setIsCheckInModalOpen}
            setIconClickedForRow={setIconClickedForRow}
            setCheckInModalKind={setCheckInModalKind}
            setSidePanelOnClose={setSidePanelOnClose}
            setIsExtendModalOpen={setIsExtendModalOpen}
            setSessionToLaunch={setSessionToLaunch}
          />
        );
      case TYPE.endpoint:
        return (
          <SpfEndpoint
            data={data}
            credentialRequestOnClick={credentialRequestOnClick}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes} data-testid={dataTestId}>
      {renderSidePanelFooter(data.type)}
    </div>
  );
}

SidePanelFooter.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  credentialRequestOnClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  setIsCheckInModalOpen: PropTypes.func,
  setIconClickedForRow: PropTypes.func,
  setCheckInModalKind: PropTypes.func,
  setSidePanelOnClose: PropTypes.func,
  setIsExtendModalOpen: PropTypes.func,
  setSessionToLaunch: PropTypes.func,
};

SidePanelFooter.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
  setIsCheckInModalOpen: null,
  setIconClickedForRow: null,
  setCheckInModalKind: null,
  setSidePanelOnClose: null,
  setIsExtendModalOpen: null,
  setSessionToLaunch: null,
};

export default SidePanelFooter;
