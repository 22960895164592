import React from 'react';
import PropTypes from 'prop-types';

import ButtonIcon from '../../ButtonIcon/ButtonIcon';
import Icon from '../../Icon/Icon';

import './ModalPageHeader.css';

function ModalPageHeader({
  title,
  subHeadingContent,
  closeHandler,
  className,
  dataTestId,
}) {
  return (
    <header
      className={`ModalPageHeader-background ${className}`}
      data-testid={dataTestId}>
      <div className='ModalPageHeader'>
        <div className='ModalPageHeader-textContainer'>
          <h1 className='ModalPageHeader-title'>{title}</h1>
          <div className='ModalPageHeader-subHeadingContent'>
            {subHeadingContent}
          </div>
        </div>
        <ButtonIcon
          size='large'
          kind='ghostInverse'
          className='ModalPageHeader-closeButton'
          icon={<Icon kind='Close' color='neutral-100' size='xSmall' />}
          onClick={() => {
            closeHandler();
          }}
        />
      </div>
    </header>
  );
}

ModalPageHeader.propTypes = {
  title: PropTypes.string,
  subHeadingContent: PropTypes.node,
  closeHandler: PropTypes.func,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

ModalPageHeader.defaultProps = {
  title: null,
  subHeadingContent: null,
  closeHandler: () => window.history.back(),
  className: null,
  dataTestId: null,
};

export default ModalPageHeader;
