import { checkEnvironment } from '@saviynt/common';

// currently metadataType is either:
// 'REQUEST_ATTACHMENT_MAX_FILE_SIZE' or 'REQUEST_ATTACHMENT_MAX_FILE_COUNT'
const getFileUploadMetadataApiUrl = (metadataType) => {
  const { GATEWAY_API_URL } = checkEnvironment();

  return `${GATEWAY_API_URL}/api/idw/config/${metadataType}`;
};

export default getFileUploadMetadataApiUrl;
