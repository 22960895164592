import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';

import './TooltipComponent.css';

const POSITIONS = [
  'topLeft',
  'top',
  'topRight',
  'rightTop',
  'right',
  'rightBottom',
  'bottomLeft',
  'bottom',
  'bottomRight',
  'leftTop',
  'left',
  'leftBottom',
];

function TooltipComponent({
  isDarkMode,
  children,
  text,
  position,
  delay,
  maxWidth,
  className,
}) {
  const [initialScrollY, setInitialScrollY] = useState(window.scrollY);
  const [isVisible, setIsVisible] = useState(false);
  const [flopPosition, setFlopPosition] = useState(position);
  const tooltipRef = useRef(null);
  const childRef = useRef(null);

  const tooltipClasses = classnames(
    'TooltipComponent',
    `TooltipComponent--${flopPosition}`,
    isVisible && 'TooltipComponent--isVisible',
    className
  );

  const tooltipArrowClasses = classnames(
    'TooltipComponent-arrow',
    `TooltipComponent-arrow--${flopPosition}`,
    flopPosition === 'rightTop' ||
      flopPosition === 'right' ||
      flopPosition === 'rightBottom' ||
      flopPosition === 'leftTop' ||
      flopPosition === 'left' ||
      flopPosition === 'leftBottom'
      ? 'TooltipComponent-arrow--onX'
      : 'TooltipComponent-arrow--onY'
  );

  const debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const handleScroll = debounce(() => {
    if (Math.abs(window.scrollY - initialScrollY) > 10) {
      setIsVisible(false);
    }
  }, 100);

  const checkBoundsAndUpdatePosition = () => {
    if (isVisible && tooltipRef.current && childRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      const outOfBounds = {
        top: tooltipRect.top < 0,
        left: tooltipRect.left < 0,
        right: tooltipRect.right > window.innerWidth,
        bottom: tooltipRect.bottom > window.innerHeight,
      };

      const shouldFlop = Object.values(outOfBounds).some(
        (isOutOfBounds) => isOutOfBounds
      );

      const getFlopPosition = () => {
        const oppositePositions = {
          topLeft: 'bottomLeft',
          top: 'bottom',
          topRight: 'bottomRight',
          rightTop: 'leftTop',
          right: 'left',
          rightBottom: 'leftBottom',
          bottomLeft: 'topLeft',
          bottom: 'top',
          bottomRight: 'topRight',
          leftTop: 'rightTop',
          left: 'right',
          leftBottom: 'rightBottom',
        };

        return oppositePositions[position] || position;
      };

      if (shouldFlop) {
        const newFlopPosition = getFlopPosition(flopPosition);

        setFlopPosition(newFlopPosition);
      }
    }
  };

  useEffect(() => {
    setInitialScrollY(window.scrollY);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    checkBoundsAndUpdatePosition();
    if (!isVisible) setFlopPosition(position);
  }, [isVisible]);

  const tooltipStyle = () => {
    const baseStyle = { maxWidth };

    if (isVisible) {
      baseStyle.transitionDelay = `${delay}ms`;
    }

    return baseStyle;
  };

  const rotateArrow = () => {
    switch (flopPosition) {
      case 'topLeft':
      case 'top':
      case 'topRight':
        return '270';
      case 'rightTop':
      case 'right':
      case 'rightBottom':
        return null;
      case 'bottomLeft':
      case 'bottom':
      case 'bottomRight':
        return '90';
      case 'leftTop':
      case 'left':
      case 'leftBottom':
        return '180';
      default:
        return null;
    }
  };

  return (
    <div
      className='TooltipComponent-container'
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      onFocus={() => setIsVisible(true)}
      onBlur={() => setIsVisible(false)}>
      <div className='TooltipComponent-children' ref={childRef}>
        {children}
      </div>
      <div style={tooltipStyle()} className={tooltipClasses} ref={tooltipRef}>
        <p className='TooltipComponent-text'>{text}</p>
        <div className={tooltipArrowClasses}>
          <Icon kind='TooltipArrow' rotate={rotateArrow()} size='xSmall' />
        </div>
      </div>
    </div>
  );
}

TooltipComponent.defaultProps = {
  isDarkMode: false,
  position: 'bottom',
  delay: null,
  maxWidth: '11rem',
  className: null,
};

TooltipComponent.propTypes = {
  isDarkMode: PropTypes.bool,
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  position: PropTypes.oneOf(POSITIONS),
  delay: PropTypes.number,
  maxWidth: PropTypes.string,
  className: PropTypes.string,
};

export default TooltipComponent;

TooltipComponent.displayName = 'Tooltip';
