import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import LandingPage from './LandingPage/LandingPage';

function Routes() {
  return (<div>
    <Suspense key="Certs" fallback={() => <div>Loading!!!</div>}>
      <Router basename="/certs">
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/hello" component={() => <div>Hello world!</div>} />
      </Router>
    </Suspense>
  </div>)
}

export default Routes;
