import {
  formatDateToReduxStore,
  getCurrentDate,
  Logger,
  trimEmptyUndefinedNull,
} from '@saviynt/common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MIN_LENGTH_FOR_JUSTIFICATION } from '../../components/ModalPageForms/constants';
import {
  handleClearCredentialForm,
  handleCredentialFormDates,
  handleCredentialFormJustification,
  requestFileUploadFailed,
  requestSubmittedId,
} from '../../store/actions/index';
import {
  getRequestFileUploadApi,
  postCredentialRequest,
} from '../../utilities/api/getDataFromApi';

const assembleFilesForApi = (filesArray) => {
  // TODO: move this to a central location if/when it's needed elsewhere
  const formData = new FormData();

  filesArray.forEach((fileObj) => {
    if (!fileObj.isError) {
      formData.append('file', fileObj.file);
    }
  });

  return formData;
};

const uploadAttachments = async (requestKey, filesData) => {
  try {
    const response = await getRequestFileUploadApi(requestKey, filesData);

    if (response.ok !== undefined && response.ok === false) {
      throw new Error('error');
    }

    return {
      status: 'success',
      data: response,
    };
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};

const ModalPageFormSubmissionService = (
  isCOCModal,
  isRemoteAppEnabled,
  uploadedDetailFiles,
  hasErrorInDetailFiles,
  justificationInputValue,
  isJustificationInputCritical,
  isNowTab,
  isTimeBlocked,
  hasDurationBeenFilled,
  ticketNumber,
  userName,
  setIsFormCritical,
  setNowTabStartDate,
  setIsFormFailureAlertVisible,
  setIsDurationEditingAlertVisible,
  setIsJustificationInputCritical,
  dispatch,
  payloadComment
) => {
  const [
    hasCredentialFormRequestValidationPassed,
    setHasCredentialFormRequestValidationPassed,
  ] = useState(false);
  const [isUserSubmitting, setIsUserSubmitting] = useState(false);
  const [submitResponseErrorMessage, setSubmitResponseErrorMessage] = useState(
    'Submit Response Error Message'
  );
  // Form submitcompletion
  const [isCredentialRequestSuccessful, setIsCredentialRequestSuccessful] =
    useState(false);
  const [credentialResponseData, setCredentialResponseData] = useState(null);
  const [isFilesUploadSuccessful, setIsFilesUploadSuccessful] = useState(false);
  const [shouldRetryUploadAPI, setShouldRetryUploadAPI] = useState(false);

  const history = useHistory();
  // Justification details validation variables
  const hasFilesForUpload = uploadedDetailFiles?.length > 0;
  const isJustificationRequiredLength =
    justificationInputValue?.trim().length >= MIN_LENGTH_FOR_JUSTIFICATION;
  const isJustificationIncludeAngleBrackets = /<|>/.test(
    justificationInputValue
  );

  // Redux store data
  const requestForm = useSelector((state) => state.credentialRequestForm);
  const {
    accountId: formAccountId,
    businessjustification: formJustification,
    startdate: formStartDate,
    enddate: formEndDate,
    requesttype: requestSessionRequestType,
    justintimedetails: { endpointname: jitEndpointName },
    remoteApp: {
      name: remoteAppName,
      displayName: remoteAppDisplayName,
      icon: remoteAppIcon,
    },
    privileges: formPrivileges,
  } = useSelector((state) => state.credentialRequestForm.sessions[0]);
  const isDurationBeingEdited = useSelector(
    (state) => state.credentialRequestForm.isDurationBeingEdited
  );

  const handleIsCritical = (key, bool) => {
    setIsFormCritical((prevState) => ({
      ...prevState,
      [key]: bool,
    }));
  };

  // Form validation
  const isStartDateValid = () => {
    const currentDate = getCurrentDate();
    const isFormStartDateBeforeCurrentDateFuture =
      new Date(formStartDate) > currentDate;

    // Skip the check for Now, because that is updated in the finalSubmitPayload handlers
    if (isNowTab) return true;

    return isFormStartDateBeforeCurrentDateFuture;
  };

  const isCommonKeysFilled = Boolean(
    requestForm.requestor &&
      requestForm.requestedfor &&
      formStartDate &&
      formEndDate &&
      isJustificationRequiredLength &&
      !isJustificationIncludeAngleBrackets &&
      !isJustificationInputCritical &&
      isStartDateValid()
  );

  // Basic JIT
  const isJITInfoFilled = Boolean(
    requestSessionRequestType && (jitEndpointName || formPrivileges?.length > 0)
  );

  // Basic Remote app enabled
  const isRemoteDetailsFilled = Boolean(
    (formAccountId || isJITInfoFilled) && remoteAppName && remoteAppDisplayName
  );

  const isCredentialRequestFormFilled = Boolean(
    (!isDurationBeingEdited && isCommonKeysFilled && formAccountId) ||
      (isCommonKeysFilled && isJITInfoFilled)
  );

  const handleCredentialFormRequest = () => {
    const isEpochDate = (date) =>
      new Date(date).getTime() <=
      new Date('1970-01-02T00:00:00.000Z').getTime();

    setHasCredentialFormRequestValidationPassed(false);

    // Account
    if (!formAccountId && !isJITInfoFilled) {
      handleIsCritical('accountId', true);
    }

    // App Launcher
    if (!isRemoteDetailsFilled) {
      handleIsCritical('appSelection', true);
    }

    // Duration
    const isCOCWithAccount = isCOCModal && formAccountId;
    const isNotRAEWithAccount = !isRemoteAppEnabled && formAccountId;
    const isRAEWithAccount = isRemoteAppEnabled && isRemoteDetailsFilled;
    const isDateSet =
      isCOCWithAccount ||
      isNotRAEWithAccount ||
      isRAEWithAccount ||
      isJITInfoFilled;

    if (isDateSet) {
      if (!formStartDate || isEpochDate(formStartDate) || !isStartDateValid())
        handleIsCritical('startdate', true);

      if (!formEndDate || isEpochDate(formEndDate))
        handleIsCritical('enddate', true);
    }

    if (isDurationBeingEdited) {
      handleIsCritical('isDurationBeingEdited', true);
      setIsDurationEditingAlertVisible(true);
    }

    // Justification Details
    if (
      (isJustificationIncludeAngleBrackets || !isJustificationRequiredLength) &&
      hasDurationBeenFilled
    ) {
      setIsJustificationInputCritical(true);
    }

    if (isCredentialRequestFormFilled && !hasErrorInDetailFiles) {
      dispatch(
        handleCredentialFormJustification(
          justificationInputValue,
          'Justification success'
        )
      );
      setHasCredentialFormRequestValidationPassed(true);
    }

    if (
      isCredentialRequestSuccessful &&
      hasCredentialFormRequestValidationPassed
    ) {
      setShouldRetryUploadAPI(true);
    }
  };

  useEffect(() => {
    const hasJustInReduxAndCorrectFiles =
      isCredentialRequestFormFilled &&
      formJustification &&
      !hasErrorInDetailFiles;

    if (hasJustInReduxAndCorrectFiles) {
      if (isNowTab) {
        setNowTabStartDate(getCurrentDate());
      }

      setIsUserSubmitting(true);
    }
  }, [
    requestForm.requestor,
    requestForm.requestedfor,
    formAccountId,
    formStartDate,
    formEndDate,
    formJustification,
  ]);

  useEffect(() => {
    if (
      isCredentialRequestFormFilled &&
      formJustification &&
      !isTimeBlocked &&
      isUserSubmitting
    ) {
      let finalSubmitPayload = null;
      let submitStartDate = null;
      let submitEndDate = null;

      // Calculate dates based on current tab
      if (isNowTab) {
        const differenceInSeconds =
          (new Date(formEndDate) - new Date(formStartDate)) / 1000;

        submitStartDate = new Date();
        submitStartDate.setSeconds(submitStartDate.getSeconds());
        submitEndDate = new Date(
          submitStartDate.getTime() + differenceInSeconds * 1000
        );
      } else {
        submitStartDate = new Date(formStartDate);
        submitStartDate.setSeconds(submitStartDate.getSeconds() + 1);
        submitEndDate = new Date(formEndDate);
      }

      // Prepare finalSubmitPayload object
      finalSubmitPayload = {
        // Always PRIVILEGEDACCESS
        accesstype: 'PRIVILEGEDACCESS',
        // Always username from cookies (requestor && requestedfor)
        requestor: requestForm.requestor,
        requestedfor: requestForm.requestedfor,
        comments: payloadComment,

        sessions: [
          {
            // Always required (businessjustification && startdate && enddate)
            businessjustification:
              ticketNumber?.length > 0
                ? `${formJustification.trim()}[ticket-${ticketNumber.trim()}]`
                : formJustification,
            startdate: submitStartDate.toISOString(),
            enddate: submitEndDate.toISOString(),

            // Used in all flows where an actual account is selected
            accountId: formAccountId,

            // JIT selected
            requesttype: requestSessionRequestType,
            justintimedetails: { endpointname: jitEndpointName },

            // Remote App enabled account selected
            ...(remoteAppDisplayName !== 'Terminal' && {
              remoteApp: {
                name: remoteAppName,
                displayName: remoteAppDisplayName,
                // TODO: remove this once backend has added icon key to the terminal remote details
                icon: remoteAppIcon,
              }
            }),
            privileges: formPrivileges,
          },
        ],
        // Only used in COC normal flow: 'credentials'
        pamtype: requestForm.pamtype,
      };

      // Use the function to clean your payload
      finalSubmitPayload = trimEmptyUndefinedNull(finalSubmitPayload);

      // Dispatch actions
      dispatch(
        handleCredentialFormDates(
          formatDateToReduxStore(submitStartDate),
          formatDateToReduxStore(submitEndDate),
          'Date Range success'
        )
      );

      // Post the request and handle the response
      postCredentialRequest(finalSubmitPayload)
        .then(async (res) => {
          if (res.status === 400) {
            res.json().then((errorBody) => {
              setIsFormFailureAlertVisible(true);
              setSubmitResponseErrorMessage(errorBody.message);
            });
          } else {
            dispatch(
              requestSubmittedId(res?.requests?.[0]?.requestid, 'success')
            );

            if (hasFilesForUpload) {
              setCredentialResponseData(res?.requests?.[0]);
            } else {
              setIsCredentialRequestSuccessful(true);
            }
          }
        })
        .catch((error) => {
          Logger.error('Error during Check Out Request:', error);
        });
    }

    setIsUserSubmitting(false);
  }, [formEndDate, isUserSubmitting]);

  useEffect(() => {
    // Handle file upload if necessary
    if (credentialResponseData && hasFilesForUpload && !hasErrorInDetailFiles) {
      const filesData = assembleFilesForApi(uploadedDetailFiles);

      (async () => {
        const uploadResponse = await uploadAttachments(
          credentialResponseData.requestkey,
          filesData
        );

        if (uploadResponse.status === 'error' || hasErrorInDetailFiles) {
          setIsCredentialRequestSuccessful(true);
        } else {
          setIsFilesUploadSuccessful(true);
          setIsCredentialRequestSuccessful(true);
        }
      })();
    }

    if (shouldRetryUploadAPI) {
      setShouldRetryUploadAPI(false);
    }
  }, [credentialResponseData, shouldRetryUploadAPI]);

  // Send user back and reset Form in redux store if request is success and upload success
  useEffect(() => {
    if (!isCredentialRequestSuccessful) return;

    if (hasFilesForUpload) {
      if (isFilesUploadSuccessful) {
        history.push('/request/privilegedAccess');
        dispatch(
          handleClearCredentialForm('Clear Credential Request Form success')
        );
      } else {
        // TODO: If there is an upload error, handleCredentialFormRequest should behave in a new way
        // to simple allow the user to submit upload api using the credentialResponseData.
        Logger.error(
          'Check form submission process, potential issue detected in upload process.'
        );
        dispatch(requestFileUploadFailed(userName));
        history.push('/request/privilegedAccess');
      }
    } else {
      history.push('/request/privilegedAccess');
      dispatch(
        handleClearCredentialForm('Clear Credential Request Form success')
      );
    }
  }, [isCredentialRequestSuccessful, isFilesUploadSuccessful]);

  // Reset Crits OnChange
  useEffect(() => {
    if (formAccountId || isJITInfoFilled) handleIsCritical('accountId', false);
  }, [formAccountId, isJITInfoFilled]);

  useEffect(() => {
    if (remoteAppName) handleIsCritical('appSelection', false);
    // Reset this so when it opens on account switch, it will not be critical
    if (!formAccountId) handleIsCritical('appSelection', false);
  }, [remoteAppName, formAccountId]);

  useEffect(() => {
    if (formStartDate) handleIsCritical('startdate', false);
  }, [formStartDate, isDurationBeingEdited]);

  useEffect(() => {
    if (formEndDate) handleIsCritical('enddate', false);
  }, [formEndDate, isDurationBeingEdited]);

  useEffect(() => {
    if (isDurationBeingEdited) handleIsCritical('isDurationBeingEdited', false);
  }, [isDurationBeingEdited]);

  useEffect(() => {
    if (!isJustificationInputCritical && !isJustificationIncludeAngleBrackets)
      setIsJustificationInputCritical(false);
  }, [justificationInputValue]);

  return {
    isCredentialRequestFormFilled,
    submitResponseErrorMessage,
    handleCredentialFormRequest,
  };
};

export default ModalPageFormSubmissionService;
