import { getCurrentDate, localize } from '@saviynt/common';
import { Box, Chip, Typography } from '@saviynt/design-system';
import {
  addSeconds,
  differenceInSeconds,
  isAfter,
  isValid,
  parseISO,
} from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { CredentialRequestAccountSelection } from '../../../../models/PamModels';
import { CHIP_OPTIONS, msgs } from '../../constants';

function DurationChips({
  isNowTab,
  maxRequestTimeAsNumber,
  nowTabStartDate,
  setNowTabStartDate,
  setNowTabEndDate,
  futureTabStartDate,
  setFutureTabEndDate,
  isDurationCustom,
  setIsDurationCustom,
  durationChipValue,
  setDurationChipValue,
  setShouldDurationContinue,
  nowStartDateOfNextCredential,
  futureStartDateOfNextCredential,
  setHasDurationBeenFilled,
  accountSelection,
  setCustomDurationInputValue,
}) {
  const [isChipDisabledNow, setIsChipDisabledNow] = useState(
    new Array(CHIP_OPTIONS.length).fill(false)
  );
  const intl = useIntl();

  const DURATION_CHIP_CUSTOM_TEXT = localize(
    intl,
    msgs.pam.modalPage.durationChips.custom
  );

  const parsedNowStartDateOfNextCredential = parseISO(
    nowStartDateOfNextCredential
  );
  const parsedFutureStartDateOfNextCredential = parseISO(
    futureStartDateOfNextCredential
  );

  useEffect(() => {
    const startDateObj = new Date(nowTabStartDate);

    if (isValid(startDateObj) && isValid(parsedNowStartDateOfNextCredential)) {
      const timeToFirstSession = differenceInSeconds(
        parsedNowStartDateOfNextCredential,
        startDateObj
      );

      const updatedIsChipDisabledNow = CHIP_OPTIONS.map(
        ({ value }) => value > timeToFirstSession
      );

      setIsChipDisabledNow(updatedIsChipDisabledNow);
    } else {
      setIsChipDisabledNow(CHIP_OPTIONS.map(() => false));
    }
  }, [accountSelection, nowStartDateOfNextCredential, nowTabStartDate]);

  const handleChipSelection = (chipDurInSecs) => {
    if (isNowTab && chipDurInSecs) {
      setIsDurationCustom(false);
      setDurationChipValue(chipDurInSecs);
      setNowTabStartDate(getCurrentDate());
      setNowTabEndDate(getCurrentDate(chipDurInSecs));
      setShouldDurationContinue(true);
      setHasDurationBeenFilled(true);
    } else if (!chipDurInSecs) {
      // Reset on custom NOW and FUTURE
      if (!isNowTab) {
        setFutureTabEndDate(null);
      }

      setIsDurationCustom(true);
      setDurationChipValue(0);
      setCustomDurationInputValue('');
    } else if (!isNowTab && chipDurInSecs) {
      setIsDurationCustom(false);
      setDurationChipValue(chipDurInSecs);
      if (futureTabStartDate)
        setFutureTabEndDate(addSeconds(futureTabStartDate, chipDurInSecs));
    }
  };

  return (
    <Box className='MpaTimeAccessDuration-durationChips-container'>
      <Typography kind='label' className='MpaTimeAccessDuration-durationLabel'>
        Duration
      </Typography>
      <Box className='MpaTimeAccessDuration-durationChips'>
        {CHIP_OPTIONS.map(({ value, label }, index) => {
          const isChipSelected = durationChipValue === value;
          const chipKind = isChipSelected ? 'boldFilled' : 'outlined';

          // User max time
          const isWithinUserMaxTime = value > maxRequestTimeAsNumber;
          // NOW
          const isDisabledNowTab = isNowTab && isChipDisabledNow[index];
          // FUTURE
          const isDisabledFutureTab =
            !isNowTab &&
            isAfter(
              addSeconds(futureTabStartDate, value),
              parsedFutureStartDateOfNextCredential
            );

          const isChipDisabled =
            isWithinUserMaxTime || isDisabledNowTab || isDisabledFutureTab;

          return (
            <Chip
              key={value}
              kind={chipKind}
              label={label}
              isSelected={isChipSelected}
              onClick={() => handleChipSelection(value)}
              color='brandSecondary'
              isDisabled={isChipDisabled}
            />
          );
        })}
        <Box className='MpaTimeAccessDuration-dividerLine' />
        <Chip
          kind={isDurationCustom ? 'boldFilled' : 'outlined'}
          label={DURATION_CHIP_CUSTOM_TEXT}
          isSelected={isDurationCustom}
          onClick={() => handleChipSelection(false)}
          color='brandSecondary'
        />
      </Box>
    </Box>
  );
}

DurationChips.propTypes = {
  isNowTab: PropTypes.bool.isRequired,
  maxRequestTimeAsNumber: PropTypes.number.isRequired,
  nowTabStartDate: PropTypes.instanceOf(Date),
  setNowTabStartDate: PropTypes.func.isRequired,
  setNowTabEndDate: PropTypes.func.isRequired,
  futureTabStartDate: PropTypes.instanceOf(Date),
  setFutureTabEndDate: PropTypes.func.isRequired,
  isDurationCustom: PropTypes.bool.isRequired,
  setIsDurationCustom: PropTypes.func.isRequired,
  durationChipValue: PropTypes.number,
  setDurationChipValue: PropTypes.func.isRequired,
  setShouldDurationContinue: PropTypes.func.isRequired,
  nowStartDateOfNextCredential: PropTypes.string,
  futureStartDateOfNextCredential: PropTypes.string,
  setHasDurationBeenFilled: PropTypes.func.isRequired,
  accountSelection: CredentialRequestAccountSelection,
  setCustomDurationInputValue: PropTypes.func.isRequired,
};

DurationChips.defaultProps = {
  nowTabStartDate: null,
  futureTabStartDate: null,
  durationChipValue: null,
  nowStartDateOfNextCredential: null,
  futureStartDateOfNextCredential: null,
  accountSelection: null,
};

export default DurationChips;
