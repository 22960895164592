import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Icon, Link } from '@saviynt/design-system';
import Button from '@material-ui/core/Button';
import EndpointsList from './EndpointsList';
import RequestTypeColumn from './RequestTypeColumn';
import get from 'lodash/get';
import { checkEnvironment } from 'ui-common/src/lib/utils';
import recentRequests from './requestData';
import './RecentRequests.scss';
import { TooltipComponent } from '@saviynt/design-system';

export const ArrowRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M12.098 5.13907C11.9352 4.97638 11.6714 4.97644 11.5087 5.13919C11.346 5.30194 11.3461 5.56576 11.5088 5.72845L16.0793 10.297H2.91667C2.68655 10.297 2.5 10.4836 2.5 10.7137C2.5 10.9438 2.68655 11.1304 2.91667 11.1304H16.0748L11.5088 15.6946C11.3461 15.8573 11.346 16.1211 11.5087 16.2839C11.6714 16.4466 11.9352 16.4467 12.098 16.284L17.3779 11.0061C17.456 10.928 17.5 10.822 17.5 10.7115C17.5 10.6009 17.456 10.4949 17.3779 10.4168L12.098 5.13907Z"
        fill="#3751FF"
      />
    </svg>
  );
};
export const RecentRequestsResponsive = () => {
  return (
    <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2163_11607)">
        <path
          d="M25.6479 32H11.1786C9.05512 32 7.32642 30.2621 7.32642 28.1274V22.4828C7.32642 22.0107 7.70755 21.6275 8.17716 21.6275C8.64676 21.6275 9.02789 22.0107 9.02789 22.4828V28.1274C9.02789 29.3248 9.99433 30.2895 11.1786 30.2895H25.6479C26.839 30.2895 27.7986 29.3179 27.7986 28.1274V3.87257C27.7986 2.68206 26.8322 1.7105 25.6479 1.7105H11.1786C9.98753 1.7105 9.02789 2.68206 9.02789 3.87257V9.27774C9.02789 9.74984 8.64676 10.133 8.17716 10.133C7.70755 10.133 7.32642 9.74984 7.32642 9.27774V3.87257C7.32642 1.73787 9.05512 0 11.1786 0H25.6479C27.7714 0 29.5001 1.73787 29.5001 3.87257V28.1206C29.5001 30.2553 27.7714 31.9932 25.6479 31.9932V32Z"
          fill="#387CFF"
        />
        <path
          d="M22.5375 15.4419L17.0043 9.87937C16.6709 9.54412 16.1332 9.54412 15.7997 9.87937C15.4662 10.2146 15.4662 10.7551 15.7997 11.0904L19.8832 15.1956H6.91117C1.92924 15.1888 0.540835 19.1571 0.5 21.2644V31.1443C0.5 31.6164 0.881131 31.9995 1.35074 31.9995C1.82035 31.9995 2.20148 31.6164 2.20148 31.1443V21.285C2.21509 20.8334 2.44649 16.9061 6.91117 16.9061H19.8764L15.7657 21.0387C15.4322 21.3739 15.4322 21.9144 15.7657 22.2497C15.929 22.4139 16.1468 22.5028 16.3646 22.5028C16.5824 22.5028 16.8002 22.4207 16.9635 22.2497L22.5239 16.6598C22.6805 16.5024 22.7758 16.2835 22.7758 16.0577C22.7758 15.8319 22.6873 15.613 22.5239 15.4556L22.5375 15.4419Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2163_11607">
          <rect width="29" height="32" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
const TableColumn = ({ columns }) => (
  <>
    {columns.map((column) => (
      <th>{column.column_name}</th>
    ))}
  </>
);

const TableRowCell = ({ item, column }) => {
  const value = get(item, column.key);
  if (column.Cell) {
    return <td className={`${column.key}`}>{column.Cell(item)}</td>;
  }
  return <td className={`${column.key}`}>{column.render ? column.render(column, item) : value}</td>;
};

const TableRow = ({ columns, data }) => (
  <>
    {data.map((item) => (
      <tr>
        {columns.map((column) => (
          <TableRowCell item={item} column={column} />
        ))}
      </tr>
    ))}
  </>
);

const RecentRequests = ({ intl }) => {
  const ECMmUrl = checkEnvironment().ECM_URL;

  const { recentRequests, recentRequestsFetchError } = useSelector(
    (state) => state.requestHome.initialLoad
  );
  const userInfo = useSelector((state) => state.user.userInfo) || {};

  const getStatusClass = (status) => {
    switch (true) {
      case status.replace(/\s+/g, '') === 'Open':
        return 'request-status inprogress';
      case status.replace(/\s+/g, '') === 'Completed':
        return 'request-status complete';
      case status.replace(/\s+/g, '') === 'Discontinued':
        return 'request-status canceled';
      case status.replace(/\s+/g, '') === 'Expired':
        return 'request-status expired';
      default:
        return 'status';
    }
  };

  const getStatusValue = (status) => {
    switch (true) {
      case status.replace(/\s+/g, '') === 'Open':
        return intl.formatMessage({
          id: 'NewRequestHome.RecentRequests.Status.InProgress.Label',
        });

      case status.replace(/\s+/g, '') === 'Completed':
        return intl.formatMessage({
          id: 'NewRequestHome.RecentRequests.Status.Complete.Label',
        });
      case status.replace(/\s+/g, '') === 'Discontinued':
        return intl.formatMessage({
          id: 'NewRequestHome.RecentRequests.Status.Canceled.Label',
        });
      case status.replace(/\s+/g, '') === 'Expired':
        return intl.formatMessage({
          id: 'NewRequestHome.RecentRequests.Status.Expired.Label',
        });
      default:
        return '';
    }
  };

  const getEndpointsName = (endpoints) => {
    if (endpoints.length > 0) {
      let apps = endpoints.split(',');
      if (apps.length > 1) {
        let lastElement = apps[apps.length - 1];
        lastElement = lastElement.split('&');

        apps.splice(-1, 1);
        apps = apps.concat(lastElement);
      }
      const enspointList = apps.map((item) => {
        item = item.replace(/\s+/g, '');
        return item;
      });

      return enspointList;
    }
    return [];
  };

  const columns = [
    {
      key: 'requesttype',
      column_name: intl.formatMessage({
        id: 'NewRequestHome.RecentRequests.Type.Column.Label',
      }),
      Cell: (row) => <RequestTypeColumn column={row} />,
    },
    // ToDo: The below commented codes to be deleted after testing.
    // {
    //   key: 'endpoints',
    //   column_name: intl.formatMessage({
    //     id: 'NewRequestHome.RecentRequests.Apps.Column.Label',
    //   }),
    //   Cell: (row) => <EndpointsList apps={getEndpointsName(row.endpoints)} />,
    // },
    // end here
    {
      key: 'endpoints',
      column_name: intl.formatMessage({
        id: 'NewRequestHome.RecentRequests.Apps.Column.Label',
      }),
      Cell: (row) => {
        return (row.endpoints && row.endpoints.length > 25 ? (<TooltipComponent
          delay={250}
          position="top"
          text={row.endpoints}
          className="requestedfor-tooltip-section"
        >
          <div className='requestedfor-section'>
            <EndpointsList apps={getEndpointsName(row.endpoints)} />
          </div>
        </TooltipComponent>) : <div><EndpointsList apps={getEndpointsName(row.endpoints)} /></div>)
      },
    },
    // ToDo: The below commented codes to be deleted after testing.
    // {
    //   key: 'requestedfor',
    //   column_name: intl.formatMessage({
    //     id: 'NewRequestHome.RecentRequests.RequestedFor.Column.Label',
    //   }),
    // },
    {
      key: 'requestedfor',
      column_name: intl.formatMessage({
        id: 'NewRequestHome.RecentRequests.RequestedFor.Column.Label',
      }),
      Cell: (row) => {
        return (row.requestedfor && row.requestedfor.length > 25 ? (<TooltipComponent
          delay={250}
          position="top"
          text={row.requestedfor}
          className="requestedfor-tooltip-section"
        >
          <div className='requestedfor-section'>{row.requestedfor}</div>
        </TooltipComponent>) : <div>{row.requestedfor}</div>)
      },
    },
    {
      key: 'assignee',
      column_name: intl.formatMessage({
        id: 'NewRequestHome.RecentRequests.AssignedTo.Column.Label',
      }),
    },
    {
      key: 'requestsubmittedon',
      column_name: intl.formatMessage({
        id: 'NewRequestHome.RecentRequests.Submtted.Column.Label',
      }),
    },
    // ToDo: To be removed these commented lines after Testing{
    //   key: 'duedate',
    //   column_name: intl.formatMessage({
    //     id: 'NewRequestHome.RecentRequests.Expires.Column.Label',
    //   }),
    // },
    {
      key: 'status',
      column_name: intl.formatMessage({
        id: 'NewRequestHome.RecentRequests.Status.Column.Label',
      }),
      Cell: (row) => (
        <span className={getStatusClass(row.status)}>{getStatusValue(row.status)}</span>
      ),
    },
  ];

  const navigateToRequestHistory = () => {
    window.location.replace(`${ECMmUrl}/jbpmworkflowmanagement/showmyhistoryrequests`);
  };

  const createRequestNavigation = () => {
    window.location.replace(
      `${ECMmUrl}/workflowmanagement/createrequestfirststep/${userInfo.id}?requestforself=1`
    );
  };

  return (
    <div className="recent-requests-section">
      <div className="title-section d-flex">
        <div className="recent-requests-logo">
          <RecentRequestsResponsive />
        </div>
        <h4 className="my-recent-title">
          {intl.formatMessage({ id: 'NewRequestHome.RecentRequests.Title' })}
        </h4>
        {recentRequests.length > 0 && (
          <div
            role="button"
            tabIndex={0}
            className="view-all"
            onClick={navigateToRequestHistory}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                navigateToRequestHistory();
              }
            }}
          >
            {intl.formatMessage({
              id: 'NewRequestHome.RecentRequests.ViewAll.Label',
            })}
          </div>
        )}
      </div>
      {recentRequests.length > 0 ? (
        <table className="recent-request-table">
          <tr>
            <TableColumn columns={columns} />
          </tr>
          <TableRow data={recentRequests} columns={columns} />
        </table>
      ) : (
        <div>
          {recentRequestsFetchError ? (
            <div className="recent-requests-fetch-error">
              <div className="recent-requests-alert-icon">
                <Icon
                  kind="AlertCriticalFilledSeparateColors"
                  className="ModalIsland-alertContent-icon"
                />
              </div>
              <div>
                <span className="recent-requests-error-text">Feature Unavailable. </span>
                <span className="recent-requests-error-description">
                  The server can't display the Recent Requests because of a network problem. You can
                  try again later.
                  <Link
                    className="link-button"
                    RouterComponent={RouterLink}
                    url="/request/requestHistory"
                    text="Go to Request History"
                    size="medium"
                    suffixIconKind="ChevronRight"
                  />
                </span>
              </div>
            </div>
          ) : (
            <div className="no-request-section">
              <div className="no-request-message">
                {intl.formatMessage({
                  id: 'NewRequestHome.RecentRequests.EmptyRequest.Label',
                })}
              </div>
              <Button
                className="start-request-btn"
                color="primary"
                disableElevation
                endIcon={<ArrowRight />}
                onClick={createRequestNavigation}
              >
                {intl.formatMessage({
                  id: 'NewRequestHome.RecentRequests.StartRequest.Label',
                })}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

RecentRequests.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(RecentRequests);
