import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ButtonCore from '../../../Button/ButtonCore/ButtonCore';
import Icon from '../../../Icon/Icon';

import './PaginationItem.css';

const KINDS = {
  number: 'number',
  ellipses: 'ellipses',
  start: 'start',
  end: 'end',
};

function PaginationItem({
  itemIndex,
  kind,
  tabIndex,
  isSelected,
  isDisabled,
  isVisible,
  onClick,
  className,
  dataTestId,
}) {
  const paginationItemClass = classnames(
    'PaginationItem',
    `PaginationItem-${kind}`,
    isDisabled && `PaginationItem--isDisabled`,
    isSelected && `PaginationItem--isSelected`,
    kind === 'ellipses' && `PaginationItem--isEllipses`,
    className
  );

  const renderIcon = () => {
    if (kind === 'start' || kind === 'end') {
      return (
        <Icon
          kind='ChevronDown'
          rotate={kind === 'start' ? '90' : '270'}
          size='smallMedium'
        />
      );
    }

    return null;
  };

  if (isVisible === false) return null;

  return (
    <ButtonCore
      tabIndex={kind === 'ellipses' ? -1 : tabIndex}
      onClick={onClick}
      isDisabled={isDisabled}
      className={paginationItemClass}
      dataTestId={dataTestId}>
      {kind === 'number' && itemIndex}
      {kind === 'ellipses' && '...'}
      {renderIcon()}
    </ButtonCore>
  );
}

PaginationItem.defaultProps = {
  itemIndex: null,
  tabIndex: 0,
  isDisabled: false,
  isSelected: false,
  isVisible: true,
  onClick: null,
  className: null,
  dataTestId: null,
};

PaginationItem.propTypes = {
  itemIndex: PropTypes.number,
  kind: PropTypes.oneOf(Object.values(KINDS)).isRequired,
  tabIndex: PropTypes.number,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default PaginationItem;
