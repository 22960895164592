import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './TableHead.css';

function TableHead({ className, dataTestId, children }) {
  return (
    <thead
      className={classnames('TableHeadDS', className)}
      data-testid={dataTestId}>
      {children}
    </thead>
  );
}

TableHead.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  children: PropTypes.node,
};
TableHead.defaultProps = {
  className: null,
  dataTestId: null,
  children: null,
};

export default TableHead;
