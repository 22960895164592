import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';

import './Badge.css';

const KINDS = {
  dot: 'dot',
  dotNumber: 'dotNumber',
  number: 'number',
};

const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const COLORS = {
  neutral: 'neutral',
  primary: 'primary',
  secondary: 'secondary',
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info',
};

function Badge({ kind, number, size, color, className, dataTestId }) {
  const getIconSize = () => {
    switch (size) {
      case SIZES.small:
        return 'xxxSmall';
      case SIZES.medium:
        return 'xSmall';
      case SIZES.large:
        return 'medium';
      default:
        return 'xx-xxxSmall';
    }
  };

  const badgeCircle = kind !== KINDS.number && (
    <Icon kind='BadgeCircle' size={getIconSize()} />
  );

  const classes = classnames(
    'Badge',
    `Badge--${kind}`,
    `Badge--${size}`,
    `Badge--${color}`,
    className
  );

  return (
    <div className={classes} data-testid={dataTestId}>
      {badgeCircle}
      {Boolean(number) && <div className='Badge-number'>{number}</div>}
    </div>
  );
}

Badge.propTypes = {
  kind: PropTypes.oneOf([KINDS.dot, KINDS.number, KINDS.dotNumber]),
  size: PropTypes.oneOf(Object.values(SIZES)),
  number: PropTypes.string,
  color: PropTypes.oneOf(Object.values(COLORS)),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

Badge.defaultProps = {
  kind: KINDS.dot,
  size: SIZES.small,
  color: COLORS.primary,
  number: null,
  className: null,
  dataTestId: null,
};

export default Badge;
