import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ButtonCore from '../Button/ButtonCore/ButtonCore';
import Typography from '../Typography/Typography';

import './RadioButton.css';

function RadioButton({
  id,
  name,
  title,
  description,
  value,
  radioSelection,
  onClick,
  isDisabled,
  isTruncated,
  frontIcon,
  className,
  dataTestId,
}) {
  const isRadioChecked = value === radioSelection;

  const radioButtonClasses = classnames(
    'RadioButton',
    isDisabled && 'RadioButton--isDisabled',
    frontIcon && 'RadioButton--hasFrontIcon',
    isRadioChecked && 'RadioButton--isChecked',
    className
  );

  const inputButtonClasses = classnames(
    'RadioButton-button',
    isDisabled && 'RadioButton-button--isDisabled'
  );

  const titleClasses = classnames(
    'RadioButton-title',
    isTruncated && 'RadioButton-title--isTruncated'
  );

  const descriptionClasses = classnames(
    'RadioButton-description',
    isTruncated && 'RadioButton-description--isTruncated'
  );

  return (
    <ButtonCore
      className={radioButtonClasses}
      isDisabled={isDisabled}
      onClick={(e) => {
        if (!isDisabled) onClick(e, value);
      }}
      dataTestId={`${dataTestId}-button`}
      aria-checked={isRadioChecked}>
      <div className='RadioButton-content'>
        {frontIcon}
        <div className='RadioButton-text'>
          <Typography kind='label' htmlFor={name} className={titleClasses}>
            {title}
          </Typography>
          <p className={descriptionClasses}>{description}</p>
        </div>
        <div className='RadioButton-input-contatiner'>
          <input
            type='radio'
            id={id}
            name={name}
            value={value}
            checked={isRadioChecked}
            onChange={() => {}}
            disabled={isDisabled}
            className='RadioButton-input'
            aria-hidden
            data-testid={`${dataTestId}-input`}
          />
          <span className={inputButtonClasses} />
        </div>
      </div>
      <span className='RadioButton-overlay' />
    </ButtonCore>
  );
}

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  radioSelection: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
  ]),
  onClick: PropTypes.func,
  frontIcon: PropTypes.element,
  isDisabled: PropTypes.bool,
  isTruncated: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

RadioButton.defaultProps = {
  description: null,
  radioSelection: null,
  onClick: () => {},
  frontIcon: null,
  isDisabled: false,
  isTruncated: false,
  className: null,
  dataTestId: null,
};

export default RadioButton;
