import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppLogo from 'ui-home/src/features/MainLayout/Header/AppLogo/AppLogo';
import classes from './BrandingConfig.module.scss';
import { loadImage } from './utils/helper';
import Icon from './Components/Icons/Icon';

const LogoStrip = ({ name, setShowSelectLogoModal, selectionCallback }) => {
  const logoToShow = useSelector((state) => state.adminHome?.brandingConfig?.logoToShow);
  const [imageSrc, setImageSrc] = useState('');

  const getImageSource = () => {
    const dynamicDataUrl = loadImage(logoToShow[name]);
    return `${dynamicDataUrl}`;
  };

  useEffect(() => {
    if (logoToShow[name]) {
      const imageSrcTemp = getImageSource();
      setImageSrc(imageSrcTemp);
    }
  }, [logoToShow[name]]);

  return (
    <div className={classes.logoStrip} key={name}>
      <div className={classes.logoStripContainer}>
        <div tabIndex="-1" className={classes.imagePreview} onClick={() => setShowSelectLogoModal()}>
          <AppLogo src={imageSrc} isSideNav />
        </div>
        <span className={classes.fileName}>
          {name}
        </span>
        <span className={classes.editIcon} onClick={() => setShowSelectLogoModal()}>
          <Icon kind="edit" size="full" />
        </span>
      </div>
    </div>
  );
};

export default LogoStrip;
