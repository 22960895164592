/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
import { formatLocalizedDateTime, localize } from '@saviynt/common';
import {
  ApplicationIcon,
  ButtonIcon,
  Chip,
  Icon,
  MenuAction,
  Tooltip,
} from '@saviynt/design-system';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import CountdownTimer from '../components/CountdownTimer/CountdownTimer';

const useCreateRowObject = (
  row,
  isModalOpen,
  setIsModalOpen,
  setIconClickedForRow,
  setCheckInModalKind,
  listRowData,
  setListRowData,
  setIsExtendModalOpen,
  onLaunchSession
) => {
  const history = useHistory();
  const intl = useIntl();
  const [{ userKey, user_name: userName }] = useCookies([
    'user_name',
    'userKey',
  ]);
  const JIT = 'JIT';

  const KINDS = {
    LABEL_VALUE: 'label-value',
    TEXT: 'text',
    SUBTLE_FILLED: 'subtleFilled',
    GHOST: 'ghost',
    SIMPLE: 'simple',
  };
  const SESSION_STATUSES = {
    SESSION_APPROVED: 1,
    SESSION_ACTIVE: 2,
  };
  const SESSION_STARTED =
    new Date(row?.sessionStartDate).getTime() <= new Date().getTime() &&
    row?.sessionStatus === SESSION_STATUSES.SESSION_ACTIVE;
  const ENDPOINT = 'endpoint';
  const SESSION = 'session';
  const ACCOUNT = 'account';
  const CREDENTIALLESS = 'CREDENTIALLESS';
  const END_TYPE_CHECKIN = 'checkIn';
  const END_TYPE_END_SESSION = 'end';
  const END_TYPE_CANCEL_SESSION = 'cancelSession';
  const END_TYPE_CANCEL_REQUEST = 'cancelRequest';
  const EXTEND_SESSION = 'extendSession';
  const IS_CREDENTIALLESS = row?.pamType?.toUpperCase() === CREDENTIALLESS;

  const msgs = {
    pamCheckInModal: {
      extendLabel: {
        id: 'pam.extend.title',
        defaultMessage: 'Extend',
      },
      connectButtonTooltip: {
        id: 'pam.connect.tooltip',
        defaultMessage: 'Launch Session',
      },
      endButtonCredentiallessTooltip: {
        id: 'pam.end.tooltip',
        defaultMessage: 'End Session',
      },
      endButtonCredentialedTooltip: {
        id: 'pam.end.tooltip',
        defaultMessage: 'Check In Credential',
      },
      cancelRequestLabel: {
        id: 'pam.cancelRequest.title',
        defaultMessage: 'Cancel Request',
      },
      cancelSessionLabel: {
        id: 'pam.cancelSession.title',
        defaultMessage: 'Cancel Session',
      },
    },
  };

  const createIcon = (type, kind, color, size) => {
    if (type === ACCOUNT) {
      return <Icon kind='Account' color='neutral-1000' size='small' />;
    }

    return <Icon kind={kind} color={color} size={size} />;
  };

  const createButtonIcon = (actionMap, kind, icon, size, isDisabled) => (
    <ButtonIcon
      size={size}
      kind={kind}
      icon={icon}
      isDisabled={isDisabled}
      onClick={(e) => {
        e.stopPropagation();
        actionMap();
      }}
    />
  );

  const getObjectForCategory = (account, platform, type) => {
    const icon = createIcon(type);

    const obj = {
      account,
      platform,
      icon,
    };

    return obj;
  };

  const getObjectForTitle = (endpointName, accountName, pamAccountType) => {
    const obj = [
      {
        kind: KINDS.TEXT,
        text: endpointName,
      },
      {
        kind: KINDS.LABEL_VALUE,
        label: pamAccountType?.toUpperCase().includes(JIT)
          ? 'Account (JIT)'
          : 'Account',
        value: accountName,
      },
    ];

    return obj;
  };

  const getObjectForDescription = (data) => {
    let obj = [];

    if (data.type === ACCOUNT) {
      obj = [
        {
          kind: KINDS.TEXT,
          text: data.accountDescription,
        },
      ];
    } else if (data.type === ENDPOINT || data.type === SESSION) {
      obj = [];

      switch (data?.assetType?.toLowerCase()) {
        case 'server':
        case 'application':
        case 'database':
          obj.push({
            kind: 'icon-text',
            icon: createIcon(data.type, data.assetType, null, 'small'),
            text: data.assetType,
          });
          break;
        default:
          break;
      }

      switch (data?.platformType?.toLowerCase()) {
        case 'aws':
        case 'gcp':
        case 'azure':
        case 'okta':
        case 'ad':
        case 'sap':
          obj.push({
            kind: 'avatar-text',
            avatar: (
              <ApplicationIcon
                size='xxSmall'
                kind='logo'
                logo={data.platformType}
              />
            ),
            text: data.platformType,
          });
          break;
        default:
          break;
      }

      if (
        data?.remoteAppMetadata?.displayName &&
        data?.remoteAppMetadata?.displayName?.toLowerCase() !== 'terminal'
      ) {
        obj.push({
          kind: KINDS.LABEL_VALUE,
          label: 'Remote App',
          value: data?.remoteAppMetadata?.displayName,
        });
      }

      if (data.epCustomproperty10) {
        obj.push({
          kind: KINDS.LABEL_VALUE,
          label: 'Location',
          value: data.epCustomproperty10,
        });
      }

      if (data.endpointDescription) {
        obj.push({ kind: KINDS.TEXT, text: data.endpointDescription });
      }
    }

    return obj;
  };

  function getTitleObjectBasedOnType() {
    if (row.type === SESSION) {
      return getObjectForTitle(
        row.endpointName,
        row.accountName,
        row.pamAccountType
      );
    }

    if (row.type === ACCOUNT) {
      return [{ kind: KINDS.TEXT, text: row.accountName }];
    }

    return [{ kind: KINDS.TEXT, text: row.endpointName }];
  }

  const createPrimaryIconOnClick = () => {
    let primaryIconOnClick = () => {};

    if (row.type === ENDPOINT) {
      primaryIconOnClick = () => {
        history.push('/request/privilegedAccess/check-out-credential');
      };
    } else if (row.type === SESSION) {
      setIsModalOpen(!isModalOpen);
      setIconClickedForRow(row);
      setCheckInModalKind(
        IS_CREDENTIALLESS ? END_TYPE_END_SESSION : END_TYPE_CHECKIN
      );
    }

    return primaryIconOnClick();
  };

  const createSecondaryIconOnClick = () => {
    if (row.type === ENDPOINT) {
      // todo : go to connect-to-session page
    } else if (row.type === SESSION) {
      const sessionToBeLaunched = {
        userKey,
        userName,
        endDate: formatLocalizedDateTime(row.sessionEndDate),
        startDate: formatLocalizedDateTime(row.sessionStartDate),
        accountId: row.privilegeId,
        instanceId: row.instanceIdSession,
        status: row.sessionStatus,
        accessKey: row.requestAccessKey.toString(),
        hostIp: row.hostIp,
        osType: row.pamPlatformType,
        requestId: row.requestId,
        entitlementName: 'Instance',
        credentialtype: row.pamType,
        remoteApp: row.remoteApp,
        remoteAppMetadata: row.remoteAppMetadata,
      };

      onLaunchSession(sessionToBeLaunched);
    }
  };

  function getPrimaryIconObject() {
    if (row.type === ENDPOINT) {
      return createIcon(null, 'ArrowRight', 'navy-900', 'medium');
    }

    if (row.type === SESSION) {
      const icon = createIcon(null, 'SubtractCircle', 'critical-700', 'medium');

      if (SESSION_STARTED) {
        return (
          <Tooltip text={localize(intl, row.pamType === CREDENTIALLESS ? msgs?.pamCheckInModal?.endButtonCredentiallessTooltip : msgs?.pamCheckInModal?.endButtonCredentialedTooltip )} position='left'>
            {createButtonIcon(
              createPrimaryIconOnClick,
              'outlinedCritical',
              icon,
              'large'
            )}
          </Tooltip>
        );
      }

      return createButtonIcon(
        createPrimaryIconOnClick,
        'bold',
        icon,
        'large',
        true
      );
    }

    return null;
  }

  function getSecondaryIconObject() {
    if (row.type === ENDPOINT) {
      return (
        <Tooltip text={localize(intl, msgs?.pamCheckInModal?.connectButtonTooltip)} position='left'>
          {createIcon(null, 'ConnectPlug', 'navy-700', 'medium')}
        </Tooltip>
      )
    }

    if (
      row.type === SESSION &&
      row?.pamType?.toUpperCase() === CREDENTIALLESS
    ) {
      const icon = createIcon(null, 'LaunchRocket', 'neutral-000', 'medium');

      if (SESSION_STARTED) {
        return (
          <Tooltip text={localize(intl, msgs?.pamCheckInModal?.connectButtonTooltip)} position='left'>
            {createButtonIcon(
              createSecondaryIconOnClick,
              'bold',
              icon,
              'large'
            )}
          </Tooltip>
        );
      }

      return createButtonIcon(
        createSecondaryIconOnClick,
        'bold',
        icon,
        'large',
        true
      );
    }

    return null;
  }

  function getStatusObjectBasedOnType() {
    if (row?.type?.toLowerCase() === SESSION) {
      if (SESSION_STARTED) {
        return (
          <Chip
            kind={KINDS.SUBTLE_FILLED}
            color='brandSecondary'
            label={
              <CountdownTimer
                targetDate={row?.sessionEndDate}
                leftText='left'
                onTimerExpired={() => {
                  // todo : add fade out transition
                  const updatedListRowData = { ...listRowData };

                  updatedListRowData.result.userAccesses =
                    updatedListRowData.result.userAccesses.filter(
                      (access) =>
                        access.requestAccessKey !== row.requestAccessKey
                    );
                  setListRowData(updatedListRowData);
                }}
              />
            }
            leftIcon={<Icon kind='Time' size='medium' />}
          />
        );
      }

      if (!row.sessionStatus) {
        return (
          <Chip
            kind={KINDS.SUBTLE_FILLED}
            color='info'
            label='Pending Approval'
          />
        );
      }

      if (row.sessionStatus === SESSION_STATUSES.SESSION_APPROVED) {
        return (
          <Chip
            kind={KINDS.SUBTLE_FILLED}
            color='brandSecondary'
            label={formatLocalizedDateTime(row?.sessionStartDate, false, false)}
            leftIcon={<Icon kind='Calendar' size='medium' />}
          />
        );
      }
    }

    return null;
  }

  const optionsForActiveSessions = [
    {
      label: localize(intl, msgs?.pamCheckInModal?.extendLabel),
      value: EXTEND_SESSION,
    },
  ];

  const optionsForFutureApprovedSession = [
    {
      label: localize(intl, msgs?.pamCheckInModal?.cancelSessionLabel),
      value: END_TYPE_CANCEL_SESSION,
    },
  ];

  const optionsForPendingSessions = [
    {
      label: localize(intl, msgs?.pamCheckInModal?.cancelRequestLabel),
      value: END_TYPE_CANCEL_REQUEST,
    },
  ];

  let options = [];

  if (!row.sessionStatus) {
    options = optionsForPendingSessions;
  } else if (row.sessionStatus === 1) {
    options = optionsForFutureApprovedSession;
  } else if (row.sessionStatus === 2) {
    options = optionsForActiveSessions;
  }

  function getMoreOptions() {
    const handleChange = (selectedOption) => {
      if (selectedOption.value === EXTEND_SESSION) {
        setIsExtendModalOpen(true);
        setIconClickedForRow(row);
      } else {
        setIsModalOpen(!isModalOpen);
        setIconClickedForRow(row);
        setCheckInModalKind(selectedOption.value);
      }
    };

    const trigger = (
      <ButtonIcon
        size='large'
        kind={KINDS.GHOST}
        icon={<Icon kind='KebabVertical' color='neutral-100' size='medium' />}
      />
    );

    return (
      <MenuAction
        kind={KINDS.SIMPLE}
        options={options}
        onChange={handleChange}
        trigger={trigger}
        expandDirection='bottom'
      />
    );
  }

  const createObjectForRow = () => {
    const rowObject = {
      category:
        row.type === ACCOUNT
          ? getObjectForCategory(row.accountName, row.platformType, row.type)
          : null,
      title: getTitleObjectBasedOnType(),
      description: getObjectForDescription(row),

      status: getStatusObjectBasedOnType(row),

      primaryIcon: getPrimaryIconObject(),
      secondaryIcon: getSecondaryIconObject(),
      moreOptionsIcon: row.type === SESSION ? getMoreOptions() : null,
      type: row.type,
    };

    return rowObject;
  };

  return createObjectForRow;
};

export default useCreateRowObject;
