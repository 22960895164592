import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './ToggleButtonGroup.css';

/**
 * #### WIP Info:
 *
 * ###### TODO:
 * - ToolTips
 */

function ToggleButtonGroup({
  children,
  className,
  selection,
  setSelection,
  dataTestId,
}) {
  const modalContentClass = classnames('ToggleButtonGroup', className);

  const childrenArray = React.Children.toArray(children);

  const childrenWithProps = childrenArray.map((child, index) => {
    let kind;

    if (index === 0) {
      kind = 'start';
    } else if (index < childrenArray.length - 1) {
      kind = 'centered';
    } else {
      kind = 'end';
    }

    return React.cloneElement(child, {
      index: index + 1,
      selection,
      setSelection,
      kind,
      dataTestId: `toggleButton${index + 1}`,
    });
  });

  return (
    <div data-testid={dataTestId} className={modalContentClass}>
      {childrenWithProps}
    </div>
  );
}

ToggleButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  selection: PropTypes.number,
  setSelection: PropTypes.func,
  dataTestId: PropTypes.string,
};

ToggleButtonGroup.defaultProps = {
  className: null,
  selection: null,
  setSelection: null,
  dataTestId: null,
};

export default ToggleButtonGroup;
