import './Scheduler.css';

import { formatSchedulerLocalizedDateString, localize } from '@saviynt/common';
import { Box } from '@saviynt/design-system';
import classnames from 'classnames';
import { isSameDay } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { Session } from '../../models/PamModels';
import { msgs, STATUS } from './constants';
import SchedulerDay from './ScheduleDay/SchedulerDay';

function Scheduler({
  sessions,
  numOfDaysDisplayed,
  timeFormat,
  isModalView,
  dataTestId,
  className,
  filters,
}) {
  const classes = classnames('Scheduler', className);

  const intl = useIntl();

  const SUNDAY = localize(intl, msgs.pam.scheduler.sunday);
  const MONDAY = localize(intl, msgs.pam.scheduler.monday);
  const TUESDAY = localize(intl, msgs.pam.scheduler.tuesday);
  const WEDNESDAY = localize(intl, msgs.pam.scheduler.wednesday);
  const THURSDAY = localize(intl, msgs.pam.scheduler.thursday);
  const FRIDAY = localize(intl, msgs.pam.scheduler.friday);
  const SATURDAY = localize(intl, msgs.pam.scheduler.saturday);

  const isFiltered = (reservations) => {
    const isNoFilters = filters.length === 0;

    const isAvailableOnly =
      filters.every((filter) => filter === STATUS.AVAILABLE) &&
      reservations.length === 0;

    const isUnavailableOnly =
      filters.every((filter) => filter === STATUS.UNAVAILABLE) &&
      reservations.length > 0;

    const isAvailableOrUnavailable =
      filters.includes(STATUS.AVAILABLE) &&
      filters.includes(STATUS.UNAVAILABLE);

    return (
      isNoFilters ||
      isAvailableOnly ||
      isUnavailableOnly ||
      isAvailableOrUnavailable
    );
  };

  const renderBlock = () => {
    const weekdays = [
      SUNDAY,
      MONDAY,
      TUESDAY,
      WEDNESDAY,
      THURSDAY,
      FRIDAY,
      SATURDAY,
    ];
    const daysArray = [];

    for (let i = 0; i < numOfDaysDisplayed; i += 1) {
      const currentDate = new Date();

      currentDate.setDate(currentDate.getDate() + i);
      daysArray.push(currentDate);
    }

    return daysArray.map((day) => {
      const reservedSessions = [];

      sessions.forEach((session) => {
        const sessionStartDate = new Date(session.sessionStartDate);
        const currentDay = new Date(day);

        if (isSameDay(sessionStartDate, currentDay)) {
          reservedSessions.push(session);
        }
      });

      const availableStartDate = new Date(day);

      availableStartDate.setHours(0, 0, 0, 0);

      return (
        <Box
          key={daysArray.indexOf(day)}
          className={
            isFiltered(reservedSessions)
              ? 'Scheduler-block'
              : 'Scheduler-block--hidden'
          }>
          {isFiltered(reservedSessions) && (
            <section className='Scheduler-blockLabel'>
              <div className='Scheduler-blockLabel--weekday'>
                {`${
                  daysArray.indexOf(day) === 0
                    ? 'Today'
                    : weekdays[day.getDay()]
                }, `}
              </div>
              <div className='Scheduler-blockLabel--date'>
                {formatSchedulerLocalizedDateString(day)}
              </div>
            </section>
          )}
          <SchedulerDay
            sessions={reservedSessions}
            timeFormat={timeFormat}
            isModalView={isModalView}
            availableStartDate={availableStartDate}
            filters={filters}
          />
        </Box>
      );
    });
  };

  return (
    <section className={classes} data-testid={dataTestId}>
      {renderBlock()}
    </section>
  );
}

Scheduler.propTypes = {
  sessions: PropTypes.arrayOf(Session).isRequired,
  numOfDaysDisplayed: PropTypes.number,
  timeFormat: PropTypes.shape({
    roundToNearest: PropTypes.string,
    is24HourFormat: PropTypes.bool,
  }).isRequired,
  isModalView: PropTypes.bool,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.string),
};

Scheduler.defaultProps = {
  numOfDaysDisplayed: 10,
  isModalView: false,
  dataTestId: null,
  className: null,
  filters: [],
};

export default Scheduler;
