import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import GridItem from './GridItem/GridItem';

import './Grid.css';

/**
 * A very rough Grid container component.
 *
 * @component
 * @param {number} spacing - spacing between grid items.
 * @param {string} className - additional CSS class name for the grid container.
 * @param {string} dataTestId - data-testid attribute for testing purposes.
 * @param {ReactNode} children - child elements to be rendered inside the grid container.
 * @returns {JSX.Element} The rendered Grid component.
 */
function Grid({ spacing, className, dataTestId, children }) {
  const style = {
    // assume 1 spacing unit is 8px
    ...(spacing && { gap: `${(spacing * 8) / 16}rem` }),
    // add other grid-related styles here as needed.
  };
  const classes = classnames('Grid', className);

  return (
    <div className={classes} style={style} data-testid={dataTestId}>
      {children}
    </div>
  );
}

Grid.propTypes = {
  spacing: PropTypes.number,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  children: PropTypes.node,
};
Grid.defaultProps = {
  spacing: 1,
  className: null,
  dataTestId: null,
  children: null,
};
Grid.Item = GridItem;

export default Grid;
