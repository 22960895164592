import {
  CLEAR_REQUEST_ID,
  STORE_REQUEST_SUBMITTED_ID,
} from '../actions/requestSubmittedDetailsAction';

const initialState = { requestId: null };

export const requestSubmittedDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case STORE_REQUEST_SUBMITTED_ID: {
      return {
        ...state,
        requestId: action.payload.requestId,
      };
    }

    case CLEAR_REQUEST_ID: {
      return initialState;
    }

    default:
      return state;
  }
};

export default requestSubmittedDetailsReducer;
