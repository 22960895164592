import './LandingPageSidePanel.css';

import { SidePanel } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ModuleDetails } from '../../models/PamModels';
import { ASSET_TYPES, TYPES } from './constants';
import SidePanelFooter from './SidePanelFooter/SidePanelFooter';
import SidePanelView from './SidePanelView/SidePanelView';

function LandingPageSidePanel({
  data,
  isOpen,
  onClose,
  credentialRequestOnClick,
  dataTestId,
  setIsCheckInModalOpen,
  setIconClickedForRow,
  setCheckInModalKind,
  setIsExtendModalOpen,
  setSessionToLaunch,
  className,
}) {
  const classes = classnames('LandingPageSidePanel', className);
  const APPLICATION = 'Application';

  const setIcon = () => {
    switch (data.assetType) {
      case ASSET_TYPES.ACCOUNT:
        return 'Account';
      case ASSET_TYPES.APPLICATION:
        return APPLICATION;
      case ASSET_TYPES.DATABASE:
        return 'Database';
      case ASSET_TYPES.SERVER:
        return 'Server';
      case ASSET_TYPES.CONSOLE:
        return 'WebAppConsole';
      default:
        return APPLICATION;
    }
  };

  return (
    <SidePanel
      header={{
        title: data.endointName || data.endpointName || data.accountName,
        description: data.assetType || 'No Description',
        icon: setIcon(),
      }}
      headerClassName={data.type === 'endpoint' ? 'LandingPageSidePanel-header' : null}
      isOpen={isOpen}
      onClose={onClose}
      className={classes}
      layout={<SidePanelView data={data} layoutC />}
      layoutClassName={
        data.type === TYPES.SESSION
        ? 'SidePanel-layout LandingPageSidePanel-layout'
        : null
      }
      footerActionBarChildren={
        <SidePanelFooter
        data={data}
        credentialRequestOnClick={credentialRequestOnClick}
        setIsCheckInModalOpen={setIsCheckInModalOpen}
        setIconClickedForRow={setIconClickedForRow}
        setSidePanelOnClose={onClose}
        setCheckInModalKind={setCheckInModalKind}
        setIsExtendModalOpen={setIsExtendModalOpen}
        setSessionToLaunch={setSessionToLaunch}
        />
      }
      showCenterMessage
      dataTestId={dataTestId}
    />
  );
}

LandingPageSidePanel.propTypes = {
  data: ModuleDetails,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  credentialRequestOnClick: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  setIsCheckInModalOpen: PropTypes.func,
  setIconClickedForRow: PropTypes.func,
  setCheckInModalKind: PropTypes.func,
  setIsExtendModalOpen: PropTypes.func,
  setSessionToLaunch: PropTypes.func,
};

LandingPageSidePanel.defaultProps = {
  data: null,
  isOpen: false,
  onClose: () => {},
  dataTestId: null,
  className: null,
  setIsCheckInModalOpen: null,
  setIconClickedForRow: null,
  setCheckInModalKind: null,
  setIsExtendModalOpen: null,
  setSessionToLaunch: null,
};
export default LandingPageSidePanel;
