// eslint-disable-next-line import/prefer-default-export
export const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  small_width: 16,
  small_height: 16,
  medium_width: 20,
  medium_height: 20,
  large_width: 24,
  large_height: 24,
};
