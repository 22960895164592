export const REQUEST_VERSION_LIST = 'REQUEST_VERSION_LIST';
export const REQUEST_VERSION_LIST_SUCCESS = 'REQUEST_VERSION_LIST_SUCCESS';
export const REQUEST_VERSION_LIST_FAILURE = 'REQUEST_VERSION_LIST_FAILURE';

export const FETCH_ECM_VERSION = 'FETCH_ECM_VERSION';
export const FETCH_ECM_VERSION_SUCCESS = 'FETCH_ECM_VERSION_SUCCESS';
export const FETCH_ECM_VERSION_FAILED = 'FETCH_ECM_VERSION_FAILED';

export const UPDATE_UI_VERSION = 'UPDATE_UI_VERSION';

export const APP_ECM = 'ECM';
export const APP_UI = 'Application UI'