import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '../Typography/Typography';

import './Switch.css';

function Switch({ name, label, pressed, labelPlacement, isDisabled }) {
  const toggleClass = classnames(
    'toggle',
    `toggle-placement-${labelPlacement}`
  );

  const [isPressed, setIsPressed] = useState(pressed);

  const handleChange = () => {
    setIsPressed((prev) => !prev);
  };

  return (
    <Typography kind='label' htmlfor={name} className={toggleClass}>
      <input
        name={name}
        className='toggle-checkbox'
        type='checkbox'
        onChange={handleChange}
        checked={isPressed}
        disabled={isDisabled}
      />
      <div
        className={`toggle-switch ${
          isPressed ? 'toogle-checked' : 'toogle-unchecked'
        }`}
      />
      <Typography kind='body1'>{label}</Typography>
    </Typography>
  );
}

Switch.defaultProps = {
  label: '',
  pressed: false,
  labelPlacement: 'right',
  isDisabled: false,
};

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  pressed: PropTypes.bool,
  isDisabled: PropTypes.bool,
  labelPlacement: PropTypes.oneOf(['right', 'left']),
};

export default Switch;
