import { useEffect, useState } from 'react';

import useApiOptions from '../../hooks/use-apioptions';
import {
  mockAccountResponse,
  mockAssetResponse,
  mockPlatformResponse,
} from '../../models/MockData/MockData';
import {
  getAccountTypesApi,
  getAssetTypeApi,
  getPlatformTypeApi,
} from '../../utilities/api/getDataFromApi';

const LandingPageFilterService = (setIsSidePanelOpen, setCurrentListItem) => {
  // Platform
  const [platformTypeResponse, setPlatformTypeResponse] = useState(null);
  const [platformTypeOptions, setPlatformTypeOptions] = useState([]);
  const [checkedPlatforms, setCheckedPlatforms] = useState([]);
  // Asset Type
  const [assetTypeResponse, setAssetTypeResponse] = useState(null);
  const [assetTypeOptions, setAssetTypeOptions] = useState([]);
  const [checkedAssets, setCheckedAssets] = useState([]);
  // Account
  const [accountResponse, setAccountResponse] = useState(null);
  const [accountOptions, setAccountOptions] = useState([]);
  // Badge/Clear Button
  const [isFilterChecked, setIsFilterChecked] = useState(false);

  useEffect(() => {
    getPlatformTypeApi().then((response) => {
      setPlatformTypeResponse(response);
    });

    getAssetTypeApi().then((response) => {
      setAssetTypeResponse(response);
    });

    getAccountTypesApi().then((response) => {
      setAccountResponse(response);
    });
  }, []);

  // Select Button badge render useEffect
  useEffect(() => {
    const platformHasChecked = platformTypeOptions.some(
      (option) => option.isChecked
    );
    const assetHasChecked = assetTypeOptions.some((option) => option.isChecked);
    const accountHasChecked = accountOptions.some((option) => option.isChecked);

    if (platformHasChecked || assetHasChecked || accountHasChecked) {
      setIsFilterChecked(true);
    } else {
      setIsFilterChecked(false);
    }
  }, [platformTypeOptions, assetTypeOptions, accountOptions]);

  // Mock Data for filter testing, API response should update this
  useApiOptions(mockPlatformResponse, setPlatformTypeOptions);
  useApiOptions(mockAssetResponse, setAssetTypeOptions);
  useApiOptions(mockAccountResponse, setAccountOptions);

  //   Actual data
  useApiOptions(platformTypeResponse, setPlatformTypeOptions);
  useApiOptions(assetTypeResponse, setAssetTypeOptions);
  useApiOptions(accountResponse, setAccountOptions);

  const handleSelectedOptions = (selectedOptions, options, setOptions) => {
    const updatedOptions = options.map((option) => {
      const isSelected = selectedOptions.some(
        (selOption) => selOption.value === option.value
      );

      return {
        ...option,
        isChecked: isSelected,
      };
    });

    setOptions(updatedOptions);
    setIsSidePanelOpen(false);
    setCurrentListItem(null);
  };

  const clearAllFilters = () => {
    const clearedPlatformOptions = platformTypeOptions.map((option) => ({
      ...option,
      isChecked: false,
    }));

    setPlatformTypeOptions(clearedPlatformOptions);

    const clearedAssetOptions = assetTypeOptions.map((option) => ({
      ...option,
      isChecked: false,
    }));

    setAssetTypeOptions(clearedAssetOptions);

    const clearedAccountOptions = accountOptions.map((option) => ({
      ...option,
      isChecked: false,
    }));

    setAccountOptions(clearedAccountOptions);
    setIsFilterChecked(false);
    setIsSidePanelOpen(false);
    setCurrentListItem(null);
  };

  useEffect(() => {
    const getCheckedPlatforms = platformTypeOptions
      .filter((item) => item.isChecked)
      .map((item) => item.value);

    setCheckedPlatforms(getCheckedPlatforms);
    const getCheckedAssets = assetTypeOptions
      .filter((item) => item.isChecked)
      .map((item) => item.value);

    setCheckedAssets(getCheckedAssets);
  }, [platformTypeOptions, assetTypeOptions]);

  return {
    platform: {
      options: platformTypeOptions,
      setSelected: (selectedOptions) =>
        handleSelectedOptions(
          selectedOptions,
          platformTypeOptions,
          setPlatformTypeOptions
        ),
    },
    asset: {
      options: assetTypeOptions,
      setSelected: (selectedOptions) =>
        handleSelectedOptions(
          selectedOptions,
          assetTypeOptions,
          setAssetTypeOptions
        ),
    },
    account: {
      options: accountOptions,
      setSelected: (selectedOptions) =>
        handleSelectedOptions(
          selectedOptions,
          accountOptions,
          setAccountOptions
        ),
    },
    isFilterChecked,
    clearAllFilters,
    checkedPlatforms,
    checkedAssets,
  };
};

export default LandingPageFilterService;
