import './SidePanelView.css';

import { Logger } from '@saviynt/common';
import { Box, Loader } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { useDispatch } from 'react-redux';

import { ModuleDetails } from '../../../models/PamModels';
import {
  selectedListAccountRemoteMetadatas,
  selectedListIsExclusiveAccess,
  selectedListMaxCredlessSessionRequestTime,
} from '../../../store/actions';
import {
  getAccountDetailsApi,
  getEndpointDetailsApi,
  getRequestDetailsApi,
  getSessionDetailsApi,
} from '../../../utilities/api/getDataFromApi';
import { JIT_PAM_TYPES, TYPES } from '../constants';
import SpvAccounts from './SpvAccounts/SpvAccounts';
import SpvEndpoint from './SpvEndpoint/SpvEndpoint';
import SpvSession from './SpvSession/SpvSession';

function SidePanelView({ data, dataTestId, className }) {
  const classes = classnames('SidePanelView', className);

  const [platformDetails, setPlatformDetails] = useState(data);

  // Sessions
  const [session, setSession] = useState(data);
  const [sessionDetails, setSessionDetails] = useState(null);
  const [sessionAccountDetails, setSessionAccountDetails] = useState(data);
  const [sessionEndpointDetails, setSessionEndpointDetails] = useState(data);
  const [requestDetails, setRequestDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const cookies = new Cookies();
  const dispatch = useDispatch();

  const isPendingSession = !data.sessionStatus;
  const isFutureApprovedSession = data.sessionStatus === 1;
  const isActiveSession = data.sessionStatus === 2;

  const callApiByType = () => {
    switch (data.type) {
      case TYPES.ACCOUNT:
        setIsLoading(true);
        getAccountDetailsApi(data.accountKey).then((response) => {
          setPlatformDetails(response);
          setIsLoading(false);
        });
        break;
      case TYPES.SESSION:
        setIsLoading(true);
        getAccountDetailsApi(data.accountKey).then((response) => {
          setSessionAccountDetails(response);
        });

        getEndpointDetailsApi(data.endpointKey).then((response) => {
          setSessionEndpointDetails(response);
        });

        // Payload is Pending Session or Future Approved Session
        if (isPendingSession || isFutureApprovedSession) {
          setSession(data);
          getRequestDetailsApi(cookies.get('user_name'), data.requestId).then(
            (response) => {
              setRequestDetails(response);
              setIsLoading(false);
            }
          );
        }

        // Payload is Active Session
        else if (isActiveSession) {
          setSession(data);
          getSessionDetailsApi(
            data.requestAccessKey,
            cookies.get('userKey')
          ).then((response) => {
            if (response.error) {
              Logger.error(response.error);
              setSessionDetails(null);
              setIsLoading(false);

              return;
            }

            setSessionDetails(response);
            setIsLoading(false);
          });
        }

        // All other session types
        else {
          setSession(data);
          setSessionDetails(null);
          setRequestDetails(null);
          setIsLoading(false);
        }

        break;
      case TYPES.ENDPOINT:
        setIsLoading(true);
        getEndpointDetailsApi(data.endpointKey).then((response) => {
          setPlatformDetails(response);
          setIsLoading(false);
          // JIT max time
          // Get the maxCredlessSessionRequestTime based on pamPlatformType
          const pamPlatformType = response.pamPlatformType?.toLowerCase();

          if (Object.values(JIT_PAM_TYPES).includes(pamPlatformType)) {
            dispatch(
              selectedListMaxCredlessSessionRequestTime(
                response.bootstrapConfigModel[pamPlatformType]
                  .maxCredlessSessionRequestTime,
                'success'
              )
            );
          }

          // Is exclusive access
          dispatch(
            selectedListIsExclusiveAccess(response.exclusiveAccess, 'success')
          );

          // Is remote app enabled
          if (response.remoteAppMetadatas?.length > 0) {
            dispatch(
              selectedListAccountRemoteMetadatas(
                response.remoteAppMetadatas,
                'success'
              )
            );
          } else {
            dispatch(selectedListAccountRemoteMetadatas(null, 'success'));
          }
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // only make 1 API call at a time
    callApiByType();
  }, [data, session]);

  const renderSidePanelView = () => {
    switch (data.type) {
      case TYPES.ACCOUNT:
        return <SpvAccounts data={platformDetails} />;

      case TYPES.SESSION:
        return (
          <SpvSession
            data={data}
            accountDetails={sessionAccountDetails}
            endpointDetails={sessionEndpointDetails}
            session={session}
            sessionDetails={sessionDetails}
            requestDetails={requestDetails}
          />
        );
      case TYPES.ENDPOINT:
        return <SpvEndpoint data={platformDetails} />;
      default:
        return null;
    }
  };

  return (
    <aside className={classes} data-testid={dataTestId}>
      {isLoading ? (
        <Box className='SidePanelView-loader'>
          <Loader kind='dots' format='inline' color='info' size='medium' />
        </Box>
      ) : (
        renderSidePanelView()
      )}
    </aside>
  );
}

SidePanelView.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SidePanelView.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SidePanelView;
