import { SECONDS_PER_MINUTE } from '@saviynt/common';
// Objects
export const MODAL_PAGE_KINDS = { COC: 'coc', CTS: 'cts' };
export const ACCOUNT_JIT_SELECTION = {
  value: 'JIT',
  title: 'Temporary Privileged Account',
  description:
    'Create a new one-time-use account for Just-in-Time (JIT) access.',
  maxtime: '36000',
};
export const SESSION_STATUSES = {
  PENDING: undefined,
  APPROVED: 1,
  APPROVED_ACTIVE: 2,
  COMPLETED: 3,
};
export const ASSET_TYPES = {
  ACCOUNT: 'Account',
  APPLICATION: 'Application',
  DATABASE: 'Database',
  SERVER: 'Server',
  CONSOLE: 'Console',
};
export const INTERVALS = {
  MINUTES: 'minutes',
  HOURS: 'hours',
  DAYS: 'days',
};
export const DURATION_TAB_TYPES = {
  NOW: 'NOW',
  FUTURE: 'FUTURE'
};

// Single Values
// TODO: Update this with NOW duration leap year criteria information
export const OFFSET_SECONDS_PER_YEAR = 31104000;
export const MIN_LENGTH_FOR_JUSTIFICATION = 3;
export const MAX_ACCOUNTS_BEFORE_SPLICE = 5;
export const DEFAULT_USER_MAX_TIME = 36000;
export const MAX_RADIO_OPTIONS_TO_SHOW = 6;
export const INTERVAL_LENGTH_IN_MINS = 15;
export const BYTES_PER_MB = 1024 * 1024;
export const MAX_APPS_BEFORE_SPLICE = 5;
export const MAX_ACCOUNTS_TO_SHOW = 6;
export const AVG_DAYS_PER_MONTH = 30;
export const MIN_TARGET_LENGTH = 5;
export const MAX_APPS_TO_SHOW = 6;
export const MINUTE_INTERVAL = 15;
export const MIN_PORT_LENGTH = 4;

// Initial State Objects
export const initialCriticalState = {
  accountId: false,
  appSelection: false,
  serverTarget: false,
  startdate: false,
  enddate: false,
  isDurationBeingEdited: false,
};
export const initialAccordionSnapshotState = {
  isNowTab: true,
  isDurationCustom: false,
  customDurationInputValue: '',
  checkedIntervalOption: INTERVALS.MINUTES,
  futureTabStartDate: null,
  futureTabEndDate: null,
  durationChipValue: null,
};

// Initial State Arrays
export const initialIntervalOptions = [
  {
    label: 'Minutes',
    value: INTERVALS.MINUTES,
    isChecked: true,
  },
  {
    label: 'Hours',
    value: INTERVALS.HOURS,
  },
  {
    label: 'Days',
    value: INTERVALS.DAYS,
  },
];
export const CHIP_OPTIONS = [
  { value: 30 * SECONDS_PER_MINUTE, label: '30 min' },
  { value: 60 * SECONDS_PER_MINUTE, label: '1 hour' },
  { value: 120 * SECONDS_PER_MINUTE, label: '2 hours' },
  { value: 240 * SECONDS_PER_MINUTE, label: '4 hours' },
  { value: 480 * SECONDS_PER_MINUTE, label: '8 hours' },
];

export const msgs = {
  pam: {
    common: {
      buttons: {
        edit: {
          id: 'pam.common.buttons.edit',
          defaultMessage: 'Edit',
        },
      },
      links: {
        view: {
          id: 'pam.common.links.view',
          defaultMessage: 'View',
        },
      },
      db: {
        select: {
          id: 'pam.common.db.select',
          defaultMessage: 'select',
        },
        selectLabel: {
          id: 'pam.common.db.selectLabel',
          defaultMessage:
            'Fetch records from tables based on the query formulated.',
        },
        create: {
          id: 'pam.common.db.create',
          defaultMessage: 'create',
        },
        createLabel: {
          id: 'pam.common.db.createLabel',
          defaultMessage:
            'Create multiple database objects like functions, indexes, procedures, roles, schemas, synonyms, tables, triggers and views.',
        },
        alter: {
          id: 'pam.common.db.alter',
          defaultMessage: 'alter',
        },
        alterLabel: {
          id: 'pam.common.db.alterLabel',
          defaultMessage: 'Change the structure of a table.',
        },
        insert: {
          id: 'pam.common.db.insert',
          defaultMessage: 'insert',
        },
        insertLabel: {
          id: 'pam.common.db.insertLabel',
          defaultMessage: 'Create new row(s) in a table.',
        },
        update: {
          id: 'pam.common.db.update',
          defaultMessage: 'update',
        },
        updateLabel: {
          id: 'pam.common.db.updateLabel',
          defaultMessage: 'Update existing records in a table.',
        },
      },
      save: {
        id: 'pam.common.save',
        defaultMessage: 'Save',
      },
      cancel: {
        id: 'pam.common.cancel',
        defaultMessage: 'Cancel',
      },
      selectAll: {
        id: 'pam.common.selectAll',
        defaultMessage: 'Select All',
      },
      deselectAll: {
        id: 'pam.common.deselectAll',
        defaultMessage: 'Deselect All',
      },
    },
    cts: {
      db: {
        jitAccount: {
          id: 'pam.cts.db.jitAccount',
          defaultMessage: 'JIT Account',
        },
        jitAccountDetails: {
          id: 'pam.cts.db.jitAccountDetails',
          defaultMessage: 'Account Details',
        },
        jitAccountName: {
          id: 'pam.cts.db.jitAccountName',
          defaultMessage: 'Account Name',
        },
        jitAccountDesc: {
          id: 'pam.cts.db.jitAccountDesc',
          defaultMessage: 'Account Description',
        },
        jitAccountValue: {
          id: 'pam.cts.db.jitAccountValue',
          defaultMessage:
            'This is a one-time-use account for Just-in-Time (JIT) access.',
        },
        jitEntitlementsNeeded: {
          id: 'pam.cts.db.jitEntitlementsNeeded',
          defaultMessage:
            'Which entitlements do you need for your JIT account?',
        },
        jitEntitlements: {
          id: 'pam.cts.db.jitEntitlements',
          defaultMessage: 'Entitlements',
        },
        jitTempPrivilegedAccount: {
          id: 'pam.cts.db.jitTempPrivilegedAccount',
          defaultMessage: 'Temporary Privileged Account (JIT)',
        },
        jitTempPrivAcctModalTitle: {
          id: 'pam.cts.db.jitTempPrivAcctModalTitle',
          defaultMessage: 'Temporary Privileged Account',
        },
        jitTempAcctModalSubTitle: {
          id: 'pam.cts.db.jitCreateTempAcctModalSubtitle',
          defaultMessage:
            'Create a new one-time-use account for Just-in-Time (JIT) access.',
        },
      },
      page: {
        modalHeader: {
          id: 'pam.cts.page.modalHeader',
          defaultMessage: 'Connect To Session',
        },
      },
    },
    coc: {
      page: {
        modalHeader: {
          id: 'pam.coc.page.modalHeader',
          defaultMessage: 'Check Out Credential',
        },
      },
    },
    modalPage: {
      credentialRequest: {
        comment: {
          id: 'pam.modalPage.credentialRequest.comment',
          defaultMessage:
            'This user is requesting privileged access. No additional comments are needed.',
        },
      },
      page: {
        noAccountsToDisplay: {
          id: 'pam.modalPage.page.noAccountsToDisplay',
          defaultMessage: 'There are no accounts to be displayed.',
        },
        submitBtn: {
          id: 'pam.modalPage.page.submitBtn',
          defaultMessage: 'Submit',
        },
        viewScheduleLink: {
          id: 'pam.modalPage.page.viewScheduleLink',
          defaultMessage: 'View Schedule',
        },
      },
      critical: {
        submitErrorAlert: {
          id: 'pam.modalPage.critical.submitErrorAlert',
          defaultMessage: 'Submit Failure',
        },
        // Duration
        customRangeCritical: {
          id: 'pam.modalPage.critical.customRangeCritical',
          defaultMessage:
            'Duration must be a minimum of 5 minutes and a maximum of {date}.',
        },
        sessionConflictWarning: {
          id: 'pam.modalPage.critical.sessionConflictWarning',
          defaultMessage:
            'The duration you entered conflicts with another session starting at {date}.',
        },
        editingAlert: {
          id: 'pam.modalPage.critical.editingAlert',
          defaultMessage:
            "You've made changes to this request that haven't been saved.",
        },
        editButtonsMessage: {
          id: 'pam.modalPage.critical.editButtonsMessage',
          defaultMessage: 'You must save or discard your changes.',
        },
      },
      // Accordions
      accordionHeading: {
        // Account
        accountHeader: {
          id: 'pam.modalPage.accordionHeading.accountHeader',
          defaultMessage: 'Which account do you need?',
        },
        accountSubheader: {
          id: 'pam.modalPage.accordionHeading.accountSubheader',
          defaultMessage: 'Select an account or create a new one',
        },
        noAccountRecords: {
          id: 'pam.modalPage.accordionHeading.noAccountRecords',
          defaultMessage:
            'No records found. This asset requires a JIT account for privileged sessions.',
        },
        noAccountMatchingRecords: {
          id: 'pam.modalPage.accordionHeading.noAccountMatchingRecords',
          defaultMessage: "Your search doesn't match any records",
        },
        // App Launcher
        appLauncherHeader: {
          id: 'pam.modalPage.accordionHeading.appLauncherHeader',
          defaultMessage: 'What would you like to connect to?',
        },
        appLauncherSubheader: {
          id: 'pam.modalPage.accordionHeading.appLauncherSubheader',
          defaultMessage: 'Select Application Launcher',
        },
        // Server Details
        serverDetailsHeader: {
          id: 'pam.modalPage.accordionHeading.serverDetailsHeader',
          defaultMessage: 'What server would you like to connect to?',
        },
        serverDetailsSubheader: {
          id: 'pam.modalPage.accordionHeading.serverDetailsSubheader',
          defaultMessage: 'Enter the Target Server Details',
        },
        // Duration
        durationHeader: {
          id: 'pam.modalPage.accordionHeading.durationHeader',
          defaultMessage: 'When do you need access?',
        },
        durationSubheader: {
          id: 'pam.modalPage.accordionHeading.durationSubheader',
          defaultMessage: 'Select a timeframe',
        },
        // Jusification Details
        detailsHeader: {
          id: 'pam.modalPage.accordionHeading.detailsHeader',
          defaultMessage: 'Do you have other details to include?',
        },
        detailsSubheader: {
          id: 'pam.modalPage.accordionHeading.detailsSubheader',
          defaultMessage:
            'Provide optional comments, attachments, and ticket number',
        },
      },
      // Account
      accountSelection: {
        moreLink: {
          id: 'pam.modalPage.accountSelection.moreLink',
          defaultMessage: 'Show More',
        },
        // JIT Database only
        dataBaseLabelHeading: {
          id: 'pam.modalPage.accountSelection.dataBaseLabelHeading',
          defaultMessage: 'Create a Temporary Privileged Account',
        },
        dataBaseLabelSubheading: {
          id: 'pam.modalPage.accountSelection.dataBaseLabelSubheading',
          defaultMessage:
            'Create a new one-time-use account for Just-in-Time (JIT) access.',
        },
        dataBaseButton: {
          id: 'pam.modalPage.accountSelection.dataBaseButton',
          defaultMessage: 'Create Account',
        },
        // JIT
        jitRadioLabel: {
          id: 'pam.modalPage.accountSelection.jitRadioLabel',
          defaultMessage: 'Create a New Account',
        },
        accountRadioGroupLabel: {
          id: 'pam.modalPage.accountSelection.accountRadioGroupLabel',
          defaultMessage: 'Select a Current Account',
        },
      },
      // Duration
      timeAccessDuration: {
        toggleTabNow: {
          id: 'pam.modalPage.timeAccessDuration.toggleTabNow',
          defaultMessage: 'Now',
        },
        toggleTabFuture: {
          id: 'pam.modalPage.timeAccessDuration.toggleTabFuture',
          defaultMessage: 'Future',
        },
      },
      customDuration: {
        invalidInterval: {
          id: 'pam.modalPage.customDuration.invalidInterval',
          defaultMessage: 'Invalid interval',
        },
        inputLabel: {
          id: 'pam.modalPage.customDuration.inputLabel',
          defaultMessage: 'Custom duration',
        },
        inputPlacholder: {
          id: 'pam.modalPage.customDuration.inputPlacholder',
          defaultMessage: 'Enter number',
        },
      },
      durationChips: {
        custom: {
          id: 'pam.modalPage.durationChips.custom',
          defaultMessage: 'Custom',
        },
      },
      durationContinueButton: {
        buttonText: {
          id: 'pam.modalPage.durationContinueButton.buttonText',
          defaultMessage: 'Continue',
        },
      },
      dateAndTimePicker: {
        startDate: {
          id: 'pam.modalPage.dateAndTimePicker.startDate',
          defaultMessage: 'Start Date',
        },
        endDate: {
          id: 'pam.modalPage.dateAndTimePicker.endDate',
          defaultMessage: 'End Date',
        },
        startTime: {
          id: 'pam.modalPage.dateAndTimePicker.startTime',
          defaultMessage: 'Start Time',
        },
        endTime: {
          id: 'pam.modalPage.dateAndTimePicker.endTime',
          defaultMessage: 'End Time',
        },
      },
      // Details
      justificationDetails: {
        justifLabel: {
          id: 'pam.modalPage.justificationDetails.justifLabel',
          defaultMessage: 'Justification',
        },
        justifPlaceholder: {
          id: 'pam.modalPage.justificationDetails.justifPlaceholder',
          defaultMessage: 'Provide a reason for your request',
        },
        ticketLabel: {
          id: 'pam.modalPage.justificationDetails.ticketLabel',
          defaultMessage: 'Ticket Number (optional)',
        },
        ticketPlaceholder: {
          id: 'pam.modalPage.justificationDetails.ticketPlaceholder',
          defaultMessage: 'Enter ticket number',
        },
        attachTitle: {
          id: 'pam.modalPage.justificationDetails.attachTitle',
          defaultMessage: 'Attachments',
        },
      },

      // Schedule Modal
      scheduleModal: {
        // Modal Title
        schedule: {
          id: 'pam.coc.scheduleModal.schedule',
          defaultMessage: 'Schedule',
        },

        // Labels
        assetLabel: {
          id: 'pam.coc.scheduleModal.assetLabel',
          defaultMessage: 'Asset:',
        },
        accountLabel: {
          id: 'pam.coc.scheduleModal.accountLabel',
          defaultMessage: 'Account:',
        },
        timeZoneLabel: {
          id: 'pam.coc.scheduleModal.timeZoneLabel',
          defaultMessage: 'Time Zone:',
        },
        available: {
          id: 'pam.coc.scheduleModal.available',
          defaultMessage: 'Available',
        },
        unavailable: {
          id: 'pam.coc.scheduleModal.unavailable',
          defaultMessage: 'Unavailable',
        },
      },
    },
  },
};
