import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Tabs.css';

function TabItem({
  label, isActive, onClick, icon, size, className,
}) {
  const classes = classNames(
    'TabItem',
    `TabItem--${size}`,
    isActive && 'TabItem--isActive',
    className,
  );

  return (
    <div
      role="button"
      tabIndex="0"
      className={classes}
      onClick={onClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onClick();
        }
      }}
    >
      {icon}
      <div className="TabItem-label">{label}</div>
    </div>
  );
}

TabItem.propTypes = {
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  size: PropTypes.oneOf(['medium', 'large']),
  className: PropTypes.string,
};

TabItem.defaultProps = {
  isActive: false,
  onClick: () => {},
  icon: null,
  size: 'large',
  className: null,
};

function TabContent({
  value, tabValue, children, className,
}) {
  const classes = classNames('TabContent', className);

  return value === tabValue ? <div className={classes}>{children}</div> : null;
}

TabContent.propTypes = {
  value: PropTypes.string.isRequired,
  tabValue: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TabContent.defaultProps = { className: null };

function TabGroup({
  value, onChange, children, className,
}) {
  const classes = classNames('TabGroup', className);

  return (
    <div className={classes}>
      {React.Children.map(children, (child) => {
        if (child.type === TabItem) {
          return React.cloneElement(child, {
            isActive: value === child.props.value,
            onClick: () => onChange(child.props.value),
          });
        }

        return child;
      })}
    </div>
  );
}

TabGroup.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export {
  TabItem, TabContent, TabGroup,
};
