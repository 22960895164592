import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../../../../../../ECMv6/assets/logo/Saviynt-logo.svg';
import WhiteLogo from '../../../../../../ECMv6/assets/logo/Saviynt-logo-white.svg';

const AppLogo = ({ src, isWhite, isSideNav, isGen2Nav }) => (
  <div className="cl-logo">
    <img
      src={src || ((!isWhite && isSideNav) && WhiteLogo) || (!isGen2Nav && Logo)}
      className="cl-logo-icon"
      alt="App Logo"
      data-testid="logo"
      width="100px"
    />
  </div>
);

AppLogo.propTypes = {
  src: PropTypes.string.isRequired,
};

export default AppLogo;
