import React from 'react';
import { useIntl } from 'react-intl';
import { localize } from '@saviynt/common';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ButtonIcon from '../ButtonIcon/ButtonIcon';
import Icon from '../Icon/Icon';

import { msgs } from './constants';

import './InlineMessage.css';

const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
};
const KINDS = {
  PLAIN: 'plain',
  BACKGROUND: 'background',
  MISSING_REQUIRED_FIELD: 'missingRequiredField',
};
const COLOR_THEMES = {
  NEUTRAL: 'neutral', // 'black'
  CRITICAL: 'critical', // 'red'
  WARNING: 'warning', // 'yellow'
  SUCCESS: 'success', // 'green'
  INFO: 'info', // 'blue'
  DISABLED: 'disabled', // 'gray'
};

function InlineMessage({
  kind,
  size,
  leftIcon,
  text,
  children,
  colorTheme,
  linkComponent,
  isDismissible,
  isVisible,
  onDismiss,
  className,
}) {
  const intl = useIntl();

  if (!isVisible) return null;

  const MISSING_REQUIRED_FIELD_TEXT = localize(
    intl,
    msgs.neo.inlineMessage.missingReqField
  );

  const classes = classnames(
    'InlineMessage',
    colorTheme && `InlineMessage--${colorTheme}`,
    `InlineMessage--${kind}`,
    `InlineMessage--${size}`,
    kind === KINDS.MISSING_REQUIRED_FIELD && 'InlineMessage--critical',
    className
  );

  const renderByKind = () => {
    switch (kind) {
      case KINDS.MISSING_REQUIRED_FIELD:
        return (
          <div className='InlineMessage-leftContent'>
            <div className='InlineMessage-leftIconContainer'>
              <Icon kind='AlertCritical' color='critical-700' />
            </div>
            <div className='InlineMessage-textContainer'>
              {MISSING_REQUIRED_FIELD_TEXT}
            </div>
          </div>
        );
      default:
        return (
          <div className='InlineMessage-leftContent'>
            {leftIcon && (
              <div className='InlineMessage-leftIconContainer'>{leftIcon}</div>
            )}
            {text && <div className='InlineMessage-textContainer'>{text}</div>}
            {children}
            {linkComponent && (
              <div className='InlineMessage-linkContainer'>{linkComponent}</div>
            )}
          </div>
        );
    }
  };

  return (
    <div role='status' className={classes}>
      {renderByKind()}
      {isDismissible && (
        <ButtonIcon
          kind='ghost'
          size='small'
          icon={<Icon kind='close' color='neutral-100' size='medium' />}
          onClick={onDismiss}
        />
      )}
    </div>
  );
}

InlineMessage.propTypes = {
  kind: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  leftIcon: PropTypes.element,
  text: PropTypes.string,
  children: PropTypes.element,
  colorTheme: PropTypes.oneOf(Object.values(COLOR_THEMES)),
  linkComponent: PropTypes.element,
  isDismissible: PropTypes.bool,
  isVisible: PropTypes.bool,
  onDismiss: PropTypes.func,
  className: PropTypes.string,
};

InlineMessage.defaultProps = {
  kind: KINDS.PLAIN,
  size: SIZES.MEDIUM,
  leftIcon: null,
  text: null,
  children: null,
  colorTheme: null,
  linkComponent: null,
  isDismissible: false,
  isVisible: true,
  onDismiss: () => {},
  className: null,
};

export default InlineMessage;
