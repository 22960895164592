import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemText } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import IconButton from '@material-ui/core/IconButton';
import { TooltipComponent } from '@saviynt/design-system';
import ProfileIcon from '../../ProfileIcon/ProfileIcon';
import ImageUploadModal from '../ProfileUpload/ImageUploadModal';

export const ProfileEdit = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1241 1.85884C9.94658 1.68201 9.7043 1.5573 9.46102 1.51546C9.21913 1.47385 8.93637 1.50993 8.73117 1.71512L1.76821 8.67769C1.72925 8.71665 1.70425 8.76738 1.6971 8.82201L1.51433 10.2176C1.50412 10.2955 1.53116 10.3737 1.58734 10.4287C1.64351 10.4837 1.72227 10.509 1.79998 10.4972L3.16907 10.2879C3.22171 10.2799 3.27041 10.2552 3.30807 10.2176L10.271 3.255C10.4767 3.04935 10.5119 2.76521 10.4698 2.52306C10.4275 2.27925 10.3021 2.03612 10.1241 1.85884ZM9.08471 2.06869C9.13731 2.01609 9.2333 1.98363 9.37627 2.00822C9.51785 2.03257 9.66656 2.1088 9.77131 2.21311C9.87561 2.31699 9.95244 2.46609 9.97722 2.60867C10.0023 2.7529 9.96963 2.84931 9.91749 2.90144L9.10141 3.71748L8.26865 2.88471L9.08471 2.06869ZM7.91508 3.23825L8.74785 4.07102L3.01249 9.80605L2.05329 9.95263L2.18183 8.97118L7.91508 3.23825Z"
      fill="white"
    />
    <path
      d="M4.72445 9.89138C4.58638 9.89138 4.47445 10.0033 4.47445 10.1414C4.47445 10.2794 4.58638 10.3914 4.72445 10.3914H10.2359C10.3739 10.3914 10.4859 10.2794 10.4859 10.1414C10.4859 10.0033 10.3739 9.89138 10.2359 9.89138H4.72445Z"
      fill="white"
    />
  </svg>
);
const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  primary: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#141419',
    maxWidth: '276px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  secondary: {
    fontWeight: 500,
    fontSize: '12px',
    color: '#676B80',
  },
  editIcon: {
    backgroundColor: 'var(--primary-main)',
    width: '16px',
    height: '16px',
    padding: '2px',
    borderRadius: '100%',
    '&:hover': {
      backgroundColor: 'var(--primary-main)',
    },
  },
  profUsernameTooltip: {
    wordBreak: 'break-all',
    maxWidth: '100%',
  },
}));

const UserDetails = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onDialogClose = () => {
    setOpen(false);
  };

  const { username, email, profileImage } = useSelector((state) => state.profile);

  return (
    <List>
      <ListItem
        disableGutters
        classes={{
          root: classes.root,
        }}
      >
        <ListItemAvatar>
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            // badgeContent={(
            //   <IconButton
            //     aria-label="edit-icon"
            //     size="small"
            //     className={classes.editIcon}
            //     onClick={handleClickOpen}
            //   >
            //     <ProfileEdit />
            //   </IconButton>
            // )}
          >
            <ProfileIcon
              size={42}
              url={profileImage}
              name={username?.substring(0, 1).toUpperCase()}
            />
          </Badge>
        </ListItemAvatar>
        {
          username && username.length > 25
            ? (
              <TooltipComponent
                delay={250}
                position="bottom"
                text={username}
                className={classes.profUsernameTooltip}
              >
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secondary,
                  }}
                  primary={username}
                  secondary={email}
                />
              </TooltipComponent>
            )
            : (
              <ListItemText
                classes={{
                  primary: classes.primary,
                  secondary: classes.secondary,
                }}
                primary={username}
                secondary={email}
              />
            )
        }
      </ListItem>
      <ImageUploadModal open={open} onDialogClose={onDialogClose} />
    </List>
  );
};

export default UserDetails;
