import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Box from '../Box/Box';

import './Avatar.css';

const SIZES = {
  xxSmall: 'xxSmall',
  xSmall: 'xSmall',
  small: 'small',
  medium: 'medium',
};

function Avatar({ alt, src, size, children, className, dataTestId }) {
  const avatarClass = classnames('Avatar', `Avatar--${size}`, className);

  return (
    <Box className={avatarClass} dataTestId={dataTestId}>
      {src ? (
        <img className='Avatar-img' alt={alt} src={src} />
      ) : (
        children || (
          <img
            className='Avatar-img'
            alt={alt}
            src='data:image/svg+xml,<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1" y="1" width="42" height="42" rx="21" fill="%23CFD9E9"/><path fill-rule="evenodd" clip-rule="evenodd" d="M22.1378 10.0013C18.8241 10.0013 16.1378 12.6876 16.1378 16.0013C16.1378 19.315 18.8241 22.0013 22.1378 22.0013C25.4515 22.0013 28.1378 19.315 28.1378 16.0013C28.1378 12.6876 25.4515 10.0013 22.1378 10.0013ZM17.4712 16.0013C17.4712 13.424 19.5605 11.3346 22.1378 11.3346C24.7152 11.3346 26.8045 13.424 26.8045 16.0013C26.8045 18.5786 24.7152 20.668 22.1378 20.668C19.5605 20.668 17.4712 18.5786 17.4712 16.0013Z" fill="%23254B86"/><path d="M13.9081 27.2778C18.894 24.3075 25.1065 24.3075 30.0924 27.2778C31.1377 27.9006 31.778 29.0274 31.778 30.2441V33.332C31.778 33.7002 32.0765 33.9987 32.4447 33.9987C32.8129 33.9987 33.1114 33.7002 33.1114 33.332V30.2441C33.1114 28.5576 32.2237 26.9956 30.7748 26.1324C25.3684 22.9116 18.6321 22.9116 13.2257 26.1324C11.7768 26.9956 10.8891 28.5576 10.8891 30.2441V33.332C10.8891 33.7002 11.1876 33.9987 11.5558 33.9987C11.924 33.9987 12.2225 33.7002 12.2225 33.332V30.2441C12.2225 29.0274 12.8628 27.9006 13.9081 27.2778Z" fill="%23254B86"/><rect x="1" y="1" width="42" height="42" rx="21" stroke="%23F2F4F8" stroke-width="2"/></svg>'
          />
        )
      )}
    </Box>
  );
}

Avatar.propTypes = {
  size: PropTypes.oneOf([
    SIZES.xxSmall,
    SIZES.xSmall,
    SIZES.small,
    SIZES.medium,
  ]),
  src: PropTypes.string,
  alt: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

Avatar.defaultProps = {
  size: SIZES.small,
  alt: '',
  src: '',
  children: null,
  className: null,
  dataTestId: null,
};

export default Avatar;
