import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SideMenu from './SideMenu/SideMenu';
import Header from './Header/Header';
import styles from './Layout.module.css';
import { InitializeSideMenu } from '../actions/sideMenuAction';
import { InitializeProfileMenu, InitializeGetHelp } from '../actions/profileMenuAction';
import { sideMenuIsVisibleFor } from '../Constants';
import { useTheme } from '../features/AdminHome/views/BrandingConfig/utils/helper';
import {
  getActiveFavIcon, getActiveHeaderLogo, getActiveLogo,
} from '../features/AdminHome/redux/adminHome-actions';

const history = createBrowserHistory({ window });
const drawerWidth = 240;
const mobileDrawerWidth = 280;

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(55, 81, 255, 1)',
      contrastText: '#fff',
    },
    typography: {
      fontFamily: 'Rubik, sans-serif',
      fontSize: 12,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        lineHeight: 'inherit',
        border: 'none',
        borderRadius: 3,
        fontSize: 12,
        textAlign: 'center',
        letterSpacing: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
      },
      contained: {
        padding: '9px 15px',
      },
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 0,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    // backgroundColor: 'var(--DRAWER_BACKGROUND)',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-moz-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    scrollbarColor: 'transparent transparent',
  },
  drawerOpen: {
    width: drawerWidth,
    // background: 'var(--SIDEMENU-BACKGROUND)',
    color: 'var(--SIDEMENU-TEXT-COLOR)',
    overflowY: 'auto',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      // duration: theme.transitions.duration.enteringScreen,
      duration: '500ms',
    }),
    background: 'inherit',
  },
  drawerClose: {
    // background: 'var(--SIDEMENU-BACKGROUND)',
    color: 'var(--SIDEMENU-COLOR)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      // duration: theme.transitions.duration.leavingScreen,
      duration: '300ms',
    }),
    overflowX: 'hidden',
    overflowY: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: 60,
    },
    background: 'inherit',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    ...theme.mixins.toolbar,
  },
  toolbarCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    ...theme.mixins.toolbar,
  },
  toolbarMobileCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  navBar: {
    paddingTop: 0,
    height: '100vh',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-moz-scrollbar': {
      display: 'none',
    },
    paddingBottom: '90px',
  },
  searchText: {
    display: 'block',
    margin: '8px 16px',
    color: 'var(--SIDEMENU-SUBTEXT-COLOR)',
  },
  menuButtonExpanded: {
    marginLeft: '5rem',
  },
  leftNavigationFooter: {
    position: 'fixed',
    bottom: '0',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '1rem',
    color: 'var(--SIDEMENU-TEXT-COLOR)',
    background: 'var(--SIDEMENU-BACKGROUND)',
    padding: 0,
    overflow: 'hidden',
    opacity: '0',
    whiteSpace: 'break-spaces',
    height: '90px',
    wordBreak: 'break-word',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  leftNavigationFooterHide: {
    width: 0,
    padding: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      // duration: theme.transitions.duration.leavingScreen,
      duration: '300ms',
    }),
  },
  leftNavigationFooterShow: {
    width: 'inherit',
    padding: '1rem',
    opacity: '1',
  },
  notMobileFooter: {
    overflow: 'hidden',
    minWidth: '230px'
  },
  mobileDrawer: {
    width: mobileDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowY: 'hidden',
    background: 'var(--SIDEMENU-BACKGROUND)',
    // background: getBackgroundColor(),
    color: 'var(--SIDEMENU-COLOR)',
  },
}));

const getSessionMenuValue = () => {
  const openflag = window.localStorage.getItem('menuOpened');
  if (openflag === null) {
    window.localStorage.setItem('menuOpened', 'false');
    window.dispatchEvent(new Event('storage'));
  }

  return openflag;
};

const Layout = ({ children, isVisible, hideSideMenu }) => {
  const dispatch = useDispatch();
  const classes = useStyles(theme);
  const sideMenuData = useSelector((state) => state.app.sideMenu);
  const isMenuOpenedInSession = getSessionMenuValue() === 'true';
  const [expandSideMenu, setExpandSideMenu] = useState(isMenuOpenedInSession);
  const [collapseSideMenu, setCollapseSideMenu] = useState(!isMenuOpenedInSession);
  const [openSideMenu, setOpenSideMenu] = useState(isMenuOpenedInSession);
  const [backIcon, setBackIcon] = useState(false);
  const sideMenuAvailable = sideMenuData !== null && sideMenuData?.length > 0;
  const [mobileDrawer, setMobileDrawer] = React.useState(false);
  const [lsGen2ThemeState, setLSGen2ThemeState] = useState();
  const [showFooter, setShowFooter] = useState(isMenuOpenedInSession);
  const activeTheme = useSelector((state) => state.adminHome?.brandingConfig?.activeTheme) || {};
  const { logo, headerlogo } = activeTheme?.logos || {};

  const { setThemeVars } = useTheme();

  // ToDo - Do Not delete the below commented code as we need this in future. 
  // const showSideMenu = hideSideMenu
  //   || (sideMenuAvailable
  //     && sideMenuIsVisibleFor.findIndex((f) => window.location.pathname.includes(f)) > -1);

  const showSideMenu = hideSideMenu || (sideMenuAvailable);

  const addSideMenuClasses = (sideMenu) => {
    const ecmLayout = window.document.getElementById('pageLayout');
    if (ecmLayout) {
      if (!sideMenu) {
        ecmLayout.setAttribute('class', 'LayoutWithoutSideNav');
      } else if (sideMenu && openSideMenu) {
        ecmLayout.setAttribute('class', 'LayoutWithSideNavMenuOpen');
      } else if (sideMenu && !openSideMenu) {
        ecmLayout.setAttribute('class', 'LayoutWithSideNav');
      }
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setLSGen2ThemeState(localStorage.getItem('activeThemeGen2'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setLSGen2ThemeState(localStorage.getItem('activeThemeGen2'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (!sideMenuAvailable) {
      dispatch(InitializeSideMenu());
    }
    dispatch(InitializeProfileMenu());
    dispatch(InitializeGetHelp());
    setThemeVars();
  }, []);

  useEffect(() => {
    const footer = window.document.querySelector('.footer');
    if (footer) {
      footer.style.visibility = 'hidden';
    }
  }, []);

  useEffect(() => {
    if (activeTheme) {
      setThemeVars();
      dispatch(getActiveLogo());
      dispatch(getActiveHeaderLogo());
      dispatch(getActiveFavIcon());
    }
  }, [activeTheme]);

  useEffect(() => {
    addSideMenuClasses(sideMenuAvailable);
  }, [sideMenuAvailable]);

  const handleDrawerOpen = () => {
    setExpandSideMenu(true);
    setCollapseSideMenu(false);
    setShowFooter(true);
  };

  const handleDrawerClose = () => {
    if (!openSideMenu) {
      setExpandSideMenu(false);
      setCollapseSideMenu(true);
      setShowFooter(false);
    }
  };

  // on click of toggle button
  const handleDrawerClick = () => {
    setOpenSideMenu(!openSideMenu);
  };

  // on click of side menu item
  const handleDrawer = () => {
    // setOpenSideMenu(!openSideMenu);
  };

  const handleMobileDrawerClick = () => {
    setMobileDrawer(!mobileDrawer);
    setExpandSideMenu(true);
  };

  const handleDrawerClickBack = () => {
    setOpenSideMenu(!openSideMenu);
    setBackIcon(!backIcon);
  };

  useEffect(() => {
    if (openSideMenu) {
      handleDrawerOpen();
      window.localStorage.setItem('menuOpened', true);
    } else {
      handleDrawerClose();
      window.localStorage.setItem('menuOpened', false);
    }
    window.dispatchEvent(new Event('storage'));
    addSideMenuClasses(sideMenuAvailable);
  }, [openSideMenu]);

  return (
    <BrowserRouter history={history}>
      <div className={classnames(styles.page)}>
        {/* Commenting the code for future use, uncomment along with the below footer */}
        {/* <div className={styles.container}> */}
        <ScopedCssBaseline>
          <ThemeProvider theme={theme}>
            {isVisible ? (
              <Header
                classes={classes}
                isSideMenuAvailable={showSideMenu}
                expandSideMenu={expandSideMenu}
                isVisible
                handleMobileDrawerClick={handleMobileDrawerClick}
              />
            ) : null}
            {showSideMenu && isVisible ? (
              <SideMenu
                classes={classes}
                expandSideMenu={expandSideMenu}
                collapseSideMenu={collapseSideMenu}
                isSideMenuAvailable={showSideMenu}
                handleDrawerClick={handleDrawerClick}
                handleDrawer={handleDrawer}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                location={`${window.location.pathname}${window.location.search}`}
                handleDrawerClickBack={handleDrawerClickBack}
                backIcon={backIcon}
                mobileDrawer={mobileDrawer}
                handleMobileDrawerClick={handleMobileDrawerClick}
                showFooter={showFooter}
              />
            ) : null}
          </ThemeProvider>
        </ScopedCssBaseline>
        <main className={classes.content}>
          <div aria-label="content">{children}</div>
        </main>
        {/* Commenting the code for future use */}
        {/* </div> */}
        {/* <div className={styles.footer}> */}
        {/*  <Footer /> */}
        {/* </div> */}
      </div>
    </BrowserRouter>
  );
};

Layout.propTypes = {
  isVisible: PropTypes.bool,
  openSideMenu: PropTypes.bool,
  hideSideMenu: PropTypes.bool,
};

Layout.defaultProps = {
  isVisible: true,
  openSideMenu: false,
  hideSideMenu: false,
};

export default Layout;
