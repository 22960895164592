import React from 'react';
import PropTypes from 'prop-types';

import './TableCell.css';

function TableCell({ className, isHeader, dataTestId, children }) {
  return isHeader ? (
    <th
      className={`TableCellDS--headerCell ${className}`}
      data-testid={dataTestId}>
      {children}
    </th>
  ) : (
    <td className={`TableCellDS ${className}`} data-testid={dataTestId}>
      {children}
    </td>
  );
}

TableCell.propTypes = {
  className: PropTypes.string,
  isHeader: PropTypes.bool,
  dataTestId: PropTypes.string,
  children: PropTypes.node,
};
TableCell.defaultProps = {
  className: '',
  isHeader: false,
  dataTestId: null,
  children: null,
};

export default TableCell;
