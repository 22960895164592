import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Cookies } from 'react-cookie';
import { push } from 'react-router-redirect';
import { withRouter, Link } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Card, CardContent, Button, Paper, MobileStepper, Dialog } from '@material-ui/core';
import clsx from 'clsx';
import { Icon, EmptyScreen, Carousel, ModuleLink, EllipsedLabel } from 'ui-components/src';
import {
  checkEnvironment,
  replaceLinksWithCookies,
  changePageTitle,
  isJson,
} from 'ui-common/src/lib/utils';
import { matchRouteToRegExArr } from 'ui-common/src/lib/featureUtil';
import './KpiWidget.scss';
import {
  requestActionsList,
  requestImportantActionsList,
  requestUserList,
  resetRefreshKPIFlag,
} from '../redux/requestHome-actions';

import './RequestHomeDashboard.scss';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PendingActions from './PendingActions';
import KpiWidget from './KpiWidget';
import KpiSrcEdit from './KpiSrcEdit';
import MyTeams from './MyTeams';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  button: {
    fontWeight: 500,
    textAlign: 'center',
    borderRadius: '3px',
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
  },
  root: {
    // maxWidth: 400,
    flexGrow: 1,
    position: 'sticky',
    backgroundColor: `var(--home-rightpanel-bg)`,
    zIndex: 1111,
    top: 0,
    paddingLeft: 23,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },

  reviewButton: {
    width: 74,
    lineHeight: '1.25',
    padding: '4px 16px',
  },
  card: {
    padding: '0px 24px 0px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    height: '100%',
    width: '100%',
  },
  cardcontent: {
    padding: 0,
    '&:first-child': {
      paddingBottom: 0,
    },
  },
  actionButton: {
    padding: `11px 34px`,
  },
  buttonPostion: {
    bottom: '42px',
    position: 'relative',
    left: '247px',
    backgroundColor: 'transparent',
  },
  minWidth: {
    minWidth: 30,
    border: 'solid 1px #d7d7d7',
  },
  tabroot: {
    minWidth: 40,
    padding: 2,
    margin: '0 9px',
  },
  wrapper: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.1px',
  },
  wrapperNotSelected: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.1px',
    cursor: 'default',
  },
  selected: {
    color: 'var(--home-black-dark) !important',
  },
  indicator: {
    display: 'none',
  },
  indicatorSelected: {
    height: 2,
    backgroundColor: 'var(--primary-main)',
  },
  bodyone: {
    height: '100%',
  },
}));

const TabPanel = (props) => {
  const { children, value, index, bodyone, ...other } = props;

  return (
    <Typography
      classes={{ body1: bodyone }}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  );
};

TabPanel.defaultProps = {
  children: null,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};
const KPISection = React.memo(
  injectIntl(({ intl }) => {
    const [kpiSources, setKpiSources] = useState([]);
    const [addKpiOpen, setAddKpiOpen] = useState(false);
    const [newKpiCodeStr, setNewKpiCodeStr] = useState('');
    const [kpiLoading, setKpiLoading] = useState(true);
    const userRoles = useSelector((state) => state.user.userInfo.roles) || [];
    const refreshKPI = useSelector((state) => state.requestHome.initialLoad.refreshKPI) || false;
    const isKpiEditable = checkEnvironment().BETA_FEATURES
      ? checkEnvironment().BETA_FEATURES.includes('KPIEDITING')
      : false;
    useEffect(() => {
      fetchKpiSources();
    }, []);
    useEffect(() => {
      if (refreshKPI) {
        setKpiLoading(true);
        fetchKpiSources();
      }
    }, [refreshKPI]);
    const setKpiSourcesSort = (arr) => {
      setKpiSources(arr.sort((a, b) => a.id - b.id));
    };

    const fetchKpiSources = () => {
      const val = [];
      const { IDWMS_API_URL } = checkEnvironment();
      fetch(`${IDWMS_API_URL}/kpi`, {
        headers: {
          'X-NOLOADER': true,
        },
      })
        .then((res) => {
          res
            .json()
            .then((data) => {
              let count = 0;
              if (data.kpis.length === 0) setKpiLoading(false);
              data.kpis.map((k) => {
                fetch(IDWMS_API_URL + k.sourceLocation, {
                  headers: {
                    'X-NOLOADER': true,
                  },
                })
                  .then((res) => {
                    res
                      .text()
                      .then((txt) => {
                        count += 1;
                        if (res.status === 200) {
                          val.push({ ...k, source: txt });
                        }
                        if (count === data.kpis.length) {
                          setKpiSourcesSort(val);
                          setKpiLoading(false);
                        }
                      })
                      .catch((err1) => {
                        count += 1;
                        if (count === data.kpis.length) {
                          setKpiSourcesSort(val);
                          setKpiLoading(false);
                        }
                      });
                  })
                  .catch((err2) => {
                    count += 1;
                    if (count === data.kpis.length) {
                      setKpiSourcesSort(val);
                      setKpiLoading(false);
                    }
                  });
              });
            })
            .catch(() => setKpiLoading(false));
        })
        .catch((err) => setKpiLoading(false));
    };

    const deleteKpi = (kpiId) => {
      const { IDWMS_API_URL } = checkEnvironment();
      fetch(`${IDWMS_API_URL}/kpi/${kpiId}`, {
        headers: { 'X-NOLOADER': true },
        method: 'DELETE',
      }).then((res) => {
        if (res.status === 200) {
          setKpiSources(kpiSources.filter((k) => k.id !== kpiId));
        }
      });
    };
    return (
      <div className="overview">
        <div className="d-flex justify-content-between mt-3">
          <span className="h6 card-overview">
            {intl.formatMessage({ id: 'RequestHomeActivity.Overview' })}
          </span>
          {(userRoles.includes('ROLE_ADMIN') || userRoles.includes('ROLE_UIADMIN')) &&
            isKpiEditable && (
              <KpiWidget
                userName={cookies.get('user_name')}
                editable
                isNew
                kpiInfo={{
                  name: '',
                  features: [],
                  miniApp: [],
                  rank: null,
                  sourceLocation: '',
                  source: '',
                }}
              />
            )}
        </div>
        <div className="d-flex">
          {kpiLoading && (
            <div className="loaderCss">
              {[1, 2, 3].map(() => {
                return (
                  <div className="dataLoading requestAction-Tiles">
                    <div className="gridLoading loadin-image" />
                    <div className="dataLoading status-Tile-Data">
                      <h6 className="gridLoading status-count" />
                    </div>
                    <h6 className="gridLoading status-Tile tile" />
                  </div>
                );
              })}
            </div>
          )}
          {!kpiLoading && (
            <Carousel cardwidth={450} cardDist={10} style={{ zIndex: 0 }} useArrowOpacity>
              {kpiSources.map((kpiInfo) => {
                // const src = kpiSources[key];
                return (
                  <KpiWidget
                    kpiInfo={kpiInfo}
                    userName={cookies.get('user_name')}
                    editable={
                      (userRoles.includes('ROLE_ADMIN') || userRoles.includes('ROLE_UIADMIN')) &&
                      isKpiEditable
                    }
                    deleteKpi={deleteKpi}
                  />
                );
              })}
            </Carousel>
          )}
        </div>
      </div>
    );
  })
);
const RequestHomeContainer = (props) => {
  const ecmUrl = checkEnvironment().ECM_URL;
  const classes = useStyles();
  const [hoveredIcon, setHoveredIcon] = useState(['', -1, -1]);
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const [mappingForRequestHome, setMappingForRequestHome] = useState(null);
  const [importantActions, setImportantActions] = useState({
    PendingBeneficiary: 'NA',
    PendingApprovals: 'NA',
    PasswordExpiry: 'NA',
    PendingCertifications: 'NA',
  });
  const [welcomeHomeImage, setWelcomeHomeImage] = useState({ background: '', illustration: '' });
  const [username, setUserName] = useState('');
  const [activeTabs, setActiveTabs] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const IDW_ENDPOINT = checkEnvironment().IDWMS_API_URL;
  const featureList = useSelector((state) => state.user.featureNameList);
  const refreshKPI = useSelector((state) => state.requestHome.initialLoad.refreshKPI) || false;

  useEffect(() => {
    const body = document.getElementsByClassName('footer-layout')[0];
    if (body) {
      body.classList.add('left-align');
    }
  }, [props.messages]);
  useEffect(() => {
    if (refreshKPI) {
      props.resetRefreshKPIFlag();
    }
  }, [refreshKPI]);

  useEffect(() => {
    if (props.domainInfo && props.domainInfo.TILES) {
      const tiles = props.domainInfo.TILES;
      const tempTiles = JSON.parse(tiles.replace(/\\"/g, '"').replace(/\\&/g, '&')).requestHome;
      const allowedRoute = props.allowedRoute.map((r) => {
        if (r) return r.replace('/ECMv6', '').replace('/ECM', '');
        return r;
      });
      const featureList = props.featureNameList;
      if (props.blockedUrlList) {
        const blockedUrlList = props.blockedUrlList.map((r) => {
          if (r) return r.replace('/ECMv6', '').replace('/ECM', '');
          return r;
        });
        setTimeout(() => {
          setMappingForRequestHome(
            tempTiles
              .filter((item) => {
                if (item.link) {
                  return matchRouteToRegExArr({
                    url: item.featureName ? null : item.link,
                    featureName: item.featureName,
                    allowedUrls: allowedRoute,
                    allowedFeatures: featureList,
                    blockedUrl: blockedUrlList,
                    blockedFeatures: props.blockedNames,
                  });
                }
                return false;
              })
              .map((tiles) => {
                if (tiles.ECM) tiles.link = `${ecmUrl}${tiles.link}`;
                else tiles.link = `${tiles.link}`;
                tiles.link = replaceLinksWithCookies(tiles.link);
                return tiles;
              })
          );
        }, 1000);
      } else {
        setTimeout(() => {
          setMappingForRequestHome(
            tempTiles
              .filter((item) => {
                if (item.link) {
                  return matchRouteToRegExArr({
                    url: item.featureName ? null : item.link,
                    featureName: item.featureName,
                    allowedUrls: allowedRoute,
                    allowedFeatures: featureList,
                  });
                }
                return false;
              })
              .map((tiles) => {
                if (tiles.ECM) tiles.link = `${ecmUrl}${tiles.link}`;
                else tiles.link = `${tiles.link}`;
                tiles.link = replaceLinksWithCookies(tiles.link);
                return tiles;
              })
          );
        }, 1000);
      }
    } else {
      setMappingForRequestHome([]);
    }
    if (
      props.domainInfo &&
      props.domainInfo.REQUESTHOME_WELCOME_IMAGE &&
      Object.keys(props.domainInfo.REQUESTHOME_WELCOME_IMAGE).length > 0
    ) {
      setWelcomeHomeImage(JSON.parse(props.domainInfo.REQUESTHOME_WELCOME_IMAGE));
    }
  }, [props.domainInfo, props.allowedRoute]);

  useEffect(() => {
    const { BETA_FEATURES } = checkEnvironment();
    const featureLis = props.featureNameList;
    const tabfeatures = ['home.recentActivity', 'home.pendingActions', 'home.myTeams'];
    const activeTabs = [];
    // featureLis.push('home.pendingActions');
    tabfeatures.forEach((ele) => {
      if (
        matchRouteToRegExArr({
          featureName: ele,
          allowedFeatures: featureLis,
        })
      ) {
        if (BETA_FEATURES && BETA_FEATURES.includes('TEAMS_FEATURE') && ele === 'home.myTeams')
          activeTabs.push(ele);
        else if (
          BETA_FEATURES &&
          BETA_FEATURES.includes('PENDING_ACTIONS') &&
          ele === 'home.pendingActions'
        )
          activeTabs.push(ele);
        else if (ele === 'home.recentActivity') activeTabs.push(ele);
      }
    });
    setActiveTabs(activeTabs);
  }, [props.featureNameList]);

  useEffect(() => {
    // if (impActionsData.filter(item => item.type === 'PasswordExpiry').length === 0)
    // setMappingForRequestHome(null);
    const label = changePageTitle(props.intl.formatMessage({ id: 'requestHome.RequestHome' }));
    props.requestActionsList();
    props.requestUserList();
    // props.requestImportantActionsList();// not needed becos each kpi will fetch their data
    let body = document.getElementsByClassName('footer-layout')[0];
    if (body) {
      body.classList.add('left-align');
    }
    const dynamicbody = document.getElementsByClassName('dynamicModules')[0];
    if (dynamicbody) {
      dynamicbody.classList.remove('d-block');
      dynamicbody.classList.add('d-none');
    }
    return () => {
      body = document.getElementsByClassName('footer-layout')[0];
      if (body) {
        body.classList.remove('left-align');
      }
      const dynamicbody = document.getElementsByClassName('dynamicModules')[0];
      if (dynamicbody) {
        dynamicbody.classList.remove('d-none');
        dynamicbody.classList.add('d-block');
      }
    };
  }, []);

  useEffect(() => {
    if (props.userInfo) {
      let name = cookies.get('user_name');
      if (props.userInfo.firstname || props.userInfo.lastname) {
        name = `${props.userInfo.firstname || ''} ${props.userInfo.lastname || ''}`;
      }
      setUserName(name);
    }
  }, [props.userInfo]);

  const addDefaultSrc = (e) => {
    e.target.style.display = 'none';
  };

  const ImageUrl = `${IDW_ENDPOINT}/resource/image/app/`;
  useEffect(() => {
    if (props.actionsitems.PendingBeneficiary) {
      const finalObj = {
        PendingBeneficiary: 'NA',
        PendingApprovals: 'NA',
        PasswordExpiry: 'NA',
        PendingCertifications: 'NA',
      };
      if (
        props.actionsitems.PendingBeneficiary.response ||
        props.actionsitems.PendingBeneficiary.response === 0
      )
        finalObj.PendingBeneficiary = props.actionsitems.PendingBeneficiary.response;
      if (
        props.actionsitems.PendingApprovals.response ||
        props.actionsitems.PendingApprovals.response === 0
      )
        finalObj.PendingApprovals = props.actionsitems.PendingApprovals.response;
      if (
        props.actionsitems.PasswordExpiry.response ||
        props.actionsitems.PasswordExpiry.response === 0
      ) {
        finalObj.PasswordExpiry = props.actionsitems.PasswordExpiry.response;
      }
      if (
        props.actionsitems.PendingCertifications.response ||
        props.actionsitems.PendingCertifications.response === 0
      ) {
        finalObj.PendingCertifications = props.actionsitems.PendingCertifications.response;
      }
      setImportantActions(finalObj);
    }
  }, [props.actionsitems]);

  const removeCharacter = (str) => {
    if (str) {
      const removeSpace = str.trim().toUpperCase();
      return removeSpace.slice(0, 1);
    }
    return null;
  };

  return (
    <div className="requestHome privilegedAccounts box-layout">
      <div className="request-home-container">
        <div className="requesthome-content">
          <div
            className={`requesthome-welcome disable-scrollbars ${
              activeTabs.length === 0 ? 'home-full-width' : ''
            } `}
          >
            <div
              className="requesthome-header"
              style={{
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${ImageUrl}${welcomeHomeImage.background})`,
              }}
            >
              <img
                className="illustration mr-3"
                src={`${ImageUrl}${welcomeHomeImage.illustration}`}
                alt=" "
              />

              <div className="welcometext-part">
                <>
                  <h1 className="request-welcome-user">
                    {`${props.intl.formatMessage({
                      id: 'RequestHomeWelcome',
                    })}`}{' '}
                    {username}
                  </h1>
                  <p className="home-priorityactions">
                    <h2 className="request-hometitle">
                      {`${props.intl.formatMessage({
                        id: 'RequestHomeTitle',
                      })} ${cookies.get('user_name')}`}
                    </h2>
                  </p>
                </>
              </div>
              <KPISection />
            </div>
            <div className="request-feature">
              <div className="reqeust-cards">
                {mappingForRequestHome === null && (
                  <div>
                    <>
                      {[1].map((row) => (
                        <div key={row} className="d-flex">
                          {[1, 2, 3].map((column) => (
                            <div
                              className="feature-content text-center dummy"
                              role="button"
                              tabIndex="0"
                            >
                              <div className="loaderCss loaderdummy" key={column}>
                                <div className="imgIcon" />
                                <div className="dataLoading dataLoadingdummy">
                                  <div className="gridLoading mb-2" />
                                  <div className="gridLoading" />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </>
                  </div>
                )}
                {mappingForRequestHome &&
                  mappingForRequestHome.map((mappedItem, index) => {
                    if (mappedItem.ECM)
                      return (
                        <a className="col pr-0 mb-4 tile-width" href={mappedItem.link}>
                          <div key={mappedItem.label} className="d-flex ">
                            <div className="feature-content text-center" role="button">
                              <div className="feature-img">
                                <svg>
                                  <use xlinkHref={`${ImageUrl}${mappedItem.icon}.svg#V3`} />
                                </svg>
                              </div>
                              <h5 className="feature-title">
                              <EllipsedLabel
                                linesToDisplay={2}
                                label={props.intl.formatMessage({
                                  id: mappedItem.label,
                                })}
                              />
                              </h5>
                              <p className="feature-description mb-0">
                              <EllipsedLabel
                                linesToDisplay={2}
                                label={props.intl.formatMessage({
                                  id: mappedItem.description,
                                })}
                              />
                              </p>
                            </div>
                          </div>
                        </a>
                      );

                    return (
                      <Link to={mappedItem.link} className="col pr-0 mb-4 tile-width">
                        <div key={mappedItem.label} className="d-flex ">
                          <div className="feature-content text-center" role="button">
                            <div className="feature-img">
                              <svg>
                                <use xlinkHref={`${ImageUrl}${mappedItem.icon}.svg#V3`} />
                              </svg>
                            </div>
                            <h5 className="feature-title">
                            <EllipsedLabel
                                linesToDisplay={2}
                                label={props.intl.formatMessage({
                                  id: mappedItem.label,
                                })}
                              />
                            </h5>
                            <p className="feature-description mb-0">
                              <EllipsedLabel
                                linesToDisplay={2}
                                label={props.intl.formatMessage({
                                  id: mappedItem.description,
                                })}
                              />
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                    // }
                  })}
              </div>
            </div>
          </div>
          <div
            className={
              activeTabs.length === 0 ? 'd-none' : 'requesthome-container disable-scrollbars'
            }
          >
            <div className="requesthome-activity disable-scrollbars">
              <div className="recent-activity">
                <Tabs
                  variant="standard"
                  classes={{
                    indicator: classes.indicatorSelected,
                    flexContainer: classes.flexContainer,
                    root: classes.root,
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  {activeTabs.includes('home.recentActivity') && (
                    <Tab
                      label={props.intl.formatMessage({
                        id: 'RequestHomeActivity',
                      })}
                      classes={
                        checkEnvironment().DEMO_MODE === 'ON'
                          ? {
                              wrapper: classes.wrapper,
                              root: classes.tabroot,
                              selected: classes.selected,
                            }
                          : {
                              wrapper: classes.wrapperNotSelected,
                              root: classes.tabroot,
                              selected: classes.selected,
                            }
                      }
                      tabIndex="0"
                    />
                  )}
                  {activeTabs.includes('home.pendingActions') && (
                    <Tab
                      label={props.intl.formatMessage({ id: 'RequestHome.PendingTasks' })}
                      classes={{
                        wrapper: classes.wrapper,
                        root: classes.tabroot,
                        selected: classes.selected,
                      }}
                      tabIndex="0"
                    />
                  )}
                  {activeTabs.includes('home.myTeams') && props.count > 0 && (
                    <Tab
                      label={props.intl.formatMessage({ id: 'RequestHome.MyTeams' })}
                      classes={{
                        wrapper: classes.wrapper,
                        root: classes.tabroot,
                        selected: classes.selected,
                      }}
                      tabIndex="0"
                    />
                  )}
                </Tabs>
                {/* <h4 className="activity-title mb-0">
                    {`${props.intl.formatMessage({
                      id: 'RequestHomeActivity',
                    })}`}
                  </h4> */}
                {activeTabs.includes('home.recentActivity') && (
                  <TabPanel
                    value={value}
                    index={activeTabs.indexOf('home.recentActivity')}
                    bodyone={classes.bodyone}
                  >
                    <>
                      {props.loading && (
                        <>
                          {[1, 2, 3, 4, 5, 6].map((row) => (
                            <div key={row}>
                              {[1].map((column) => (
                                <div className="loaderCss" key={column}>
                                  <div className="imgIcon" />
                                  <div className="dataLoading">
                                    <div className="gridLoading mb-2" />
                                    <div className="gridLoading" />
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                        </>
                      )}
                      {!props.loading && props.noRecords && (
                        <EmptyScreen
                          title="UserActivity.EmptyScreen.Title"
                          Description="UserActivity.EmptyScreen.SubTitle"
                          iconName="RequestHomeIllustration.svg"
                          redirectText="UserActivity.EmptyScreen.RedirectionText"
                          insidePanel
                          redirectLink="Request.EmptyScreen.RedirectLink"
                          footer="Request.EmptyScreen.footer"
                        />
                      )}
                      {props.activityItems.announcement && (
                        <div className="recent-announcement" role="presentation">
                          <div className="announcement-title d-flex justify-content-between">
                            <h4 className="title mb-0">
                              {`${props.intl.formatMessage({
                                id: 'RequestHomeAnnouncement',
                              })}`}
                            </h4>
                            <h4 className="just-now mb-0">
                              {props.activityItems.announcement.time}
                            </h4>
                          </div>
                          <div className="announcement-content d-flex">
                            <div className="announcement-icon">
                              <div>
                                <img
                                  src="/ECMv6/assets/images/request_home_icons/announcement.svg"
                                  alt="announcement"
                                />
                              </div>
                            </div>

                            <p className="announcement-description mb-0">
                              {props.activityItems.announcement.title}
                            </p>
                          </div>
                        </div>
                      )}

                      {props.activityItems &&
                        props.activityItems.map((item, itemIndx) => (
                          <div
                            className={`recent-notification ${
                              item.activities.length === 0 ? 'd-none' : ''
                            }`}
                          >
                            {/* <h6 className="title mb-0">{item.acitivityType}</h6> */}
                            {item.activities.map((announcement, announcementIndex) => (
                              <div
                                className="notification-content d-flex row"
                                tabIndex="0"
                                role="button"
                                onMouseEnter={() =>
                                  setHoveredIcon([
                                    announcement.connectionType,
                                    itemIndx,
                                    announcementIndex,
                                  ])
                                }
                                onMouseLeave={() => setHoveredIcon(['', -1, -1])}
                              >
                                <div className="notification-icon col-2 p-0 mr-1">
                                  {announcement.entityType !== 'Enterprise Role' && (
                                    <div className="item-img">
                                      <img
                                        className="icon-recent"
                                        name="default"
                                        src={`${IDW_ENDPOINT}/resource/image/app/${announcement.imageId}`}
                                        onError={addDefaultSrc}
                                        alt="icon"
                                      />
                                      <p className="app-initial">
                                        {removeCharacter(announcement.applicationName)}
                                      </p>
                                    </div>
                                  )}

                                  {announcement.entityType === 'Enterprise Role' && (
                                    <div className="item-img">
                                      <img
                                        className="erproleicon"
                                        src="/ECMv6/assets/images/app_req_icons/EnterpriseRole.svg"
                                        alt="EnterpriseRole Icon"
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="content-section col-8 p-0">
                                  <h5 className="notification-title mb-0">{announcement.title}</h5>
                                  <p className="notification-description mb-0">
                                    {announcement.description}
                                  </p>
                                </div>
                                {checkEnvironment().DEMO_MODE !== 'PROD' && (
                                  <div className="review-btn">
                                    <Button
                                      variant="outlined"
                                      fontSize="small"
                                      className={clsx(classes.button, classes.reviewButton)}
                                    >
                                      {`${props.intl.formatMessage({
                                        id: 'RequestHomeReview',
                                      })}`}
                                    </Button>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ))}
                    </>
                  </TabPanel>
                )}

                {activeTabs.includes('home.pendingActions') && (
                  <TabPanel
                    value={value}
                    index={activeTabs.indexOf('home.pendingActions')}
                    bodyone={classes.bodyone}
                  >
                    {value === activeTabs.indexOf('home.pendingActions') && <PendingActions />}
                  </TabPanel>
                )}
                {activeTabs.includes('home.myTeams') && props.count > 0 && (
                  <TabPanel value={value} index={activeTabs.indexOf('home.myTeams')}>
                    {value === activeTabs.indexOf('home.myTeams') && <MyTeams />}
                  </TabPanel>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RequestHomeContainer.propTypes = {
  requestActionsList: PropTypes.func.isRequired,
  requestImportantActionsList: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  actionsitems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      connectionType: PropTypes.string.isRequired,
    })
  ).isRequired,
  activityItems: PropTypes.arrayOf({
    activities: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  }).isRequired,
  noRecords: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  tiles: PropTypes.string,
  allowedRoute: PropTypes.arrayOf(PropTypes.string),
  userInfo: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }),
  featureNameList: PropTypes.arrayOf(PropTypes.string),
};

RequestHomeContainer.defaultProps = {
  allowedRoute: [],
  tiles: null,
  welcomeimage: {},
  featureNameList: [],
};

const mapStateToProps = (state) => {
  return {
    actionsitems: state.requestHome.initialLoad.actionsitems,
    activityItems: state.requestHome.initialLoad.activityItems,
    noRecords: state.requestHome.initialLoad.noRecords,
    loading: state.requestHome.initialLoad.loading,
    domainInfo: state.user.domainInfo,
    allowedRoute: state.user.userModules,
    featureNameList: state.user.featureNameList,
    userInfo: state.user.userInfo,
    loadingImpActions: state.requestHome.initialLoad.loadingImpActions,
    count: state.requestHome.initialLoad.userList.count,
    messages: state.user.messages,
    blockedUrlList: state.user.blockedUrlList,
    blockedNames: state.user.blockedNames,
  };
};

const mapDispatchToProps = {
  requestActionsList,
  requestImportantActionsList,
  requestUserList,
  resetRefreshKPIFlag,
};

const ConnectedRequestHomeContainer = injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestHomeContainer))
);
ConnectedRequestHomeContainer.displayName = 'ConnectedRequestHomeContainer';
export default ConnectedRequestHomeContainer;
