import React, { useEffect, useState } from 'react';
import styles from './LayoutWrapper.module.css';

const LayoutWrapper = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(window.localStorage.getItem('menuOpened'));

  useEffect(() => {
    const handleEvent = () => {
      setMenuOpen(window.localStorage.getItem('menuOpened'));
    };

    window.addEventListener('storage', handleEvent);

    return () => {
      window.removeEventListener('storage', handleEvent);
    };
  }, []);

  const classes = window.location.href?.includes('/login') ? styles.menuClosed : isMenuOpen === 'true' ? styles.menuOpen : styles.menuClosed;
  const isNewSidebarClass = window.location.href?.includes('/login') ? '' : styles.sideBarSpacing;

  return <div className={`${classes} ${isNewSidebarClass} ${isNewSidebarClass ? 'sideBarSpacing' : ''} ${isMenuOpen === 'true' ? 'Gen2NavMenuOpen' : ''}`}>{children}</div>;
};

export default LayoutWrapper;
