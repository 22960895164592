import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import TabItem from '../TabItem/TabItem';

import './TabGroup.css';

function TabGroup({ value, onChange, children, className }) {
  const classes = classNames('TabGroup', className);

  return (
    <div className={classes}>
      {React.Children.map(children, (child) => {
        if (child.type === TabItem) {
          return React.cloneElement(child, {
            isActive: value === child.props.value,
            onClick: () => onChange(child.props.value),
          });
        }

        return child;
      })}
    </div>
  );
}

TabGroup.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default TabGroup;
