import React, { cloneElement, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import useOutsideClickEffect from '../../hooks/use-outsideclickeffect';
import ButtonCore from '../Button/ButtonCore/ButtonCore';
import Icon from '../Icon/Icon';

import MenuItem from './MenuItem/MenuItem';

import './Menu.css';

const KINDS = {
  default: 'default',
  simple: 'simple', // i.e., pagination number dropdown
};

/**
 * Menu renders a simple 'button' and dropdown list.
 *
 * #### WIP Todo (CPAM):
 * - Finish styling.
 * - Expand menu up/down logic (if applicable).
 */

function Menu({ options, label, trigger, onChange, kind }) {
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef(null);

  useOutsideClickEffect(wrapperRef, () => setIsOpen(false), isOpen);

  const selectedElement = options.find((option) => option.isChecked);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const renderTrigger = () =>
    trigger ? (
      cloneElement(trigger, {
        isOpen,
        onClick: toggleDropdown,
        dataTestId: 'menu-trigger',
      })
    ) : (
      <ButtonCore
        type='button'
        className='Menu-button'
        onClick={toggleDropdown}
        dataTestId='menu-trigger'
        aria-pressed={isOpen}>
        {label || selectedElement?.label || options?.[0]?.label}
        <Icon
          kind='ButtonSelectChevronDown'
          size='xxxSmall'
          rotate={isOpen ? '180' : null}
        />
      </ButtonCore>
    );

  const renderMenuItems = () =>
    options.map((option) => (
      <React.Fragment key={option.value}>
        <MenuItem
          key={option.value}
          option={option}
          onClick={() => {
            !option.isDisabled && handleOptionClick(option);
          }}
          isDisabled={option.isDisabled}
          tabIndex={isOpen ? 0 : -1}
        />
      </React.Fragment>
    ));

  return (
    <div
      className={classnames('Menu', `Menu--${kind}`)}
      ref={wrapperRef}
      role='combobox'
      aria-controls='custom-select-listbox'
      aria-expanded={isOpen}
      aria-label={label}>
      {renderTrigger()}
      <div
        className={classnames(
          'Menu-dropdown',
          isOpen && 'Menu-dropdown--isVisible'
        )}>
        <ul className='Menu-items' role='listbox' tabIndex='-1'>
          {renderMenuItems()}
        </ul>
      </div>
    </div>
  );
}

Menu.propTypes = {
  kind: PropTypes.oneOf(Object.values(KINDS)),
  label: PropTypes.string,
  trigger: PropTypes.element,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      isDisabled: PropTypes.bool,
      isChecked: PropTypes.bool,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

Menu.defaultProps = {
  kind: 'default',
  label: '',
  trigger: null,
};

export default Menu;
