import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ButtonCore from '../Button/ButtonCore/ButtonCore';
import Icon from '../Icon/Icon';

import './Link.css';

const KINDS = {
  link: 'link',
  button: 'button',
};
const COLORS = {
  brand: 'brand',
  neutral: 'neutral',
  neutralInverse: 'neutralInverse',
};
const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};
const TARGETS = {
  _blank: '_blank',
  _self: '_self',
  _parent: '_parent',
  _top: '_top',
};

const handleKeyDown = (e) => {
  if (e.key === 'Enter' || e.key === ' ') {
    e.preventDefault();
    e.currentTarget.querySelector('a').click();
  }
};

function Link({
  kind,
  color,
  size,
  RouterComponent,
  url,
  text,
  target,
  rel,
  prefixIconKind,
  suffixIconKind,
  className,
  onClick,
  shouldStopPropagation,
  isDisabled,
  tabIndex,
}) {
  const handleClick = (e) => {
    if (isDisabled) {
      return;
    }

    if (shouldStopPropagation) {
      e.stopPropagation();
    }

    if (kind === 'link' && !onClick) {
      return;
    }

    onClick?.(e);
  };

  const classes = classnames(
    'Link',
    `Link--${color}`,
    `Link--${size}`,
    isDisabled && 'Link--isDisabled',
    className
  );
  const buttonClasses = classnames(
    'ButtonLink',
    `ButtonLink--${size}`,
    isDisabled && 'ButtonLink--isDisabled',
    className
  );

  const renderedPrefixIcon = prefixIconKind && (
    <Icon className='Link-icon' kind={prefixIconKind} />
  );

  const renderedSuffixIcon = suffixIconKind && (
    <Icon className='Link-icon' kind={suffixIconKind} />
  );

  const renderedLinkContent = (
    <>
      {renderedPrefixIcon}
      <span className='Link-textWrapper'>{text}</span>
      {renderedSuffixIcon}
    </>
  );

  if (kind === 'button') {
    return (
      <ButtonCore
        className={buttonClasses}
        onClick={(e) => handleClick(e)}
        tabIndex={isDisabled ? -1 : tabIndex}
        role='button'
        aria-label={text}
        onKeyDown={handleKeyDown}>
        {renderedLinkContent}
      </ButtonCore>
    );
  }

  if (RouterComponent) {
    return (
      <span
        className={classes}
        tabIndex={isDisabled ? -1 : tabIndex}
        role='link'
        aria-label={text}
        onKeyDown={handleKeyDown}>
        <RouterComponent
          to={url}
          className='Link-router Link-wrapper'
          onClick={(e) => handleClick(e)}
          aria-label={text}
          target={target}
          rel={rel}
          tabIndex='-1'>
          {renderedLinkContent}
        </RouterComponent>
      </span>
    );
  }

  return (
    <span
      className={classes}
      tabIndex={isDisabled ? -1 : tabIndex}
      role='link'
      aria-label={text}
      onKeyDown={handleKeyDown}>
      <a
        href={url}
        className='Link-router Link-wrapper'
        onClick={(e) => handleClick(e)}
        aria-label={text}
        target={target}
        rel={rel}
        tabIndex='-1'>
        {renderedLinkContent}
      </a>
    </span>
  );
}

Link.propTypes = {
  RouterComponent: PropTypes.elementType,
  kind: PropTypes.oneOf(Object.values(KINDS)),
  color: PropTypes.oneOf(Object.values(COLORS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  url: PropTypes.string,
  text: PropTypes.string.isRequired,
  target: PropTypes.oneOf(Object.values(TARGETS)),
  rel: PropTypes.string,
  prefixIconKind: PropTypes.string,
  suffixIconKind: PropTypes.string,
  onClick: PropTypes.func,
  shouldStopPropagation: PropTypes.bool,
  isDisabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  className: PropTypes.string,
};

Link.defaultProps = {
  RouterComponent: null,
  kind: KINDS.link,
  color: COLORS.brand,
  size: SIZES.medium,
  url: null,
  target: TARGETS._blank,
  rel: 'noopener noreferrer',
  prefixIconKind: null,
  suffixIconKind: null,
  onClick: null,
  shouldStopPropagation: false,
  isDisabled: false,
  tabIndex: 0,
  className: null,
};

export default Link;
