import './ByTypeDateAndTimePicker.css';

import { getCurrentDate, localize } from '@saviynt/common';
import { DateAndTimePicker } from '@saviynt/design-system';
import { addSeconds, isSameDay, isToday } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { DurationAccordionSnapshot } from '../../../../models/PamModels';
import { MINUTE_INTERVAL, msgs } from '../../constants';

function ByTypeDateAndTimePicker({
  type,
  isNowTab,
  futureTabStartDate,
  setFutureTabStartDate,
  futureTabEndDate,
  setFutureTabEndDate,
  isDurationCustom,
  isExpandedDuration,
  durationChipValue,
  rangeMaxDate,
  futureDatesToExclude,
  futureTimesToExclude,
  accordionValueSnapshot,
}) {
  const [isPassedTimeFilter, setIsPassedTimeFilter] = useState(false);
  const startTimeMin = { minutes: 0, hours: 0 };
  const startTimeMax = { minutes: 45, hours: 23 };
  const endTimeMin = { minutes: 0, hours: 0 };
  const endTimeMax = { minutes: 45, hours: 23 };
  const [intervalsToExclude, setIntervalsToExclude] = useState([]);
  const intl = useIntl();

  const START_DATE = localize(
    intl,
    msgs.pam.modalPage.dateAndTimePicker.startDate
  );
  const END_DATE = localize(intl, msgs.pam.modalPage.dateAndTimePicker.endDate);
  const START_TIME = localize(
    intl,
    msgs.pam.modalPage.dateAndTimePicker.startTime
  );
  const END_TIME = localize(intl, msgs.pam.modalPage.dateAndTimePicker.endTime);

  const roundToNextInterval = (dateToRound) => {
    const roundedDate = new Date(dateToRound);
    const minutesToAdd =
      MINUTE_INTERVAL - (roundedDate.getMinutes() % MINUTE_INTERVAL);

    roundedDate.setMinutes(roundedDate.getMinutes() + minutesToAdd);
    roundedDate.setSeconds(0);

    return roundedDate;
  };

  const timeSetterForStartDate = (date) => {
    const currentTime = getCurrentDate();

    if (isToday(date)) {
      const roundedDate = roundToNextInterval(currentTime);

      setFutureTabStartDate(roundedDate);
    } else {
      setFutureTabStartDate(date);
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  useEffect(() => {
    const hasStartDateAndChipSelected =
      !isDurationCustom && durationChipValue && futureTabStartDate;

    // Set the startTimeMin on today selection
    if (isToday(futureTabStartDate)) {
      setIsPassedTimeFilter(true);
    } else {
      setIsPassedTimeFilter(false);
    }

    if (hasStartDateAndChipSelected)
      setFutureTabEndDate(addSeconds(futureTabStartDate, durationChipValue));
  }, [futureTabStartDate]);

  useEffect(() => {
    // Filter out the times to exclude that match the day of futureTabStartDate
    const timesForThatDay = futureTimesToExclude.filter((date) =>
      isSameDay(date, futureTabStartDate)
    );

    setIntervalsToExclude(timesForThatDay);
  }, [futureTimesToExclude, futureTabStartDate]);

  useEffect(() => {
    // If the user is editing and there current time moves past the earliest interval. Move to the next one on open.
    const currentTime = getCurrentDate();

    if (
      isExpandedDuration &&
      accordionValueSnapshot?.futureTabStartDate < currentTime
    )
      timeSetterForStartDate(currentTime);
  }, [isExpandedDuration]);

  if (isNowTab) return null;

  if (type === 'start') {
    return (
      <div className='MpaTimeAccessDuration-dateInputs'>
        {/* Start Date */}
        <DateAndTimePicker
          kind='dateStart'
          label={START_DATE}
          startDate={futureTabStartDate}
          setStartDate={timeSetterForStartDate}
          maxDate={rangeMaxDate}
          minDate={new Date()}
          backgroundColor='secondary'
          excludeDates={futureDatesToExclude}
        />
        {/* Start Time */}
        <DateAndTimePicker
          kind='timeStart'
          label={START_TIME}
          startDate={futureTabStartDate}
          setStartDate={setFutureTabStartDate}
          maxDate={rangeMaxDate}
          minDate={new Date()}
          timeIntervals={MINUTE_INTERVAL}
          dateFormat='h:mm aa'
          timeHeaderText=''
          timeMin={startTimeMin}
          timeMax={startTimeMax}
          filterTime={isPassedTimeFilter ? filterPassedTime : null}
          backgroundColor='secondary'
          excludeTimes={intervalsToExclude}
        />
      </div>
    );
  }

  if (type === 'end') {
    return (
      <div className='MpaTimeAccessDuration-dateInputs'>
        <DateAndTimePicker
          kind='dateEnd'
          label={END_DATE}
          isReadOnly
          startDate={futureTabStartDate}
          setStartDate={setFutureTabStartDate}
          endDate={futureTabEndDate}
          setEndDate={setFutureTabEndDate}
          maxDate={rangeMaxDate}
          minDate={new Date()}
          backgroundColor='secondary'
        />
        <DateAndTimePicker
          kind='timeEnd'
          label={END_TIME}
          isReadOnly
          startDate={futureTabStartDate}
          setStartDate={setFutureTabStartDate}
          endDate={futureTabEndDate}
          setEndDate={setFutureTabEndDate}
          maxDate={rangeMaxDate}
          minDate={new Date()}
          timeIntervals={MINUTE_INTERVAL}
          dateFormat='h:mm aa'
          timeHeaderText=''
          timeMin={endTimeMin}
          timeMax={endTimeMax}
          backgroundColor='secondary'
        />
      </div>
    );
  }

  return null;
}

ByTypeDateAndTimePicker.propTypes = {
  type: PropTypes.string.isRequired,
  isNowTab: PropTypes.bool.isRequired,
  futureTabStartDate: PropTypes.instanceOf(Date),
  setFutureTabStartDate: PropTypes.func.isRequired,
  futureTabEndDate: PropTypes.instanceOf(Date),
  setFutureTabEndDate: PropTypes.func.isRequired,
  isDurationCustom: PropTypes.bool.isRequired,
  isExpandedDuration: PropTypes.bool,
  rangeMaxDate: PropTypes.instanceOf(Date),
  durationChipValue: PropTypes.number,
  futureDatesToExclude: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  futureTimesToExclude: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  accordionValueSnapshot: PropTypes.shape(DurationAccordionSnapshot),
};

ByTypeDateAndTimePicker.defaultProps = {
  futureTabStartDate: null,
  futureTabEndDate: null,
  isExpandedDuration: false,
  rangeMaxDate: null,
  durationChipValue: null,
  futureDatesToExclude: [],
  futureTimesToExclude: [],
  accordionValueSnapshot: null,
};

export default ByTypeDateAndTimePicker;
