import { checkEnvironment } from '@saviynt/common';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

function SessionLauncher({ session }) {
  const launchFormRef = useRef(null);
  const {
    WETTY_LINK_WINDOWS: windowsUrl,
    WETTY_LINK_SSH: sshUrl,
    WETTY_LINK_DATABASE: databaseUrl,
  } = checkEnvironment();

  const CONNECTION_TYPES = {
    SSH: 'SSH',
    RDS: 'RDS',
    WINDOWS: 'windows',
    DB: 'db',
    TERMINAL: 'terminal',
  };

  useEffect(() => {
    if (launchFormRef) {
      launchFormRef.current.submit();
    }
  }, [session, launchFormRef]);

  if (!session) {
    return null;
  }

  const osTypeLower = session.osType?.toLowerCase();
  let wettyLink = sshUrl;
  let connection = CONNECTION_TYPES.SSH;

  if (session.remoteAppMetadata !== undefined) {
    if (
      session.remoteAppMetadata.nativeLaunch?.toLowerCase() ===
      CONNECTION_TYPES.SSH.toLowerCase()
    ) {
      wettyLink = sshUrl;
    } else if (
      session.remoteAppMetadata?.displayName?.toLowerCase() ===
      CONNECTION_TYPES.TERMINAL
    ) {
      wettyLink = databaseUrl;
      connection = CONNECTION_TYPES.RDS;
    } else {
      wettyLink = windowsUrl;
      connection = windowsUrl;
    }
  } else if (osTypeLower === CONNECTION_TYPES.WINDOWS) {
    wettyLink = windowsUrl;
    connection = windowsUrl;
  } else if (osTypeLower === CONNECTION_TYPES.DB) {
    wettyLink = databaseUrl;
    connection = CONNECTION_TYPES.RDS;
  }

  return (
    <form
      ref={launchFormRef}
      name='requestnowactive'
      target='_blank'
      id='requestnowactive'
      method='post'
      useToken='true'
      action={wettyLink}>
      <input
        type='hidden'
        name='connection'
        id='connection'
        value={connection}
      />
      <input
        type='hidden'
        name='accesskey'
        id='accesskey'
        value={session.accessKey}
      />
      <input
        type='hidden'
        name='requestid'
        id='requestid'
        value={session.requestId}
      />
      <input
        type='hidden'
        name='userkey'
        id='userkey'
        value={session.userKey}
      />
      {session.ipValue && (
        <input
          type='hidden'
          name='workloadIp'
          id='workloadIp'
          value={session.ipValue}
        />
      )}
      {session.portValue && (
        <input
          type='hidden'
          name='portNumber'
          id='portNumber'
          value={session.portValue}
        />
      )}
    </form>
  );
}

SessionLauncher.propTypes = {
  session: PropTypes.shape({
    osType: PropTypes.string,
    remoteAppMetadata: PropTypes.shape({
      nativeLaunch: PropTypes.string,
      displayName: PropTypes.string,
    }),
    accessKey: PropTypes.string.isRequired,
    requestId: PropTypes.string.isRequired,
    userKey: PropTypes.number.isRequired,
  }).isRequired,
};

export default SessionLauncher;
