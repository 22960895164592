import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../Icons/Icon';

import styles from './SidePanel.module.css';

function SidePanel({
  dataTestId,
  className,
  isOpen,
  footerActionBarChildren,
  onClose,
  isScrolled,
  layout,
  header,
}) {
  const classes = classnames(styles.SidePanel, className);

  return (
    <>
      {isOpen && (
        <div id='SidePanel' className={classes} data-testid={dataTestId}>
          <div className={styles.SidePanelHeader}>
            <div className='SidePanel-header-text'>
              <h4 className={styles.SidePanelHeaderTitle}>{header.title}</h4>
              <div className='SidePanel-header-description'>
                {header.description}
              </div>
            </div>
            <div>
                <span className={styles.SidePanelClose} onClick={onClose}><Icon kind='close' color='neutral-100' size='medium' /></span>
            </div>
          </div>
          <div className={styles.SidePanelContent}>{layout}</div>
          <div className={styles.SidePanelFooter}>
              {footerActionBarChildren}
          </div>
        </div>
      )}
    </>
  );
}

SidePanel.propTypes = {
  dataTestId: PropTypes.string,
  isOpen: PropTypes.bool,
  layout: PropTypes.element,
  footerActionBarChildren: PropTypes.node,
  onClose: PropTypes.func,
  isScrolled: PropTypes.bool,
  header: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  className: PropTypes.string,
};

SidePanel.defaultProps = {
  dataTestId: null,
  isOpen: false,
  layout: null,
  footerActionBarChildren: null,
  onClose: () => {},
  isScrolled: false,
  header: null,
  className: null,
};

export default SidePanel;
