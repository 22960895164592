import React from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import BackChevronIcon from '../../ECMv6/assets/BackChevronIcon';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  icon: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: '0',
    marginLeft: '-15px',
    marginRight: '2px',
  },
});
const BackChevron = ({ isVisible, handleBackClick }) => {
  const classes = useStyles();

  if (isVisible) {
    return (
      <ListItemIcon classes={{ root: classes.icon }} onClick={handleBackClick}>
        <BackChevronIcon fontSize="small" htmlColor="var(--SIDEMENU-ICON-BACKCHEVRON)" />
      </ListItemIcon>
    );
  }
  return null;
};

export default BackChevron;
