import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './FooterActionBar.css';

function FooterActionBar({
  isDarkMode,
  size,
  children,
  isScrolled,
  dataTestId,
  className,
}) {
  const classes = classnames(
    'FooterActionBar',
    `FooterActionBar--${size}`,
    isScrolled && 'FooterActionBar--isScrolled',
    className
  );

  return (
    <div id='FooterActionBar' className={classes} data-testid={dataTestId}>
      {children}
    </div>
  );
}

FooterActionBar.propTypes = {
  isDarkMode: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large']),
  children: PropTypes.node.isRequired,
  isScrolled: PropTypes.bool,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

FooterActionBar.defaultProps = {
  isDarkMode: false,
  size: 'large',
  isScrolled: false,
  dataTestId: null,
  className: null,
};

export default FooterActionBar;
