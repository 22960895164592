import React from 'react';
import PropTypes from 'prop-types';

import Description from '../ListRowHelpers/Description/Description';
import Title from '../ListRowHelpers/Title/Title';

import './ListRowTarget.css';

function ListRowTarget({ title, description }) {
  return (
    <div className='ListRowTarget-data'>
      <div className='ListRowTarget-data-title'>{title.length > 0 && <Title data={title} />}</div>
      <div className='ListRowTarget-data-description'>
        {description.length > 0 && <Description data={description} />}
      </div>
    </div>
  );
}

ListRowTarget.propTypes = {
  title: PropTypes.arrayOf(
    PropTypes.shape({
      kind: PropTypes.oneOf(['text', 'label-value']),
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  description: PropTypes.arrayOf(
    PropTypes.shape({
      kind: PropTypes.oneOf(['icon-text', 'avatar-text', 'label-value', 'text'])
        .isRequired,
      icon: PropTypes.element,
      text: PropTypes.string,
      avatar: PropTypes.element,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

ListRowTarget.defaultProps = {
  title: [],
  description: [],
};

export default ListRowTarget;
