import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import { useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SideMenuIcon from '../../ECMv6/assets/SideMenuIcon';
import ResponsiveBackIcon from '../../ECMv6/assets/ResponsiveBackIcon';
import Logo from '../Logo/Logo';
import SearchBar from '../SearchBar/SearchBar';
import { homePageUrl } from '../../Constants';
import ChildMenu from '../ChildMenu/ChildMenu';
import SearchResults from '../SearchResults/SearchResults';
import { Footer } from '../Footer';

const SideMenu = ({
  classes,
  expandSideMenu,
  collapseSideMenu,
  handleDrawer,
  handleDrawerClick,
  location,
  isSideMenuAvailable,
  handleDrawerOpen,
  handleDrawerClose,
  backIcon,
  handleDrawerClickBack,
  mobileDrawer,
  handleMobileDrawerClick,
  showFooter,
}) => {
  const IsMobileDevice = useMediaQuery('(max-width:480px)');
  const data = useSelector((state) => state.app.sideMenu);
  const sideMenuLoading = useSelector((state) => state.app.sideMenuLoading);
  const [topLevelMenuOpen, setTopLevelMenuOpen] = useState(false);

  // to capture menu id when user browses through side menu, but does not click on link
  const [selectedMenuId, setSelectedMenuId] = useState('');

  const [searchData, setSearchData] = useState(data);
  const [searchKey, setSearchKey] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);

  const handleReset = () => {
    setIsSearchActive(false);
    setSearchData(data);
    setSearchKey('');

    if (location === homePageUrl) {
      setTopLevelMenuOpen(false);
    }
  };

  useEffect(() => {
    if (location !== homePageUrl) {
      setTopLevelMenuOpen(true);
    }
  }, [location]);

  // for handling expand/collapse of level 1 menu on hover off
  useEffect(() => {
    if (location !== homePageUrl) {
      setTopLevelMenuOpen(true);
      setSelectedMenuId('');
    }
    if (collapseSideMenu && isSearchActive && !expandSideMenu) {
      const timeoutID = setTimeout(() => {
        handleReset();
      }, 5000);
      return () => {
        clearTimeout(timeoutID);
      };
    }
  }, [collapseSideMenu]);

  // for handling expand/collapse of level 1 menu on hover in when in homepage
  useEffect(() => {
    if (location === homePageUrl && collapseSideMenu && !isSearchActive) {
      setTopLevelMenuOpen(false);
    }
  }, [expandSideMenu]);

  const handleBackClick = (menuId) => {
    setTopLevelMenuOpen(!topLevelMenuOpen);
    setSelectedMenuId(menuId);
  };

  const handleSearchData = (searchResult, isSearch) => {
    if (location === homePageUrl) {
      if (isSearch) {
        setTopLevelMenuOpen(true);
      } else {
        setTopLevelMenuOpen(false);
      }
    }
    setIsSearchActive(isSearch);
    setSearchData(searchResult);
  };

  if (data && !sideMenuLoading) {
    return IsMobileDevice ? (
      <Drawer
        open={mobileDrawer}
        onClose={handleMobileDrawerClick}
        classes={{
          paper: classes.mobileDrawer,
        }}
      >
        <div className={expandSideMenu ? classes.toolbar : classes.toolbarMobileCentered}>
          <Logo isSideMenuAvailable={isSideMenuAvailable} isWhite isSideNav />
          {' '}
          <IconButton
            onClick={handleMobileDrawerClick}
            className={expandSideMenu ? classes.menuButtonExpanded : classes.menuButton}
          >
            <ResponsiveBackIcon htmlColor="var(--SIDEMENU-ICON-HAMBURGER)" />
          </IconButton>
        </div>
        <Divider style={{ 'background-color': 'transparent' }} />
        <SearchBar
          data={data}
          handleSearchData={handleSearchData}
          open
          handleReset={handleReset}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
        />
        <List component="nav" className={classes.navBar}>
          <SearchResults
            isSearchActive={isSearchActive ? expandSideMenu : false}
            searchData={searchData}
            classes={classes}
            handleDrawerClose={handleDrawer}
            expandSideMenu={expandSideMenu}
            handleReset={handleReset}
          >
            <ChildMenu
              data={searchData}
              selected={location}
              handleBackClick={handleBackClick}
              topLevelMenuOpen={topLevelMenuOpen}
              expandSideMenu
              collapseSideMenu={collapseSideMenu}
              selectedMenuId={selectedMenuId}
              isSearchActive={isSearchActive}
              handleDrawerClose={handleDrawer}
            />
          </SearchResults>
        </List>
        <Footer classes={classes} expandSideMenu={expandSideMenu} />
      </Drawer>
    ) : (
      <Drawer
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        onClose={handleDrawerClick}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: expandSideMenu,
          [classes.drawerClose]: !expandSideMenu,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: expandSideMenu,
            [classes.drawerClose]: !expandSideMenu,
          }),
        }}
        style={{
          // background: getBackgroundColor(),
          background: 'var(--SIDEMENU-BACKGROUND)',
        }}
      >
        <div className={expandSideMenu ? classes.toolbar : classes.toolbarCentered}>
          {!expandSideMenu && (
            <IconButton
              onClick={handleDrawerClick}
              className={expandSideMenu ? classes.menuButtonExpanded : classes.menuButton}
            >
              <SideMenuIcon htmlColor="var(--SIDEMENU-ICON-HAMBURGER)" />
            </IconButton>
          )}
          {expandSideMenu && (
            <>
              <Logo isSideMenuAvailable={isSideMenuAvailable} isWhite isSideNav />
              {' '}
            </>
          )}
          {/* ToDo: Do not delete the below commented codes */}
          {/* {expandSideMenu && !backIcon && (
            <IconButton
              // onClick={handleDrawerClickBack}
              className={expandSideMenu ? classes.menuButtonExpanded : classes.menuButton}
            >
              <SideMenuSmallIcon htmlColor="var(--SIDEMENU-ICON-HAMBURGER)" />
            </IconButton>
          )} */}
          {/* {expandSideMenu && backIcon && (
            <IconButton
              onClick={handleDrawerClickBack}
              className={expandSideMenu ? classes.menuButtonExpanded : classes.menuButton}
            >
              <SideMenuIconBack htmlColor="var(--SIDEMENU-ICON-HAMBURGER)" />
            </IconButton>
          )} */}
        </div>
        <Divider style={{ 'background-color': 'transparent' }} />
        <SearchBar
          data={data}
          handleSearchData={handleSearchData}
          open={expandSideMenu}
          handleReset={handleReset}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
        />
        <List component="nav" className={classes.navBar}>
          <SearchResults
            isSearchActive={isSearchActive ? expandSideMenu : false}
            searchData={searchData}
            classes={classes}
            handleDrawerClose={handleDrawer}
            expandSideMenu={expandSideMenu}
            handleReset={handleReset}
          >
            <ChildMenu
              data={searchData}
              selected={location}
              handleBackClick={handleBackClick}
              topLevelMenuOpen={topLevelMenuOpen}
              expandSideMenu={expandSideMenu}
              collapseSideMenu={collapseSideMenu}
              selectedMenuId={selectedMenuId}
              isSearchActive={isSearchActive}
              handleDrawerClose={handleDrawer}
            />
          </SearchResults>
        </List>
        {showFooter && <Footer classes={classes} expandSideMenu={expandSideMenu} isNotMobileFooter />}
      </Drawer>
    );
  }

  return null;
};

export default SideMenu;
