import React from 'react';
import './AvatarText.css';

const AvatarText = ({ text, avatar }) => {
  return (
    <span className='AvatarText'>
      {avatar}
      <span className='AvatarText-text'>{text}</span>
    </span>
  );
};

export default AvatarText;
