import { getRequestDetailsApi } from './api/getDataFromApi';

const STATUS_PENDING = 1;
const STATUS_APPROVED = 3;

// Todo: Work on localisation once DB MR is merged

function displayAlertBannersForRequestSubmit(
  requestId,
  userName,
  hasLastRequestFileUploadFailed,
  setAlertBannerData
) {
  getRequestDetailsApi(userName, requestId).then((response) => {
    if (response?.status === STATUS_PENDING) {
      setAlertBannerData((prevState) => [
        ...prevState,
        {
          colorTheme: 'Success',
          title: 'Success!',
          description: `Your request for ${response?.endpoints[0]?.accountName} is Pending Approval.`,
        },
      ]);
    } else if (response?.status === STATUS_APPROVED) {
      setAlertBannerData((prevState) => [
        ...prevState,
        {
          colorTheme: 'Success',
          title: 'Success!',
          description: `Your request for ${response?.endpoints[0]?.accountName} has been approved and will be available soon. Refresh your list in a few minutes.`,
        },
      ]);
    }

    if (hasLastRequestFileUploadFailed) {
      setAlertBannerData((prevState) => [
        ...prevState,
        {
          colorTheme: 'Warning',
          title: 'Upload Failed',
          description: `Your files for ${response?.endpoints[0]?.accountName} could not be uploaded.`,
        },
      ]);
    }
  });
}

export default displayAlertBannersForRequestSubmit;
