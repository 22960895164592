import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { capitalizeFirstLetter, localize, Logger } from '@saviynt/common';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import InlineMessage from '../InlineMessage/InlineMessage';
import InputCore from '../Input/InputCore/InputCore';
import Link from '../Link/Link';
import Tooltip from '../TooltipComponent/TooltipComponent';

import UploadedFile from './UploadedFile/UploadedFile';
import {
  addSpaceBetweenAcceptTypes,
  formatUploadFileSize,
  getAcceptTypesCount,
  getXNumberOfAcceptTypes,
} from './helpers';

import './Upload.css';

const KINDS = { dropzone: 'dropzone', button: 'button' };
const MAX_NUM_ACCEPT_TYPES_TO_SHOW = 5;
const REUPLOAD_DELAY_MS = 100;

const msgs = {
  neo: {
    common: {
      browse: {
        id: 'neo.common.browse',
        defaultMessage: 'browse',
      },
    },
    upload: {
      maxFilesReached: {
        id: 'neo.upload.maxFilesReached',
        defaultMessage: 'Max files reached.',
      },
      maxFileCount: {
        id: 'neo.upload.maxFileCount',
        defaultMessage: 'Max {maxFileCount} files.',
      },
      maxFileSize: {
        id: 'neo.upload.maxFileSize',
        defaultMessage: 'Max {maxFileSizeString} per file.',
      },
      allowedTypesTooltip: {
        id: 'neo.upload.allowedTypesTooltip',
        defaultMessage: 'Only {acceptTypes} files. {maxNumberFilesText}',
      },
      dragAndDropOr: {
        id: 'neo.upload.dragAndDropOr',
        defaultMessage: 'Drag and drop or',
      },
    },
  },
};

/**
 * ###### TODO:
 * - Add uploaded preview image for uploaded images.
 */
function Upload({
  kind,
  isMultiple,
  helperText,
  onFileSelect,
  onFileDelete,
  onFileReupload,
  shouldReuploadWithDelete, // for backwards compatibility
  uploadedFiles,
  isUploading,
  acceptTypes,
  reuploadFileName,
  isMaxNumberUploaded,
  buttonLabel,
  dropzoneLinkText,
  fileTypesAllowedText,
  maxFileSizeString,
  maxFileCount,
  className,
  dataTestId,
}) {
  const [isDragging, setIsDragging] = useState(false);
  const dragCounterRef = useRef(0);
  const fileInputRef = useRef(null);
  const intl = useIntl();

  const MAX_UPLOADS_REACHED_TEXT = localize(
    intl,
    msgs.neo.upload.maxFilesReached
  );

  useEffect(() => {
    // trigger file input when a file is set for reupload
    if (reuploadFileName && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [reuploadFileName]);

  const preventDefaultAndStopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleBrowseClick = (e) => {
    e.stopPropagation();
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    preventDefaultAndStopPropagation(e);
  };

  const handleDragEnter = (e) => {
    preventDefaultAndStopPropagation(e);
    dragCounterRef.current += 1;
    if (dragCounterRef.current === 1) setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    preventDefaultAndStopPropagation(e);
    dragCounterRef.current -= 1;
    if (dragCounterRef.current === 0) setIsDragging(false);
  };

  const handleDrop = (e) => {
    preventDefaultAndStopPropagation(e);
    setIsDragging(false);
    dragCounterRef.current = 0;

    let files = Array.from(e.dataTransfer.files);

    if (!isMultiple) {
      files = files.slice(0, 1);
    }

    const allowedFileTypes = acceptTypes
      ? acceptTypes.split(',').map((type) => type.trim())
      : null; // allow all types if acceptTypes is falsy

    onFileSelect?.(files, allowedFileTypes);
  };

  const handleFileChange = (e) => {
    let files = Array.from(e.target.files);

    if (!isMultiple) {
      files = files.slice(0, 1);
    }

    const allowedFileTypes = acceptTypes
      ? acceptTypes.split(',').map((type) => type.trim())
      : null; // allow all types if acceptTypes is falsy

    onFileSelect?.(files, allowedFileTypes);

    e.target.value = null; // reset the file input for the next file.
  };

  const renderedUploadedFiles = uploadedFiles.map((file) => {
    let fadeClass = file.isUploading ? 'UploadedFile--fadingIn' : '';

    if (reuploadFileName === file.fileName) {
      fadeClass = 'UploadedFile--fadingOut';
    }

    return (
      <UploadedFile
        key={file.fileName}
        fileName={file.fileName}
        fileSize={formatUploadFileSize(file.fileSize)}
        error={file.isError ? file.errorMessage : null}
        onReupload={() => {
          if (shouldReuploadWithDelete) {
            onFileDelete?.(file.fileName);
            setTimeout(() => {
              // slight delay makes the experience smoother
              onFileReupload?.(file.fileName);
            }, REUPLOAD_DELAY_MS);
          } else {
            onFileReupload?.(file.fileName); // original behavior for backwards compatibility
          }
        }}
        onDelete={() => onFileDelete(file.fileName)}
        isUploading={isUploading}
        className={fadeClass}
      />
    );
  });

  const shouldShowDropzoneOrButton = isMultiple || uploadedFiles.length === 0;

  const maxNumberFilesText = maxFileCount
    ? localize(intl, msgs.neo.upload.maxFileCount, { maxFileCount })
    : '';

  // fileTypesAllowedText prop gets priority for backwards compatibility
  let newFileTypesAllowedText = fileTypesAllowedText;
  let acceptTypesTooltip = null;

  if (!fileTypesAllowedText) {
    newFileTypesAllowedText = `Only ${getXNumberOfAcceptTypes(
      acceptTypes,
      MAX_NUM_ACCEPT_TYPES_TO_SHOW
    )} files.`;

    if (maxFileSizeString) {
      newFileTypesAllowedText += ` ${localize(
        intl,
        msgs.neo.upload.maxFileSize,
        { maxFileSizeString }
      )}`;
    }

    if (getAcceptTypesCount(acceptTypes) > MAX_NUM_ACCEPT_TYPES_TO_SHOW) {
      acceptTypesTooltip = (
        <Tooltip
          text={localize(intl, msgs.neo.upload.allowedTypesTooltip, {
            acceptTypes: addSpaceBetweenAcceptTypes(acceptTypes),
            maxNumberFilesText,
          })}
          position='top'>
          <Icon kind='info' size='xSmall' color='info-700' />
        </Tooltip>
      );
    }
  }

  let buttonLabelFinal = buttonLabel;
  let dropzoneLinkTextFinal = dropzoneLinkText;

  if (buttonLabel === 'Browse') {
    // localize since it's the default prop.
    buttonLabelFinal = localize(intl, msgs.neo.common.browse);
    buttonLabelFinal = capitalizeFirstLetter(buttonLabelFinal);
  } else {
    Logger.warn('Be sure to localize the buttonLabel prop.');
  }

  if (dropzoneLinkText === 'browse') {
    // localize since it's the default prop.
    dropzoneLinkTextFinal = localize(intl, msgs.neo.common.browse);
  } else {
    Logger.warn('Be sure to localize the dropzoneLinkText prop.');
  }

  return (
    <section
      className={classnames('Upload-container', className)}
      data-testid={dataTestId}>
      {helperText && <div className='Upload-helperText'>{helperText}</div>}
      {kind === KINDS.dropzone ? (
        <>
          {shouldShowDropzoneOrButton && (
            <div
              className={classnames(
                'Upload-dropzone',
                isMaxNumberUploaded && 'Upload-dropzone--isMaxNumberUploaded',
                isDragging && 'Upload--isDragging'
              )}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}>
              <div className='Upload-content'>
                <div className='Upload-icon'>
                  <Icon
                    kind='document'
                    color={isMaxNumberUploaded ? 'neutral-600' : 'navy-900'}
                  />
                </div>
                {isMaxNumberUploaded ? (
                  <div className='Upload-maxNumberUploaded'>
                    <InlineMessage
                      text={MAX_UPLOADS_REACHED_TEXT}
                      colorTheme='warning'
                      size='small'
                      leftIcon={<Icon kind='Warning' />}
                    />
                    <div className='Upload-filetypes'>
                      <div className='Upload-allowedFileTypesAndTooltip'>
                        {newFileTypesAllowedText}
                        {acceptTypesTooltip}
                      </div>
                      <div>{maxNumberFilesText}</div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='Upload-instructions'>
                      {localize(intl, msgs.neo.upload.dragAndDropOr)}
                      &nbsp;
                      <Link
                        text={dropzoneLinkTextFinal}
                        onClick={handleBrowseClick}
                      />
                    </div>
                    <div className='Upload-filetypes'>
                      <div className='Upload-allowedFileTypesAndTooltip'>
                        {newFileTypesAllowedText}
                        {acceptTypesTooltip}
                      </div>
                      <div>{maxNumberFilesText}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {renderedUploadedFiles}
        </>
      ) : (
        <>
          {renderedUploadedFiles}
          <div className='Upload-buttonTriggerContainer'>
            {shouldShowDropzoneOrButton && (
              <Button
                type='button'
                kind='filled'
                size='medium'
                onClick={handleBrowseClick}
                className='Upload-buttonTrigger'
                isDisabled={isMaxNumberUploaded}
                leftIcon={
                  <Icon kind='Upload' size='smallMedium' color='neutral-100' />
                }>
                {buttonLabelFinal}
              </Button>
            )}
            {isMaxNumberUploaded && (
              <InlineMessage
                text={MAX_UPLOADS_REACHED_TEXT}
                colorTheme='warning'
                size='small'
                leftIcon={<Icon kind='Warning' />}
              />
            )}
            {(isMultiple
              ? !isMaxNumberUploaded
              : renderedUploadedFiles.length === 0) && (
              // when isMultiple, show this jsx until max number of files is reached
              <div className='Upload-filetypes'>
                <div className='Upload-allowedFileTypesAndTooltip'>
                  {newFileTypesAllowedText}
                  {acceptTypesTooltip}
                </div>
                {isMultiple && <div>{maxNumberFilesText}</div>}
              </div>
            )}
          </div>
        </>
      )}
      <InputCore
        type='file'
        className='Upload-input'
        accept={acceptTypes}
        multiple={isMultiple}
        onChange={handleFileChange}
        ref={fileInputRef}
      />
    </section>
  );
}

Upload.propTypes = {
  kind: PropTypes.oneOf([KINDS.button, KINDS.dropzone]),
  isMultiple: PropTypes.bool,
  helperText: PropTypes.string,
  onFileSelect: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func.isRequired,
  onFileReupload: PropTypes.func.isRequired,
  shouldReuploadWithDelete: PropTypes.bool,
  uploadedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      fileSize: PropTypes.number,
      isError: PropTypes.bool,
      errorMessage: PropTypes.string,
      isUploading: PropTypes.bool,
    })
  ).isRequired,
  isUploading: PropTypes.bool,
  acceptTypes: PropTypes.string,
  reuploadFileName: PropTypes.string,
  isMaxNumberUploaded: PropTypes.bool,
  buttonLabel: PropTypes.string,
  dropzoneLinkText: PropTypes.string,
  fileTypesAllowedText: PropTypes.string,
  maxFileSizeString: PropTypes.string, // i.e., '1MB', '100KB'
  maxFileCount: PropTypes.number,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

Upload.defaultProps = {
  kind: KINDS.dropzone,
  isMultiple: false,
  helperText: '',
  acceptTypes: '',
  shouldReuploadWithDelete: true,
  isUploading: false,
  reuploadFileName: null,
  isMaxNumberUploaded: false,
  buttonLabel: 'Browse', // if other than 'Browse', be sure to localize the prop.
  dropzoneLinkText: 'browse', // if other than 'browse', be sure to localize the prop.

  // TODO: Deprecated prop. keep below for backwards compatibility in host, remove in the future.
  fileTypesAllowedText: '', // 'Only .PNG or .JPG files. Max 1MB per file.',

  maxFileSizeString: null,
  maxFileCount: null,
  className: null,
  dataTestId: null,
};

export default Upload;
