import HttpService from 'ui-home/src/misc/HttpService/HttpService';

export const FETCH_PARALLEL_ENV = 'FETCH_PARALLEL_ENV';
export const FETCH_PARALLEL_ENV_SUCCESS = 'FETCH_PARALLEL_ENV_SUCCESS';
export const FETCH_PARALLEL_ENV_ERROR = 'FETCH_PARALLEL_ENV_ERROR';

const fetchParallelEnv = () => (dispatch) => {
  dispatch({ type: FETCH_PARALLEL_ENV })

  const response = HttpService.get(`/ECM/api/v5/maintenance/upgrade/version`);
  //ToDo - Mocked API data
  // const data = {
  //   "current_version": "24.4",
  //   "upgrade_status": "UPGRADE_READY",
  //   "upgrade_from_version": "24.4",
  //   "upgrade_to_version": "24.5",
  // };

  // dispatch({
  //   type: FETCH_PARALLEL_ENV_SUCCESS,
  //   data: data,
  // });


  response
    .then((res) => {
      dispatch({
        type: FETCH_PARALLEL_ENV_SUCCESS,
        data: res,
      });
    });
};

export {
  fetchParallelEnv,
};
