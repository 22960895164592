import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import CheckboxCore from '../CheckboxCore/CheckboxCore';
import { SIZES } from '../constants';

import './CheckboxSimpleText.css';
/*
https://www.figma.com/file/KACl1WLOzsyCUU5kKqp1Ep/Saviynt-Design-System-Component-Library?type=design&node-id=16463-151491
*/

const TEXT_POSITIONS = {
  BEFORE: 'before',
  AFTER: 'after',
};

function CheckboxSimpleText({
  name,
  size,
  label,
  isDisabled,
  isIndeterminate,
  textPosition,
  isChecked,
  handleChange,
  showHoverEffect,
  showHoverLabel,
  dataTestId,
  tabIndex,
}) {
  const classes = classnames(
    'Checkbox',
    'Checkbox--simpleText',
    isDisabled && 'Checkbox--disabled',
    isChecked && ' Checkbox--checked',
    !showHoverEffect && 'Checkbox--hoverEffectDisabled'
  );

  const labelTextClass = `Checkbox-labelText Checkbox-labelText--${textPosition}`;
  const renderedLabel = (
    <div className='Checkbox-iconTextWrapper'>
      <div
        className={labelTextClass}
        title={showHoverLabel ? label : undefined}>
        {label}
      </div>
    </div>
  );

  return (
    <div className='Checkbox-container' data-testid={dataTestId}>
      <div className={classes}>
        <label className='Checkbox-label'>
          {textPosition === TEXT_POSITIONS.BEFORE && renderedLabel}
          <CheckboxCore
            name={name}
            size={size}
            isChecked={isChecked}
            isDisabled={isDisabled}
            isIndeterminate={isIndeterminate}
            handleChange={handleChange}
            dataTestId={dataTestId}
            tabIndex={isDisabled ? -1 : tabIndex}
          />
          {textPosition === TEXT_POSITIONS.AFTER && renderedLabel}
        </label>
      </div>
    </div>
  );
}

CheckboxSimpleText.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf([SIZES.small, SIZES.medium, SIZES.large]),
  label: PropTypes.string.isRequired,
  textPosition: PropTypes.oneOf(Object.values(TEXT_POSITIONS)),
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  handleChange: PropTypes.func,
  showHoverEffect: PropTypes.bool,
  showHoverLabel: PropTypes.bool,
  dataTestId: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CheckboxSimpleText.defaultProps = {
  name: null,
  size: SIZES.medium,
  textPosition: TEXT_POSITIONS.AFTER,
  isDisabled: false,
  isChecked: undefined,
  isIndeterminate: false,
  showHoverEffect: false,
  showHoverLabel: false,
  handleChange: null,
  dataTestId: null,
  tabIndex: 0,
};

export default CheckboxSimpleText;
