import './SchedulerBlock.css';

import {
  getSchedulerTimeWindowString,
  InterPoint,
  localize,
} from '@saviynt/common';
import { Box, Typography } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { Session } from '../../../models/PamModels';
import { msgs, STATUS } from '../constants';

function SchedulerBlock({
  details,
  isCheckedOut,
  startTime,
  endTime,
  timeFormat,
  onClick,
  isModalView,
  dataTestId,
  className,
  status,
}) {
  const classes = classnames(
    'SchedulerBlock',
    isModalView && 'SchedulerBlock-modal',
    className
  );

  const isUnavailable = status === STATUS.UNAVAILABLE;
  const isPendingSession = details && !details.sessionStatus;
  const isFutureApprovedSession = details && details.sessionStatus === 1;
  const isActiveSession = details && details.sessionStatus === 2;
  const isCompletedSession = details && details.sessionStatus === 3;

  const intl = useIntl();

  const BOOKED_LABEL = localize(intl, msgs.pam.scheduler.booked);
  const REQUESTED_LABEL = localize(intl, msgs.pam.scheduler.requested);
  const COMPLETE_LABEL = localize(intl, msgs.pam.scheduler.complete);

  const getBlockStatus = () => {
    if (isUnavailable) {
      if (isPendingSession || isActiveSession || isFutureApprovedSession) {
        return STATUS.BOOKED;
      }

      if (isCompletedSession) {
        return STATUS.COMPLETE;
      }
    }

    return STATUS.AVAILABLE;
  };

  const getBlockStatusLabel = () => {
    if (isUnavailable) {
      if (isPendingSession) {
        return REQUESTED_LABEL;
      }

      if (isActiveSession || isFutureApprovedSession) {
        return BOOKED_LABEL;
      }

      if (isCompletedSession) {
        return COMPLETE_LABEL;
      }
    }

    return null;
  };

  const setInterpointColor = () => {
    if (getBlockStatus() === STATUS.BOOKED) {
      return 'criticalBold';
    }

    if (getBlockStatus() === STATUS.COMPLETE) {
      return 'neutralSubtle';
    }

    return null;
  };

  const getSessionDetails = (sessionDetails) => (
    <Box className='SchedulerBlock-detailsContainer'>
      <Typography
        kind='body2-bold'
        className={`SchedulerBlock-${getBlockStatus()}-detailsText-action`}>
        {getBlockStatusLabel()}
      </Typography>
      <InterPoint color={setInterpointColor()} font='body2' />
      <Typography
        kind='body2'
        className={`SchedulerBlock-${getBlockStatus()}-details`}>
        {`by ${sessionDetails.firstname || ''} ${
          sessionDetails.lastname || ''
        } (${sessionDetails.username || ''})`}
      </Typography>{' '}
      <InterPoint color={setInterpointColor()} font='body2' />{' '}
      <Typography
        kind='body2'
        className={`SchedulerBlock-${getBlockStatus()}-details`}>
        {sessionDetails.requestId}
      </Typography>
    </Box>
  );

  return (
    <section className={classes} data-testid={dataTestId} onClick={onClick}>
      <section className={`SchedulerBlock-${getBlockStatus()}`}>
        <div className={`SchedulerBlock-${getBlockStatus()}-content`}>
          <div className='SchedulerBlock-timeRange'>
            <Typography
              kind='body2'
              className={`SchedulerBlock-${getBlockStatus()}-timeRange`}>
              {`${getSchedulerTimeWindowString(
                startTime,
                timeFormat.roundToNearest,
                timeFormat.is24HourFormat
              )} - ${getSchedulerTimeWindowString(
                endTime,
                timeFormat.roundToNearest,
                timeFormat.is24HourFormat
              )}`}
            </Typography>
          </div>
          <Typography
            kind={
              getBlockStatus() === STATUS.AVAILABLE ? 'body2-bold' : 'body2'
            }
            className={`SchedulerBlock-${getBlockStatus()}-details`}>
            {details && isUnavailable
              ? getSessionDetails(details)
              : localize(intl, msgs.pam.scheduler.availableForRequest)}
          </Typography>
        </div>
      </section>
    </section>
  );
}

SchedulerBlock.propTypes = {
  details: Session,
  isCheckedOut: PropTypes.bool,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  timeFormat: PropTypes.shape({
    roundToNearest: PropTypes.string,
    is24HourFormat: PropTypes.bool,
  }).isRequired,
  isModalView: PropTypes.bool,
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  status: PropTypes.string,
};

SchedulerBlock.defaultProps = {
  details: null,
  isCheckedOut: false,
  onClick: () => {},
  isModalView: false,
  dataTestId: null,
  className: null,
  status: null,
};

export default SchedulerBlock;
