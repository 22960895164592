import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../../Icon/Icon';
import Typography from '../../Typography/Typography';
import ButtonCore from '../ButtonCore/ButtonCore';

import './ButtonSelectRectangle.css';
import './ButtonSelectPill.css';

const KINDS = {
  rectangle: 'rectangle',
  pill: 'pill',
};

const SIZES = {
  large: 'large',
  medium: 'medium',
  small: 'small',
};

function ButtonSelect({
  kind,
  size,
  label,
  isOpen,
  isDisabled,
  onClick,
  prefixIcon,
  BadgeComp,
  dataTestId,
}) {
  const classes = classnames(
    'ButtonSelect',
    `ButtonSelect--${kind}`,
    `ButtonSelect--${size}`,
    isDisabled && 'ButtonSelect--isDisabled',
    isOpen && `ButtonSelect--${kind}--isOpen`,
    BadgeComp && 'ButtonSelect--hasBadge'
  );

  // Size info:
  // rectangle: large and medium sizes are the same.
  // pill: only has one size (medium)
  const getChevronIconSize = () => {
    switch (kind) {
      case KINDS.pill:
        return 'xSmall';
      case KINDS.rectangle:
        return size === SIZES.small ? 'xSmall' : 'smallMedium';
      default:
        return SIZES.medium;
    }
  };

  return (
    <ButtonCore
      className={classes}
      isDisabled={isDisabled}
      onClick={onClick}
      dataTestId={dataTestId}>
      {prefixIcon && (
        <div className={`ButtonSelect--${kind}-icon`}>{prefixIcon}</div>
      )}
      <Typography
        kind='label'
        className={`ButtonSelect-label ButtonSelect--${size}`}>
        {label}
      </Typography>
      <span className={`ButtonSelect--${kind}-chevron`}>
        <Icon
          kind='ButtonSelectChevronUp'
          size={getChevronIconSize()}
          rotate={!isOpen ? '180' : null}
        />
      </span>
      {BadgeComp}
    </ButtonCore>
  );
}

ButtonSelect.propTypes = {
  kind: PropTypes.oneOf([KINDS.rectangle, KINDS.pill]),
  size: PropTypes.oneOf([SIZES.small, SIZES.medium, SIZES.large]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  isOpen: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  prefixIcon: PropTypes.element,
  BadgeComp: PropTypes.element,
  dataTestId: PropTypes.string,
};

ButtonSelect.defaultProps = {
  kind: KINDS.rectangle,
  size: SIZES.medium,
  isOpen: false,
  isDisabled: false,
  onClick: () => {},
  prefixIcon: null,
  BadgeComp: null,
  dataTestId: null,
};

export default ButtonSelect;
