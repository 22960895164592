import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(() => ({
  size: {
    width: (size) => size,
    height: (size) => size,
    fontSize: (size) => size / 2,
    borderRadius: '50% !important',
  },
}));

const ProfileIcon = ({ url, size, name }) => {
  const classes = useStyles(size);

  return (
    <Avatar className={classes.size} src={url}>
      {name}
    </Avatar>
  );
};

ProfileIcon.propTypes = {
  size: PropTypes.number,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
};

ProfileIcon.defaultProps = {
  size: 32,
  url: null,
};

export default ProfileIcon;
