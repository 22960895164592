import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './TableBody.css';

function TableBody({ className, dataTestId, children }) {
  return (
    <thead
      className={classnames('TableBodyDS', className)}
      data-testid={dataTestId}>
      {children}
    </thead>
  );
}

TableBody.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  children: PropTypes.node,
};
TableBody.defaultProps = {
  className: null,
  dataTestId: null,
  children: null,
};

export default TableBody;
