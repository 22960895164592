import React from 'react';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import DropDownMenu from '../DropDownMenu/DropDownMenu';
import MenuItems from './MenuItems/MenuItems';
import UserDetails from './UserDetails/UserDetails';

import ProfileIcon from '../ProfileIcon/ProfileIcon';

const useStyles = makeStyles(() => ({
  profileIcon: {
    padding: 0,
    border: '2px solid #fff',
    outline: (open) => (open ? '2px solid #3751FF' : '0'),
  },
}));

const Profile = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles(anchorEl || false);

  const { username, profileImage } = useSelector((state) => state.profile);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="profile"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.profileIcon}
        data-testid="profile"
      >
        <ProfileIcon
          url={profileImage}
          name={username?.substring(0, 1).toUpperCase()}
        />
      </IconButton>
      <DropDownMenu
        anchorEl="body"
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <UserDetails />
        <MenuItems />
      </DropDownMenu>
    </>
  );
};

export default Profile;
