import { useEffect } from 'react';

function useScrollbarWidth() {
  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    document.documentElement.style.setProperty(
      '--scrollbar-width-saviynt-ds',
      `${scrollbarWidth}px`,
    );
  }, []);
}

export default useScrollbarWidth;
