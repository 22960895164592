// Cookies
export const REFRESH_TOKEN = 'refresh_token';
export const USER_KEY = 'userKey';
export const USER_NAME = 'user_name';
export const ACCESS_TOKEN = 'access_token';
export const CCSRF_TOKEN = 'CCSRF-TOKEN';
export const TOKEN_TYPE = 'token_type';
export const FIRST_NAME = 'first_name';
export const INGRESSCOOKIE = 'INGRESSCOOKIE';
export const JSESSIONID = 'JSESSIONID';
export const MS_REDIRECT_URL = 'MS_REDIRECT_URL';

// Local Storage
export const MICRO_APP = 'micro_app';
export const LOCALE = 'locale';
export const APP_CONSTANTS = 'appConstants';
export const STORED_TIME = 'storedTime';

//Constants 

export const allTimeOptions = [
    { display: '12:00am', value: { hours: 0, minutes: 0, seconds: 0 } },
    { display: '12:30am', value: { hours: 0, minutes: 30, seconds: 0 } },
    { display: '1:00am', value: { hours: 1, minutes: 0, seconds: 0 } },
    { display: '1:30am', value: { hours: 1, minutes: 30, seconds: 0 } },
    { display: '2:00am', value: { hours: 2, minutes: 0, seconds: 0 } },
    { display: '2:30am', value: { hours: 2, minutes: 30, seconds: 0 } },
    { display: '3:00am', value: { hours: 3, minutes: 0, seconds: 0 } },
    { display: '3:30am', value: { hours: 3, minutes: 30, seconds: 0 } },
    { display: '4:00am', value: { hours: 4, minutes: 0, seconds: 0 } },
    { display: '4:30am', value: { hours: 4, minutes: 30, seconds: 0 } },
    { display: '5:00am', value: { hours: 5, minutes: 0, seconds: 0 } },
    { display: '5:30am', value: { hours: 5, minutes: 30, seconds: 0 } },
    { display: '6:00am', value: { hours: 6, minutes: 0, seconds: 0 } },
    { display: '6:30am', value: { hours: 6, minutes: 30, seconds: 0 } },
    { display: '7:00am', value: { hours: 7, minutes: 0, seconds: 0 } },
    { display: '7:30am', value: { hours: 7, minutes: 30, seconds: 0 } },
    { display: '8:00am', value: { hours: 8, minutes: 0, seconds: 0 } },
    { display: '8:30am', value: { hours: 8, minutes: 30, seconds: 0 } },
    { display: '9:00am', value: { hours: 9, minutes: 0, seconds: 0 } },
    { display: '9:30am', value: { hours: 9, minutes: 30, seconds: 0 } },
    { display: '10:00am', value: { hours: 10, minutes: 0, seconds: 0 } },
    { display: '10:30am', value: { hours: 10, minutes: 30, seconds: 0 } },
    { display: '11:00am', value: { hours: 11, minutes: 0, seconds: 0 } },
    { display: '11:30am', value: { hours: 11, minutes: 30, seconds: 0 } },
    { display: '12:00pm', value: { hours: 12, minutes: 0, seconds: 0 } },
    { display: '12:30pm', value: { hours: 12, minutes: 30, seconds: 0 } },
    { display: '1:00pm', value: { hours: 13, minutes: 0, seconds: 0 } },
    { display: '1:30pm', value: { hours: 13, minutes: 30, seconds: 0 } },
    { display: '2:00pm', value: { hours: 14, minutes: 0, seconds: 0 } },
    { display: '2:30pm', value: { hours: 14, minutes: 30, seconds: 0 } },
    { display: '3:00pm', value: { hours: 15, minutes: 0, seconds: 0 } },
    { display: '3:30pm', value: { hours: 15, minutes: 30, seconds: 0 } },
    { display: '4:00pm', value: { hours: 16, minutes: 0, seconds: 0 } },
    { display: '4:30pm', value: { hours: 16, minutes: 30, seconds: 0 } },
    { display: '5:00pm', value: { hours: 17, minutes: 0, seconds: 0 } },
    { display: '5:30pm', value: { hours: 17, minutes: 30, seconds: 0 } },
    { display: '6:00pm', value: { hours: 18, minutes: 0, seconds: 0 } },
    { display: '6:30pm', value: { hours: 18, minutes: 30, seconds: 0 } },
    { display: '7:00pm', value: { hours: 19, minutes: 0, seconds: 0 } },
    { display: '7:30pm', value: { hours: 19, minutes: 30, seconds: 0 } },
    { display: '8:00pm', value: { hours: 20, minutes: 0, seconds: 0 } },
    { display: '8:30pm', value: { hours: 20, minutes: 30, seconds: 0 } },
    { display: '9:00pm', value: { hours: 21, minutes: 0, seconds: 0 } },
    { display: '9:30pm', value: { hours: 21, minutes: 30, seconds: 0 } },
    { display: '10:00pm', value: { hours: 22, minutes: 0, seconds: 0 } },
    { display: '10:30pm', value: { hours: 22, minutes: 30, seconds: 0 } },
    { display: '11:00pm', value: { hours: 23, minutes: 0, seconds: 0 } },
    { display: '11:30pm', value: { hours: 23, minutes: 30, seconds: 0 } },
  ]; 
