import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './GridItem.css';

function GridItem({ mobile, tablet, desktop, className, children }) {
  const classes = classnames(
    'Grid-item',
    mobile !== null && `Grid-item--mobile-${mobile}`,
    tablet !== null && `Grid-item--tablet-${tablet}`,
    desktop !== null && `Grid-item--desktop-${desktop}`,
    className
  );

  return <div className={classes}>{children}</div>;
}

GridItem.propTypes = {
  mobile: PropTypes.number,
  tablet: PropTypes.number,
  desktop: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node,
};
GridItem.defaultProps = {
  mobile: null,
  tablet: null,
  desktop: null,
  className: null,
  children: null,
};

export default GridItem;
