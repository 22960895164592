export const loginAction = 'mainlayout/loginAction';
export const loginRequestActionType = 'mainlayout/loginRequestActionType';
export const resetErrorLoginAction = 'mainlayout/resetErrorLoginAction';
export const loginFailedActionType = 'mainlayout/loginfailedactiontype';
export const loginSuccessActionType = 'mainlayout/loginSuccessactiontype';
export const userDetailsActionType = 'mainlayout/userDetailsActionType';
export const userDetailsSuccessActionType = 'mainlayout/userDetailsSuccessActionType';
export const userInfoActionType = 'mainlayout/userInfoActionType';
export const userModulesActionType = 'mainlayout/userModulesActionType';
export const loginInfoActionType = 'mainlayout/LOGIN_INFO_ACTION';
export const loginInfoSuccessActionType = 'mainlayout/LOGIN_INFO_SUCCESS_ACTION';
export const userModulesSuccessActionType = 'mainlayout/userModulesSuccessActionType';
export const sessiontoast = 'mainlayout/sessiontoast';
export const logoutActionType = 'mainlayout/logoutactiontype';
export const APP_CONSTANTS_ACTION = 'APP_CONSTANTS_ACTION';
export const APP_CONSTANTS_SUCCESS = 'APP_CONSTANTS_SUCCESS';
export const APP_MESSAGES = 'APP_MESSAGES';
export const APP_MESSAGES_SUCCESS = 'APP_MESSAGES_SUCCESS';
export const GET_HEADER_MENU = 'GET_HEADER_MENU';
export const GET_HEADER_MENU_SUCCESS = 'GET_HEADER_MENU_SUCCESS';
export const UPDATE_EMAIL_LANGUAGE = 'UPDATE_EMAIL_LANGUAGE';
export const ECM_LOGOUT = 'mainlayout/ECM_LOGOUT';
export const FULL_SCREEN_GLOBAL_OPEN = 'mainlayout/FULL_SCREEN_GLOBAL_OPEN';
export const GET_INITIAL_DATA_ACTION = 'mainlayout/GET_INITIAL_DATA_ACTION';
export const GET_INITIAL_DATA_SUCCESS_ACTION = 'mainlayout/GET_INITIAL_DATA_SUCCESS_ACTION';
export const GET_HOMEPAGE_MODERN_EXP_GEN2 = 'mainlayout/GET_HOMEPAGE_MODERN_EXP_GEN2';
export const GET_HOMEPAGE_MODERN_EXP_GEN2_SUCCESS_ACTION =
  'mainlayout/GET_HOMEPAGE_MODERN_EXP_GEN2_SUCCESS_ACTION';
export const GET_SIDEMENU_REQUEST_CONFIG = 'mainlayout/GET_SIDEMENU_REQUEST_CONFIG';
export const GET_SIDEMENU_REQUEST_CONFIG_SUCCESS_ACTION =
  'mainlayout/GET_SIDEMENU_REQUEST_CONFIG_SUCCESS_ACTION';
export const GET_LEFTNAV_MODERN_EXP_GEN2 = 'mainlayout/GET_LEFTNAV_MODERN_EXP_GEN2';
export const GET_LEFTNAV_MODERN_EXP_GEN2_SUCCESS_ACTION = 'mainlayout/GET_LEFTNAV_MODERN_EXP_GEN2_SUCCESS_ACTION';
export const GET_PAM_GEN2 = 'mainlayout/GET_PAM_GEN2';
export const GET_PAM_GEN2_SUCCESS_ACTION = 'mainlayout/GET_PAM_GEN2_SUCCESS_ACTION';

export const UPDATE_LANGUAGE_STATE = 'UPDATE_LANGUAGE_STATE';
export const PAGE_RELOAD = 'PAGE_RELOAD';

export const getIntitialDataAction = (locale, isLogin) => ({
  type: GET_INITIAL_DATA_ACTION,
  locale,
  isLogin: !!isLogin,
});

export const getInitialDataSuccess = () => ({
  type: GET_INITIAL_DATA_SUCCESS_ACTION,
});

export const resetErrorMessage = () => ({
  type: resetErrorLoginAction,
});

export const login = data => ({
  type: loginAction,
  payload: data,
});

export const loginRequestAction = (data, destinationUrl) => ({
  type: loginRequestActionType,
  payload: data,
  destinationUrl,
});

export const loginSuccessAction = data => ({
  type: loginSuccessActionType,
  payload: data,
});

export const loginFailedAction = () => ({
  type: loginFailedActionType,
});

export const userDetailsAction = () => ({
  type: userDetailsActionType,
});

export const userDetailsSuccessAction = userDetails => ({
  type: userDetailsSuccessAction,
  payload: userDetails,
});

export const loginInfoAction = () => {
  return {
    type: loginInfoActionType,
  };
};
export const loginInfoSuccess = loginInfo => {
  return {
    type: loginInfoSuccessActionType,
    payload: loginInfo,
  };
};
export const userModulesAction = () => {
  return {
    type: userModulesActionType,
  };
};

export const userModulesSuccessAction = payload => ({
  type: userModulesSuccessActionType,
  payload,
});

export const userInfoAction = data => ({
  type: userInfoActionType,
  payload: data,
});

export const sessionExpireToast = data => ({
  type: sessiontoast,
  payload: data,
});

export const logoutAction = () => ({
  type: logoutActionType,
});

export const appConstantsAction = (domain, locale) => ({
  type: APP_CONSTANTS_ACTION,
  payload: { domain, locale },
});

export const appConstantsSuccess = data => ({
  type: APP_CONSTANTS_SUCCESS,
  payload: data,
});

export const appMessages = (locale, gateway) => ({
  type: APP_MESSAGES,
  payload: { locale, gateway },
});
export const appMessagesSuccess = messages => ({
  type: APP_MESSAGES_SUCCESS,
  payload: messages,
});

export const getHeaderDetails = gateway => ({
  type: GET_HEADER_MENU,
  payload: gateway,
});

export const getHeaderDetailsSuccess = menu => ({
  type: GET_HEADER_MENU_SUCCESS,
  payload: menu,
});

export const updateMuteEmailAndLanguage = (updateObj, noLoader) => ({
  type: UPDATE_EMAIL_LANGUAGE,
  payload: updateObj,
  noLoader,
});

export const ecmLogoutAction = () => ({
  type: ECM_LOGOUT,
});

export const setFullScreenGlobalOpen = open => ({
  type: FULL_SCREEN_GLOBAL_OPEN,
  payload: open,
});

export const setUpdateLanguageState = (result) => ({
  type: UPDATE_LANGUAGE_STATE,
  result
})

export const reloadPage = () => ({
  type: PAGE_RELOAD,
  callback: () => window.location.reload()
})

export const getLeftNavModernGen2 = () => ({
  type: GET_LEFTNAV_MODERN_EXP_GEN2,
});

export const getLeftNavModernGen2Success = (data) => ({
  type: GET_LEFTNAV_MODERN_EXP_GEN2_SUCCESS_ACTION,
  payload: data,
});

export const getHomePageGen2 = () => ({
  type: GET_HOMEPAGE_MODERN_EXP_GEN2,
});

export const getHomePageGen2Success = (data) => ({
  type: GET_HOMEPAGE_MODERN_EXP_GEN2_SUCCESS_ACTION,
  payload: data,
});

export const getRequestGen2ConfigSuccess = (data) => ({
  type: GET_SIDEMENU_REQUEST_CONFIG_SUCCESS_ACTION,
  payload: data,
});

export const getPamGen2 = () => ({
  type: GET_PAM_GEN2,
});

export const getPamGen2Success = (data) => ({
  type: GET_PAM_GEN2_SUCCESS_ACTION,
  payload: data,
});
