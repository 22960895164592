import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../Link/Link';

import './MenuMultiFooter.css';

function MenuMultiFooter({
  onSelectAll,
  onClear,
  isClearDisabled,
  isSelectAllDisabled,
  className,
  tabIndex,
  dataTestId,
}) {
  return (
    <div className={`MenuMultiFooter ${className}`} data-testid={dataTestId}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        text='Select All'
        kind='button'
        onClick={onSelectAll}
        isDisabled={isSelectAllDisabled}
        className='MenuMultiFooter-selectAll'
        tabIndex={tabIndex}
        size='small'
      />

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        text='Clear'
        kind='button'
        onClick={onClear}
        isDisabled={isClearDisabled}
        className='MenuMultiFooter-clear'
        tabIndex={tabIndex}
      />
    </div>
  );
}

MenuMultiFooter.propTypes = {
  onSelectAll: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  isClearDisabled: PropTypes.bool,
  isSelectAllDisabled: PropTypes.bool,
  className: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataTestId: PropTypes.string,
};
MenuMultiFooter.defaultProps = {
  isClearDisabled: true,
  isSelectAllDisabled: true,
  className: null,
  tabIndex: 0,
  dataTestId: null,
};

export default MenuMultiFooter;
