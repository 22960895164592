/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable object-curly-newline */
import './MpaTimeAccessDuration.css';

import {
  getCurrentDate,
  HOURS_PER_DAY,
  localize,
  SECONDS_PER_DAY,
  SECONDS_PER_HOUR,
  SECONDS_PER_MINUTE,
} from '@saviynt/common';
import {
  Icon,
  InlineMessage,
  ToggleButton,
  ToggleButtonGroup,
} from '@saviynt/design-system';
import {
  add,
  addDays,
  addMinutes,
  addSeconds,
  differenceInHours,
  differenceInMinutes,
  eachDayOfInterval,
  isBefore,
  parseISO,
  setMinutes,
  setSeconds,
  startOfDay,
} from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import {
  CredentialRequestAccountSelection,
  Session,
} from '../../../../models/PamModels';
import { handleClearCredentialFormDates } from '../../../../store/actions';
import {
  ACCOUNT_JIT_SELECTION,
  DEFAULT_USER_MAX_TIME,
  initialAccordionSnapshotState,
  initialIntervalOptions,
  INTERVAL_LENGTH_IN_MINS,
  INTERVALS,
  msgs,
  SESSION_STATUSES,
} from '../../constants';
import ViewScheduleLink from '../../ModalIslands/ViewScheduleModalIsland/ViewScheduleLink/ViewScheduleLink';
import ByTypeDateAndTimePicker from '../helpers/ByTypeDateAndTimePicker';
import CustomDuration from '../helpers/CustomDuration';
import DurationChips from '../helpers/DurationChips';
import DurationHandlerButtons from '../helpers/DurationHandlerButtons';

const SECONDS_IN_FIVE_MINUTES = SECONDS_PER_MINUTE * 5;
const HOURS_IN_THREE_DAYS = HOURS_PER_DAY * 3;

function MpaTimeAccessDuration({
  userName,
  toggleChoice,
  setToggleChoice,
  setShouldDurationContinue,
  jitMaxTime,
  nowStartDateOfNextCredential,
  futureStartDateOfNextCredential,
  nowTabStartDate,
  nowTabEndDate,
  setNowTabStartDate,
  setNowTabEndDate,
  futureTabStartDate,
  setFutureTabStartDate,
  futureTabEndDate,
  setFutureTabEndDate,
  isTimeBlocked,
  setIsTimeBlocked,
  setIsScheduleViewOpen,
  accountSelection,
  setDurationAccordionValue,
  isDurationCustom,
  setIsDurationCustom,
  hasDurationBeenFilled,
  setHasDurationBeenFilled,
  selectedAccountSessions,
  isCOCorExclusive,
  isExpandedDuration,
  setIsExpandedDuration,
  isDurationBeingEditedCritical,
}) {
  // Inline message warning
  const [isScheduleConflictWarning, setIsScheduleConflictWarning] =
    useState(false);
  // NOW tab related states
  const [customDurationInputValue, setCustomDurationInputValue] = useState('');
  const [intervalOptions, setIntervalOptions] = useState(
    initialIntervalOptions
  );
  const [formattedNowWarningDate, setFormattedNowWarningDate] = useState(null);
  const [isNowBlocked, setIsNowBlocked] = useState(false);
  // FUTURE tab realated states
  const [durationChipValue, setDurationChipValue] = useState(0);
  const [futureDatesToExclude, setFutureDatesToExclude] = useState([]);
  const [futureTimesToExclude, setFutureTimesToExclude] = useState([]);
  const [formattedFutureWarningDate, setFormattedFutureWarningDate] =
    useState(null);
  // Custom states
  const [checkedIntervalOption, setCheckedIntervalOption] = useState(
    INTERVALS.MINUTES
  );
  const [durationInSeconds, setDurationInSeconds] = useState(null);
  const [isCustomOutOfRange, setIsCustomOutOfRange] = useState(false);
  const [customInputValueAsNumber, setCustomInputValueAsNumber] = useState(
    parseFloat(customDurationInputValue)
  );
  // Editing
  const [accordionValueSnapshot, setAccordionValueSnapshot] = useState(
    initialAccordionSnapshotState
  );

  const isJITSelection = accountSelection === ACCOUNT_JIT_SELECTION;
  const userMaxTimeAsNumber =
    accountSelection.maxtime !== ''
      ? parseInt(accountSelection.maxtime, 10)
      : DEFAULT_USER_MAX_TIME;

  const maxRequestTimeAsNumber = isJITSelection
    ? parseInt(jitMaxTime, 10)
    : userMaxTimeAsNumber;

  const [intervalMaxTime, setIntervalMaxTime] = useState(
    maxRequestTimeAsNumber / SECONDS_PER_MINUTE
  );
  const roundedIntervalMaxTime = Math.floor(intervalMaxTime * 100) / 100;
  const rangeMaxDate = add(new Date(), { years: 1 });
  const isNowTab = toggleChoice === 1;
  const dispatch = useDispatch();
  const intl = useIntl();

  const NOW_TAB_TEXT = localize(
    intl,
    msgs.pam.modalPage.timeAccessDuration.toggleTabNow
  );
  const FUTURE_TAB_TEXT = localize(
    intl,
    msgs.pam.modalPage.timeAccessDuration.toggleTabFuture
  );

  const conflictWarningMessage = (date) =>
    localize(intl, msgs.pam.modalPage.critical.sessionConflictWarning, {
      date,
    });

  const formatCredentialWarningDate = (dateString) => {
    const localTimeOfCredential = new Date(dateString);

    if (
      localTimeOfCredential &&
      !Number.isNaN(localTimeOfCredential.getTime())
    ) {
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      }).format(localTimeOfCredential);
    }

    return 'TIME';
  };

  useEffect(() => {
    // Detect and set accordionValueSnapshot for NOW chip select
    const isNowChipPredifinedChipSelected =
      isNowTab && !isDurationCustom && durationChipValue && !isExpandedDuration;

    if (isNowChipPredifinedChipSelected) {
      setAccordionValueSnapshot({
        isNowTab: true,
        isDurationCustom: false,
        customDurationInputValue: '',
        selectedInterval: INTERVALS.MINUTES,
        futureTabStartDate: null,
        futureTabEndDate: null,
        durationChipValue,
      });

      return;
    }

    // If the user closes the accordion, Reset to the saved state
    if (!isExpandedDuration) {
      // Tab selection
      setToggleChoice(accordionValueSnapshot.isNowTab ? 1 : 2);
      // Custom
      setIsDurationCustom(accordionValueSnapshot.isDurationCustom);
      setCheckedIntervalOption(accordionValueSnapshot.checkedIntervalOption);
      setIntervalOptions((currentOptions) =>
        currentOptions.map((option) => ({
          ...option,
          isChecked:
            option.value === accordionValueSnapshot.checkedIntervalOption,
        }))
      );
      setCustomDurationInputValue(
        accordionValueSnapshot.customDurationInputValue
      );
      // Future
      setFutureTabStartDate(accordionValueSnapshot.futureTabStartDate);
      setFutureTabEndDate(accordionValueSnapshot.futureTabEndDate);
      // Duration
      setDurationChipValue(accordionValueSnapshot.durationChipValue);
    }
  }, [isExpandedDuration]);

  useEffect(() => {
    const formattedDate = formatCredentialWarningDate(
      nowStartDateOfNextCredential
    );

    setFormattedNowWarningDate(formattedDate);
  }, [nowStartDateOfNextCredential]);

  useEffect(() => {
    const formattedDate = formatCredentialWarningDate(
      futureStartDateOfNextCredential
    );

    setFormattedFutureWarningDate(formattedDate);
  }, [futureStartDateOfNextCredential]);

  useEffect(() => {
    setFutureDatesToExclude([]);
    setFutureTimesToExclude([]);

    if (selectedAccountSessions?.length > 0) {
      let fullDaysToExclude = [];
      const intervalsToExclude = [];
      const sessionsWithValidStatusCOC = selectedAccountSessions.filter(
        (session) =>
          (session.sessionStatus !== SESSION_STATUSES.COMPLETED &&
            session.username === userName) || // Filter in matching usernames with any status except 3
          (session.sessionStatus === undefined &&
            session.username === userName) || // Filter in undefined status only for matching username
          (session.sessionStatus !== undefined &&
            session.sessionStatus !== SESSION_STATUSES.COMPLETED) // Keep the original condition for other usernames
      );
      const sessionsWithValidStatusCTS = selectedAccountSessions.filter(
        // UserName is being filtered in The duration service file, so only removing completed sessions here
        (session) => session.sessionStatus !== SESSION_STATUSES.COMPLETED
      );

      const sessionsToBeIterated = isCOCorExclusive
        ? sessionsWithValidStatusCOC
        : sessionsWithValidStatusCTS;

      sessionsToBeIterated.forEach((session) => {
        const startDate = parseISO(session.sessionStartDate);
        const endDate = parseISO(session.sessionEndDate);

        // If the session spans at least a full day (24 hours)
        if (differenceInHours(endDate, startDate) >= HOURS_PER_DAY) {
          // Get an array of full days between the start and end date, excluding the end date
          const days = eachDayOfInterval({
            start: startOfDay(startDate),
            end: addDays(startOfDay(endDate), -1),
          });

          fullDaysToExclude = fullDaysToExclude.concat(days);
        }
        // If the session is more than three days but less than a full day in hours
        else if (differenceInHours(endDate, startDate) > HOURS_IN_THREE_DAYS) {
          const days = eachDayOfInterval({
            // Start from the day after the start date and day before the end date to avoid lossing segments of times not selected
            start: addDays(startOfDay(startDate), 1),
            end: addDays(startOfDay(endDate), -1),
          });

          fullDaysToExclude = fullDaysToExclude.concat(days);
        } else {
          let currentTime = startDate;

          // Round start time down to the nearest interval
          currentTime = setMinutes(
            currentTime,
            Math.floor(currentTime.getMinutes() / INTERVAL_LENGTH_IN_MINS) *
              INTERVAL_LENGTH_IN_MINS
          );
          // Set seconds to 0 for clean intervals
          currentTime = setSeconds(currentTime, 0);

          while (differenceInMinutes(endDate, currentTime) >= 0) {
            // Add the current time to the intervals to exclude
            intervalsToExclude.push(new Date(currentTime));
            // Increment the current time by the interval length
            currentTime = addMinutes(currentTime, INTERVAL_LENGTH_IN_MINS);
          }
        }
      });

      setFutureDatesToExclude(fullDaysToExclude);
      setFutureTimesToExclude([...new Set(intervalsToExclude)]);
    }
  }, [selectedAccountSessions]);

  useEffect(() => {
    setIsNowBlocked(false);
    setToggleChoice(1);
    setDurationAccordionValue('');
    dispatch(handleClearCredentialFormDates('Date Range reset success'));
    const isAccountBasedMaxTimeDay =
      accountSelection?.maxtime < SECONDS_PER_DAY;
    const isJITBasedMaxTimeDay = jitMaxTime < SECONDS_PER_DAY;
    const isMaxTimeLessThanOneDay = isJITSelection
      ? isJITBasedMaxTimeDay
      : isAccountBasedMaxTimeDay;

    if (isMaxTimeLessThanOneDay) {
      // Filter out the 'Days' option
      const updatedIntervalOptions = initialIntervalOptions.filter(
        (option) => option.value !== INTERVALS.DAYS
      );

      setIntervalOptions(updatedIntervalOptions);
    } else {
      setIntervalOptions(initialIntervalOptions);
    }
  }, [accountSelection]);

  useEffect(() => {
    if (isNowBlocked) setToggleChoice(2);
  }, [isNowBlocked]);

  useEffect(() => {
    const isFuturePredefinedChipWithStartDate =
      !isNowTab && nowTabStartDate && durationChipValue && !isDurationCustom;
    const isNowWithStartDate =
      isNowTab && nowTabStartDate && durationChipValue && !isDurationCustom;
    const isCustomNowWithStartDate =
      isNowTab && nowTabStartDate && durationChipValue && isDurationCustom;

    const shouldSetEndDate =
      (isFuturePredefinedChipWithStartDate ||
        isNowWithStartDate ||
        isCustomNowWithStartDate) &&
      nowTabStartDate &&
      durationChipValue;

    if (shouldSetEndDate) {
      setNowTabEndDate(addSeconds(nowTabStartDate, durationChipValue));
    }
  }, [nowTabStartDate, durationChipValue]);

  // Set the StartDate based on the context
  useEffect(() => {
    if (isNowTab) {
      // NOW needs to start at the current user time in their timezone
      setNowTabStartDate(getCurrentDate());
    }
  }, [isNowTab, durationChipValue]);

  // Duration Blocking and Warning Effect
  useEffect(() => {
    // Reset before eash calclation
    setIsTimeBlocked(false);
    setIsScheduleConflictWarning(false);

    // NOW blocking conditions
    if (isNowTab && nowStartDateOfNextCredential) {
      const nowStartDateOfNextCredentialObj = new Date(
        nowStartDateOfNextCredential
      );
      const nowStartDateObj = new Date(nowTabStartDate);
      const nowEndDateObj = new Date(nowTabEndDate);
      const nowEndDateWithAddedDuration = addSeconds(
        nowStartDateObj,
        durationChipValue
      );
      const checkNowBlockerWhenCustom =
        nowEndDateWithAddedDuration >= nowStartDateOfNextCredentialObj;

      const isBlocked = Boolean(
        !isDurationCustom
          ? nowStartDateOfNextCredential &&
              nowTabEndDate &&
              nowEndDateObj >= nowStartDateOfNextCredentialObj
          : checkNowBlockerWhenCustom
      );

      setIsTimeBlocked(isBlocked);
      setIsScheduleConflictWarning(isBlocked);
    } else if (!isNowTab && futureStartDateOfNextCredential) {
      // FUTURE blocking conditions
      const futureStartDateObj = new Date(futureTabStartDate);
      const futureEndDateObj = new Date(futureTabEndDate);
      const futureStartDateOfNextCredentialObj = new Date(
        futureStartDateOfNextCredential
      );

      const futureEndDateWithAddedDuration = addSeconds(
        futureStartDateObj,
        durationInSeconds
      );

      const hasFutureStartDateAndNextCred =
        futureTabStartDate && futureStartDateOfNextCredential;

      const checkFutureBlocker =
        hasFutureStartDateAndNextCred &&
        futureEndDateObj >= futureStartDateOfNextCredentialObj;

      const checkFutureBlockerWhenCustom =
        hasFutureStartDateAndNextCred &&
        futureEndDateWithAddedDuration >= futureStartDateOfNextCredentialObj;

      const isBlocked = Boolean(
        isDurationCustom ? checkFutureBlockerWhenCustom : checkFutureBlocker
      );

      setIsTimeBlocked(isBlocked);
      setIsScheduleConflictWarning(isBlocked);
    }

    // TAB blocking conditions
    if (nowStartDateOfNextCredential) {
      const nowPlusFiveMinutes = addMinutes(new Date(), 5);
      const isNowPlusFiveBlocked = isBefore(
        nowPlusFiveMinutes,
        new Date(nowStartDateOfNextCredential)
      );

      setIsNowBlocked(!isNowPlusFiveBlocked);
    } else {
      setIsNowBlocked(false);
    }
  }, [
    isNowTab,
    isDurationCustom,
    durationChipValue,
    nowTabStartDate,
    nowStartDateOfNextCredential,
    futureTabStartDate,
    futureTabEndDate,
    futureStartDateOfNextCredential,
  ]);

  useEffect(() => {
    setCustomInputValueAsNumber(parseFloat(customDurationInputValue));
  }, [customDurationInputValue]);

  useEffect(() => {
    const isCustomUnderFiveMin = durationInSeconds < SECONDS_IN_FIVE_MINUTES;
    const isValueOutOfRange = Boolean(
      customDurationInputValue &&
        (isCustomUnderFiveMin ||
          customInputValueAsNumber > roundedIntervalMaxTime)
    );

    setIsCustomOutOfRange(isValueOutOfRange);
  }, [
    accountSelection,
    customInputValueAsNumber,
    durationInSeconds,
    customDurationInputValue,
  ]);

  useEffect(() => {
    if (durationInSeconds <= maxRequestTimeAsNumber) {
      setDurationChipValue(durationInSeconds);
    } else {
      setDurationChipValue(maxRequestTimeAsNumber);
    }
  }, [durationInSeconds]);

  useEffect(() => {
    if (isDurationCustom && customDurationInputValue) {
      switch (checkedIntervalOption) {
        case INTERVALS.MINUTES:
          setDurationInSeconds(customInputValueAsNumber * SECONDS_PER_MINUTE);
          setIntervalMaxTime(maxRequestTimeAsNumber / SECONDS_PER_MINUTE);
          break;
        case INTERVALS.HOURS:
          setDurationInSeconds(customInputValueAsNumber * SECONDS_PER_HOUR);
          setIntervalMaxTime(maxRequestTimeAsNumber / SECONDS_PER_HOUR);
          break;
        case INTERVALS.DAYS:
          setDurationInSeconds(customInputValueAsNumber * SECONDS_PER_DAY);
          setIntervalMaxTime(maxRequestTimeAsNumber / SECONDS_PER_DAY);
          break;
        default:
          setDurationInSeconds(0);
      }
    }
  }, [customInputValueAsNumber, checkedIntervalOption]);

  const onWarningClose = () => {
    setIsScheduleConflictWarning(false);
  };

  return (
    <div className='MpaTimeAccessDuration'>
      <InlineMessage
        kind='background'
        text={conflictWarningMessage(
          isNowTab ? formattedNowWarningDate : formattedFutureWarningDate
        )}
        colorTheme='warning'
        leftIcon={<Icon kind='Warning' />}
        isVisible={isScheduleConflictWarning || isTimeBlocked}
        onDismiss={onWarningClose}
        linkComponent={
          <ViewScheduleLink
            setIsScheduleViewOpen={setIsScheduleViewOpen}
            kind='warning'
          />
        }
      />
      <ToggleButtonGroup
        selection={toggleChoice}
        setSelection={setToggleChoice}>
        <ToggleButton label={NOW_TAB_TEXT} isDisabled={isNowBlocked} />
        <ToggleButton label={FUTURE_TAB_TEXT} />
      </ToggleButtonGroup>
      <ByTypeDateAndTimePicker
        type='start'
        isNowTab={isNowTab}
        futureTabStartDate={futureTabStartDate}
        setFutureTabStartDate={setFutureTabStartDate}
        futureTabEndDate={futureTabEndDate}
        setFutureTabEndDate={setFutureTabEndDate}
        isDurationCustom={isDurationCustom}
        isExpandedDuration={isExpandedDuration}
        durationChipValue={durationChipValue}
        rangeMaxDate={rangeMaxDate}
        futureDatesToExclude={futureDatesToExclude}
        futureTimesToExclude={futureTimesToExclude}
        accordionValueSnapshot={accordionValueSnapshot}
      />
      <DurationChips
        isNowTab={isNowTab}
        maxRequestTimeAsNumber={maxRequestTimeAsNumber}
        nowTabStartDate={nowTabStartDate}
        setNowTabStartDate={setNowTabStartDate}
        setNowTabEndDate={setNowTabEndDate}
        futureTabStartDate={futureTabStartDate}
        setFutureTabEndDate={setFutureTabEndDate}
        isDurationCustom={isDurationCustom}
        setIsDurationCustom={setIsDurationCustom}
        durationChipValue={durationChipValue}
        setDurationChipValue={setDurationChipValue}
        setShouldDurationContinue={setShouldDurationContinue}
        nowStartDateOfNextCredential={nowStartDateOfNextCredential}
        futureStartDateOfNextCredential={futureStartDateOfNextCredential}
        setHasDurationBeenFilled={setHasDurationBeenFilled}
        accountSelection={accountSelection}
        setCustomDurationInputValue={setCustomDurationInputValue}
      />
      <CustomDuration
        isNowTab={isNowTab}
        isDurationCustom={isDurationCustom}
        customDurationInputValue={customDurationInputValue}
        setCustomDurationInputValue={setCustomDurationInputValue}
        durationChipValue={durationChipValue}
        checkedIntervalOption={checkedIntervalOption}
        setCheckedIntervalOption={setCheckedIntervalOption}
        intervalOptions={intervalOptions}
        setIntervalOptions={setIntervalOptions}
        maxRequestTimeAsNumber={maxRequestTimeAsNumber}
        rangeMaxDate={rangeMaxDate}
        futureTabStartDate={futureTabStartDate}
        setFutureTabStartDate={setFutureTabStartDate}
        futureTabEndDate={futureTabEndDate}
        setFutureTabEndDate={setFutureTabEndDate}
        isCustomOutOfRange={isCustomOutOfRange}
        isExpandedDuration={isExpandedDuration}
      />
      <DurationHandlerButtons
        isNowTab={isNowTab}
        isDurationCustom={isDurationCustom}
        isTimeBlocked={isTimeBlocked}
        isScheduleConflictWarning={isScheduleConflictWarning}
        isCustomOutOfRange={isCustomOutOfRange}
        customDurationInputValue={customDurationInputValue}
        durationChipValue={durationChipValue}
        hasDurationBeenFilled={hasDurationBeenFilled}
        futureTabStartDate={futureTabStartDate}
        futureTabEndDate={futureTabEndDate}
        maxRequestTimeAsNumber={maxRequestTimeAsNumber}
        checkedIntervalOption={checkedIntervalOption}
        setNowTabStartDate={setNowTabStartDate}
        setNowTabEndDate={setNowTabEndDate}
        setFutureTabEndDate={setFutureTabEndDate}
        setDurationChipValue={setDurationChipValue}
        setShouldDurationContinue={setShouldDurationContinue}
        setHasDurationBeenFilled={setHasDurationBeenFilled}
        isExpandedDuration={isExpandedDuration}
        setIsExpandedDuration={setIsExpandedDuration}
        isDurationBeingEditedCritical={isDurationBeingEditedCritical}
        accordionValueSnapshot={accordionValueSnapshot}
        setAccordionValueSnapshot={setAccordionValueSnapshot}
      />
    </div>
  );
}

MpaTimeAccessDuration.propTypes = {
  userName: PropTypes.string.isRequired,
  toggleChoice: PropTypes.number.isRequired,
  setToggleChoice: PropTypes.func.isRequired,
  setShouldDurationContinue: PropTypes.func.isRequired,
  jitMaxTime: PropTypes.string.isRequired,
  nowStartDateOfNextCredential: PropTypes.string,
  futureStartDateOfNextCredential: PropTypes.string,
  nowTabStartDate: PropTypes.instanceOf(Date),
  nowTabEndDate: PropTypes.instanceOf(Date),
  setNowTabStartDate: PropTypes.func.isRequired,
  setNowTabEndDate: PropTypes.func.isRequired,
  futureTabStartDate: PropTypes.instanceOf(Date),
  futureTabEndDate: PropTypes.instanceOf(Date),
  setFutureTabStartDate: PropTypes.func.isRequired,
  setFutureTabEndDate: PropTypes.func.isRequired,
  isTimeBlocked: PropTypes.bool.isRequired,
  setIsTimeBlocked: PropTypes.func.isRequired,
  setIsScheduleViewOpen: PropTypes.func.isRequired,
  accountSelection: CredentialRequestAccountSelection,
  setDurationAccordionValue: PropTypes.func.isRequired,
  isDurationCustom: PropTypes.bool.isRequired,
  setIsDurationCustom: PropTypes.func.isRequired,
  hasDurationBeenFilled: PropTypes.bool.isRequired,
  setHasDurationBeenFilled: PropTypes.func.isRequired,
  selectedAccountSessions: PropTypes.arrayOf(Session),
  isCOCorExclusive: PropTypes.bool.isRequired,
  isExpandedDuration: PropTypes.bool.isRequired,
  setIsExpandedDuration: PropTypes.func.isRequired,
  isDurationBeingEditedCritical: PropTypes.bool.isRequired,
};

MpaTimeAccessDuration.defaultProps = {
  nowStartDateOfNextCredential: null,
  futureStartDateOfNextCredential: null,
  nowTabStartDate: null,
  nowTabEndDate: null,
  futureTabStartDate: null,
  futureTabEndDate: null,
  accountSelection: null,
  selectedAccountSessions: [],
};

export default React.memo(MpaTimeAccessDuration);
