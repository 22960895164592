import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useIntl } from 'react-intl';
import { getDateNextYear, localize } from '@saviynt/common';
import classnames from 'classnames';
import { setHours, setMinutes } from 'date-fns';
import PropTypes from 'prop-types';

import ButtonCore from '../Button/ButtonCore/ButtonCore';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';

import 'react-datepicker/dist/react-datepicker.css';
import './DateAndTimePicker.css';

const msgs = {
  neo: {
    dateTimePicker: {
      invalidDateType: {
        id: 'neo.dateTimePicker.invalidDateType',
        defaultMessage: 'Error: minDate and maxDate must be of type Date',
      },
    },
  },
};

const SIZES = {
  small: 'small',
  large: 'large',
};

const KINDS = {
  dateStart: 'dateStart',
  dateEnd: 'dateEnd',
  timeStart: 'timeStart',
  timeEnd: 'timeEnd',
  dateAndTimeStart: 'dateAndTimeStart',
  dateAndTimeEnd: 'dateAndTimeEnd',
};

const startKinds = ['dateStart', 'timeStart', 'dateAndTimeStart'];
const endKinds = ['dateEnd', 'timeEnd', 'dateAndTimeEnd'];
const dateKinds = ['dateEnd', 'dateStart'];
const timeKinds = ['timeStart', 'timeEnd'];
const dateAndTimeKinds = ['dateAndTimeStart', 'dateAndTimeEnd'];

const DATES_FORMAT = {
  MDY: 'MM/dd/yyyy',
  HM: 'h:mm aa',
  HMS: 'h:mm:ss aa',
  MDYHM: 'MM/dd/yyyy, h:mm aa',
};

/**
 * #### WIP TODO:
 * - Replace encode SVG in css files with ButtonIcon comp from DSL.
 */

function DateAndTimePicker({
  kind,
  size,
  label,
  isRequired,
  isCritical,
  setIsCritical,
  isDisabled,
  isReadOnly,
  CriticalHelperText,
  HelperText,
  backgroundColor,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  filterTime,
  maxDate,
  minDate,
  dateFormat,
  timeIntervals,
  timeHeaderText,
  timeMin,
  timeMax,
  excludeDates,
  excludeTimes,
}) {
  const startDatePickerRef = useRef(null);
  const endDatePickerRef = useRef(null);
  const [isUsingMouse, setIsUsingMouse] = useState(false);
  const intl = useIntl();
  const INVALID_DATE_TYPE_ERROR = localize(
    intl,
    msgs.neo.dateTimePicker.invalidDateType
  );

  const handleMouseDown = () => {
    setIsUsingMouse(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      setIsUsingMouse(false);
    }
  };

  const addEventListeners = () => {
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('keydown', handleKeyDown);
  };

  const removeEventListeners = () => {
    document.removeEventListener('mousedown', handleMouseDown);
    document.removeEventListener('keydown', handleKeyDown);
  };

  useEffect(() => {
    addEventListeners();

    return removeEventListeners;
  }, []);

  // This changes the close button rendered tabIndex to 0
  useEffect(() => {
    const clearButtons = document.querySelectorAll(
      '.react-datepicker__input-container .react-datepicker__close-icon'
    );

    clearButtons.forEach((button) => {
      const currentButton = button;

      currentButton.tabIndex = 0;
    });
  });

  // This changes the close button rendered tabIndex to 0
  useEffect(() => {
    const clearButtons = document.querySelectorAll(
      '.react-datepicker__input-container .react-datepicker__close-icon'
    );

    clearButtons.forEach((button) => {
      const currentButton = button;

      currentButton.tabIndex = 0;
    });
  });

  const popperOptionsArray = [
    // TODO: New ticket to handle the popper position
    {
      name: 'offset',
      options: { offset: [100, 0] },
    },
    {
      name: 'preventOverflow',
      options: {
        boundary: 'viewport',
        altAxis: true,
        padding: 10,
      },
    },
  ];

  const pickerContainerClass = classnames(
    'DateAndTimeInputField-inputContainer',
    isDisabled && 'DateAndTimeInputField-inputContainer--isDisabled',
    isReadOnly && 'DateAndTimeInputField-inputContainer--isReadOnly'
  );

  const pickerClass = classnames(
    'DatePicker-input',
    `DatePicker-input--${size}`,
    `DatePicker-input--${backgroundColor}`,
    `DatePicker-input-${kind}`,
    isDisabled && `DatePicker-input--isDisabled`,
    isCritical && `DatePicker-input--isCritical`,
    isReadOnly && `DatePicker-input--isReadOnly`,
    isUsingMouse && 'DatePicker-input-noOutline'
  );

  const suffixButtonClass = classnames(
    'DateAndTimeInputField-suffixButton',
    isRequired &&
      isCritical &&
      'DateAndTimeInputField-suffixButton--isCritical',
    isReadOnly && `DateAndTimeInputField-suffixButton--isReadOnly`,
    isUsingMouse && 'DateAndTimeInputField-noOutline'
  );

  if (
    (minDate && !(minDate instanceof Date)) ||
    (maxDate && !(maxDate instanceof Date))
  ) {
    return (
      <p className='DateAndTimeInputField-errorMessage'>
        {INVALID_DATE_TYPE_ERROR}
      </p>
    );
  }

  const getSuffixButtonIcon = () => {
    switch (kind) {
      case 'dateStart':
      case 'dateEnd':
      case 'dateAndTimeStart':
      case 'dateAndTimeEnd':
        return (
          <Icon
            className='DateAndTimeInputField-suffixButton-icon'
            kind='calendar'
            color='navy-700'
            size='smallMedium'
          />
        );
      case 'timeStart':
      case 'timeEnd':
        return (
          <Icon
            className='DateAndTimeInputField-suffixButton-icon'
            kind='Time'
            color='navy-700'
            size='smallMedium'
          />
        );
      default:
        return null;
    }
  };

  const suffixButtonClick = () => {
    if (startDatePickerRef.current) {
      startDatePickerRef.current.input.click();
    }

    if (endDatePickerRef.current) {
      endDatePickerRef.current.input.click();
    }
  };

  // Variables to build a Picker
  // Picker Ref
  const pickerRef = () => {
    switch (kind) {
      case 'dateStart':
      case 'timeStart':
      case 'dateAndTimeStart':
        return startDatePickerRef;
      case 'dateEnd':
      case 'timeEnd':
      case 'dateAndTimeEnd':
        return endDatePickerRef;
      default:
        return null;
    }
  };

  // Kind Date or Time month toggle
  // TODO: try to use the top const vars here, mayber chagne to is...Date
  const isKindDate = () =>
    kind === 'dateStart' ||
    kind === 'dateEnd' ||
    kind === 'dateAndTimeStart' ||
    kind === 'dateAndTimeEnd';

  // PlaceHolder
  const placeHolderKind = () => {
    switch (kind) {
      case 'dateStart':
      case 'dateEnd':
        return 'MM/DD/YYYY';

      case 'dateAndTimeStart':
      case 'dateAndTimeEnd':
        return 'MM/DD/YYYY, HH:MM';

      case 'timeStart':
      case 'timeEnd':
        return dateFormat === 'h:mm aa' ? 'HH : MM' : 'HH : MM : SS';

      default:
        return null;
    }
  };

  // Start or End Variables
  const pickerStartOrEnd = (toggle) => {
    if (toggle === 'start' && startKinds.includes(kind)) {
      return true;
    }

    if (toggle === 'end' && endKinds.includes(kind)) {
      return true;
    }

    return false;
  };

  const valueSelectKind = () => {
    if (startKinds.includes(kind)) {
      return startDate;
    }

    if (endKinds.includes(kind)) {
      return endDate;
    }

    return null;
  };

  const onChangeKind = (date) => {
    if (startKinds.includes(kind)) {
      return setStartDate(date);
    }

    if (endKinds.includes(kind)) {
      if (setIsCritical) setIsCritical(false);

      return setEndDate(date);
    }

    return null;
  };

  const getTimeSelect = (timeSelector) => {
    if (dateKinds.includes(kind)) return false;

    if (
      timeSelector === 1 &&
      (timeKinds.includes(kind) || dateAndTimeKinds.includes(kind))
    ) {
      return true;
    }

    if (timeSelector === 2 && timeKinds.includes(kind)) {
      return true;
    }

    return false;
  };

  // Date formating functions
  const getMinTimeForEndTime = (startDateProp) => {
    if (!startDate) return null;
    const selectedDate = new Date(startDateProp);

    const hours = selectedDate.getHours();
    const minutes = selectedDate.getMinutes();
    const newDate = setHours(setMinutes(new Date(), minutes), hours);

    return newDate;
  };

  const getMaxTimeForStartTime = (endDateProp) => {
    if (!endDate) return null;
    const selectedDate = new Date(endDateProp);

    const hours = selectedDate.getHours();
    const minutes = selectedDate.getMinutes();
    const newDate = setHours(setMinutes(new Date(), minutes), hours);

    return newDate;
  };

  const handleMaxDate = () => {
    if (timeKinds.includes(kind)) return null;
    const nextYearDate = getDateNextYear();

    if (endKinds.includes(kind)) {
      return maxDate || nextYearDate;
    }

    if (startKinds.includes(kind)) {
      return endDate || maxDate || nextYearDate;
    }

    return nextYearDate;
  };

  const handleMinDate = () => {
    if (timeKinds.includes(kind)) return null;

    if (startKinds.includes(kind)) {
      return minDate || new Date();
    }

    if (endKinds.includes(kind)) {
      return startDate || minDate || new Date();
    }

    return new Date();
  };

  const handleMaxTime = () => {
    if (dateKinds.includes(kind)) return null;

    if (endKinds.includes(kind)) {
      return setHours(setMinutes(new Date(), timeMax.minutes), timeMax.hours);
    }

    if (startKinds.includes(kind)) {
      return (
        getMaxTimeForStartTime(endDate) ||
        setHours(setMinutes(new Date(), timeMax.minutes), timeMax.hours)
      );
    }

    return new Date();
  };

  const handleMinTime = () => {
    if (dateKinds.includes(kind)) return null;

    if (startKinds.includes(kind)) {
      return setHours(setMinutes(new Date(), timeMin.minutes), timeMin.hours);
    }

    if (endKinds.includes(kind)) {
      return (
        getMinTimeForEndTime(startDate) ||
        setHours(setMinutes(new Date(), timeMin.minutes), timeMin.hours)
      );
    }

    return new Date();
  };

  return (
    <div className='DateAndTimeInputField-container'>
      {/* Label Container */}
      <div className='DateAndTimeInputField-labelContainer'>
        {/* isRequired */}
        {isRequired && (
          <div className='DateAndTimeInputField--isRequired'>
            {!startDate && (
              <div
                className={`DateAndTimeInputField--isRequired--${
                  isCritical ? 'isCritical' : 'isWarning'
                }`}>
                *
              </div>
            )}
          </div>
        )}
        <Typography kind='label' htmlFor={label}>
          {label}
        </Typography>
      </div>
      <div className={pickerContainerClass}>
        {/* Single / Start Range Picker */}
        <DatePicker
          tabIndex={0}
          ref={pickerRef()}
          showMonthYearDropdown={isKindDate()}
          showDisabledMonthNavigation={isKindDate()}
          placeholderText={placeHolderKind()}
          className={pickerClass}
          calendarClassName='DatePicker-calendar'
          selectsStart={pickerStartOrEnd('start')}
          selectsEnd={pickerStartOrEnd('end')}
          selected={valueSelectKind()}
          isClearable={!!valueSelectKind()}
          onChange={(date) => onChangeKind(date)}
          showTimeSelect={getTimeSelect(1)}
          showTimeSelectOnly={getTimeSelect(2)}
          startDate={startDate}
          endDate={endDate}
          filterTime={filterTime}
          timeIntervals={timeIntervals}
          timeCaption={timeHeaderText}
          dateFormat={dateFormat}
          maxDate={handleMaxDate()}
          minDate={handleMinDate()}
          maxTime={handleMaxTime()}
          minTime={handleMinTime()}
          excludeDates={excludeDates}
          excludeTimes={excludeTimes}
          readOnly={isReadOnly}
          // Popper
          wrapperClassName='DatePicker-wrapper'
          showPopperArrow={false}
          popperModifiers={popperOptionsArray}
        />
        <ButtonCore
          type='button'
          tabIndex={0}
          onClick={() => suffixButtonClick()}
          isDisabled={isDisabled}
          className={suffixButtonClass}>
          {getSuffixButtonIcon()}
          <span
            aria-hidden='true'
            className={classnames(
              'DateAndTimeInputField-suffixButton-overlay',
              isDisabled &&
                'DateAndTimeInputField-suffixButton-overlay--isDisabled'
            )}
          />
        </ButtonCore>
      </div>
      {HelperText || (isCritical && CriticalHelperText)}
    </div>
  );
}

DateAndTimePicker.defaultProps = {
  kind: KINDS.dateStart,
  size: SIZES.large,
  label: null,
  isDisabled: false,
  isRequired: false,
  isCritical: false,
  setIsCritical: () => {},
  isReadOnly: false,
  CriticalHelperText: null,
  HelperText: null,
  backgroundColor: 'neutral',
  startDate: null,
  setStartDate: null,
  endDate: null,
  setEndDate: null,
  filterTime: null,
  dateFormat: DATES_FORMAT.MDY,
  timeIntervals: 15,
  timeHeaderText: 'Time',
  timeMin: { minutes: 0, hours: 0 },
  timeMax: { minutes: 0, hours: 23 },
  excludeDates: null,
  excludeTimes: null,
};

DateAndTimePicker.propTypes = {
  kind: PropTypes.oneOf(Object.values(KINDS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isCritical: PropTypes.bool,
  setIsCritical: PropTypes.func,
  isReadOnly: PropTypes.bool,
  CriticalHelperText: PropTypes.node,
  HelperText: PropTypes.node,
  backgroundColor: PropTypes.oneOf(['neutral', 'secondary']),
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func,
  endDate: PropTypes.instanceOf(Date),
  setEndDate: PropTypes.func,
  filterTime: PropTypes.func,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  dateFormat: PropTypes.oneOf(Object.values(DATES_FORMAT)),
  timeIntervals: PropTypes.number,
  timeHeaderText: PropTypes.string,
  // Minutes: 0-59 Hours: 0-23
  timeMin: PropTypes.shape({
    minutes: PropTypes.number,
    hours: PropTypes.number,
  }),
  // Minutes: 0-59 Hours: 0-23
  timeMax: PropTypes.shape({
    minutes: PropTypes.number,
    hours: PropTypes.number,
  }),
  excludeDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  excludeTimes: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
};

export default DateAndTimePicker;
