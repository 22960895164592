import { addMinutes, addSeconds, subMinutes } from 'date-fns';

import { periodToLowerCase } from './time';

/**
 * A utility function to format a date string to follow the following format:
 * MM/DD/YYYY, HH:MM[am/pm] [3 letter Timezone]
 *
 *
 * @function
 * @param date {string} - The string date that should be formatted.
 * @param shouldIncludeYear {boolean} - boolean denoting whether to include the year in the outputted date string
 * @param shouldIncludeTimeZone {boolean} - boolean denoting whether to include the time zone in the outputted date string
 * @returns {string} A formatted localized date string.
 *
 * @example
 * 07/24/2023, 11:00pm PST
 */
const formatLocalizedDateTime = (
  date,
  shouldIncludeYear = true,
  shouldIncludeTimeZone = true
) => {
  const options = {
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
  };

  if (shouldIncludeYear) options.year = 'numeric';
  if (shouldIncludeTimeZone) options.timeZoneName = 'short';

  return periodToLowerCase(new Date(date).toLocaleString('en-US', options));
};

const getDateNextYear = () => {
  const now = new Date();

  return new Date(now.setFullYear(now.getFullYear() + 1));
};

/**
 * A utility function for the scheduler component to format a date string to follow the following format:
 * Weekday, Month DD, YYYY
 *
 *
 * @function
 * @param date {string} - The string date that should be formatted.
 * @returns {string} A formatted localized date string for the scheduler component
 *
 * @example
 * Monday, November 13, 2023
 */
const formatSchedulerLocalizedDateString = (date) => {
  const options = {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  };

  return new Date(date).toLocaleString('en-US', options);
};

const getCurrentDate = (timeInSecs) => {
  const currentDateUTC = new Date();

  if (timeInSecs) return addSeconds(currentDateUTC, timeInSecs);

  return currentDateUTC;
};

/**
 * A utility function to adjust a date time by a specified number of minutes
 *
 *
 * @function
 * @param dateTime {string} a string date time to adjust
 * @param addOrSubtract {string} the adjustment action (add or subtract)
 * @param numMinutes {number} the number of minutes to adjust the date by
 *
 * @returns {Date} An updated adjusted date object
 */
const addOrSubtractMinutesFromDateTime = (
  dateTime,
  addOrSubtract,
  numMinutes
) => {
  let date = new Date(dateTime);

  if (addOrSubtract === 'add') {
    date = addMinutes(date, numMinutes);
  }

  if (addOrSubtract === 'subtract') {
    date = subMinutes(date, numMinutes);
  }

  return date;
};

export {
  addOrSubtractMinutesFromDateTime,
  formatLocalizedDateTime,
  formatSchedulerLocalizedDateString,
  getCurrentDate,
  getDateNextYear,
};
