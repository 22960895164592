import React, { useState } from 'react';

import Checkbox from '../components/Checkbox/Checkbox';

function useCheckboxGroup(checkboxConfigs) {
  const [checkboxStates, setCheckboxStates] = useState(checkboxConfigs);

  const handleChange = (event) => {
    const { name, checked } = event.target;

    setCheckboxStates((prevState) =>
      prevState.map((checkbox) =>
        checkbox.name === name ? { ...checkbox, isChecked: checked } : checkbox
      )
    );
  };

  const checkAllCheckboxes = () => {
    setCheckboxStates((prevState) =>
      prevState.map((checkbox) => ({ ...checkbox, isChecked: true }))
    );
  };

  const uncheckAllCheckboxes = () => {
    setCheckboxStates((prevState) =>
      prevState.map((checkbox) => ({ ...checkbox, isChecked: false }))
    );
  };

  const getCheckedNames = () =>
    checkboxStates
      .filter((checkbox) => checkbox.isChecked)
      .map((checkbox) => checkbox.name);

  const setCheckedFromArrayOfNames = (namesArray) =>
    setCheckboxStates((prevState) =>
      prevState.map((checkbox) => ({
        ...checkbox,
        isChecked: namesArray.includes(checkbox.name),
      }))
    );

  const checkboxes = checkboxStates.map((checkbox) => (
    <Checkbox
      key={`Checkbox-${checkbox.name}`}
      name={checkbox.name}
      dataTestId={checkbox.name}
      isChecked={checkbox.isChecked}
      onChange={handleChange}
      label={checkbox.label}
      title={checkbox?.title || null}
      isDisabled={checkbox.isDisabled}
      kind={checkbox?.kind || 'text'}
      size={checkbox?.size || 'medium'}
      showHoverLabel={checkbox?.showHoverLabel || false}
    />
  ));

  return {
    checkboxes,
    checkboxStates,
    checkAllCheckboxes,
    uncheckAllCheckboxes,
    getCheckedNames,
    setCheckedFromArrayOfNames,
  };
}

export { useCheckboxGroup };
