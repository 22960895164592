import React from 'react';
import PropTypes from 'prop-types';

import Category from '../ListRowHelpers/Category/Category';
import Description from '../ListRowHelpers/Description/Description';
import Title from '../ListRowHelpers/Title/Title';

import './ListRowAccount.css';

function ListRowAccount({ category, title, description }) {
  return (
    <div className='ListRowAccount-data'>
      <div className='ListRowAccount-data-category'>
        {category && <Category data={category} />}
      </div>
      <div className='ListRowAccount-data-title'>
        {title.length > 0 && <Title data={title} />}
      </div>
      <div className='ListRowAccount-data-description'>
        {description.length > 0 && <Description data={description} />}
      </div>
    </div>
  );
}

ListRowAccount.propTypes = {
  category: PropTypes.shape({
    account: PropTypes.string,
    icon: PropTypes.node,
    platform: PropTypes.string,
  }),
  title: PropTypes.arrayOf(
    PropTypes.shape({
      kind: PropTypes.oneOf(['text', 'label-value']),
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  description: PropTypes.arrayOf(
    PropTypes.shape({
      kind: PropTypes.oneOf(['icon-text', 'avatar-text', 'label-value', 'text'])
        .isRequired,
      icon: PropTypes.element,
      text: PropTypes.string,
      avatar: PropTypes.element,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

ListRowAccount.defaultProps = {
  category: null,
  title: [],
  description: [],
};

export default ListRowAccount;
