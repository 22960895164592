/**
 * Calculates the time difference between a target date and the current date.
 * @param { number } countDown - Time difference between the target date and the current date in milliseconds.
 * @returns { number[] } An array containing the time left in days, hours, minutes, and seconds.
 */
import { useEffect, useState } from 'react';

const MILLISECONDS_PER_MINUTE = 1000 * 60;
const MILLISECONDS_PER_HOUR = MILLISECONDS_PER_MINUTE * 60;
const MILLISECONDS_PER_DAY = MILLISECONDS_PER_HOUR * 24;

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (MILLISECONDS_PER_DAY));
  const hours = Math.floor(
    (countDown % (MILLISECONDS_PER_DAY)) / (MILLISECONDS_PER_HOUR)
  );
  const minutes = Math.floor((countDown % (MILLISECONDS_PER_HOUR)) / (MILLISECONDS_PER_MINUTE));
  const seconds = Math.floor((countDown % (MILLISECONDS_PER_MINUTE)) / 1000);

  return [+days, +hours, +minutes, +seconds];
};

const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

export { useCountdown };
