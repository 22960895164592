import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { requestVersionList, fetchECMVersion, updateUIVersion } from '../redux/version-actions';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppLoading } from 'ui-components/src';
import packageJson from '../../../../../../../package.json';
import './VersionPage.scss';

const VersionPage = props => {
  const [rows, setRows] = useState([]);
  const { loadingECMVersion, loadingMSVersion} = props;

  useEffect(() => {
    const { requestVersionList, fetchECMVersion, updateUIVersion } = props;
    requestVersionList();
    fetchECMVersion();
    updateUIVersion(packageJson.version);
  }, []);

  useEffect(() => {
    let dataArray = props.versionPageList;
    if (dataArray.length > 0) {
      setRows(dataArray);
    }
  }, [props.versionPageList]);

  const sanitizeData = data => data === 'unknown' ? '-' : data;
  
  return (
    <div className="version-container">
      <TableContainer className="table-container" component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="table-head-row">
              <TableCell className="th-col">Name</TableCell>
              <TableCell className="th-col">Commit Id</TableCell>
              <TableCell className="th-col">Version</TableCell>
              <TableCell className="th-col">Branch</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((data, key) => (
                <TableRow className="table-tr" key={key}>
                  <TableCell>{data.name}</TableCell>
                  <TableCell>{sanitizeData(data.commitId)}</TableCell>
                  <TableCell>{sanitizeData(data.version)}</TableCell>
                  <TableCell>{sanitizeData(data.branch)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className="table-tr">
                <TableCell colSpan={4}>
                  <div className="td-ndf">{props.intl.formatMessage({ id: 'USER.NOT.FOUND' })}</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {loadingECMVersion === true || loadingMSVersion == true ? <AppLoading/> : null}
    </div>  
  );
};
const mapStateToProps = state => ({
  versionPageList: state.versionPage.versionPageList,
  loadingECMVersion: state.versionPage.loadingECMVersion,
  loadingMSVersion: state.versionPage.loadingMSVersion,
});
VersionPage.propTypes = {
  versionPageList: PropTypes.array.isRequired,
};
const mapDispatchToProps = {
  requestVersionList,
  fetchECMVersion,
  updateUIVersion
};

const ConnectedVersionPage = connect(mapStateToProps, mapDispatchToProps)(VersionPage);
ConnectedVersionPage.displayName = 'ConnectedVersionPage';

export default injectIntl(withRouter(ConnectedVersionPage));
