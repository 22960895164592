import React from 'react';
import './IconText.css';
const IconText = ({ text, icon }) => {
  return (
    <span className='IconText'>
      {icon}
      <span className='IconText-text'>{text}</span>
    </span>
  );
};

export default IconText;
