import React from 'react';
import { InSeparator } from '@saviynt/common';
import PropTypes from 'prop-types';

import './Category.css';

function Category({ data }) {
  return (
    <span className='Category-content'>
      {data.icon}
      <span className='Category-text--bold'>Account</span>
      <InSeparator />
      <span className='Category-text--bold'>{data.platform}</span>
    </span>
  );
}

PropTypes.Category = {
  data: PropTypes.shape({
    icon: PropTypes.element,
    account: PropTypes.string,
    platform: PropTypes.string,
  }),
};

PropTypes.defaultProps = { data: null };

export default Category;
